import { faUpload } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { faCheckCircle, faTimesCircle } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useMemo, useCallback } from "react";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import Table from "../UI/components/Table";

import { TableFlowImporter } from "@tableflow/react";
import { Button } from "../UI/components";
import toast, { Toaster } from "react-hot-toast";

export default function DistributorPricing({ distributor, team, user, app_contexts }) {
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const columns = useMemo(() => [
    {
      Header: "Brand",
      accessor: "product.brand.title",
      disableFilters: true,
    },
    {
      Header: "SKU",
      accessor: "product.sku",
      disableFilters: true,
    },
    {
      Header: "Price",
      accessor: "price",
      disableFilters: true,
    },
    {
      Header: "RRP",
      accessor: "product.price",
      disableFilters: true,
    },
    {
      Header: "Margin",
      accessor: "product.margin",
      disableFilters: true,
    },
    {
      Header: "Effective From",
      accessor: "effective_from",
      disableFilters: true,
      Cell: ({ value }) => {
        return new Date(value).toLocaleString();
      },
    },
    {
      Header: "Effective To",
      accessor: "effective_to",
      disableFilters: true,
      Cell: ({ value }) => {
        return new Date(value).toLocaleString();
      },
    },
    {
      Header: "Last Updated",
      accessor: "updated_at",
      disableFilters: true,
      Cell: ({ value }) => {
        return new Date(value).toLocaleString();
      },
    },
  ]);

  const fetchData = useCallback(async ({ pageIndex, pageSize, sortBy, filters, globalFilter }) => {
    let params = {
      page: pageIndex + 1,
      per: pageSize,
    };

    if (sortBy.length > 0) {
      params["sort"] = sortBy[0].id;
      params["sort_dir"] = sortBy[0].desc ? "desc" : "asc";
    }

    if (filters.length > 0) {
      params["filters"] = filters;
    }

    if (globalFilter) {
      params["query"] = globalFilter;
    }

    const requestConfig = {
      responseType: "json",
      headers: {
        ...ReactOnRails.authenticityHeaders(),
        "Content-Type": "application/json", // Add this line
      },
      method: "POST",
      body: JSON.stringify(params), // Add this line
    };
    setLoading(true);

    const result = await fetch("pricing/datatable.json", requestConfig);
    const data = await result.json();
    setData(data.data);

    setPageCount(Math.ceil(data.meta.total_count / 1));
    setTotalCount(data.meta.total_count);

    setLoading(false);
  }, []);

  return (
    <>
      <Header app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8 pb-24 pt-5">
            <div className="flex w-full items-center justify-between">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Pricing</h2>
              <div title="bulk actions">
                <Button label="Import" icon={faUpload} onClick={() => setIsOpen(true)} />
              </div>
            </div>

            <div className="overflow-visible rounded-lg bg-white p-5 shadow">
              <Table
                columns={columns}
                data={data}
                onFetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                totalItems={totalCount}
                initialPageSize={100}
                globalFilterPlaceholder="SKU Search..."
                globaleFilterSize="xs"
                setFilter={fetchData}
              />
            </div>
          </div>
        </div>
      </section>

      <TableFlowImporter
        importerId={"e458ec6c-909b-464f-91e9-c7e7b7958cdc"}
        modalIsOpen={isOpen}
        modalOnCloseTriggered={() => setIsOpen(false)}
        darkMode={false}
        metadata={{
          user_id: user.id,
          team_id: team.id,
          distributor_id: distributor.id,
          import_type: "pricing",
        }}
        onComplete={(data, error) => {
          console.log(data);

          if (error) {
            console.log(error);
            toast.error(error);
          } else {
            setIsOpen(false);
            toast.success(
              "Import successful. You'll receive an email when we've finished processing your data."
            );
          }
        }}
      />

      <Toaster />
    </>
  );
}
