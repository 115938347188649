import React, { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import { far } from "@awesome.me/kit-989a8e6dbe/icons";
import {
  faCheckCircle,
  faChevronLeft,
  faDiamondExclamation,
  faExclamationCircle,
  faSpinner,
  faXmark,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useDndMonitor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import { useShipmentById, useShipmentDeliveryBooking, useShipmentUpdate } from "@/apis/distributor";
import ActionBar from "@/common/components/ActionBar";
import Button from "@/common/components/Button";
import Card from "@/common/components/Card";
import InputField from "@/common/components/InputField";
import MutationButton from "@/common/components/MutationButton";
import PartbotIcon from "@/common/components/PartbotIcon";
import { Spinner } from "@/common/components/Spinner";
import StatusBadge from "@/common/components/StatusBadge";

import { cn } from "../../../../utils/classNames";
import { DimensionsBadge } from "../../components/Shipment/DimensionsBadge";
import { ShipmentParty } from "../../components/Shipment/ShipmentParty";
import { shipmentSchema } from "../../components/Shipment/validationSchemas";
import { WeightBadge } from "../../components/Shipment/WeightBadge";

const mapShipmentData = (data) => {
  const items = data?.units?.map((unit) => ({
    id: unit.id,
    name: unit.label,
    quantity: unit.quantity,
    weight: unit.weight_g / 1000,
    dimensions: {
      length: unit.length_mm / 10,
      width: unit.width_mm / 10,
      height: unit.height_mm / 10,
    },
  }));

  const boxes = data?.cartons?.map((carton) => ({
    id: carton.id,
    dimensions: {
      length: carton.length_mm / 10,
      width: carton.width_mm / 10,
      height: carton.height_mm / 10,
    },
    weight: carton.weight_g / 1000,
    items: carton.carton_items.map((item) => ({
      id: item.shipment_unit_id,
      quantity: item.quantity,
    })),
  }));

  const details = {
    created_at: data?.created_at,
    status: data?.status,
    identifiers: data?.identifiers,
    tracking_reference: data?.tracking_reference,
    distributor_reference: data?.distributor_reference,
    store_reference: data?.store_reference,
    freight_broker_reference: data?.freight_broker_reference,
    stores: data?.stores,
  };

  const formattedFata = {
    details: details,
    items: items,
    sender: data?.sender,
    recipient: data?.recipient,
    cartons: boxes?.map((box) => ({
      id: box.id,
      weight: box.weight,
      length: box.dimensions.length,
      width: box.dimensions.width,
      height: box.dimensions.height,
      items: box.items,
    })),
  };

  return formattedFata;
};

export default function Shipment() {
  const mouseSensor = useSensor(MouseSensor, { activationConstraint: { distance: 5 } });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: { delay: 250, tolerance: 5 },
  });
  const sensors = useSensors(mouseSensor, touchSensor);
  const { shipmentId } = useParams();
  const { data, error, isLoading, queryKey } = useShipmentById(shipmentId);

  if (isLoading)
    return (
      <div className="flex h-96 items-center justify-center">
        <Spinner />
      </div>
    );
  if (error) return <div>Error: {error.message}</div>;

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter}>
      <ShipmentForm initialData={mapShipmentData(data)} queryKey={queryKey} />
    </DndContext>
  );
}

const ShipmentForm = ({ initialData, queryKey }) => {
  console.log("Initial Data", initialData);
  const formMethods = useForm({
    mode: "onBlur",
    resolver: zodResolver(shipmentSchema),
    defaultValues: initialData,
  });

  return (
    <FormProvider {...formMethods}>
      <PlanningContent queryKey={queryKey} />
      <DragOverlayWrapper />
    </FormProvider>
  );
};

const PlanningContent = ({ queryKey }) => {
  const queryClient = useQueryClient();
  const shipmentUpdateMutation = useShipmentUpdate({
    mutation: {
      onSuccess: (data) => {
        console.log("Success", data);
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        console.log("Error", error);
      },
    },
  });
  const bookingMutation = useShipmentDeliveryBooking({
    mutation: {
      onSuccess: (data) => {
        console.log("Success", data);
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        console.log("Error", error);
      },
    },
  });

  const { locationId, shipmentId } = useParams();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    getValues,
    reset,
  } = useFormContext();

  const {
    fields: cartonFields,
    append: appendCarton,
    remove: removeCarton,
    update: updateCarton,
  } = useFieldArray({
    control,
    name: "cartons",
    keyName: "uuid",
  });

  const isFormDirty = !!Object.keys(dirtyFields).length;
  const isFormValid = Object.keys(errors).length === 0;
  const isUpdateable = ["pending", "quoted"].includes(getValues("details.status"));
  const isLocked = !isUpdateable || shipmentUpdateMutation.isLoading || bookingMutation.isLoading;
  const cartons = getValues("cartons");
  const items = getValues("items");

  const isAllItemsPacked = items.every((item) => {
    const quantityPacked = cartons.reduce((acc, box) => {
      const itemInBox = box.items.find((i) => i.id === item.id);
      return acc + (itemInBox ? itemInBox.quantity : 0);
    }, 0);
    return quantityPacked >= item.quantity;
  });

  const moveItem = (fromBoxId, toBoxId, itemId, quantityToMove) => {
    // Perform the original move item logic
    //originalMoveItem(fromBoxId, toBoxId, itemId, quantityToMove);

    if (fromBoxId === toBoxId && fromBoxId !== null) {
      return;
    }

    if (toBoxId === null) {
      // add item to new box
      const values = getValues();
      const updatedCartons = values.cartons.map((carton) => {
        if (carton.id === fromBoxId) {
          carton.items = carton.items.filter((item) => item.id !== itemId);
        }
        return carton;
      });

      appendCarton({
        id: uuidv4(),
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        items: [{ id: itemId, quantity: quantityToMove }],
      });
      updateCarton(updatedCartons);
      return;
    }

    // Update the useFieldArray state
    const values = getValues();

    const updatedCartons = values.cartons.map((carton) => {
      if (carton.id === fromBoxId) {
        carton.items = carton.items.filter((item) => item.id !== itemId);
      }
      if (carton.id === toBoxId) {
        const existingItem = carton.items.find((item) => item.id === itemId);
        if (existingItem) {
          existingItem.quantity += quantityToMove;
        } else {
          carton.items.push({ id: itemId, quantity: quantityToMove });
        }
      }
      return carton;
    });
    updateCarton(updatedCartons);
  };

  const removeBox = (index) => {
    removeCarton(index);
  };

  const removeItem = (boxId, itemId) => {
    const values = getValues();
    const updatedCartons = values.cartons.map((carton) => {
      if (carton.id === boxId) {
        carton.items = carton.items.filter((item) => item.id !== itemId);
      }
      return carton;
    });
    updateCarton(updatedCartons);
  };

  useDndMonitor({
    onDragEnd: ({ active, over }) => {
      if (!active || !over) return;

      const activeData = active.data.current;
      const overData = over.data.current;
      const quantityToMove = activeData.moveableQuantity;

      if (overData.type !== "Box" && overData.type !== "NewBox") {
        return;
      }

      const fromBoxId = activeData.box?.id || null;
      const toBoxId = overData.box?.id || null;

      moveItem(fromBoxId, toBoxId, activeData.item.id, quantityToMove);
    },
  });

  const isLoading = shipmentUpdateMutation.isLoading || bookingMutation.isLoading;

  const onSubmit = (data) => {
    if (isLocked) {
      return;
    }

    const response = shipmentUpdateMutation.mutate(
      {
        id: shipmentId,
        data: {
          shipment: {
            cartons: data.cartons.map((box) => ({
              id: isNaN(box.id) ? null : box.id,
              length_mm: box.length * 10,
              width_mm: box.width * 10,
              height_mm: box.height * 10,
              weight_g: box.weight * 1000,
              carton_items: box.items.map((item) => ({
                shipment_unit_id: item.id,
                quantity: item.quantity,
              })),
            })),
            sender: {
              company: data.sender.company,
              name: data.sender.name,
              email: data.sender.email,
              phone: data.sender.phone,
              address1: data.sender.address1,
              address2: data.sender.address2,
              city: data.sender.city,
              subdivision_code: data.sender.subdivision_code,
              postal_code: data.sender.postal_code,
              country: data.sender.country,
            },
            recipient: {
              company: data.recipient.company,
              name: data.recipient.name,
              email: data.recipient.email,
              phone: data.recipient.phone,
              address1: data.recipient.address1,
              address2: data.recipient.address2,
              city: data.recipient.city,
              subdivision_code: data.recipient.subdivision_code,
              postal_code: data.recipient.postal_code,
              country: data.recipient.country,
            },
          },
        },
      },
      {
        onSuccess: (data) => {
          reset(mapShipmentData(data));
        },
      },
    );
  };

  const handleBooking = () => {
    bookingMutation.mutate({
      id: shipmentId,
    });
  };

  return (
    <>
      <ActionBar>
        <Button onClick={() => navigate(-1)} disabled={isLoading}>
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          Back
        </Button>

        {isAllItemsPacked}

        <div className="flex items-center space-x-2">
          <MutationButton
            mutation={bookingMutation}
            variables={{
              id: shipmentId,
            }}
            onSuccess={(data) => {
              reset(mapShipmentData(data));
            }}
            successMessage="Booking Confirmed"
            errorMessage="Booking Error"
            loadingMessage="Booking..."
            disabled={isLoading || isFormDirty || isLocked || !isAllItemsPacked}
          >
            Confirm Booking
          </MutationButton>

          <Button onClick={handleSubmit(onSubmit)} disabled={isLoading || isLocked}>
            {shipmentUpdateMutation.isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
            ) : shipmentUpdateMutation.isSuccess && !isFormDirty ? (
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2 text-green-500" />
            ) : shipmentUpdateMutation.isError && !isFormDirty ? (
              <FontAwesomeIcon icon={faExclamationCircle} className="mr-2 text-red-500" />
            ) : null}
            {shipmentUpdateMutation.isLoading
              ? "Saving..."
              : shipmentUpdateMutation.isSuccess && !isFormDirty
                ? "Changes Saved"
                : shipmentUpdateMutation.isError && !isFormDirty
                  ? "Error Saving"
                  : "Save Changes"}
            {isFormDirty && !shipmentUpdateMutation.isLoading && (
              <span className="absolute right-0 top-0 -mr-1 -mt-1 flex h-3 w-3">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-rose-400 opacity-75"></span>
                <span className="relative inline-flex h-3 w-3 rounded-full bg-rose-500"></span>
              </span>
            )}
          </Button>
        </div>
      </ActionBar>
      {!isFormValid && <ErrorList errors={errors} />}
      <div className="grid grid-cols-3 gap-4">
        <OrderDetails control={control} />
      </div>
      <div className="grid grid-cols-7 gap-6 2xl:gap-8">
        <ShipmentPlanner
          control={control}
          cartonFields={cartonFields}
          itemFields={getValues("items")}
          removeBox={removeBox}
          removeItem={removeItem}
        />
      </div>
    </>
  );
};

const keyTranslations = {
  recipient: "Recipient",
  email: "Email",
  cartons: "Boxes",
  height: "Height",
  weight: "Weight",
  sender: "Sender",
  city: "City",
};

const translateKey = (key) => keyTranslations[key] || key;

const ErrorList = ({ errors }) => {
  const renderErrors = (errorObj, path = []) => {
    if (errorObj === null) {
      return null;
    }

    return Object.keys(errorObj).map((key) => {
      if (errorObj[key].message) {
        return (
          <div key={key} className="mb-0.5 flex items-center text-xs text-red-600">
            {/* <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> */}
            <span>{errorObj[key].message}</span>
          </div>
        );
      } else if (Array.isArray(errorObj[key])) {
        return (
          <div key={key} className="mb-2">
            <span className="heading-sm mb-1 !text-rose-700">{translateKey(key)}</span>
            {errorObj[key]
              .filter((item) => item)
              .map((item, index) => (
                <div key={index} className="ml-4">
                  <h3 className="heading-sm mb-0.5 !font-semibold !text-rose-600">
                    Number {index + 1}
                  </h3>
                  {renderErrors(item, [...path, translateKey(key), index + 1])}
                </div>
              ))}
          </div>
        );
      } else if (typeof errorObj[key] === "object") {
        return (
          <div key={key} className="mb-2">
            <h2 className="heading-sm mb-1 !text-rose-700">{translateKey(key)}</h2>
            <div className="ml-4">{renderErrors(errorObj[key], [...path, translateKey(key)])}</div>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className="rounded-lg border border-rose-300 bg-rose-50 p-2 shadow shadow-rose-300/20">
      {renderErrors(errors)}
    </div>
  );
};

function formatDate(dateString) {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date string provided");
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  return `${day} ${month} ${year} at ${hours}:${minutes} ${ampm}`;
}

const OrderDetails = ({ control }) => {
  const { getValues } = useFormContext();

  const details = getValues("details");
  const sender = getValues("sender");
  const recipient = getValues("recipient");

  return (
    <>
      <Card className="relative flex items-stretch">
        {/* <h2 className="heading-sm pb-2">Order Details</h2> */}
        <div>
          <div className="flex h-full flex-col justify-between">
            <div className="">
              <div className="flex items-start justify-between">
                <span className="text-lg font-semibold leading-none tracking-wider">
                  {details.distributor_reference}
                </span>
                <StatusBadge status={details.status} className="absolute right-1.5 top-1.5" />
              </div>
              <div className="p-0 leading-none">
                <span className="text-xs text-gray-400">
                  {formatDate(details.created_at)} from{" "}
                  {details.stores.map((store) => store.name).join(", ")}
                </span>
              </div>
            </div>
            <div className="text-sm">
              <div className="flex items-center" title="Partbot Reference">
                <PartbotIcon className="me-1.5 h-3.5 w-4 fill-current text-slate-400" />
                {details.tracking_reference}
              </div>
              <div className="flex items-center" title="Store Reference">
                <FontAwesomeIcon icon={far.faStore} className="me-1.5 h-4 w-4 text-slate-400" />
                {details.store_reference}
              </div>
              <div className="flex items-center" title="Store Reference">
                <FontAwesomeIcon
                  icon={far.faTruckPlane}
                  className="me-1.5 h-4 w-4 text-slate-400"
                />
                {details.freight_broker_reference}
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card>
        <ShipmentParty party={sender} partyType="sender" partyLabel="Sender" control={control} />
      </Card>
      <Card>
        <ShipmentParty
          party={recipient}
          partyType="recipient"
          partyLabel="Recipient"
          control={control}
        />
      </Card>
    </>
  );
};

const ShipmentPlanner = ({ control, cartonFields, itemFields, removeBox, removeItem }) => {
  const { getValues } = useFormContext();

  const items = getValues("items");
  const cartons = getValues("cartons");

  const itemsCount = items.reduce((acc, item) => acc + item.quantity, 0);
  const itemsPackedCount = items.reduce((acc, item) => {
    const quantityPacked = cartons.reduce((acc, box) => {
      const itemInBox = box.items.find((i) => i.id === item.id);
      return acc + (itemInBox ? itemInBox.quantity : 0);
    }, 0);
    return acc + quantityPacked;
  }, 0);

  return (
    <>
      <div className="col-span-2 min-h-96">
        <div className="flex h-full flex-col">
          <h2 className="heading-sm flex items-start justify-between pb-2 pl-2">
            Items to Ship
            {itemsPackedCount === itemsCount && (
              <span className="-mb-1 inline-block rounded bg-green-200 px-2 py-1 text-xs font-semibold capitalize leading-none tracking-normal text-green-900">
                Packed
              </span>
            )}
            {itemsPackedCount !== itemsCount && (
              <span className="-mb-1 inline-block rounded bg-yellow-200 px-2 py-1 text-xs font-semibold capitalize leading-none tracking-normal text-yellow-900">
                {itemsCount - itemsPackedCount} Remaining
              </span>
            )}
          </h2>
          <div className="h-full space-y-2 rounded-lg border border-gray-200 bg-gray-50 p-2 shadow-inner 2xl:p-4">
            {itemFields.map((item) => (
              <SidebarUnitDraggable
                key={`sidebar-item-${item.id}`}
                item={item}
                boxes={cartonFields}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="col-span-5">
        <div className="flex h-full flex-grow flex-col">
          <h2 className="heading-sm pb-2 pl-2">Packing Plan</h2>
          <div className="h-full space-y-4 rounded-lg border border-gray-200 bg-gray-50 p-2 shadow-inner 2xl:p-4">
            <div className="grid grid-cols-2 gap-2 2xl:grid-cols-3 2xl:gap-4">
              {cartonFields.map((field, index) => (
                <Box
                  key={`box:${field.id}`}
                  box={field}
                  control={control}
                  index={index}
                  remove={removeBox}
                  removeItem={removeItem}
                />
              ))}
              <NewBox />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Box = ({ box, control, index, remove, removeItem }) => {
  const { setNodeRef, isOver, active } = useDroppable({
    id: `box:${box.id}`,
    data: { box, type: "Box" },
  });

  const isSameBox = active?.data?.current?.box?.id === box.id;

  // check if active item is already in this box, if so, don't show the overlay
  const activeItem = active?.data?.current?.item;
  const activeItemInBox = box.items.find((item) => item.id === activeItem?.id);

  return (
    <div
      ref={setNodeRef}
      className={cn(
        "flex min-h-72 flex-col rounded-lg border border-orange-500/25 bg-white p-2 shadow-sm",
        isOver && !isSameBox && "ring-2 ring-orange-500/50 ring-offset-2",
      )}
    >
      <div className="mb-2 grid grid-cols-[auto,3fr,auto] items-start gap-2 2xl:mb-4">
        <h3 className="heading-sm col-start-1 !text-orange-900">Box {index + 1}</h3>
        <div className="col-span-3 col-start-1 flex items-center justify-between">
          <div className="flex items-center space-x-1">
            <InputField
              name={`cartons[${index}].length`}
              control={control}
              placeholder="L"
              className="input block !w-14 !pr-2 text-right sm:!text-sm"
            />
            <span className="text-xs text-slate-500">
              <FontAwesomeIcon icon={faXmark} className="h-2.5 w-2.5" />
            </span>
            <InputField
              name={`cartons[${index}].width`}
              control={control}
              placeholder="W"
              className="input block !w-14 !pr-2 text-right sm:!text-sm"
            />
            <span className="text-xs text-slate-500">
              <FontAwesomeIcon icon={faXmark} className="h-2.5 w-2.5" />
            </span>
            <InputField
              name={`cartons[${index}].height`}
              control={control}
              placeholder="height"
              className="input block !w-14 !pr-2 text-right sm:!text-sm"
            />
            <span className="text-xs text-slate-500">cm</span>
          </div>
          <div className="flex items-center space-x-1">
            <InputField
              name={`cartons[${index}].weight`}
              control={control}
              placeholder="weight"
              className="input block !w-14 !pr-2 text-right sm:!text-sm"
            />
            <span className="text-xs text-slate-500">kg</span>
          </div>
        </div>
        <div className="col-start-3 row-start-1 flex items-start">
          <button
            onClick={() => remove(index)}
            className="-my-1 p-0 text-rose-300 hover:text-rose-500"
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      </div>
      <div className="h-full space-y-1.5 rounded-md border border-orange-200 bg-orange-50 bg-none px-2 py-1.5 shadow-inner shadow-orange-100">
        <div className="grid grid-cols-12 items-center gap-y-1.5">
          {box.items.map((item) => (
            <BoxUnit
              key={`box-${box.id}-item-${item.id}`}
              box={box}
              item={item}
              highlight={activeItem?.id === item.id}
              onUnpack={() => removeItem(box.id, item.id)}
            />
          ))}
        </div>
        {isOver && !isSameBox && !activeItemInBox && (
          <div className="w-full">
            <div className="h-[48px] rounded-sm border-2 border-dashed border-orange-500/50 bg-none">
              <div className="flex h-full items-center justify-center">
                <h3 className="heading-sm !text-orange-900/75">Drop item to pack in box</h3>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const NewBox = () => {
  const droppable = useDroppable({
    id: `new-box`,
    data: {
      type: "NewBox",
    },
  });
  return (
    <div
      ref={droppable.setNodeRef}
      className={cn(
        "flex min-h-72 flex-col items-center justify-center rounded-lg border-2 border-dashed border-orange-900/50 bg-none p-4",
        droppable.isOver && "border-orange-500/50 ring-2 ring-orange-500/50 ring-offset-2",
      )}
    >
      <div className="mb-4 flex w-1/2 items-center justify-between text-center">
        <h3 className="heading-sm !text-orange-900/75">Drag item to pack in a new box</h3>
      </div>
    </div>
  );
};

const SidebarUnit = ({ item }) => {
  const { getValues } = useFormContext();
  const boxes = getValues("cartons");

  const quantityPacked = boxes.reduce((acc, box) => {
    const itemInBox = box.items.find((i) => i.id === item.id);
    return acc + (itemInBox ? itemInBox.quantity : 0);
  }, 0);

  return (
    <div className="flex items-center justify-between p-1">
      <div className="px-1 text-sm/8 font-semibold text-gray-900">
        {item.name}
        <div className="flex space-x-2">
          <WeightBadge weight={item.weight} />
          <DimensionsBadge dimensions={item.dimensions} />
        </div>
      </div>
      <div
        className={cn(
          "flex items-center justify-between rounded-sm bg-gray-100 px-2 py-1 font-medium text-gray-700",
          quantityPacked >= item.quantity && "bg-emerald-100 text-emerald-950",
          quantityPacked > 0 && quantityPacked < item.quantity && "bg-orange-100 text-orange-950",
        )}
      >
        <p className="flex items-center self-center">
          {quantityPacked}
          <span
            className={cn(
              "px-1 text-xs font-semibold text-gray-500",
              quantityPacked >= item.quantity && "text-emerald-700",
              quantityPacked > 0 && quantityPacked < item.quantity && "text-orange-700",
            )}
          >
            /
          </span>
          {item.quantity}
        </p>
      </div>
    </div>
  );
};

const SidebarUnitDraggable = ({ item }) => {
  const { getValues } = useFormContext();
  const boxes = getValues("cartons");
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const quantityPacked = boxes.reduce((acc, box) => {
    const itemInBox = box.items.find((i) => i.id === item.id);
    return acc + (itemInBox ? itemInBox.quantity : 0);
  }, 0);

  const moveableQuantity = item.quantity - quantityPacked;

  const { setNodeRef, attributes, listeners, isDragging } = useDraggable({
    id: `sidebar-unit-${item.id}`,
    data: { item, type: "SidebarUnit", moveableQuantity: selectedQuantity },
    disabled: quantityPacked >= item.quantity,
  });

  useDndMonitor({
    onDragCancel: (e) => {
      // ignore unless we are dragging this item
      if (e.active.data.current.item.id !== item.id) {
        return;
      }
      setSelectedQuantity(1);
    },
    onDragEnd: (e) => {
      // ignore unless we are dragging this item
      if (e.active.data.current.item.id !== item.id) {
        return;
      }
      setSelectedQuantity(1);
    },
  });

  return (
    <div
      ref={setNodeRef}
      className={cn(
        "hover:ring-indigo-500hover:ring-indigo-500/50 relative cursor-grab rounded-sm border border-indigo-300 bg-white shadow shadow-indigo-50 hover:border-indigo-500 hover:shadow-md hover:ring-1 hover:ring-offset-2 hover:ring-offset-indigo-500/50",
        isDragging && "cursor-grabbing ring-2",
        quantityPacked >= item.quantity &&
          "cursor-default border-emerald-200 shadow-sm hover:border-emerald-200 hover:shadow-none hover:ring-0 hover:ring-offset-0",
        selectedQuantity > 1 && "ring-2 ring-indigo-500",
      )}
      {...attributes}
      {...listeners}
      onClick={(e) => {
        // shift + click to move all
        if (e.shiftKey) {
          setSelectedQuantity(moveableQuantity);
        } else if (e.ctrlKey) {
          setSelectedQuantity(1);
        } else {
          setSelectedQuantity((prev) => (prev + 1) % (moveableQuantity + 1) || 1);
        }
      }}
    >
      <SidebarUnit item={item} />
      {/* Selected Quantity Popout */}
      {selectedQuantity > 1 && (
        <div className="absolute -right-2.5 -top-2.5 flex h-6 w-6 items-center justify-center rounded-full bg-indigo-500 text-center text-xs font-semibold text-indigo-50">
          <span className="mr-[1px] text-indigo-300">x</span>
          <span className="mr-0.5">{selectedQuantity}</span>
        </div>
      )}
    </div>
  );
};

const SidebarUnitDragOverlay = ({ item, quantity }) => {
  return (
    <div
      className={cn(
        "cursor-grabbing rounded-sm border border-indigo-500/25 bg-white shadow-sm ring-2 ring-indigo-500",
      )}
    >
      <SidebarUnit item={item} />
      {quantity > 0 && (
        <div className="absolute -right-2.5 -top-2.5 flex h-6 w-6 items-center justify-center rounded-full bg-indigo-500 text-center text-xs font-semibold text-white">
          <span>{quantity}</span>
        </div>
      )}
    </div>
  );
};

const BoxUnit = ({ box, item, onUnpack, highlight }) => {
  const { getValues } = useFormContext();
  const items = getValues("items");

  const { setNodeRef, attributes, listeners, isDragging } = useDraggable({
    id: `box-${box.id}-unit-${item.id}`,
    data: { item, box, type: "BoxUnit", moveableQuantity: item.quantity },
  });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={cn(
        "col-span-12 grid cursor-grab grid-cols-subgrid items-center rounded-md",
        "hover:border-orange-500 hover:shadow-md hover:ring-1 hover:ring-orange-500 hover:ring-orange-500/50 hover:ring-offset-2 hover:ring-offset-orange-500/50",
        isDragging && "ring-2 ring-orange-500/50",
        highlight && "ring-2 ring-orange-500",
      )}
    >
      <div
        className={cn(
          "rounded-y-md col-span-11 grid grid-cols-subgrid gap-2 rounded-s-md border border-orange-200 bg-white p-2 shadow-sm shadow-orange-100",
        )}
      >
        {/* {item.id % 2 == 0 && (
        <FontAwesomeIcon
          icon={faDiamondExclamation}
          className="col-start-1 h-5 w-5 text-yellow-500"
          style={{
            "--fa-primary-color": "#111111",
          }}
        />
      )} */}
        <span className="justify-content-start col-span-6 col-start-2 text-sm font-semibold">
          {items.find((i) => i.id === item.id)?.name}
        </span>
        <button
          onClick={onUnpack}
          className="col-start-11 -my-1 p-0 text-rose-300 hover:text-rose-500"
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
      <div className="col-start-12 flex h-full items-center justify-center rounded-e-md border-y border-r border-orange-200 bg-slate-50 text-center tracking-tighter shadow-sm shadow-orange-100">
        <span className="text-xs font-semibold text-slate-900">{item.quantity}</span>
        <FontAwesomeIcon icon={faXmark} className="h-2.5 w-2.5 text-slate-500" />
      </div>
    </div>
  );
};

const BoxUnitDragOverlay = ({ box, item }) => {
  const { getValues } = useFormContext();
  const cartons = getValues("cartons");
  const items = getValues("items");
  const quantityRemaining =
    item.quantity -
    cartons.reduce((acc, b) => {
      const itemInBox = b.items.find((i) => i.id === item.id);
      return acc + (itemInBox ? itemInBox.quantity : 0);
    }, 0);

  return (
    <div className="cursor-grabbing rounded-sm border border-orange-500/50 bg-white p-2 shadow-sm shadow-orange-100">
      {items.find((i) => i.id === item.id).name} (x{item.quantity}){" "}
      <small>{quantityRemaining}</small>
    </div>
  );
};

const DragOverlayWrapper = () => {
  const [draggedItem, setDraggedItem] = useState(null);
  const [draggedQuantity, setDraggedQuantity] = useState(0);

  useDndMonitor({
    onDragStart: (event) => {
      const dragItem = event.active;
      setDraggedItem(dragItem);

      if (!dragItem) {
        return;
      }

      if (dragItem?.data?.current?.type === "SidebarUnit") {
        setDraggedQuantity(dragItem.data.current.moveableQuantity);
      }
    },
    onDragCancel: () => {
      setDraggedItem(null);
      setDraggedQuantity(0);
    },
    onDragEnd: () => {
      setDraggedItem(null);
      setDraggedQuantity(0);
    },
  });

  if (!draggedItem) {
    return null;
  }

  const isSidebarUnit = draggedItem.data?.current?.type === "SidebarUnit";
  const isBoxUnit = draggedItem.data?.current?.type === "BoxUnit";

  let node = <div>Unknown</div>;

  if (isSidebarUnit) {
    node = (
      <SidebarUnitDragOverlay item={draggedItem.data.current.item} quantity={draggedQuantity} />
    );
  }

  if (isBoxUnit) {
    node = (
      <BoxUnitDragOverlay box={draggedItem.data.current.box} item={draggedItem.data.current.item} />
    );
  }

  return <DragOverlay>{node}</DragOverlay>;
};
