import { faPlus, faSearch } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, Fragment, useLayoutEffect } from "react";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import LocationEditor from "./components/LocationEditor";
import { Button, Checkbox } from "../UI/components";
import {
  faBarcode,
  faClipboardList,
  faPencil,
  faShippingFast,
  faTruckArrowRight,
  faWarning,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import dayjs from "dayjs";
import PackagingModal from "../Products/components/PackagingModal";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import FulfillmentHeader from "./components/Fulfillment/FulfillmentHeader";
import ListStatusBadge from "./components/Fulfillment/ListStatusBadge";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const titleize = (str) => {
  if (!str) return "";
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export default function DistributorFulfillments({
  distributor,
  distributor_location,
  initialFulfillments,
  team,
  app_contexts,
}) {
  const [fulfillments, setFulfillments] = useState(initialFulfillments || []);
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedFulfillments, setSelectedFulfillments] = useState([]);
  const [requestingPickup, setRequestingPickup] = useState(false);
  const [openPackagingModal, setOpenPackagingModal] = useState(false);
  const [activeFulfilment, setActiveFulfilment] = useState(null);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedFulfillments.length > 0 && selectedFulfillments.length < fulfillments.length;
    setChecked(selectedFulfillments.length === fulfillments.length);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedFulfillments]);

  function toggleAll() {
    setSelectedFulfillments(checked || indeterminate ? [] : fulfillments);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const requestFulfillments = () => {
    setRequestingPickup(true);
  };

  const resolveIssues = ({ fulfilment }) => {
    setActiveFulfilment(fulfilment);
    setOpenPackagingModal(true);
  };

  const updatePackaging = () => {
    setOpenPackagingModal(false);
  };

  const search = async (value) => {
    try {
      const result = await axios({
        method: "get",
        url: `?query=${value}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      console.log("result", result);

      setFulfillments(result.data.fulfilments);
    } catch (e) {
      console.log(e);
      toast.error("An error occured while searching for fulfilments.");
    }
  };

  return (
    <>
      <Header app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8 pb-24 pt-5">
            <FulfillmentHeader distributor_location={distributor_location} />
            <div className="flex w-full items-center justify-between">
              <div className="flex flex-grow space-x-5">
                <div className="relative max-w-[50vw] flex-grow">
                  <h2 className="m-0 text-3xl font-extrabold tracking-tight text-gray-900">
                    Packing
                  </h2>
                  <p className="text-sm text-gray-500">
                    Confirm and validate box weights and dimensions in preparation for shipping.
                  </p>
                </div>
                <div className="relative max-w-[33vw] flex-grow">
                  <input
                    type="text"
                    placeholder="Search by order, reference or tracking number..."
                    className="w-full"
                    onChange={(e) => search(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="absolute right-3 top-2.5 h-4 w-4 text-gray-500"
                  />
                </div>
              </div>
              {/* {selectedFulfillments.length > 0 && (
                <div>
                  <Button
                    type="button"
                    className="btn m-0"
                    icon={faShippingFast}
                    label="Request Selected Pickups"
                    showLoading={requestingPickup}
                    disabled={requestingPickup}
                    onClick={requestFulfillments}
                  />
                </div>
              )} */}
            </div>

            {fulfillments && fulfillments.length ? (
              <div className="w-full space-y-5 overflow-clip rounded-lg bg-white pb-5 shadow">
                <table className="text-xs">
                  <thead>
                    <tr>
                      <th className="relative pl-4 pr-0">
                        {/* <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        /> */}
                      </th>
                      <th>Location</th>
                      <th>Number</th>
                      <th>Order</th>
                      <th>Contains</th>
                      {/* <th className="text-center">Shipping</th>
                      <th className="text-center">Shipping Tax</th> */}
                      <th>Tracking</th>
                      <th>Carrier</th>
                      <th>Label</th>
                      <th className="text-right">Shipment Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fulfillments.map((fulfillment, fIndex) => (
                      <Fragment key={fulfillment.id}>
                        <tr
                          className={classNames(
                            fIndex === 0 ? "border-indigo-500" : "border-indigo-400",
                            "border-b-0 border-t-2"
                          )}
                        >
                          <td className="relative pl-4 pr-0">
                            {selectedFulfillments.includes(fulfillment) && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                            )}
                            {!fulfillment.error_messages.length > 0 ? (
                              // <input
                              //   type="checkbox"
                              //   className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              //   value={fulfillment.id}
                              //   checked={selectedFulfillments.includes(fulfillment)}
                              //   onChange={(e) =>
                              //     setSelectedFulfillments(
                              //       e.target.checked
                              //         ? [...selectedFulfillments, fulfillment]
                              //         : selectedFulfillments.filter((f) => f !== fulfillment)
                              //     )
                              //   }
                              // />
                              <></>
                            ) : (
                              <div className="text-warning-500 flex items-center space-x-2">
                                <FontAwesomeIcon icon={faWarning} className="h-5 w-5" />
                                <span>{fulfillment.error_messages.length}</span>
                              </div>
                            )}
                          </td>
                          <td>
                            <span
                              className={classNames(
                                "rounded px-2 py-1.5 font-medium uppercase",
                                fulfillment.restrictions
                                  ? "bg-warning-200 text-warning-700"
                                  : "bg-gray-100 text-gray-900"
                              )}
                            >
                              {fulfillment.distributor_location.code}
                            </span>
                          </td>
                          <td className="text-sm text-gray-900">
                            {fulfillment.distributor_order_id}
                          </td>
                          <td>
                            <ListStatusBadge
                              type="fulfillment"
                              status={fulfillment.distributor_status}
                            />
                          </td>
                          <td>{fulfillment.deliveries.length} Shipments</td>
                          {/* <td className="text-center">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "AUD",
                              }).format(fulfillment.shipping_charge)}
                            </td>
                            <td className="text-center">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "AUD",
                              }).format(fulfillment.shipping_tax)}
                            </td> */}
                          <td className=""></td>
                          <td></td>
                          <td></td>
                          <td className="relative whitespace-nowrap py-4 pl-3 text-right text-sm font-medium">
                            <div className="flex w-full justify-end">
                              {/* <button
                                    disabled={true}
                                    title="Generate Return Label"
                                    className="rounded border bg-white px-2.5 py-1.5 text-xs text-gray-700 transition hover:border-gray-400 disabled:pointer-events-none disabled:opacity-25"
                                  >
                                    <FontAwesomeIcon
                                      icon={faTruckArrowRight}
                                      className="-scale-x-100"
                                    />
                                  </button>
                                  <a
                                    href={fulfillment.packing_slip_url}
                                    download
                                    target="_blank"
                                    title="Download Packing Slip"
                                    className="rounded border bg-white px-2.5 py-1.5 text-xs text-gray-700 transition hover:border-gray-400"
                                  >
                                    <FontAwesomeIcon icon={faClipboardList} />
                                  </a>
                                  <a
                                    href={fulfillment.label_url}
                                    download
                                    target="_blank"
                                    title="Download Label"
                                    className="rounded border bg-white px-2.5 py-1.5 text-xs text-gray-700 transition hover:border-gray-400"
                                  >
                                    <FontAwesomeIcon icon={faBarcode} />
                                  </a>
                                  <Button
                                    label={"Request Pickup"}
                                    className="btn btn-sm"
                                    disabled={requestingPickup}
                                    showLoading={
                                      requestingPickup && selectedFulfillments.includes(fulfillment)
                                    }
                                  /> */}
                              <a
                                href={`fulfillments/${fulfillment.id}`}
                                title="Manage"
                                className="rounded border bg-white px-2.5 py-1.5 text-xs text-gray-700 transition hover:border-gray-400"
                              >
                                Prepare For Shipping
                              </a>
                            </div>
                          </td>
                        </tr>
                        {fulfillment.deliveries.map((delivery, dIndex) => (
                          <tr
                            key={fulfillment.id}
                            className={classNames(
                              dIndex === 0 ? "border-gray-300" : "border-gray-200",
                              "border-t"
                            )}
                          >
                            <td colSpan="2"></td>
                            <td>{delivery.identifier}</td>
                            <td className=""></td>
                            <td>{delivery.parcels.length} Boxes</td>
                            <td>
                              <a href={delivery.tracking_url} target="_blank" key={delivery.id}>
                                {delivery.tracking_number}
                              </a>
                            </td>
                            <td>
                              <span key={delivery.id}>{delivery.carrier}</span>
                            </td>
                            <td>
                              <ListStatusBadge type="label" status={delivery.label_status} />
                            </td>
                            <td className="text-right">
                              <ListStatusBadge type="delivery" status={delivery.delivery_status} />
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="flex w-full items-center justify-center p-8 text-sm text-gray-500">
                No fulfilments found.
              </div>
            )}
          </div>
        </div>
      </section>
      <PackagingModal
        isOpen={openPackagingModal}
        closeModal={updatePackaging}
        options={["Carton", "Satchel", "Bag"]}
      />
      <Toaster />
    </>
  );
}
