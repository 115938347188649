import React, { useState, useEffect } from "react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";

import PartbotIcon from "/app/assets/images/partbot-icon-dark.svg";
import TecdocIcon from "/app/assets/images/tecdoc-icon.svg";
import PartsDbIcon from "/app/assets/images/parts-db-icon.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";

function useAttributes(searchTerm) {
  const [attributes, setAttributes] = useState([]);
  useEffect(() => {
    if (searchTerm.trim() !== "") {
      let isFresh = true;
      fetchAttributes(searchTerm).then((attributes) => {
        // if the searchTerm is not found exactly in attributes and searchTerm is more than 3 characters in length, return a dummy attribute using the searchTerm, with the id of 0, modify the title to be "Create {searchTerm}"
        if (searchTerm.length > 3 && !attributes.find((a) => a.name === searchTerm)) {
          let attributeTitle = searchTerm;
          if (searchTerm.charAt(0) === searchTerm.charAt(0).toLowerCase()) {
            // title case, capitalize the first letter of each word, unless the word is already all caps
            attributeTitle = searchTerm.replace(
              /\w\S*/g,
              (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            );
          }

          // prepend the dummy attribute to the attributes array
          attributes.unshift({ id: null, name: attributeTitle });
        }

        if (isFresh) setAttributes(attributes);
      });
      return () => (isFresh = false);
    }
  }, [searchTerm]);
  return attributes;
}

const cache = {};
async function fetchAttributes(value) {
  if (cache[value]) {
    return Promise.resolve(cache[value]);
  }
  return fetch("/attribute_search?term=" + value)
    .then((res) => res.json())
    .then((result) => {
      cache[value] = result;
      return result;
    });
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AttributeCombobox({ selectedOption, handleAttributeSelect }) {
  const [query, setQuery] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const attributes = useAttributes(query);

  const [loading, setLoading] = useState(false);

  // setLoading to false when attributes change
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, [attributes]);

  useEffect(() => {
    if (!selectedOption) {
      setQuery("");
      setSelectedAttribute(null);
    }

    setSelectedAttribute(selectedOption);
  }, [selectedOption]);

  const handleSelect = (value) => {
    setSelectedAttribute(value);
    if (value !== null) {
      handleAttributeSelect(value);
    }
  };

  const getAttributeIcon = (attribute) => {
    if (attribute.parts_db_id) {
      return PartsDbIcon;
    } else if (attribute.tec_doc_criteria_id) {
      return TecdocIcon;
    } else {
      return PartbotIcon;
    }
  };

  return (
    <Combobox as="div" value={selectedAttribute} onChange={(e) => handleSelect(e)}>
      <Combobox.Label>Attribute</Combobox.Label>
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => {
            setLoading(true);
            setQuery(event.target.value);
          }}
          placeholder="Type to search..."
          displayValue={(attribute) => (attribute === null ? "" : attribute.name)}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          {loading ? (
            <FontAwesomeIcon
              icon={faSpinnerThird}
              className="h-4 w-4 animate-spin text-gray-400"
              aria-hidden="true"
            />
          ) : (
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          )}
        </Combobox.Button>

        {attributes.length > 0 && (
          <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {attributes.map((attribute) => (
              <Combobox.Option
                key={attribute.id}
                value={attribute}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-100 text-indigo-600" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-start justify-between space-x-2">
                      <div>
                        <div className="flex items-center justify-start space-x-2">
                          <span className="p-0.5">
                            <img className="h-4 w-4" src={getAttributeIcon(attribute)} />
                          </span>
                          <span
                            className={classNames(
                              "block truncate",
                              selected && "font-semibold",
                              attribute.id === null && "font-semibold"
                            )}
                          >
                            {attribute.id === null ? "Create " + attribute.name : attribute.name}
                          </span>
                          {attribute.tec_doc_criteria_id && (
                            <span className="rounded-md bg-indigo-200/50 px-1.5 text-[10px] text-indigo-600">
                              {attribute.tec_doc_criteria_id}
                            </span>
                          )}
                        </div>
                      </div>
                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </div>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
