import { motion } from "framer-motion";
import React from "react";
import { useMarketplaceProductState } from "../../hooks/useMarketplaceProductState";
import { Card } from "./Card";
import { CardSkeleton } from "./CardSkeleton";
import { ListItem } from "./ListItem";
import { ViewLayout } from "./ViewLayout";

export const Hits = ({ view = "grid", searchQuery }) => {
  const { products, perPage, productsLoading, fetchProductDetails, currentProductLoading } =
    useMarketplaceProductState();

  const skeletonItems = Array.from(Array(perPage), (_, i) => i + 1);

  return (
    <div>
      {productsLoading && (
        <ViewLayout view={view}>
          {skeletonItems.map((_, index) => (
            <CardSkeleton key={index} compact={view === "list"} />
          ))}
        </ViewLayout>
      )}
      {!productsLoading && products.length > 0 && (
        <ViewLayout view={view}>
          {products.map((product) => {
            const isExactMatch =
              !!searchQuery &&
              product["variants"]
                .filter((i) => i["sku"])
                .map((i) => i["sku"].toLowerCase())
                .includes(searchQuery.toLowerCase());

            return (
              <motion.div key={product.id} className="h-full w-full" initial={{ x: "0", y: "0" }}>
                {view === "grid" && (
                  <motion.div key={product.id} className="h-full w-full" layoutId={product.id}>
                    <Card
                      key={product.id}
                      product={product}
                      currentProductLoading={currentProductLoading}
                      onViewMore={() => fetchProductDetails(product?.id)}
                      isExactMatch={isExactMatch}
                    />
                  </motion.div>
                )}
                {view === "list" && (
                  <motion.div
                    key={`${product.id}-list-item`}
                    className="h-full w-full"
                    initial={{ x: "0", y: "0" }}
                  >
                    <motion.div
                      key={`${product.id}-list-item`}
                      className="h-full w-full"
                      layoutId={product.id}
                    >
                      <ListItem
                        key={product.id}
                        product={product}
                        currentProductLoading={currentProductLoading}
                        onViewMore={() => fetchProductDetails(product?.id)}
                        isExactMatch={isExactMatch}
                      />
                    </motion.div>
                  </motion.div>
                )}
              </motion.div>
            );
          })}
        </ViewLayout>
      )}
    </div>
  );
};
