import React, { useState } from "react";
import TextEditor from "../../UI/components/TextEditor";
import { Input, Checkbox } from "../../UI/components/Controls";

import { RadioGroup } from "@headlessui/react";

import { Controller, useFormContext } from "react-hook-form";

import { classNames } from "../../../utils";
import ProductTypeCombobox from "../../UI/components/ProductTypeCombobox";
import BrandCombobox from "../../UI/components/BrandCombobox";
import SubBrandCombobox from "../../UI/components/SubBrandCombobox";
import ProductCategoryCombobox from "../../UI/components/ProductCategoryCombobox";
import ProductNumberManagement from "./ProductNumberManagement";

const settings = [
  { name: "Part", description: "This product is an individual part" },
  {
    name: "Assembly",
    description: "This product comprises two or more individual parts",
  },
];

export default function ProductDetails({ product, brands, user, apiKey }) {
  const { control } = useFormContext();
  const isAdmin = user.role.title === "Admin";
  const [selectedBrand, setSelectedBrand] = useState(product.brand);
  const [selectedSubBrand, setSelectedSubBrand] = useState(product.sub_brand);

  function onBrandChange(brand) {
    setSelectedBrand(brand);
    setSelectedSubBrand("");
  }

  return (
    <div className="grid grid-cols-2 gap-8 py-8">
      <div className="col">
        <div className="form-group">
          <Input name="title" label="Title" type="text" />
        </div>
        <div className="form-group relative flex items-start">
          <Checkbox name="override_title" label="Override vehicle generated title" />
        </div>
        <div className="grid grid-cols-2 gap-8">
          <div className="form-group">
            <Input name="sku" label="SKU" type="text" required />
          </div>
          <div className="form-group flex items-end gap-2">
            <div className="flex-grow">
              <Input name="manufacturer_sku" label="Manufacturer Part Number (MPN)" type="text" />
            </div>
          </div>
        </div>
        {product.id ? null : (
          <div className="form-group">
            <label>Type</label>
            <Controller
              name="record_type"
              control={control}
              defaultValue={"Part"}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <RadioGroup.Label className="sr-only">Type</RadioGroup.Label>
                  <div className="-space-y-px rounded-md bg-white">
                    {settings.map((setting, settingIdx) => (
                      <RadioGroup.Option
                        key={setting.name}
                        value={setting.name}
                        className={({ checked }) =>
                          classNames(
                            settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                            settingIdx === settings.length - 1 ? "rounded-bl-md rounded-br-md" : "",
                            checked ? "z-10 border-indigo-200 bg-indigo-50" : "border-gray-200",
                            "relative flex cursor-pointer border p-4 focus:outline-none"
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <span
                              className={classNames(
                                checked
                                  ? "border-transparent bg-indigo-600"
                                  : "border-gray-300 bg-white",
                                active ? "ring-2 ring-indigo-500 ring-offset-2" : "",
                                "mt-0.5 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full border"
                              )}
                              aria-hidden="true"
                            >
                              <span className="h-1.5 w-1.5 rounded-full bg-white" />
                            </span>
                            <div className="ml-3 flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className={classNames(
                                  checked ? "text-indigo-900" : "text-gray-900",
                                  "block text-sm font-medium"
                                )}
                              >
                                {setting.name}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className={classNames(
                                  checked ? "text-indigo-700" : "text-gray-500",
                                  "block text-sm"
                                )}
                              >
                                {setting.description}
                              </RadioGroup.Description>
                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              )}
            />
          </div>
        )}

        <div className="grid grid-cols-2 gap-8">
          <div className="col-md-6">
            <div className="form-group">
              <Controller
                name="product_category_id"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <ProductCategoryCombobox
                      selectedOption={product.product_category}
                      handleProductCategorySelect={(e) => onChange(e.id)}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <Controller
                name="product_type_id"
                control={control}
                defaultValue={"Part"}
                render={({ field: { onChange, value } }) => (
                  <ProductTypeCombobox
                    required
                    selectedOption={product.product_type}
                    handleProductTypeSelect={(e) => onChange(e.id)}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-8">
          <div className="col-md-6">
            <div className="form-group">
              <Controller
                name="brand_id"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <BrandCombobox
                    required
                    selectedOption={selectedBrand}
                    handleBrandSelect={(e) => {
                      onBrandChange(e);
                      onChange(e.id);
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group z-50">
              <Controller
                name="sub_brand_id"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SubBrandCombobox
                    required
                    selectedOption={selectedSubBrand}
                    handleSubBrandSelect={(e) => {
                      setSelectedSubBrand(e);
                      onChange(e.id);
                    }}
                    brand={selectedBrand}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-8">
          <div className="col-span-1">
            <div className="form-group relative">
              <Input
                name="price"
                label="RRP"
                prefix={product.currency_symbol}
                suffix={product.currency}
                type="number"
                step={0.01}
                min={0}
              />
            </div>
            {isAdmin && (
              <div className="form-group relative flex items-start pb-1">
                <Checkbox name="use_retail_charm_pricing" label="Use Charm Pricing" />
              </div>
            )}
          </div>
          <div className="col-md-6">
            <div className="flex items-end gap-2">
              <div className="flex-grow">
                <Input
                  suffix={product.default_gtin.description}
                  name="barcode"
                  label="Product Indentifiers"
                  type="text"
                  readOnly
                />
              </div>
              <ProductNumberManagement
                apiKey={apiKey}
                productId={product.id}
                count={product.product_numbers_count}
              />
            </div>
            <div className="mt-2 text-[10px] italic text-stone-500">
              GTIN, OEM References, Alternate
            </div>
          </div>
        </div>
      </div>
      <div className="col h-full">
        <div className="form-group h-full">
          <label>Description</label>
          <Controller
            name="description"
            control={control}
            defaultValue={"..."}
            render={({ field: { value, onChange } }) => {
              return <TextEditor value={value} onChange={onChange} />;
            }}
          />
        </div>
      </div>
    </div>
  );
}
