import React from "react";

export const Card = ({ id, image, children }) => {
  const imgSrc = image
    ? `https://res.cloudinary.com/proex/image/fetch/f_auto,w_1200,h_1200,c_pad,b_white,q_80,dpr_auto/${image}`
    : null;

  return (
    <div key={id} className="group relative flex flex-1 flex-col rounded-lg border border-solid">
      {imgSrc && (
        <div className="p-4">
          <img src={imgSrc} alt="Card image" className="mx-auto w-64 rounded-lg" />
        </div>
      )}

      <div className="flex flex-1 flex-col justify-between p-6">{children}</div>
    </div>
  );
};
