import React from "react";
import { Header } from "./components/Header";
import AccountSettings from "../Account/AccountSettings";

export default function MarketplaceAccount({ user, team, domains, app_contexts }) {
  return (
    <>
      {app_contexts && <Header section="Account" app_contexts={app_contexts} />}
      <section className="relative flex min-h-screen w-full">
        <div className="mx-auto h-auto w-full max-w-7xl">
          <div className="flex w-full flex-col space-y-8 p-12">
            <AccountSettings user={user} team={team} domains={domains} />
          </div>
        </div>
      </section>
    </>
  );
}
