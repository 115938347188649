import { useState } from "react";

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  const onModalClose = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsOpen(false);
  };

  return { isOpen, setIsOpen, onModalClose };
}
