import { CheckCircleIcon } from "@heroicons/react/solid";
import React, { Fragment, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useStore } from "../../state/StoreProvider";
import { BillingRequiredModal } from "../BillingRequiredModal";
import { BrandSellerAgreementModal } from "./BrandSellerAgreementModal";
import { CrossSellProduct } from "./CrossSellProduct";
import { Grid } from "./Grid";
import { LoadingIndicator } from "./LoadingIndicator";
import { PublishListingsModal } from "./PublishListingsModal";
import { PublishSuccessfulModal } from "./PublishSuccessfulModal";
import { Tabs } from "../Tabs";
import TextEditor from "../TextEditor";
import { Variant } from "./Variant";
import { formatErrors } from "../../utils";
import { useForm } from "react-hook-form";

export const ImportList = ({ item, team }) => {
  const [title, setTitle] = useState(item.title || item.original_title);
  const [description, setDescription] = useState(item.description || item.original_description);
  const [sku, setSku] = useState(item.sku || item.original_sku);
  const [handle, setHandle] = useState(item.handle || item.original_handle);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isBrandSellerAgreementModalOpen, setIsBrandSellerAgreementModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onChange" });

  const selectedAttributes = item.options.split(",");
  const variantOverrides = item.variant_overrides;

  const removeImportListing = useStore((state) => state.removeImportListing);
  const updateImportListing = useStore((state) => state.updateImportListing);
  const publishSingleListing = useStore((state) => state.publishSingleListing);
  const brandSellerAgreements = useStore((state) => state.brandSellerAgreements);

  const updateListing = async (payload) => {
    setLoading(true);
    let response;

    // Check if the payload is an array
    if (Array.isArray(payload)) {
      // Spread the array as arguments if payload is an array
      response = await updateImportListing(item.id, ...payload);
    } else if (typeof payload === "object" && payload !== null) {
      // Pass the object directly if payload is an object
      response = await updateImportListing(item.id, payload);
    } else {
      // Handle unexpected payload types (e.g., null, undefined, primitives)
      console.error("Invalid payload type for updateListing:", payload);
      setLoading(false);
      setError("Invalid data provided for update."); // Adjust error handling as needed
      return;
    }

    if (response?.status === 200) {
      setError(null);
      setLoading(false);
    } else {
      setError(formatErrors(response?.data?.errors));
      setLoading(false);
    }
  };

  const debounced = useDebouncedCallback(async (values) => {
    if (!errors.title && !errors.description && !errors.sku && !errors.handle) {
      // console.log(values);
      updateListing(values);
    }
  }, 1000);

  const handlePublish = async () => {
    setLoading(true);
    const response = await publishSingleListing(item.id);
    if (response?.status === 200) {
      setIsPublishModalOpen(false);
      setLoading(false);
    } else {
      setError(formatErrors(response?.data?.errors));
      setLoading(false);
    }
  };

  const updateVariantOverrides = async (variantId, field, value) => {
    updateListing({
      variant_override_attributes: {
        id: variantId,
        field,
        value,
      },
    });
  };

  const handleRemoval = async () => {
    setLoading(true);
    await removeImportListing(item?.id);
    setLoading(false);
  };

  const brandSellerAgreement = brandSellerAgreements.find(
    (agreement) => agreement.brand === item.brand
  );

  const checkBrandSellerAgreements = () => {
    if (!!brandSellerAgreement) {
      setIsBrandSellerAgreementModalOpen(true);
    } else {
      setIsPublishModalOpen(true);
    }
  };

  const handleImport = () => {
    setError(null);
    if (team.subscription_active) {
      checkBrandSellerAgreements();
    } else {
      setIsBillingModalOpen(true);
    }
  };

  return (
    <div className="relative rounded-lg bg-white shadow-md">
      <div className="flex w-full items-center justify-between space-x-6 rounded-t-lg border p-6">
        <div className="flex items-center space-x-8">
          <div className="text-xs font-bold uppercase tracking-wide text-gray-800">
            <img
              src={`https://res.cloudinary.com/proex/image/upload/v1/partbot/marketplace/brands/${item.brand
                .replace(/ /g, "-")
                .toLowerCase()}-logo.svg`}
              className="h-auto w-[100px]"
              alt={item.brand}
            />
          </div>
          <h5 className="text-sm font-bold text-gray-900">{item?.original_title}</h5>
        </div>
        <div className="btn-group">
          <button
            className={`btn btn-sm btn-danger opacity-90 ${
              loading && "cursor-not-allowed opacity-50"
            }`}
            disabled={loading}
            onClick={handleRemoval}
          >
            Remove
          </button>
          <button
            disabled={loading}
            className={`btn btn-sm ${loading && "cursor-not-allowed opacity-50"}`}
            onClick={handleImport}
          >
            Import
          </button>

          <BrandSellerAgreementModal
            isOpen={isBrandSellerAgreementModalOpen}
            onClose={() => setIsBrandSellerAgreementModalOpen(false)}
            onConfirm={() => {
              setIsBrandSellerAgreementModalOpen(false);
              setIsPublishModalOpen(true);
            }}
            agreements={[brandSellerAgreement]}
          />

          <PublishListingsModal
            title={"Import Product"}
            description={
              <Fragment>
                This action will publish <strong>{title}</strong> to the selected store
              </Fragment>
            }
            isOpen={isPublishModalOpen}
            onClose={() => !loading && setIsPublishModalOpen(false)}
            onConfirm={handlePublish}
            loading={loading}
            error={error}
          />
          <BillingRequiredModal
            isOpen={isBillingModalOpen}
            onClose={() => setIsBillingModalOpen(false)}
            onClick={() => {
              console.log("go to billing");
              window.location.pathname = "/billing";
            }}
          />
        </div>
      </div>

      <div className="p-6">
        <Tabs>
          <div label="Details">
            <form className="form" onSubmit={(e) => e.preventDefault()}>
              <div className="grid grid-cols-2 gap-5">
                <div>
                  <div>
                    <label>Title</label>
                    <input
                      {...register("title", {
                        required: true,
                        maxLength: 255,
                      })}
                      type="text"
                      value={title}
                      className={`${
                        errors?.title &&
                        "border-danger-500 text-danger-500 placeholder-danger-500 focus:border-danger-500 focus:ring-danger-500 focus:outline-none"
                      }`}
                      onChange={(e) => {
                        setValue("title", e.target.value, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                        setTitle(e.target.value);
                        debounced({ title: e.target.value });
                      }}
                    />
                    {errors?.title?.type === "required" && <p>This field is required</p>}
                    {errors?.title?.type === "maxLength" && (
                      <p className="text-danger-600 mt-2 text-sm" id="email-error">
                        The title must be less than 255 characters.
                      </p>
                    )}
                  </div>
                  <div className="form-group mt-4">
                    <label>Description</label>
                    <TextEditor
                      value={description}
                      onChange={(description) => {
                        setDescription(description);
                        debounced({ description });
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className="form-group">
                    <label>SEO Handle</label>
                    <input
                      type="text"
                      value={handle}
                      onChange={(e) => {
                        setHandle(e.target.value);
                        debounced({ handle: e.target.value });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>SEO Description</label>
                    <textarea
                      disabled
                      rows="8"
                      className="disabled:bg-gray-100 disabled:opacity-50"
                      placeholder="Available soon"
                    />
                  </div>

                  <div className="form-group">
                    <label>SEO Keywords</label>
                    <input
                      type="text"
                      disabled
                      className="disabled:bg-gray-100 disabled:opacity-50"
                      placeholder="Available soon"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div label="Products">
            {/* render an editable text input if the item.variants is more than 1, this should map to the importlisting sku */}
            {item?.variants?.length > 1 && (
              <div className="form-group">
                <label>Listing SKU</label>
                <input
                  {...register("sku", {
                    required: true,
                    maxLength: 25,
                  })}
                  type="text"
                  value={sku}
                  className={`${
                    errors?.title &&
                    "border-danger-500 text-danger-500 placeholder-danger-500 focus:border-danger-500 focus:ring-danger-500 focus:outline-none"
                  }`}
                  onChange={(e) => {
                    setValue("sku", e.target.value, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    setSku(e.target.value);
                    debounced({ sku: e.target.value });
                  }}
                />
                {errors?.title?.type === "required" && <p>This field is required</p>}
                {errors?.title?.type === "maxLength" && (
                  <p className="text-danger-600 mt-2 text-sm" id="email-error">
                    The title must be less than 25 characters.
                  </p>
                )}
              </div>
            )}

            {item?.variants?.length > 0 && (
              <Grid>
                {item.variants.map((variant) => {
                  const overrides = variantOverrides.find((v) => v.id === variant.id)?.overrides;

                  return (
                    <Variant
                      key={variant.id}
                      variant={variant}
                      overrides={overrides}
                      selectedAttributes={selectedAttributes}
                      onChange={(field, value) => updateVariantOverrides(variant.id, field, value)}
                    />
                  );
                })}
              </Grid>
            )}
            {!item?.variants?.length > 0 && <div>No variants found</div>}
          </div>
          {item?.cross_sell_products?.length > 0 && (
            <div label="Cross-Sell">
              <Grid>
                {item.cross_sell_products.map((product) => {
                  return <CrossSellProduct key={product.id} product={product} />;
                })}
              </Grid>
            </div>
          )}
        </Tabs>

        <div className="mt-8 flex items-center space-x-6 pt-3 text-xs text-indigo-400">
          {loading ? (
            <LoadingIndicator className="h-6 w-6" aria-hidden="true" />
          ) : (
            <CheckCircleIcon className="-ml-1 mr-3 h-6 w-6" aria-hidden="true" />
          )}
          {loading ? "Saving..." : "Saved to draft"}
        </div>
      </div>
    </div>
  );
};
