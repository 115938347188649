import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faGrid } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { Tab } from "@headlessui/react";
import classNames from "../../../../utils/classNames";

export const ViewLayoutSelect = ({ onChange, selectedView }) => {
  const layouts = [
    { label: "Grid", icon: faGrid, value: "grid" },
    { label: "List", icon: faList, value: "list" },
  ];
  return (
    <Tab.Group defaultIndex={layouts.findIndex((layout) => layout.value === selectedView)}>
      <Tab.List className="flex space-x-1 rounded-md bg-gray-100 p-0.5">
        {layouts.map((layout, index) => (
          <Tab
            key={index}
            onClick={() => onChange(layout.value)}
            className={({ selected }) =>
              classNames(
                "flex items-center rounded py-[0.4375rem] pl-2 pr-2 text-sm font-medium text-gray-600 transition duration-200 lg:pr-3",
                selected ? "bg-white  shadow" : " hover:bg-white/[0.5]"
              )
            }
          >
            <FontAwesomeIcon icon={layout.icon} className="text-gray-500" />
            <span className="sr-only lg:not-sr-only lg:ml-2">{layout.label}</span>
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
};
