import { faTrashAlt } from "@awesome.me/kit-989a8e6dbe/icons/classic/light";
import { faWarning } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { Button } from "../../../UI/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RemoveListingsModal = ({ isOpen, onCancel, onAccept, loading }) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle">
                <div>
                  <div className="bg-danger-100 mx-auto flex h-12 w-12 items-center justify-center rounded-full">
                    <FontAwesomeIcon icon={faWarning} className="text-danger-600 text-xl" />
                  </div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg font-medium leading-6 text-gray-900"
                    >
                      Remove all listings?
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to remove all listings? This action cannot be undone
                        and all modifications will be lost.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="btn-group mt-5 w-full sm:mt-6">
                  <button
                    type="button"
                    className="btn btn-neutral flex-grow"
                    onClick={onCancel}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <Button
                    type="button"
                    className="btn btn-danger flex-grow"
                    onClick={onAccept}
                    disabled={loading}
                    label="Yes, remove all listings"
                    icon={faTrashAlt}
                    showLoading={loading}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
