import React, { useRef } from "react";
import "@pqina/pintura/pintura.css";
import { PinturaEditor } from "@pqina/react-pintura";
import { getEditorDefaults, openDefaultEditor } from "@pqina/pintura";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@awesome.me/kit-989a8e6dbe/icons/classic/light";

export default function ImageEditor({
  helperText,
  name,
  label,
  src,
  acceptedMimeTypes = ["image/*"],
  limitedHeight = false,
  onUpdate,
}) {
  const [files, setFiles] = useState([]);
  const editorConfig = getEditorDefaults();
  const { register, setValue } = useFormContext();

  // If existing image src is provided, show thumb
  useEffect(() => {
    if (src) {
      fetch(src).then(async (response) => {
        const contentType = response.headers.get("content-type");
        const blob = await response.blob();
        const file = new File([blob], name, { contentType });
        // create preview
        setFiles([
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ]);
      });
    }
  }, [src]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedMimeTypes,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setValue(name, acceptedFiles);
      onUpdate(acceptedFiles);
    },
  });

  const editImage = (image, done) => {
    const imageFile = image.pintura ? image.pintura.file : image;
    const imageState = image.pintura ? image.pintura.data : {};

    const editor = openDefaultEditor({
      src: imageFile,
      imageState,
    });

    editor.on("close", () => {
      // nothing
    });

    editor.on("process", ({ dest, imageState }) => {
      Object.assign(dest, {
        pintura: { file: imageFile, data: imageState },
      });
      done(dest);
    });
  };

  const removeImage = (image) => {
    const updatedFiles = files.filter((file) => file !== image);
    setFiles(updatedFiles);
    setValue(name, updatedFiles);
    onUpdate(null);
  };

  const thumbs = files.map((file, index) => (
    <div key={file.name} className="group relative">
      <div className="cursor-pointer rounded-xl border-4 border-gray-300 p-3 transition group-hover:border-indigo-500">
        <img
          src={file.preview}
          alt=""
          className={`w-auto object-contain transition group-hover:opacity-50 ${
            limitedHeight ? "max-h-[400px]" : ""
          }`}
        />
      </div>
      <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 gap-2 opacity-0 transition group-hover:opacity-100">
        <button
          type="button"
          className="btn btn-icon"
          onClick={() =>
            editImage(file, (output) => {
              // e.preventDefault();
              const updatedFiles = [...files];

              // replace original image with new image
              updatedFiles[index] = output;

              // revoke preview URL for old image
              if (file.preview) URL.revokeObjectURL(file.preview);

              // set new preview URL
              Object.assign(output, {
                preview: URL.createObjectURL(output),
              });

              // update view
              setFiles(updatedFiles);
              setValue(name, updatedFiles);
              onUpdate(updatedFiles);
            })
          }
        >
          <FontAwesomeIcon icon={faPencil} />
        </button>
        <button onClick={() => removeImage(file)} type="button" className="btn btn-danger btn-icon">
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      </div>
    </div>
  ));

  return (
    <section className="min-h-[200px] w-full">
      <label htmlFor={name}>{label}</label>
      {/*{files.length === 0 ? (*/}
      <div
        className="mb-4 flex w-full justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5"
        style={{ display: files.length === 0 ? "block" : "none" }}
        {...getRootProps()}
      >
        <input {...register(name)} id={name} {...getInputProps()} />

        <div className="flex flex-col items-center justify-center space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <span className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
              <span>Upload a file</span>
            </span>
            <span className="pl-1">or drag and drop</span>
          </div>

          <p className="text-xs text-gray-500">{helperText}</p>
          {/* {errors[name] && (
            <p className="text-xs text-danger-500">{errors[name].message}</p>
          )} */}
        </div>
      </div>
      {/*) : null}*/}
      {/*<div className="flex space-x-4">{thumbs}</div>*/}
      <div className="flex w-full justify-center">{thumbs}</div>
    </section>
  );
}
