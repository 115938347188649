import React from "react";
import { connectStats } from "react-instantsearch-dom";

export const SelectAllCheckbox = connectStats(({ nbHits, onChange, loading, selectedCount }) => {
  console.log("SelectAllCheckbox", nbHits, selectedCount, loading);
  const checked = selectedCount === nbHits && nbHits > 0;
  const selectAllDisabledClass = loading ? "opacity-50 cursor-not-allowed" : "";

  return (
    <div className="flex items-center space-x-5">
      <div
        className={`relative flex flex-grow items-center whitespace-nowrap ${selectAllDisabledClass}`}
      >
        <div className="flex h-5 items-center">
          <input
            id="select_all"
            name="select_all"
            type="checkbox"
            checked={checked}
            className={`h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 ${selectAllDisabledClass}`}
            onChange={(event) => {
              onChange(event, { nbHits });
            }}
            disabled={loading}
          />
        </div>
        <div className="ml-2 mt-1 text-sm">
          <label htmlFor="select_all" className="font-normal text-gray-700" disabled={loading}>
            {loading ? `Selecting ${nbHits} products...` : "Select all"}
          </label>
        </div>
      </div>
    </div>
  );
});
