import React from "react";

function ExternalVehicleTranslationCardSelectCell({
  cellValue,
  lookupData,
  onPropertyChange,
}) {
  const handleValueChange = (e) => {
    let id = e.target.value;
    if (!id) onPropertyChange({ id: null, name: null });
    else {
      let selectedValue = lookupData.find((x) => x.id == id);
      onPropertyChange(selectedValue);
    }
  };

  return (
    <td
      className={`col-2 p-2 border-right border-bottom ${
        cellValue ? "" : "bg-secondary"
      }`}
    >
      <select
        className="form-control form-control-sm"
        value={cellValue.id ?? undefined}
        onChange={(e) => handleValueChange(e)}
      >
        <option key={null} value=""></option>
        {lookupData.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    </td>
  );
}

export default ExternalVehicleTranslationCardSelectCell;
