import React, { useState, useEffect } from "react";
import { Form, RadioButton, FormModal, Button } from "../../UI/components";
import dayjs from "dayjs";
import { useForm, useController } from "react-hook-form";

export default function RequestPickupsModal({ closeModal, isOpen }) {
  const [isLoading, setIsLoading] = useState(false);

  const today = dayjs().format("YYYY-MM-DD");

  const nextFiveBusinessDaysAfterToday = () => {
    const today = dayjs();
    const days = [];
    let day = today;
    while (days.length < 5) {
      day = day.add(1, "day");
      if (day.day() !== 0 && day.day() !== 6) {
        days.push(day);
      }
    }
    return days;
  };

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {},
  });

  const { field } = useController({
    control,
    name: "pickup_preference",
    rules: { required: true },
  });
  const [pickupPreference, setPickupPreference] = useState(today);

  const onCancel = () => {
    closeModal(null);
  };

  // setValue to today by default
  useEffect(() => {
    setValue("pickup_preference", today);
  }, [setValue]);

  const onRadioChange = (e) => {
    const value = e.target.value;
    console.log(value);
    setPickupPreference(value);
    setValue("pickup_preference", value);
  };

  const onSubmit = (data) => {
    console.log("onSubmit");
    // fake delay so user can see something is happening
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      closeModal(data);
    }, 250);
  };

  return (
    <FormModal title="Request Pickup" {...{ onCancel, isOpen }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-4">
          <p className="my-3 text-sm leading-5 text-gray-500"></p>

          <div className="my-4 flex items-center">
            <input
              onChange={(e) => onRadioChange(e)}
              key={`preference-asap`}
              type="radio"
              name="pickup_preference"
              checked={pickupPreference === today}
              id={`preference-asap`}
              value={today}
              required
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label
              htmlFor={`preference-asap`}
              className="ml-3 block text-sm font-medium text-gray-700"
            >
              ASAP
            </label>
          </div>

          <p className="text-xs">Or no sooner than</p>

          {nextFiveBusinessDaysAfterToday().map((date, index) => (
            <div key={index} className="my-4 flex items-center">
              <input
                onChange={(e) => onRadioChange(e)}
                key={`preference-${index}`}
                type="radio"
                name="pickup_preference"
                checked={pickupPreference === date.format("YYYY-MM-DD")}
                id={`preference-${index}`}
                value={date.format("YYYY-MM-DD")}
                required
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label
                htmlFor={`preference-${index}`}
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {date.format("ddd, D MMMM")}
              </label>
            </div>
          ))}
        </div>
        <div className="m-0 mt-4 flex justify-end">
          <div className="btn-group">
            <Button label="Cancel" type="button" className="btn btn-neutral" onClick={onCancel} />
            <Button label="Submit" type="submit" className="btn" showLoading={isLoading} />
          </div>
        </div>
      </form>
    </FormModal>
  );
}
