import { faPlus, faSearch } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, Fragment, useLayoutEffect } from "react";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import LocationEditor from "./components/LocationEditor";
import { Button, Checkbox } from "../UI/components";
import {
  faBarcode,
  faClipboardList,
  faPencil,
  faShippingFast,
  faTruckArrowRight,
  faWarning,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import dayjs from "dayjs";
import PackagingModal from "../Products/components/PackagingModal";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import FulfillmentHeader from "./components/Fulfillment/FulfillmentHeader";
import ListStatusBadge from "./components/Fulfillment/ListStatusBadge";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const titleize = (str) => {
  if (!str) return "";
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export default function DistributorHandovers({
  distributor,
  distributor_location,
  handovers,
  team,
  app_contexts,
}) {
  return (
    <>
      <Header app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8 pb-24 pt-5">
            <FulfillmentHeader distributor_location={distributor_location} />
            <div className="flex w-full items-center justify-between">
              <div className="flex flex-grow space-x-5">
                <div className="relative max-w-[50vw] flex-grow">
                  <h2 className="m-0 text-3xl font-extrabold tracking-tight text-gray-900">
                    Awaiting Handover
                  </h2>
                  <p className="text-sm text-gray-500"></p>
                </div>
                <div className="relative max-w-[33vw] flex-grow"></div>
              </div>
            </div>

            {handovers && handovers.length ? (
              <div className="w-full space-y-5 overflow-clip rounded-lg bg-white pb-5 shadow">
                <table className="text-xs">
                  <thead>
                    <tr>
                      <th className="relative pl-4 pr-0"></th>
                      <th>Location</th>
                      <th>Pickup Window</th>
                      <th>Reference</th>
                      <th>Carrier</th>
                      <th>Shipments</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {handovers.map((handover, fIndex) => (
                      <>
                        <tr
                          key={handover.id}
                          className={classNames(
                            fIndex === 0 ? "border-indigo-500" : "border-indigo-400",
                            "border-b-0 border-t-2"
                          )}
                        >
                          <td className="relative pl-4 pr-0"></td>
                          <td>
                            <span
                              className={classNames(
                                "rounded px-2 py-1.5 font-medium uppercase",
                                "bg-gray-100 text-gray-900"
                              )}
                            >
                              {handover.distributor_location.code}
                            </span>
                          </td>
                          <td className="">{handover.pickup_window}</td>
                          <td>{handover.external_id}</td>
                          <td>{handover.carrier}</td>
                          <td>{handover.deliveries.length}</td>
                          <td>
                            <ListStatusBadge type="handover" status={handover.status} />
                          </td>
                          {/* <td className="text-center">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "AUD",
                              }).format(fulfillment.shipping_charge)}
                            </td>
                            <td className="text-center">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "AUD",
                              }).format(fulfillment.shipping_tax)}
                            </td> */}
                        </tr>
                        {handover.deliveries.map((shipment, sIndex) => (
                          <tr key={shipment.id} className="border-t border-gray-200">
                            <td></td>
                            <td>{shipment.identifier}</td>
                            <td colspan="4">
                              <div className="grid grid-flow-col grid-cols-6">
                                <div className="col-span-1">
                                  <a href={shipment.tracking_url} target="_blank" key={shipment.id}>
                                    {shipment.tracking_number}
                                  </a>
                                </div>
                                <div className="col-span-1">{shipment.parcel_count} Boxes</div>
                                <div className="col-span-1">{shipment.weight_kg} kg</div>
                              </div>
                            </td>
                            <td>
                              <ListStatusBadge type="delivery" status={shipment.delivery_status} />
                            </td>
                          </tr>
                        ))}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="flex w-full items-center justify-center p-8 text-sm text-gray-500">
                No handovers found.
              </div>
            )}
          </div>
        </div>
      </section>
      <Toaster />
    </>
  );
}
