import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DeliveryActions({ delivery, actions, isLoading }) {
  return (
    <div className="divide-y divide-gray-100">
      {actions
        .filter((x) => x.some((y) => y.supported))
        .map((actionGroup, groupIndex) => (
          <ul className="py-1" key={groupIndex}>
            {actionGroup
              .filter((x) => x.supported)
              .map((action, actionIndex) => (
                <li key={actionIndex} className=" group hover:bg-gray-100">
                  <button
                    disabled={isLoading || !action.supported}
                    aria-disabled={isLoading || !action.supported}
                    type="button"
                    href="#"
                    onClick={(e) => {
                      action.handler(delivery.id);
                    }}
                    className={classNames(
                      action.supported
                        ? "text-gray-700 group-hover:text-gray-800"
                        : "text-gray-400 group-hover:text-gray-500",
                      "w-100 flex items-center px-4 py-2 text-xs"
                    )}
                  >
                    <FontAwesomeIcon
                      icon={action.icon}
                      className={classNames(
                        action.supported
                          ? "text-indigo-500 group-hover:text-indigo-700"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 h-3 w-3"
                      )}
                    />
                    {action.name}
                  </button>
                </li>
              ))}
          </ul>
        ))}
    </div>
  );
}
