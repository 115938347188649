import React, { useState } from "react";
import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Button, FormModal } from "../UI/components";

export default function CheckoutForm({ fulfilment, closeModal, isOpen }) {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    setIsLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsLoading(false);
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: location.href,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setIsLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const onCancel = () => {
    setIsLoading(false);
    closeModal();
  };

  const formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  });

  return (
    <>
      <FormModal title="Pay Fulfilment" {...{ isOpen, onCancel }}>
        <form onSubmit={handleSubmit}>
          <div className="py-5">
            <div className="text-medium mb-3 flex w-full items-center justify-between rounded-md bg-indigo-50 py-2 px-3 text-sm text-indigo-500">
              <span>Fulfilment: {fulfilment.fulfillment_name}</span>
              <span className="font-bold">
                Total: {formatter.format(parseFloat(fulfilment.total ?? 0))}
              </span>
            </div>

            <PaymentElement />
          </div>

          <div className="flex items-center justify-end">
            <div className="btn-group">
              <Button
                className="btn btn-neutral"
                label="Cancel"
                disabled={false}
                onClick={onCancel}
              />
              <Button showLoading={isLoading} type="submit" className="btn" label="Pay now" />
            </div>
          </div>
          {errorMessage && (
            <div className="bg-danger-100 text-danger-600 mt-3 w-full rounded-md p-2 text-center text-sm">
              {errorMessage}
            </div>
          )}
        </form>
      </FormModal>
    </>
  );
}
