import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, FormModal, Input } from "../../UI/components";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

export default function InviteTeamMemberModal({ closeModal, isOpen, team, onUpdate }) {
  const [sending, setSending] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEditor, setIsEditor] = useState(false);
  const [isViewer, setIsViewer] = useState(false);
  const [isBilling, setIsBilling] = useState(false);

  const onCancel = () => {
    closeModal(null);
  };

  useEffect(() => {
    if (isAdmin) {
      setIsEditor(true);
      setIsViewer(true);
      setIsBilling(true);
    }
  }, [isAdmin]);

  const onOthersCheckChange = () => {
    if (isEditor || isViewer || isBilling) {
      setIsAdmin(false);
    }
  };

  const onSubmit = async (data) => {
    setSending(true);

    const formData = new FormData();

    formData.append("user[name]", data.name);
    formData.append("user[email]", data.email);
    formData.append("user[admin]", isAdmin);
    formData.append("user[editor]", isEditor);
    formData.append("user[viewer]", isViewer);
    formData.append("user[billing]", isBilling);
    formData.append("user[team_id]", team.id);

    try {
      const result = await axios({
        method: "post",
        url: `/invite-team-member`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: formData,
      });

      toast.success("New Team Member successfully invited");
      await onUpdate();
      setSending(false);
      onCancel();
    } catch (e) {
      toast.error(e.response.data["error"]);
      setSending(false);
      onCancel();
    }
  };

  return (
    <>
      <FormModal title="Invite new team member" {...{ isOpen, onCancel }}>
        <Form onSubmit={onSubmit}>
          <div className="py-5">
            <div className="grid grid-cols-1">
              <div className="space-y-6">
                <div className="form-group">
                  <Input name="name" label="Name" type="text" required />
                </div>
              </div>
              <div className="space-y-6">
                <div className="form-group">
                  <Input name="email" label="Email" type="text" required />
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="space-y-6">
                  <div className="form-group">
                    <Checkbox
                      name="admin"
                      label="Admin"
                      checked={isAdmin}
                      onChange={(value) => {
                        setIsAdmin(value.target.checked);
                        // onAdminCheckChange(value);
                      }}
                    />
                  </div>
                </div>
                <div className="space-y-6">
                  <div className="form-group">
                    <Checkbox
                      name="editor"
                      label="Editor"
                      checked={isEditor}
                      onChange={(value) => {
                        setIsEditor(value.target.checked);
                        onOthersCheckChange();
                      }}
                    />
                  </div>
                </div>
                <div className="space-y-6">
                  <div className="form-group">
                    <Checkbox
                      name="viewer"
                      label="Viewer"
                      checked={isViewer}
                      onChange={(value) => {
                        setIsViewer(value.target.checked);
                        onOthersCheckChange();
                      }}
                    />
                  </div>
                </div>
                <div className="space-y-6">
                  <div className="form-group">
                    <Checkbox
                      name="billing"
                      label="Billing"
                      checked={isBilling}
                      onChange={(value) => {
                        setIsBilling(value.target.checked);
                        onOthersCheckChange();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end pb-5">
            <div className="btn-group flex items-center space-x-2">
              <Button
                className="btn btn-neutral"
                label="Cancel"
                disabled={false}
                onClick={onCancel}
              ></Button>
              <Button
                type="submit"
                className="btn"
                showLoading={sending}
                label="Send Invite"
              ></Button>
            </div>
          </div>
        </Form>
      </FormModal>
    </>
  );
}
