import React, { useState, useRef } from "react";
import axios from "axios";
import CrossSellProductEditor from "./CrossSellProductEditor";
import toast from "react-hot-toast";
import { Button } from "../../UI/components";
import { faArrowsLeftRight, faCartPlus } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";

function CrossSellProductList({ product, cross_sell_products, settings, onUpdate }) {
  const [selected_item, setSelectedItem] = useState({
    id: null,
    product: {
      id: product.id,
      sku: product.sku,
      type: product.type,
      brand_id: product.brand_id,
      slug: product.slug,
    },
    cross_sell_product: { id: null, sku: "", type: null, slug: "" },
    relationship_type: "",
    catalogue_notes: "",
  });

  const addCrossSellProduct = (e) => {
    e.preventDefault();

    setSelectedItem({
      id: null,
      product: {
        id: product.id,
        sku: product.sku,
        type: product.type,
        brand_id: product.brand_id,
        slug: product.slug,
      },
      cross_sell_product: { id: null, sku: "", type: null, slug: "" },
      relationship_type: "",
      catalogue_notes: "",
    });

    openModal();
  };

  const editCrossSellProduct = (e, cross_sell_product) => {
    e.preventDefault();

    setSelectedItem({
      id: cross_sell_product.id,
      product: cross_sell_product.product,
      cross_sell_product: cross_sell_product.related_product,
      relationship_type: cross_sell_product.relationship_type,
      catalogue_notes: cross_sell_product.catalogue_notes,
    });

    openModal();
  };

  const deleteCrossSellProduct = async (e, id) => {
    e.preventDefault();
    if (confirm("Are you sure you want to delete this?")) {
      try {
        const requestConfig = {
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
        };

        let result = await axios.delete(`/cross_sell_products/${id}`, requestConfig);

        toast.success(result.data.success);

        await onUpdate();
      } catch (error) {
        toast.error(error?.response?.data?.message ?? "Error Deleting Cross-sell");
      }
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  async function closeModal() {
    setIsOpen(false);
  }

  const rows = cross_sell_products.map((c, index) => {
    return (
      <tr key={c.id} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
        <td width={`15%`} className="whitespace-nowrap px-6 py-4 text-xs font-medium text-gray-900">
          <a
            href={`/b/${c.related_product.brand}/products/${c.related_product.slug}/edit`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            {c.related_product.sku}
          </a>
        </td>
        <td width={`30%`} className="px-6 py-4 text-xs text-gray-500">
          {c.related_product.title}
        </td>
        <td className="whitespace-nowrap px-6 py-4 text-xs text-gray-500">{c.relationship_type}</td>
        <td width={`30%`} className="whitespace-normal px-6 py-4 text-xs text-gray-500">
          {c.catalogue_notes}
        </td>
        <td className="whitespace-nowrap px-6 py-4 text-right text-xs font-medium">
          <div className="flex justify-end space-x-5">
            <button
              onClick={(e) => deleteCrossSellProduct(e, c.id)}
              className="text-danger-600 hover:text-danger-900"
            >
              Remove
            </button>
            <button
              onClick={(e) => editCrossSellProduct(e, c)}
              className="text-indigo-600 hover:text-indigo-900"
            >
              Edit
            </button>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      <CrossSellProductEditor
        {...{ closeModal, isOpen, onUpdate, settings, product }}
        {...selected_item}
      />
      <div className="row">
        <div className="col-md-12 pb-2 pt-5 text-right">
          <Button
            label="Add Cross-sell"
            icon={faCartPlus}
            className="btn btn-sm"
            type="button"
            onClick={(e) => addCrossSellProduct(e)}
          />
        </div>
      </div>

      {cross_sell_products.length > 0 && (
        <div className="w-full">
          <div className="overflow-hidden sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    SKU
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Notes
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit / Remove</span>
                  </th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default CrossSellProductList;
