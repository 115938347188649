import produce from "immer";
import { createWithEqualityFn } from "zustand/traditional";
import Api from "../api";
import { formatErrors } from "../utils";

const initialState = {
  stores: [],
  importList: [],
  importListLoading: true,
  totalRows: 0,
  currentPage: 1,
  perPage: 10,
  isBulkPublishModalOpen: false,
  publishLoading: false,
  publishErrors: null,
  removeError: null,
  isPublishSuccessful: false,
  publishedStores: [],
  selectedStores: [],
  allImportListingIds: [],
  allListingIds: [],
  brandSellerAgreements: [],
  merchantProductsCount: 0,
  bulkPublishJobId: null,
  bulkPublishProgress: null,
  bulkPublishTotal: null,
  bulkPublishAt: null,
};

export const MarketplaceImportStore = (preloadedState = initialState) => {
  const marketplaceAPI = new Api(preloadedState.apiKey, preloadedState.location);

  return createWithEqualityFn((set, get) => ({
    ...initialState,
    ...preloadedState,
    setListingState: (data) => {
      set(
        produce((draft) => {
          draft.importList = data.data;
          draft.totalRows = parseInt(data.meta.total_items);
          draft.currentPage = parseInt(data.meta.page);
          draft.perPage = parseInt(data.meta.per_page);
          draft.allImportListingIds = data.meta.all_import_listing_ids;
          draft.allListingIds = data.meta.all_listing_ids;
          draft.brandSellerAgreements = data.meta.brand_seller_agreements;
        })
      );
    },
    setPublishLoading: (bool) => {
      set(
        produce((draft) => {
          draft.publishLoading = bool;
          draft.publishErrors = null;
        })
      );
    },
    setBulkPublishLoading: (bool) => {
      set(
        produce((draft) => {
          draft.publishLoading = bool;
          draft.publishErrors = null;
          draft.bulkPublishProgress = 0;
        })
      );
    },
    setPublishSuccess: (response) => {
      set(
        produce((draft) => {
          draft.publishLoading = false;
          draft.publishErrors = null;
          draft.isBulkPublishModalOpen = false;
          draft.isPublishSuccessful = true;
          draft.publishedStores = response.data.successfully_published_stores;
        })
      );
    },
    setBulkPublishSuccess: (response) => {
      set(
        produce((draft) => {
          draft.publishLoading = false;
          draft.publishErrors = null;
          draft.isBulkPublishModalOpen = false;
          draft.bulkPublishJobId = response.data.job_id;
        })
      );
    },
    setPublishErrors: (error) => {
      set(
        produce((draft) => {
          draft.publishLoading = false;
          draft.publishErrors = error;
        })
      );
    },
    setStoresSuccess: (data) => {
      set(
        produce((draft) => {
          draft.stores = data;
        })
      );
    },
    setSelectedStores: (stores) => {
      set(
        produce((draft) => {
          draft.selectedStores = stores;
        })
      );
    },
    setBulkPublishModal: (bool) => {
      set(
        produce((draft) => {
          draft.isBulkPublishModalOpen = bool;
          draft.publishErrors = null;
          draft.publishLoading = false;
        })
      );
    },
    setPublishSuccessModal: () => {
      set(
        produce((draft) => {
          draft.isPublishSuccessful = false;
          draft.publishErrors = null;
          draft.publishLoading = false;
        })
      );
    },
    addToImportList: async (listingId) => {
      const response = await marketplaceAPI.addToImportList(listingId);
      if (response?.status === 200) {
        get().fetchImportListings(get().currentPage, get().perPage);
      }
      return response;
    },
    fetchImportListings: async (page, perPage, query) => {
      set(
        produce((draft) => {
          draft.importListLoading = true;
          draft.searchQuery = query;
        })
      );
      const response = await marketplaceAPI.loadImportListings(page, perPage, query);
      get().setListingState(response.data);
      set(
        produce((draft) => {
          draft.importListLoading = false;
        })
      );
      return response;
    },

    fetchStores: async () => {
      const response = await marketplaceAPI.loadStores();
      get().setStoresSuccess(response);
      return response;
    },

    publishSingleListing: async (listingId) => {
      get().setPublishLoading(true);
      const response = await marketplaceAPI.publishImportList(listingId, get().selectedStores);

      if (response?.status === 200) {
        get().setPublishSuccess(response);
      } else {
        if (response?.data?.errors?.length > 0) {
          get().setPublishErrors(formatErrors(response.data.errors));
        } else {
          get().setPublishErrors("Publish failed, please try again.");
        }
      }

      get().setPublishLoading(false);

      return response;
    },

    publishBulkListings: async () => {
      get().setBulkPublishLoading(true);
      const response = await marketplaceAPI.bulkPublishImportList(
        get().allImportListingIds,
        get().selectedStores
      );

      if (response?.status === 200) {
        get().setBulkPublishSuccess(response);
      } else {
        console.log("api response", response?.data);
        if (response?.data?.errors?.length > 0) {
          get().setPublishErrors(formatErrors(response.data.errors));
        } else {
          get().setPublishErrors("Publish failed, please try again.");
        }
      }

      return response;
    },

    removeBulkListings: async () => {
      // get().setBulkPublishLoading(true);
      const response = await marketplaceAPI.bulkRemoveImportList();

      if (response?.status === 200) {
        get().fetchImportListings(1, get().perPage);
      } else {
        if (response?.data?.errors?.length > 0) {
          get().setPublishErrors(formatErrors(response.data.errors));
        } else {
          get().setPublishErrors("Removal failed, please try again.");
        }
      }

      return response;
    },

    fetchBulkPublishProgress: async () => {
      const response = await marketplaceAPI.bulkPublishProgress(get().bulkPublishJobId);

      if (response.status === 200) {
        set(
          produce((draft) => {
            draft.bulkPublishProgress = response?.data?.progress || 0;
            draft.bulkPublishTotal = response?.data?.total || null;
            draft.bulkPublishAt = response?.data?.at || null;
          })
        );
      } else {
        get().resetBulkPublishProgress();
      }

      return response;
    },

    resetBulkPublishProgress: () => {
      set(
        produce((draft) => {
          draft.bulkPublishProgress = null;
          draft.bulkPublishTotal = null;
          draft.bulkPublishAt = null;
        })
      );
    },

    updateImportListing: async (listingId, payload) => {
      const response = await marketplaceAPI.updateImportListing(listingId, payload);

      if (response?.status === 200) {
        set(
          produce((draft) => {
            draft.importList = get().importList.map((listing) => {
              if (listing.id === listingId) {
                return {
                  ...listing,
                  ...response.data,
                };
              }
              return listing;
            });
          })
        );
      }

      return response;
    },
    removeImportListing: async (listingId) => {
      const response = await marketplaceAPI.destroyImportListing(listingId);

      if (response?.status === 200) {
        if (get().importList.length === 1) {
          get().fetchImportListings(get().currentPage - 1, get().perPage);
        } else {
          get().fetchImportListings(get().currentPage, get().perPage);
        }
      } else {
        set(
          produce((draft) => {
            draft.removeError = "Cannot remove item, please try again.";
          })
        );
      }

      return response;
    },
  }));
};
