import { faGripDotsVertical } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const DragHandle = (props) => {
  return (
    <div className="relative block cursor-grab px-1" {...props}>
      <FontAwesomeIcon icon={faGripDotsVertical} className="-left-2 top-2 h-4 w-auto" />
    </div>
  );
};
