import React, { useState, useEffect } from "react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCarMirrors,
  faPencil,
  faEarthAmericas,
  faSpinnerThird,
  faSearch,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";

import clsx from "clsx";

function useProductTypes(searchTerm) {
  const [productTypes, setProductTypes] = React.useState([]);

  React.useEffect(() => {
    if (searchTerm.trim() !== "") {
      let isFresh = true;
      fetchProductTypes(searchTerm).then((productTypes) => {
        // if the searchTerm is not found exactly in productTypes and searchTerm is more than 3 characters in length, return a dummy productType using the searchTerm, with the id of 0, modify the title to be "Create {searchTerm}"
        if (searchTerm.length > 3 && !productTypes.find((pt) => pt.title === searchTerm)) {
          let productTitle = searchTerm;
          if (searchTerm.charAt(0) === searchTerm.charAt(0).toLowerCase()) {
            productTitle = searchTerm.charAt(0).toUpperCase() + searchTerm.slice(1);
          }

          // prepend the dummy productType to the productTypes array
          productTypes.unshift({ id: null, title: productTitle });
        }
        if (isFresh) setProductTypes(productTypes);
      });
      return () => (isFresh = false);
    }
  }, [searchTerm]);

  return productTypes;
}

const cache = {};
function fetchProductTypes(value) {
  if (cache[value]) {
    return Promise.resolve(cache[value]);
  }

  return fetch("/product_types_search?term=" + value)
    .then((res) => res.json())
    .then((result) => {
      cache[value] = result;
      return result;
    });
}

export default function ProductTypeCombobox({ selectedOption, handleProductTypeSelect }) {
  const [query, setQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const [selectedProductType, setSelectedProductType] = useState(selectedOption || null);
  const [isLoading, setIsLoading] = useState(false);
  const productTypes = useProductTypes(query);

  const handleSelect = async (value) => {
    console.log("value", value);
    if (value && value.id === null) {
      setIsLoading(true);
      const response = await fetch("/product-type", {
        method: "POST",
        headers: {
          ...ReactOnRails.authenticityHeaders(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ title: value.title }),
      });
      const newProductType = await response.json();
      setSelectedProductType(newProductType);
      handleProductTypeSelect(newProductType);
      setIsLoading(false);
    } else {
      setSelectedProductType(value);
      handleProductTypeSelect(value);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [productTypes]);

  return (
    <Combobox as="div" value={selectedProductType} onChange={(e) => handleSelect(e)}>
      <Combobox.Label className="flex w-full items-center justify-between space-x-2">
        <span>Product Type</span>
        {selectedProductType?.code && (
          <span className="flex items-center justify-center rounded-full bg-gray-100 px-2 text-[10px] text-gray-700">
            {selectedProductType.code}
          </span>
        )}
      </Combobox.Label>
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-8 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          onChange={(event) => {
            setIsLoading(true);
            setIsFocused(false);
            setQuery(event.target.value);
          }}
          onBlur={() => {
            setIsFocused(false);
            setIsLoading(false);
            setQuery("");
          }}
          displayValue={(pt) => (pt === null ? "" : pt.title)}
        />
        {isLoading ? (
          <FontAwesomeIcon
            icon={faSpinnerThird}
            className="absolute left-3 top-[13px] animate-spin text-sm text-indigo-500"
          />
        ) : query ? (
          <FontAwesomeIcon
            icon={faSearch}
            className={clsx("absolute left-3 top-[13px] text-xs text-indigo-500")}
          />
        ) : (
          selectedProductType && (
            <FontAwesomeIcon
              icon={
                selectedProductType.team_id
                  ? faPencil
                  : selectedProductType.universal
                  ? faEarthAmericas
                  : faCarMirrors
              }
              className={clsx(
                "absolute left-3 top-[13px] text-xs",
                selectedProductType.team_id
                  ? "text-stone-500"
                  : selectedProductType.universal
                  ? "text-success-500"
                  : "text-indigo-500"
              )}
            />
          )
        )}

        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {!isLoading &&
            productTypes.length >= 0 &&
            productTypes.map((pt) => (
              <Combobox.Option
                key={pt.id}
                value={pt}
                className={({ active }) =>
                  clsx(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <div className="flex items-center space-x-2" title={pt.title}>
                    <span>
                      <FontAwesomeIcon
                        icon={
                          pt.id === null || pt.team_id
                            ? faPencil
                            : pt.universal
                            ? faEarthAmericas
                            : faCarMirrors
                        }
                        className={clsx(
                          "text-xs",
                          pt.team_id
                            ? "text-stone-500"
                            : pt.universal
                            ? "text-success-500"
                            : "text-indigo-500"
                        )}
                      />
                    </span>
                    <span
                      className={clsx(
                        "block truncate text-xs",
                        selected && "font-semibold",
                        pt.id === null && "font-semibold"
                      )}
                    >
                      {/* if pt.id === 0 prefix title with Create {title}, otherwise just render title */}
                      {pt.id === null ? "Create " + pt.title : pt.title}
                    </span>

                    {pt.code && <span className="text-xs">({pt.code})</span>}

                    {selected && (
                      <span
                        className={clsx(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </div>
                )}
              </Combobox.Option>
            ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
