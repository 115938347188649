// create a function react component called ReindexProducts that renders a button with the text "Reindex Products" and an icon of a refresh, on click it should open a modal to confirm with the text "Are you sure you want to reindex all products?" and two buttons "Yes" and "No" that close the modal. On confirm it should make a POST request to /b/:brand_id/products/reindex with the authenticity token in the headers and on success it should show a success toast with the text "Your products are reindexing and will appear soon" and on failure it should show an error toast with the text "Something went wrong, please try again"

import React from "react";
import { useState } from "react";
import { FormModal } from "../../UI/components";
import { Button } from "../../UI/components/Button";
import { faSync } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

const ReindexProducts = ({ brand }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onCancel = () => {
    setIsOpen(false);
  };

  const onSubmit = async () => {
    setIsOpen(false);
    try {
      const response = await axios.post(`/b/${brand.id}/products/reindex`, {
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });
      if (response.data.success) {
        toast.success("Products are reindexing.");
      } else {
        console.log("ERROR", response);
        toast.error(response.data.error);
      }
    } catch (e) {
      console.log("ERROR", e, response);
      toast.error("Something went wrong, please try again");
    }
  };

  return (
    <>
      <Button
        label="Reindex"
        icon={faSync}
        className="btn btn-warning group"
        iconClass="group-hover:animate-spin-once"
        onClick={() => setIsOpen(true)}
      />

      <FormModal
        title={`Reindex ${brand?.name} Products`}
        zIndex="50"
        size="md"
        {...{ isOpen, onCancel }}
      >
        <p className="mt-1 text-sm text-gray-500">
          This will force a reindex of all products and update all connected merchant product
          listings.
        </p>
        <div className="pt-8">
          <div className="grid grid-cols-1">
            <div className="flex items-end justify-end">
              <div className="btn-group">
                <Button label="Cancel" className="btn btn-neutral" onClick={onCancel} />
                <Button label="Start Reindex" className="btn" onClick={onSubmit} />
              </div>
            </div>
          </div>
        </div>
      </FormModal>
      <Toaster />
    </>
  );
};

export default ReindexProducts;
