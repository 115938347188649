const storeTitles = {
  ShopifyIntegration: "Shopify",
  WooCommerceIntegration: "WooCommerce",
};

const statusClass = (status) => {
  switch (status) {
    case "Synced":
      return {
        statusTextClass: "text-success-800",
        statusBgClass: "bg-success-100",
      };
    case "Linked":
      return {
        statusTextClass: "text-warning-800",
        statusBgClass: "bg-warning-100",
      };

    default:
      return {
        statusTextClass: "text-gray-800",
        statusBgClass: "bg-gray-100",
      };
  }
};

export default function useMerchantProductLabels(product) {
  const storeTitle = storeTitles[product?.store?.integration_type] || "";
  const { statusTextClass, statusBgClass } = statusClass(product?.status);

  return {
    storeTitle,
    statusTextClass,
    statusBgClass,
  };
}
