import clsx from "clsx";
import React from "react";

export default function OptionCheckbox({ attributeKey, defaultChecked, disabled, handler }) {
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:opacity-50"
          id={`attr_${attributeKey}`}
          defaultChecked={defaultChecked}
          disabled={disabled}
          onChange={(e) => handler(attributeKey, e.target.checked)}
        />
      </div>
      <div className="ml-3 text-sm">
        <label
          className={clsx("font-medium text-gray-700", disabled && "opacity-50")}
          htmlFor={`attr_${attributeKey}`}
        >
          {attributeKey}
        </label>
      </div>
    </div>
  );
}
