import React, { useState, useEffect, useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faTrash, faInputNumeric } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { debounce } from "lodash";
import { Button } from "../UI/components";

const taxIdTypes = [
  {
    country: "AE",
    type: "ae_trn",
    description: "United Arab Emirates TRN",
    regex: /^[0-9]{15}$/,
    example: "123456789012345",
  },
  {
    country: "AU",
    type: "au_abn",
    description: "Australian Business Number (AU ABN)",
    regex: /^[0-9]{11}$/,
    example: "12345678912",
  },
  {
    country: "AU",
    type: "au_arn",
    description: "Australian Taxation Office Reference Number",
    regex: /^[0-9]{12}$/,
    example: "123456789123",
  },
  {
    country: "BE",
    type: "eu_vat_be",
    description: "European VAT number",
    regex: /^BE[0-9]{10}$/,
    example: "BE0123456789",
  },
  {
    country: "BG",
    type: "eu_vat_bg",
    description: "Bulgaria - European VAT number",
    regex: /^BG[0-9]{9,10}$/, // Prefix with zero ‘0’ if the customer provides a 9 digit VAT number
    example: "BG1234567890",
  },
  {
    country: "BR",
    type: "br_cnpj",
    description: "Brazilian CNPJ number",
    regex: /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}\-[0-9]{2}$/,
    example: "01.234.456/5432-10",
  },
  {
    country: "BR",
    type: "br_cpf",
    description: "Brazilian CPF number",
    regex: /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$/,
    example: "123.456.789-87",
  },
  {
    country: "CA",
    type: "ca_bn",
    description: "Canadian BN",
    regex: /^[0-9]{9}$/,
    example: "123456789",
  },
  {
    country: "CA",
    type: "ca_gst_hst",
    description: "Canadian GST/HST number",
    regex: /^[0-9]{9}RT[0-9]{4}$/,
    example: "123456789RT0002",
  },
  {
    country: "CA",
    type: "ca_pst_bc",
    description: "Canadian PST number (British Columbia)",
    regex: /^PST-[0-9]{4}-[0-9]{4}$/,
    example: "PST-1234-5678",
  },
  {
    country: "CA",
    type: "ca_pst_mb",
    description: "Canadian PST number (Manitoba)",
    regex: /^[0-9]{6}-[0-9]{1}$/,
    example: "123456-7",
  },
  {
    country: "CA",
    type: "ca_pst_sk",
    description: "Canadian PST number (Saskatchewan)",
    regex: /^[0-9]{7}$/,
    example: "1234567",
  },
  {
    country: "CA",
    type: "ca_qst",
    description: "Canadian QST number (Québec)",
    regex: /^[0-9]{10}TQ[0-9]{4}$/,
    example: "1234567890TQ1234",
  },

  {
    country: "CL",
    type: "cl_tin",
    description: "Chilean TIN",
    regex: /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}-K$/,
    example: "12.345.678-K",
  },
  {
    country: "HR",
    type: "eu_vat_hr",
    description: "Croatia - European VAT number",
    regex: /^HR[0-9]{11}$/,
    example: "HR12345678912",
  },
  {
    country: "AT",
    type: "eu_vat_at",
    description: "Austria - European VAT number",
    regex: /^ATU[0-9]{7,9}$/,
    example: "ATU12345678",
  },
  {
    country: "CY",
    type: "eu_vat_cy",
    description: "Cyprus - European VAT number",
    regex: /^CY[0-9]{8}[A-Z]$/,
    example: "CY12345678Z",
  },
  {
    country: "CZ",
    type: "eu_vat_cz",
    description: "Czech Republic - European VAT number",
    regex: /^CZ[0-9]{8,10}$/,
    example: "CZ1234567890",
  },
  {
    country: "DK",
    type: "eu_vat_dk",
    description: "Denmark - European VAT number",
    regex: /^DK[0-9]{8}$/,
    example: "DK12345678",
  },
  {
    country: "EE",
    type: "eu_vat_ee",
    description: "Estonia - European VAT number",
    regex: /^EE[0-9]{9}$/,
    example: "EE123456789",
  },
  {
    country: "FI",
    type: "eu_vat_fi",
    description: "Finland - European VAT number",
    regex: /^FI[0-9]{8}$/,
    example: "FI12345678",
  },
  {
    country: "FR",
    type: "eu_vat_fr",
    description: "France - European VAT number",
    regex: /^FR[A-Z0-9]{9,11}$/, // made more forgiving - 11 characters May include alphabetical characters (any except O or I) as first or second or first and second characters. - FR + 12345678901 X1234567890 1X123456789 XX123456789
    example: "FRAB123456789",
  },
  {
    country: "DE",
    type: "eu_vat_de",
    description: "Germany - European VAT number",
    regex: /^DE[0-9]{9}$/,
    example: "DE123456789",
  },
  {
    country: "GR",
    type: "eu_vat_gr",
    description: "Greece - European VAT number",
    regex: /^EL[0-9]{9}$/,
    example: "EL123456789",
  },
  {
    country: "HK",
    type: "hk_br",
    description: "Hong Kong BR number",
    regex: /^[0-9]{8}$/,
    example: "12345678",
  },
  {
    country: "HU",
    type: "eu_vat_hu",
    description: "Hungary - European VAT number",
    regex: /^HU[0-9]{8,11}$/,
    example: "HU12345678",
  },
  {
    country: "IN",
    type: "in_gst",
    description: "Indian GST number",
    regex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z1-9]{4}$/,
    example: "12ABCDE3456FGZH",
  },
  {
    country: "ID",
    type: "id_npwp",
    description: "Indonesian NPWP number",
    regex: /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\.[0-9]{1}\-[0-9]{3}\.[0-9]{3}$/,
    example: "12.345.678.9-012.345",
  },
  {
    country: "IE",
    type: "eu_vat_ie",
    description: "Ireland - European VAT number",
    regex: /^IE[0-9]{7}[A-Z]{1,2}$/, // 8 or 9 characters Includes one or two alphabetical characters (last, or second and last, or last 2) - 1234567WA (companies) 1234567FA (individuals)
    example: "IE1234567WA",
  },
  {
    country: "IL",
    type: "il_vat",
    description: "Israel VAT",
    regex: /^[0-9]{9}$/,
    example: "000012345",
  },

  {
    country: "IT",
    type: "eu_vat_it",
    description: "Italy - European VAT number",
    regex: /^IT[0-9]{11}$/,
    example: "IT12345678912",
  },

  {
    country: "JP",
    type: "jp_cn",
    description: "Japanese Corporate Number (*Hōjin Bangō*)",
    regex: /^[0-9]{13}$/,
    example: "1234567891234",
  },

  {
    country: "JP",
    type: "jp_rn",
    description:
      "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
    regex: /^[0-9]{5}$/,
    example: "12345",
  },

  {
    country: "KR",
    type: "kr_brn",
    description: "Korean BRN",
    regex: /^[0-9]{3}-[0-9]{2}-[0-9]{5}$/,
    example: "123-45-67890",
  },
  {
    country: "LV",
    type: "eu_vat_lv",
    description: "Latvia - European VAT number",
    regex: /^LV[0-9]{11}$/,
    example: "LV12345678912",
  },
  {
    country: "LI",
    type: "li_uid_li",
    description: "Liechtensteinian UID number",
    regex: /^CHE[0-9]{9}$/,
    example: "CHE123456789",
  },
  {
    country: "LT",
    type: "eu_vat_lt",
    description: "Lithuania - European VAT number",
    regex: /^LT[0-9]{9,12}$/, // 9 or 12 characters
    example: "LT123456789123",
  },
  {
    country: "LU",
    type: "eu_vat_lu",
    description: "Luxembourg - European VAT number",
    regex: /^LU[0-9]{8}$/,
    example: "LU12345678",
  },

  {
    country: "MY",
    type: "my_frp",
    description: "Malaysian FRP number",
    regex: /^[0-9]{8}$/,
    example: "12345678",
  },
  {
    country: "MY",
    type: "my_itn",
    description: "Malaysian ITN",
    regex: /^C(\s)?[0-9]{10}$/,
    example: "C 1234567890",
  },
  {
    country: "MY",
    type: "my_sst",
    description: "Malaysian SST number",
    regex: /^A[0-9]{2}-[0-9]{4}-[0-9]{8}$/,
    example: "A12-3456-78912345",
  },

  {
    country: "MT",
    type: "eu_vat_mt",
    description: "Malta - European VAT number",
    regex: /^MT[0-9]{8}$/,
    example: "MT12345678",
  },

  {
    country: "MX",
    type: "mx_rfc",
    description: "Mexican RFC number",
    regex: /^[A-Z]{3}[0-9]{6}[A-Z]{2}[0-9]{1}$/,
    example: "ABC010203AB9",
  },

  {
    country: "NL",
    type: "eu_vat_nl",
    description: "Netherlands - European VAT number",
    regex: /^NL[0-9]{9}B[0-9]{2}$/, // 12 characters. The 10th character is always B, Companies forming a VAT Group have the suffix BO2
    example: "NL123456789B12",
  },
  {
    country: "NZ",
    type: "nz_gst",
    description: "New Zealand GST number",
    regex: /^[0-9]{9}$/,
    example: "123456789",
  },
  {
    country: "NO",
    type: "no_vat",
    description: "Norwegian VAT number",
    regex: /^[0-9]{9}MVA$/, // 9 digits and the letters 'MVA' to indicate VAT registration, Last (ninth) digit is a MOD11 checksum digit.[20]
    example: "123456789MVA",
  },

  {
    country: "PL",
    type: "eu_vat_pl",
    description: "Poland - European VAT number",
    regex: /^PL[0-9]{10}$/,
    example: "PL1234567890",
  },
  {
    country: "PT",
    type: "eu_vat_pt",
    description: "Portugal - European VAT number",
    regex: /^PT[0-9]{9}$/,
    example: "PT123456789",
  },

  {
    country: "RO",
    type: "eu_vat_ro",
    description: "Romania - European VAT number",
    regex: /^RO[0-9]+$/,
    example: "RO1234567891",
  },

  {
    country: "RU",
    type: "ru_inn",
    description: "Russian INN",
    regex: /^[0-9]{10,12}$/, // INN (ИНН) 10 digit code - legal entities, 12 digit code - people
    example: "1234567891",
  },

  {
    country: "RU",
    type: "ru_kpp",
    description: "Russian KPP",
    regex: /^[0-9]{9,13}$/, // OGRN (ОГРН)	9999999999999	13 digit code - people and legal entities
    example: "123456789",
  },

  {
    country: "SA",
    type: "sa_vat",
    description: "Saudi Arabia VAT",
    regex: /^[0-9]{15}$/,
    example: "123456789012345",
  },

  {
    country: "SG",
    type: "sg_gst",
    description: "Singaporean GST",
    regex: /^[a-zA-Z][0-9]{8}[a-zA-Z]$/,
    example: "M12345678X",
  },
  {
    country: "SG",
    type: "sg_uen",
    description: "Singaporean UEN",
    regex: /^[0-9]{9}[a-zA-Z]$/,
    example: "123456789F",
  },

  {
    country: "SK",
    type: "eu_vat_sk",
    description: "Slovakia - European VAT number",
    regex: /^SK[0-9]{10}$/,
    example: "SK1234567891",
  },
  {
    country: "SI",
    type: "eu_vat_si",
    description: "Slovenia - European VAT number",
    regex: /^SI[0-9]{8}$/,
    example: "SI12345678",
  },

  {
    country: "ZA",
    type: "za_vat",
    description: "South African VAT number",
    regex: /^4[0-9]{9}$/,
    example: "4123456789",
  },

  {
    country: "ES",
    type: "es_cif",
    description: "Spanish CIF number",
    // https://www.avalara.com/vatlive/en/eu-vat-rules/eu-vat-number-registration/eu-vat-number-formats.html
    // X12345678 or 12345678X or X1234567X (X stands for any letter)
    regex: /^([A-Z][0-9]{8}|[A-Z][0-9]{7}[A-Z]|[0-9]{8}[A-Z])$/,
    example: "A12345678",
  },

  {
    country: "ES",
    type: "eu_vat_es",
    description: "Spain - European VAT number",
    regex: /^ES[A-Z0-9]{9}$/, // made it more forgiving - 9 characters Includes 1 or 2 alphabetical characters (first or last or first and last)
    example: "ESA1234567Z",
  },

  {
    country: "SE",
    type: "eu_vat_se",
    description: "Sweden - European VAT number",
    regex: /^SE[0-9]{12}$/,
    example: "SE123456789123",
  },

  {
    country: "CH",
    type: "ch_vat",
    description: "Switzerland - European VAT number",
    //regex: /^CHE-[0-9]{3}\.[0-9]{3}\.[0-9]{3}(\s)?MWST$/,
    //example: 'CHE-123.456.789 MWST',
    regex: /^CHE[0-9]{9}(\s)(MWST|TVA|IVA)$/, // "CHE"+9 digits and the letters "TVA/MWST/IVA", which indicates VAT registration, e.g. CHE123456789 MWST.
    example: "CHE123456789 MWST",
  },

  {
    country: "TW",
    type: "tw_vat",
    description: "Taiwanese VAT",
    regex: /^[0-9]{8}$/,
    example: "12345678",
  },

  {
    country: "TH",
    type: "th_vat",
    description: "Thai VAT",
    regex: /^[0-9]{13}$/,
    example: "1234567891234",
  },

  {
    country: "GB",
    type: "gb_vat",
    description: "United Kingdom VAT number",
    regex: /^GB[0-9]{9}$/,
    example: "GB123456789",
  },

  {
    country: "GB",
    type: "eu_vat_gb",
    description: "Northern Ireland VAT number",
    regex: /^XI[0-9]{9}$/,
    example: "XI123456789",
  },
  {
    country: "US",
    type: "us_ein",
    description: "United States EIN",
    regex: /^[0-9]{2}-[0-9]{7}$/,
    example: "12-3456789",
  },
];

const BusinessIds = ({ defaultBusinessIds = [], country = "", onChange = function () {} }) => {
  const [taxIds, setTaxIds] = useState(defaultBusinessIds?.map((id) => ({ ...id })));

  useEffect(() => {
    console.log("country changed", country, defaultBusinessIds);
    // if the country is set and defaultBusinessIds is empty, add the default taxId for the country to the taxIds array
    if ((country && !defaultBusinessIds) || !defaultBusinessIds?.length) {
      const defaultTaxId = taxIdTypes.find((t) => t.country === country);
      if (defaultTaxId) {
        setTaxIds([{ type: defaultTaxId.type, value: "", isValid: false, ...defaultTaxId }]);
      }
    }
  }, [country]);

  // const debouncedOnChange = debounce(onChange, 500);

  useEffect(() => {
    // if the taxIds array changes and all taxIds are valid, call the onChange callback with the new taxIds array
    if (taxIds && taxIds.every((t) => t.isValid)) {
      onChange(taxIds);
    }
  }, [taxIds]);

  const handleTaxIdTypeChange = (oldType, newType) => {
    // find the newType in the taxIdTypes array first, if it exists, find the oldType in the taxIds array and replace it with the newType from the taxIdTypes array and update the state
    const newTaxIds = [...taxIds];
    const taxType = taxIdTypes.find((t) => t.type === newType);
    const taxId = newTaxIds.find((t) => t.type === oldType);

    // also ensure the newType is not already in the taxIds array, only permit one of each type
    if (newTaxIds.find((t) => t.type === newType)) {
      return;
    }

    if (taxType) {
      taxId.type = newType;
      taxId.regex = taxType.regex;
      taxId.example = taxType.example;
      taxId.isValid = taxType.regex.test(taxId.value);
    }

    // update the state
    setTaxIds(newTaxIds);
  };

  const handleTaxIdChange = (type, value) => {
    const newTaxIds = [...taxIds];
    const taxType = taxIdTypes.find((t) => t.type === type);
    const isValid = taxType ? taxType.regex.test(value) : false;

    // update the taxId with the new value and validity
    const taxId = newTaxIds.find((t) => t.type === type);
    taxId.value = value;
    taxId.isValid = isValid;

    // update the state
    setTaxIds(newTaxIds);
  };

  const handleAddTaxId = () => {
    setTaxIds([...taxIds, { type: "", value: "", isValid: true }]);
  };

  const handleRemoveTaxId = (type) => {
    setTaxIds(taxIds.filter((t) => t.type !== type));
  };

  return (
    <>
      <div className="grid gap-4">
        <label className="text-gray-500">Business IDs</label>
        {taxIds?.map((item, index) => (
          <div key={index}>
            <div className="flex items-end justify-start gap-3">
              <div className="w-1/2">
                <label className="text-xs text-gray-500">Type</label>
                <select
                  value={item.type}
                  onChange={(e) => handleTaxIdTypeChange(item.type, e.target.value)}
                >
                  <option value="">Select a Type</option>
                  {taxIdTypes.map((type) => (
                    <option key={type.type} value={type.type}>
                      {type.description}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-1/2">
                <div className="flex items-center justify-between">
                  <label className="text-xs text-gray-500">Business ID</label>
                </div>
                <div className="flex w-full items-center space-x-3">
                  <input
                    type="text"
                    value={item.value || ""}
                    pattern={item.regex?.source}
                    placeholder={item.example && `e.g. ${item.example}`}
                    required
                    onChange={(e) => {
                      // convert value to uppercase first
                      const value = e.target.value.toUpperCase();
                      handleTaxIdChange(item.type, value);
                    }}
                  />
                  <button
                    className="bg-danger-400 hover:bg-danger-600 flex aspect-square size-5 flex-shrink-0 flex-grow-0 items-center justify-center rounded-full text-xs text-white"
                    onClick={() => handleRemoveTaxId(item.type)}
                  >
                    <FontAwesomeIcon icon={faMinus} className="pointer-events-none" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div>
          <Button
            label="Add Business ID"
            type="button"
            className="btn btn-sm btn-light"
            onClick={handleAddTaxId}
            icon={faInputNumeric}
          />
        </div>
      </div>
    </>
  );
};

export default BusinessIds;
