import React, { useMemo, useState, useCallback, useRef } from "react";
import Table from "../../UI/components/Table";
import Api from "../../Marketplace/api";

export default function FulfillmentOrdersTable({ team }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const fetchIdRef = useRef(0);
  // const { isShowing, toggle } = useModal();
  const [currentId, setCurrentId] = useState(null);
  const [refresh, setRefresh] = useState(0);

  const marketplaceAPI = new Api(team.details.api_key, location);

  const formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  });

  const fetchData = useCallback(async ({ pageIndex, pageSize, sortBy, filters, globalFilter }) => {
    console.log("SORT", sortBy);
    console.log("FILTERS", filters);
    console.log("GLOBAL FILTER", globalFilter);

    let params = {
      page: pageIndex + 1,
      per: pageSize,
    };

    if (sortBy.length > 0) {
      params["sort"] = sortBy[0].id;
      params["sort_dir"] = sortBy[0].desc ? "desc" : "asc";
    }

    if (globalFilter) {
      params["query"] = globalFilter;
    }

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      params: {
        section: "body",
      },
    };
    setLoading(true);

    const data = await marketplaceAPI.loadFulfillmentOrders(params);

    setData(data.data);

    setPageCount(Math.ceil(data.meta.total_count / 1));
    setTotalCount(data.meta.total_count);

    setLoading(false);
  }, []);

  const columns = useMemo(() => [
    {
      Header: "Order #",
      id: "external_reference",
      accessor: "fulfillment_name",
      sortType: "basic",
      width: 75,
      disableFilters: true,
      Cell: ({ row }) => <div>{row.original.fulfillment_name}</div>,
    },
    {
      Header: "Store",
      accessor: "store",
      sortType: "basic",
      width: 100,
      disableFilters: true,
      Cell: ({ row }) => <div>{row.original.store}</div>,
    },
    {
      Header: "Date",
      accessor: "created_at",
      sortType: "basic",
      width: 100,
      disableFilters: true,
      Cell: ({ row }) => <div>{row.original.created_at}</div>,
    },
    {
      Header: "Fulfillments",
      accessor: "fulfillments_count",
      sortType: "basic",
      width: 120,
      disableFilters: true,
      Cell: ({ row }) => <div>{row.original.fulfillments_count} Fullfillment</div>,
    },
    // {
    //   Header: "Created",
    //   accessor: "created_at",
    //   sortType: "basic",
    //   disableFilters: true,
    //   Cell: ({ row }) => <div>{row.original.created_at}</div>,
    // },
    // {
    //   Header: "Updated",
    //   accessor: "updated_at",
    //   sortType: "basic",
    //   disableFilters: true,
    //   Cell: ({ row }) => <div>{row.original.updated_at}</div>,
    // },
    {
      Header: "Fulfillment Status",
      accessor: "status",
      disableFilters: true,
      width: 80,
      Cell: ({ value }) => {
        const statusMapping = {
          pending: "indigo",
          submitted: "indigo",
          cancelled: "gray",
          shipped: "green",
          rejected: "gray",
          failed: "red",
          returned: "gray",
        };

        return (
          <span
            className={`bg-${statusMapping[value]}-100 text-${statusMapping[value]}-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium uppercase`}
          >
            {value}
          </span>
        );
      },
    },
    // {
    //   Header: "Subtotal",
    //   id: "fulfillment_subtotal",
    //   accessor: "subtotal",
    //   sortType: "basic",
    //   width: 110,
    //   disableFilters: true,
    //   Cell: ({ row }) => (
    //     <div className="">{formatter.format(parseFloat(row.original.subtotal ?? 0))}</div>
    //   ),
    // },
    // {
    //   Header: "Shipping",
    //   id: "fulfillment_charges",
    //   accessor: "charges",
    //   sortType: "basic",
    //   width: 110,
    //   disableFilters: true,
    //   Cell: ({ row }) => (
    //     <div className="">{formatter.format(parseFloat(row.original.charges ?? 0))}</div>
    //   ),
    // },
    // {
    //   Header: "Discount",
    //   id: "fulfillment_discount",
    //   accessor: "discount",
    //   sortType: "basic",
    //   width: 110,
    //   disableFilters: true,
    //   Cell: ({ row }) => (
    //     <div className="">{formatter.format(parseFloat(row.original.discount ?? 0))}</div>
    //   ),
    // },
    // {
    //   Header: "Tax",
    //   id: "fulfillment_tax",
    //   accessor: "tax",
    //   sortType: "basic",
    //   width: 110,
    //   disableFilters: true,
    //   Cell: ({ row }) => (
    //     <div className="">{formatter.format(parseFloat(row.original.tax ?? 0))}</div>
    //   ),
    // },
    {
      Header: "Total",
      accessor: "tax",
      sortType: "basic",
      width: 110,
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="">
          {formatter.format(
            parseFloat(row.original.subtotal ?? 0) +
              parseFloat(row.original.charges ?? 0) +
              parseFloat(row.original.discount ?? 0) +
              parseFloat(row.original.tax ?? 0)
          )}
        </div>
      ),
    },
    {
      Header: "Items",
      accessor: "items_count",
      sortType: "basic",
      width: 120,
      disableFilters: true,
      Cell: ({ row }) => <div>{row.original.items_count} Items</div>,
    },
    // {
    //   Header: "",
    //   id: "id",
    //   accessor: "status",
    //   disableSort: true,
    //   disableFilters: true,
    //   Cell: ({ value }) => {
    //     if (value == "complete") {
    //       return (
    //         <span className="bg-success-100 text-success-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">
    //           {"PAID"}
    //         </span>
    //       );
    //     } else if (value == "pending") {
    //       return <button className="btn-sm">{"PAY NOW"}</button>;
    //     } else {
    //       return <></>;
    //     }
    //   },
    // },

    {
      Header: "",
      accessor: "id",
      disableFilters: true,
      disableSortBy: true,
      Cell: function ({ value }) {
        return (
          <a className="text-indigo-600 hover:text-indigo-900" href={`/fulfillments/${value}`}>
            Manage
          </a>
        );
      },
    },
  ]);

  return (
    <div>
      <Table
        columns={columns}
        data={data}
        onFetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        totalItems={totalCount}
        initialPageSize={25}
        useRowSelection={false}
      />
    </div>
  );
}
