import React from "react";
import classNames from "../../../../utils/classNames";

export const CardSkeleton = ({ compact = false }) => {
  return (
    <div className="group relative h-full w-full">
      <div className="z-10 h-full min-h-max">
        <div className="relative flex h-full w-full animate-pulse flex-col space-y-3 rounded-lg border-4 border-white bg-white p-2">
          <div
            className={classNames("h-50 relative bg-gray-100", !compact && "aspect-square")}
          ></div>
          <h5 className="h-5 w-36 rounded bg-gray-100"></h5>
          <div className="flex items-center justify-between space-x-2 text-[10px]">
            <span className="h-6 w-36 rounded bg-gray-100 px-2 py-1"></span>
            {!compact && <span className="h-6 w-36 rounded bg-gray-100 px-2 py-1"></span>}
          </div>
        </div>
      </div>
    </div>
  );
};
