import React, { Fragment, useState } from "react";
import { Menu, Transition, Switch } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { classNames } from "../../../utils";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Button, FormModal } from "../../UI/components";
import { useModal } from "../../UI/hooks/useModal";
import ProductSelect from "../..//UI/components/ProductSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faWarning } from "@fortawesome/pro-solid-svg-icons";

export default function ProductSecondaryMenu({ product, onUpdate }) {
  const {
    isOpen: isCopyFromOpen,
    setIsOpen: setCopyFromIsOpen,
    onModalClose: onCopyFromModalClose,
  } = useModal();
  const {
    isOpen: isCopyToOpen,
    setIsOpen: setCopyToIsOpen,
    onModalClose: onCopyToModalClose,
  } = useModal();

  const [isLoading, setIsLoading] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [copyToDestinationProducts, setCopyToDestinationProducts] = useState([]);

  const [copyProperties, setCopyProperties] = useState({
    attributes: true,
    cross_sells: false,
    packages: false,
    vehicles: true,
  });
  const [replaceCopyFromProperties, setReplaceCopyFromProperties] = useState(false);
  const [replaceCopyToProperties, setReplaceCopyToProperties] = useState(false);

  const copyableProps = [
    {
      key: "details",
      title: "Details",
      description: "Description, Product Category, Product Type",
    },
    {
      key: "attributes",
      title: "Attributes",
      description: "",
    },
    {
      key: "cross_sells",
      title: "Cross-sells",
      description: "",
    },
    {
      key: "packages",
      title: "Packages",
      description: "",
    },
    {
      key: "vehicles",
      title: "Vehicles",
      description: "Vehicle applications, attributes, and notes",
    },
  ];

  const copyDataFromSourceProduct = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!selectedProduct) {
      toast.error(`Please select a source product`);
      return;
    }

    try {
      setIsLoading(true);
      const result = await axios({
        method: "post",
        url: `copy-from/${selectedProduct.id}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: { ...copyProperties, replace: replaceCopyFromProperties },
      });

      toast.success(`Copied data from ${selectedProduct.sku} successfully`);
      location.reload();
    } catch (e) {
      toast.error(`Could not copy data from ${selectedProduct.sku}`);
    }
    setIsLoading(false);
    setCopyFromIsOpen(false);
  };

  const copyDataToDestinationProducts = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (copyToDestinationProducts.length === 0) {
      toast.error(`Please select at least one destination product`);
      return;
    }

    try {
      setIsLoading(true);
      const result = await axios({
        method: "post",
        url: `copy-to`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: {
          destination_product_ids: copyToDestinationProducts.map((p) => p.id),
          ...copyProperties,
          replace: replaceCopyToProperties,
        },
      });

      toast.success("Data copied to destination products successfully");

      setCopyToDestinationProducts([]);
    } catch (e) {
      toast.error(`Could not copy data to destination products`);
    }
    setIsLoading(false);
    setCopyToIsOpen(false);
  };

  const exportCSV = () => {
    window.location.href = window.location.href.replace("/edit", ".csv");
  };

  const viewMarketplaceListing = () => {
    // https://partbot.io/b/lukey-performance-products/products/1734604513988/edit to
    // https://partbot.io/b/lukey-performance-products/listings/19408/edit
    let urlParts = window.location.href.split("/");
    let newUrl = urlParts.slice(0, 5).join("/");
    window.location.href = `${newUrl}/listings/${product.listing_id}/edit`;
  };

  const createMarketplaceListing = async () => {
    try {
      const result = await axios({
        method: "post",
        url: `create-marketplace-listing`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });
      toast.success("Listing created succesfully");

      window.location.href = `${window.location.href.split("products")[0]}/listings/${
        result["data"]["id"]
      }/edit`;
    } catch (e) {
      console.log(e);
      toast.error(`Couldn't create listing: ${e.response.data.messages.join(", ")}`);
    }
    setCopyFromIsOpen(false);
  };

  return (
    <>
      <Menu as="div" className="relative ml-3 inline-block text-left">
        <div>
          <Menu.Button className="btn btn-info">
            Tools
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => setCopyFromIsOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block w-full px-4 py-2 text-left text-sm"
                    )}
                  >
                    Copy from Product
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => setCopyToIsOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block w-full px-4 py-2 text-left text-sm"
                    )}
                  >
                    Copy to Products
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => exportCSV()}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block w-full px-4 py-2 text-left text-sm"
                    )}
                  >
                    Download CSV
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => {
                      product.listing_id ? viewMarketplaceListing() : createMarketplaceListing();
                    }}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block w-full px-4 py-2 text-left text-sm"
                    )}
                  >
                    {product.listing_id ? "Edit Marketplace Listing" : "Create Marketplace Listing"}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <FormModal title="Copy from Product" isOpen={isCopyFromOpen} onCancel={onCopyFromModalClose}>
        <form onSubmit={copyDataFromSourceProduct}>
          <div className="mt-5">
            <label className="sr-only">SKU</label>
            {!selectedProduct && (
              <ProductSelect
                label="Source Product"
                placeholder="Search for the product you want to copy from..."
                // brand={product.brand_id}
                onProductSelect={(p) => setSelectedProduct(p)}
                excludeSkus={[product.sku]}
              ></ProductSelect>
            )}
            {selectedProduct && (
              <div className="flex w-full items-center justify-between rounded bg-indigo-100 px-3 py-2 text-xs font-medium text-indigo-600">
                <span>{`Copy data from ${selectedProduct ? selectedProduct.sku : ""} to ${
                  product.sku
                }`}</span>
                <button type="button" className="btn-sm" onClick={() => setSelectedProduct(null)}>
                  Change
                </button>
              </div>
            )}
          </div>
          <fieldset className="space-y-5">
            <legend className="sr-only">Copyable Properties</legend>
            {copyableProps.map((x) => (
              <Switch.Group as="div" className="flex items-center justify-between" key={x.key}>
                <span className="flex flex-grow flex-col">
                  <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                    {x.title}
                  </Switch.Label>
                  {x.description && (
                    <Switch.Description as="span" className="text-xs text-gray-500">
                      {x.description}
                    </Switch.Description>
                  )}
                </span>
                <Switch
                  checked={copyProperties[x.key]}
                  onChange={(e) =>
                    setCopyProperties({
                      ...copyProperties,
                      [x.key]: e,
                    })
                  }
                  className={classNames(
                    copyProperties[x.key] ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      copyProperties[x.key] ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            ))}
          </fieldset>
          <hr className="mt-5" />
          <fieldset className="my-5">
            <div className="text-danger-600 bg-danger-100 mb-2 inline-flex items-center justify-start gap-2 rounded px-2 py-1 text-xs">
              <FontAwesomeIcon icon={faWarning} />
              <span>Danger Zone</span>
            </div>
            <legend className="sr-only">Danger zone</legend>
            <Switch.Group as="div" className="flex items-start justify-between">
              <span className="flex flex-grow flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                  Overwrite
                </Switch.Label>
                <Switch.Description as="span" className="text-xs text-gray-500">
                  Replace existing data with copied data from source product. Leave this off to copy
                  source data while preserving the existing data on this product.
                </Switch.Description>
              </span>
              <Switch
                checked={replaceCopyFromProperties}
                onChange={setReplaceCopyFromProperties}
                className={classNames(
                  replaceCopyFromProperties ? "bg-rose-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    replaceCopyFromProperties ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </fieldset>
          <div className="mt-4 flex justify-end space-x-2">
            <button type="button" className="btn btn-neutral" onClick={onCopyFromModalClose}>
              Cancel
            </button>
            <Button label="Copy" showLoading={isLoading} type="submit" className="btn btn-dark" />
          </div>
        </form>
      </FormModal>

      {/* Copy To Products Modal */}
      <FormModal title="Copy to Products" isOpen={isCopyToOpen} onCancel={onCopyToModalClose}>
        <form onSubmit={copyDataToDestinationProducts}>
          <div className="form-group mt-5">
            <label className="sr-only">SKU</label>
            <ProductSelect
              label="Add Products"
              placeholder="Search for a product you want to copy to..."
              limit={50}
              keepOpen={true}
              onProductSelect={(p) => {
                if (!copyToDestinationProducts.some((product) => product.id === p.id)) {
                  setCopyToDestinationProducts([...copyToDestinationProducts, p]);
                }
              }}
              excludeSkus={[product.sku]}
            ></ProductSelect>
            {copyToDestinationProducts.length > 0 && (
              <div className="mt-3">
                <label className="text-sm font-medium text-gray-900">
                  Copy data to these products
                </label>
                <ul className="divide-y divide-gray-200">
                  {copyToDestinationProducts.map((p) => (
                    <li key={p.id} className="flex items-center justify-between py-1">
                      <span className="text-xs font-medium text-indigo-500">{p.sku}</span>
                      <button
                        type="button"
                        className="text-danger-500 hover:text-danger-700 p-1 text-sm"
                        onClick={() =>
                          setCopyToDestinationProducts(
                            copyToDestinationProducts.filter((x) => x.id !== p.id)
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <fieldset className="space-y-5">
            <legend className="sr-only">Copyable Properties</legend>
            {copyableProps.map((x) => (
              <Switch.Group as="div" className="flex items-center justify-between" key={x.key}>
                <span className="flex flex-grow flex-col">
                  <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                    {x.title}
                  </Switch.Label>
                  <Switch.Description as="span" className="text-xs text-gray-500">
                    {x.description}
                  </Switch.Description>
                </span>
                <Switch
                  checked={copyProperties[x.key]}
                  onChange={(e) =>
                    setCopyProperties({
                      ...copyProperties,
                      [x.key]: e,
                    })
                  }
                  className={classNames(
                    copyProperties[x.key] ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      copyProperties[x.key] ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            ))}
          </fieldset>
          <hr className="mt-5" />
          <fieldset className="my-5">
            <div className="text-danger-600 bg-danger-100 mb-2 inline-flex items-center justify-start gap-2 rounded px-2 py-1 text-xs">
              <FontAwesomeIcon icon={faWarning} />
              <span>Danger Zone</span>
            </div>
            <legend className="sr-only">Danger zone</legend>
            <Switch.Group as="div" className="flex items-start justify-between">
              <span className="flex flex-grow flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                  Overwrite
                </Switch.Label>
                <Switch.Description as="span" className="text-xs text-gray-500">
                  Replace existing data with copied data from source product. Leave this off to copy
                  source data while preserving the existing data on destination products.
                </Switch.Description>
              </span>
              <Switch
                checked={replaceCopyToProperties}
                onChange={setReplaceCopyToProperties}
                className={classNames(
                  replaceCopyToProperties ? "bg-rose-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    replaceCopyToProperties ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </fieldset>
          <div className="mt-4 flex justify-end space-x-2 border-t border-gray-200 pt-4">
            <button type="button" className="btn btn-neutral" onClick={onCopyToModalClose}>
              Cancel
            </button>
            <Button label="Copy" showLoading={isLoading} type="submit" className="btn btn-dark" />
          </div>
        </form>
      </FormModal>
    </>
  );
}
