import React, { useState, useCallback, useRef } from "react";
import axios from "axios";
import Table from "../../Application/components/Table";
import ExternalVehicleModal from "./ExternalVehicleModal";
import useModal from "../../Application/hooks/useModal";

export default function ExternalVehicleList(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const fetchIdRef = useRef(0);
  const { isShowing, toggle } = useModal();
  const [currentId, setCurrentId] = useState(null);
  const [refresh, setRefresh] = useState(0);

  const fetchData = useCallback(
    async ({ pageIndex, pageSize, sortBy, filters }) => {
      console.log("fetchData");
      // pageIndex is 0 initialised. Rails wants 1.
      pageIndex = pageIndex + 1;

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const requestConfig = {
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          params: {
            pageIndex,
            pageSize,
            sortBy,
            filters,
          },
        };

        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        const result = await axios.get(
          "/admin/external_vehicles/list.json",
          requestConfig
        );
        setData(result.data.payload);

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(result.data.count / pageSize));
        setTotalItems(result.data.count);

        setLoading(false);
      }
    },
    []
  );

  const handleEdit = (id) => {
    setCurrentId(id);
    toggle();
  };

  const handleSave = () => {
    console.log("called");
    let increment = refresh + 1;
    setRefresh(increment);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Make",
        accessor: "make",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => (
          <div
            className={
              row.original.global_make_translated ? "text-primary" : ""
            }
          >
            {row.original.make}
          </div>
        ),
      },
      {
        Header: "Model",
        accessor: "model",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => (
          <div
            className={`
            ${
              row.original.global_model_translated ||
              row.original.model_translated
                ? "text-primary"
                : ""
            }
            ${row.original.model_translated ? "font-weight-bold" : ""}`}
          >
            {row.original.model}
          </div>
        ),
      },
      {
        Header: "Series",
        accessor: "series",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => (
          <div
            className={
              row.original.series_translated
                ? "text-primary font-weight-bold"
                : ""
            }
          >
            {row.original.series}
          </div>
        ),
      },
      {
        Header: "Group ID",
        accessor: "external_group_id",
      },
      {
        Header: "Vehicle ID",
        accessor: "external_id",
      },
      {
        Header: "Body",
        accessor: "body",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => (
          <div
            className={
              row.original.global_body_translated ? "text-primary" : ""
            }
          >
            {row.original.body}
          </div>
        ),
      },
      {
        Header: "Month From",
        accessor: "month_from",
        sortType: "basic",
        filter: "number",
      },
      {
        Header: "Year From",
        accessor: "year_from",
        sortType: "basic",
        filter: "number",
      },
      {
        Header: "Month To",
        accessor: "month_to",
        sortType: "basic",
        filter: "number",
      },
      {
        Header: "Year To",
        accessor: "year_to",
        sortType: "basic",
        filter: "number",
      },
      {
        Header: "Variant",
        accessor: "variant",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => (
          <div
            className={
              row.original.variant_translated ? "text-indigo-500 font-bold" : ""
            }
          >
            {row.original.variant}
          </div>
        ),
      },
      {
        Header: "Engine",
        accessor: "engine",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Capacity (L)",
        accessor: "engine_capacity",
        sortType: "number",
        filter: "text",
      },
      {
        Header: "Fuel Type",
        accessor: "fuel_type",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => (
          <div
            className={
              row.original.global_fuel_type_translated ? "text-primary" : ""
            }
          >
            {row.original.fuel_type}
          </div>
        ),
      },
      {
        Header: "Drivetrain",
        accessor: "drive_type",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => (
          <div
            className={
              row.original.global_drive_type_translated ? "text-primary" : ""
            }
          >
            {row.original.drive_type}
          </div>
        ),
      },
      {
        Header: "Source",
        accessor: "external_source",
      },
      {
        Header: " ",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => (
          <div>
            <button
              className="btn-sm"
              onClick={() => handleEdit(row.original.id)}
            >
              Edit
            </button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Table
        columns={columns}
        data={data}
        onFetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        totalItems={totalItems}
        refresh={refresh}
      />
      <ExternalVehicleModal
        isShowing={isShowing}
        id={currentId}
        hide={toggle}
        save={handleSave}
      />
    </>
  );
}
