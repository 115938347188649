import React, { Component, useState, useEffect } from "react";
import ProductTypeSelect from "./ProductTypeSelect";
import BrandSelect from "./BrandSelect";
import Select from "react-select";
import ScheduleSelect from "./ScheduleSelect";
import ReactOnRails from "react-on-rails";
import axios from "axios";

export default function ExportRow(props) {
  const [exp, setExp] = useState(props.exp);
  const [accountCode, setAccountCode] = useState(props.exp.account_code);
  const [email, setEmail] = useState(props.exp.email);
  const [productTypes, setProductTypes] = useState(props.product_types);
  const [brands, setBrands] = useState(props.brands);

  const [selectedProductTypes, setSelectedProductTypes] = useState(
    props.exp.id
      ? props.exp.selected_product_types.map((p) =>
          props.product_types.find(({ id }) => id === p.id)
        )
      : []
  );

  const [selectedBrands, setSelectedBrands] = useState(
    props.exp.id
      ? props.exp.selected_brands.map((b) =>
          props.brands.find(({ id }) => id === b.id)
        )
      : []
  );
  const [selectedTime, setSelectedTime] = useState(props.exp.export_time);
  const [response, setResponse] = useState(null);

  // useEffect(() => {
  //   if (exp.id) {
  //     setSelectedProductTypes(exp.selected_product_types.map((pt) => pt.id));

  //   }
  // }, [exp.id]);

  const updateSelectedProductTypes = (e) => {
    const options = e.map((b) => ({ id: b.value, title: b.label }));
    console.log("options", options);
    setSelectedProductTypes(options);
  };

  const updateSelectedBrands = (e) => {
    const options = e.map((b) => ({ id: b.value, name: b.label }));
    setSelectedBrands(options);
  };

  const saveExportRow = async (e) => {
    e.preventDefault();
    console.log(
      "ReactOnRails.authenticityHeaders()",
      ReactOnRails.authenticityHeaders()
    );

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      data: {
        account_code: accountCode,
        email: email,
        product_types: selectedProductTypes.map((b) => b.id),
        brands: selectedBrands.map((b) => b.id),
        export_time: selectedTime ?? "00:00",
      },
    };

    let result;
    if (exp.id) {
      result = await axios.patch(`/admin/exports/${exp.id}`, requestConfig);
    } else {
      result = await axios.post("/admin/exports", requestConfig);
    }

    setExp(result.data.exp);
    setResponse(result.data);
  };

  const updateSchedule = (e) => {
    setSelectedTime(e.target.value);
  };

  return (
    <tr className="export-row">
      <td>
        <input
          type="text"
          className="uppercase"
          value={accountCode}
          onChange={(e) => setAccountCode(e.target.value)}
          required
        />
      </td>
      <td>
        <input
          type="email"
          className="w-[350]"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </td>
      <td>
        <Select
          isMulti
          options={productTypes.map((b) => ({ value: b.id, label: b.title }))}
          onChange={updateSelectedProductTypes}
          defaultValue={selectedProductTypes.map((b) => ({
            value: b.id,
            label: b.title,
          }))}
          className="w-60"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
        />
      </td>
      <td className="">
        <Select
          isMulti
          options={brands.map((b) => ({ value: b.id, label: b.name }))}
          onChange={updateSelectedBrands}
          defaultValue={selectedBrands.map((b) => ({
            value: b.id,
            label: b.name,
          }))}
          className="w-60"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
        />
      </td>
      <td>
        <ScheduleSelect
          selected_time={selectedTime}
          handler={updateSchedule}
          required
        />
      </td>
      <td></td>
      <td>
        <div className="flex justify-end items-center">
          {exp.id ? (
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => props.delete_handler(exp.id)}
            >
              Delete
            </button>
          ) : (
            ""
          )}
          <button type="submit" className="btn btn-sm" onClick={saveExportRow}>
            Save
          </button>
        </div>
      </td>
    </tr>
  );
}
