import { faSearch } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, Fragment, useLayoutEffect, useEffect } from "react";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import LocationEditor from "./components/LocationEditor";
import { Button, Checkbox } from "../UI/components";
import {
  faBarcode,
  faClipboardList,
  faHandHoldingBox,
  faPencil,
  faPeopleCarryBox,
  faShippingFast,
  faSteeringWheel,
  faTruckArrowRight,
  faWarning,
  faClock,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import dayjs from "dayjs";
import PackagingModal from "../Products/components/PackagingModal";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import FulfillmentHeader from "./components/Fulfillment/FulfillmentHeader";
import RequestPickupsModal from "./components/RequestPickupsModal";
import HandOverModal from "./components/HandOverModal";
import SelfManageShipmentModal from "./components/SelfManageShipmentModal";
import ListStatusBadge from "./components/Fulfillment/ListStatusBadge";
import { set } from "react-hook-form";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const titleize = (str) => {
  if (!str) return "";
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export default function LabelRequests({ shipments, team, app_contexts }) {
  const [selectedShipmentIds, setSelectedShipmentIds] = useState([]);
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [openPackagingModal, setOpenPackagingModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isRequestPickupsModalOpen, setIsRequestPickupsModalOpen] = useState(false);
  const [isHandOverModalOpen, setIsHandOverModalOpen] = useState(false);
  const [isSelfManageShipmentModalOpen, setIsSelfManageShipmentModalOpen] = useState(false);

  // console.log("shipments", shipments);

  const [shipmentsData, setShipmentsData] = useState(shipments);

  const shipmentTypes = [
    {
      id: "packed",
      name: "Packed",
    },
    {
      id: "booked",
      name: "Labels Generated",
    },
    {
      id: "pickup_requested",
      name: "Pickup Requested",
    },
    {
      id: "shipped",
      name: "Recently Shipped",
    },
  ];

  useEffect(() => {
    const isIndeterminate =
      selectedShipmentIds.length > 0 && selectedShipmentIds.length < shipmentsData.length;
    setChecked(selectedShipmentIds.length === shipmentsData.length);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedShipmentIds]);

  function toggleAll() {
    setSelectedShipmentIds(checked || indeterminate ? [] : shipmentsData.map((s) => s.id));
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const requestFulfillments = () => {
    setIsRequestPickupsModalOpen(true);
  };

  const handOver = () => {
    setIsHandOverModalOpen(true);
  };

  const updatePackaging = () => {
    setOpenPackagingModal(false);
  };

  const selfManage = (e, shipmentId) => {
    e.preventDefault();
    setSelectedShipmentIds([shipmentId]);
    setIsSelfManageShipmentModalOpen(true);
  };

  const actionSupported = (action, shippingIds = []) => {
    const shippingIdsToCheck = shippingIds.length > 0 ? shippingIds : selectedShipmentIds;
    if (shippingIdsToCheck.length === 0) return false;

    const selectedShipments = shipmentsData.filter((s) => shippingIdsToCheck.includes(s.id));
    return selectedShipments.every((s) => s.supported_actions.includes(action));
  };

  const search = async (value) => {
    try {
      const result = await axios({
        method: "get",
        url: `?query=${value}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      console.log("result", result);

      setSelectedShipmentIds([]);
      setShipmentsData(result.data.shipments || []);
    } catch (e) {
      console.log(e);
      toast.error("An error occured while xping for shipments.");
    }
  };

  const onRequestPickupsModalClose = async (data) => {
    if (data) {
      setIsLoading(true);
      try {
        const result = await axios({
          method: "post",
          url: `handovers`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: {
            delivery_ids: selectedShipmentIds,
            pickup_preference: data.pickup_preference,
          },
        });

        // update shipmentsData from result
        setSelectedShipmentIds([]);
        setShipmentsData(result.data.shipments || []);

        toast.success("Pickup is being requested");
      } catch (e) {
        console.log(e);
        toast.error("Error requesting pickup");
      } finally {
        setIsLoading(false);
      }
    }
    setIsRequestPickupsModalOpen(false);
  };

  const onHandOverModalClose = (data) => {
    if (data) {
      console.log("data", data);
    }
    setIsHandOverModalOpen(false);
  };

  const onSelfManageShipmentModalClose = async (data) => {
    if (selectedShipmentIds.length == 0) {
      setIsSelfManageShipmentModalOpen(false);
      return;
    }

    if (data) {
      const shipmentId = selectedShipmentIds[0];
      setIsLoading(true);
      try {
        const result = await axios({
          method: "post",
          url: `shipments/${shipmentId}/byo`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: {
            carrier: data.carrier,
            tracking_number: data.tracking_number,
            tracking_url: data.tracking_url,
            booked_cost: data.cost,
          },
        });

        toast.success("Shipment converted to BYO");
      } catch (e) {
        console.log(e);
        toast.error("Error converting shipment to BYO");
      } finally {
        setIsLoading(false);
      }
    }
    setIsSelfManageShipmentModalOpen(false);
  };

  // const onRequestPickupModalClose = async (data) => {
  //   if (data) {
  //     const requestedSlot = data.selectedSlot;
  //     const deliveryId = requestPickupOptions.deliveryId;

  //     setIsLoading(true);
  //     try {
  //       const result = await axios({
  //         method: "post",
  //         url: `/deliveries/${deliveryId}/pickup`,
  //         responseType: "json",
  //         headers: ReactOnRails.authenticityHeaders(),
  //         data: {
  //           pickup: requestedSlot,
  //         },
  //       });

  //       toast.success("Pickup requested");
  //     } catch (e) {
  //       console.log(e);
  //       toast.error("Error requesting pickup");
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  //   setIsRequestPickupModalOpen(false);
  // };

  return (
    <>
      <Header app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8 pb-24 pt-5">
            <div className="flex w-full items-center justify-between">
              <div className="flex flex-grow space-x-5">
                <div className="relative max-w-[50vw] flex-grow">
                  <h2 className="m-0 text-3xl font-extrabold tracking-tight text-gray-900">
                    Label Requests
                  </h2>
                  <p className="text-sm text-gray-500">
                    Distributor has requested labels for the following shipments.
                  </p>
                </div>
                <div className="relative max-w-[33vw] flex-grow">
                  <input
                    type="text"
                    placeholder="Search by order, reference or tracking number..."
                    className="w-full"
                    onChange={(e) => search(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="absolute right-3 top-2.5 h-4 w-4 text-gray-500"
                  />
                </div>
              </div>
            </div>

            {shipmentsData && shipmentsData.length ? (
              <div className="w-full space-y-5 overflow-clip rounded-lg bg-white pb-5 shadow">
                <table className="text-xs">
                  <thead>
                    <tr>
                      <th className="relative pl-4 pr-0">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        />
                      </th>
                      <th>Location</th>
                      <th>Shipment</th>
                      <th>Store Reference</th>
                      {/* <th className="text-center">Shipping Tax</th> */}
                      <th>Tracking</th>
                      <th>Carrier</th>
                      <th>Label</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {shipmentTypes.map((shipmentType, index) => {
                      const filteredShipments = shipmentsData.filter(
                        (shipment) => shipment.delivery_status === shipmentType.id
                      );

                      return (
                        <Fragment key={index}>
                          {filteredShipments.length > 0 && (
                            <tr key={index} className="border-t border-gray-200">
                              <th colSpan={9} scope="colgroup" className="text-indigo-500">
                                {shipmentType.name}
                              </th>
                            </tr>
                          )}
                          {filteredShipments.map((shipment, index) => (
                            <tr
                              key={shipment.id}
                              className={classNames(
                                index === 0 ? "border-gray-300" : "border-gray-200",
                                "border-t"
                              )}
                            >
                              <td className="relative pl-4 pr-0">
                                {selectedShipmentIds.includes(shipment.id) && (
                                  <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                )}
                                {/* show checkbox if delivery_status === booked, else show a check mark icon */}
                                {shipment.delivery_status !== "pickup_requested" && (
                                  <input
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:opacity-50"
                                    value={shipment.id}
                                    disabled={shipment.delivery_status !== "booked"}
                                    checked={selectedShipmentIds.includes(shipment.id)}
                                    onChange={(e) =>
                                      setSelectedShipmentIds(
                                        e.target.checked
                                          ? [...selectedShipmentIds, shipment.id]
                                          : selectedShipmentIds.filter((f) => f !== shipment.id)
                                      )
                                    }
                                  />
                                )}

                                {shipment.delivery_status === "pickup_requested" && (
                                  <FontAwesomeIcon
                                    icon={faClock}
                                    className="text-success-500 h-4 w-4"
                                  />
                                )}
                              </td>
                              <td>
                                <span
                                  className={classNames(
                                    "rounded px-2 py-1.5 font-medium uppercase",
                                    "bg-gray-100 text-gray-900"
                                  )}
                                >
                                  {shipment.fulfillment.distributor_location.code}
                                </span>
                              </td>
                              <td>{shipment.identifier}</td>
                              <td>
                                {shipment.fulfillment.merchant_order.store.name}
                                <span className="mx-2 rounded bg-blue-100 px-1.5 py-0.5 text-blue-600">
                                  {shipment.fulfillment.merchant_order.store_order_reference}
                                </span>
                              </td>
                              {/* <td className="text-center">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "AUD",
                              }).format(fulfillment.shipping_charge)}
                            </td>
                            <td className="text-center">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "AUD",
                              }).format(fulfillment.shipping_tax)}
                            </td> */}
                              <td className="">
                                <a href={shipment.tracking_url} target="_blank" key={shipment.id}>
                                  {shipment.tracking_number}
                                </a>
                              </td>
                              <td>
                                <span key={shipment.id}>{shipment.carrier}</span>
                              </td>
                              <td className="">
                                {shipment.label_url &&
                                  (shipment.label_status == "generated" ||
                                    shipment.label_status == "manual" ||
                                    shipment.label_status == "printed") && (
                                    <a href={shipment.label_url} target="_blank" key={shipment.id}>
                                      Download Label
                                    </a>
                                  )}
                                {shipment.label_status != "generated" &&
                                  shipment.label_status != "manual" &&
                                  shipment.label_status != "printed" && (
                                    <ListStatusBadge type="label" status={shipment.label_status} />
                                  )}
                              </td>
                              <td>
                                {shipment.label_status == "label_requested" && (
                                  <div>
                                    <a
                                      href={`/label_requests/${shipment.id}`}
                                      title="Add Label Details"
                                      className="rounded border bg-white px-2.5 py-1.5 text-xs text-gray-700 transition hover:border-gray-400"
                                    >
                                      Add Label Details
                                    </a>
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="flex w-full items-center justify-center p-8 text-sm text-gray-500">
                No shipments found.
              </div>
            )}
          </div>
        </div>
      </section>
      <PackagingModal
        isOpen={openPackagingModal}
        closeModal={updatePackaging}
        options={["Carton", "Satchel", "Bag"]}
      />
      <RequestPickupsModal
        isOpen={isRequestPickupsModalOpen}
        closeModal={onRequestPickupsModalClose}
      />
      <HandOverModal
        isOpen={isHandOverModalOpen}
        closeModal={onHandOverModalClose}
        shipments={shipmentsData.filter((s) => selectedShipmentIds.includes(s.id))}
      />
      <SelfManageShipmentModal
        isOpen={isSelfManageShipmentModalOpen}
        closeModal={onSelfManageShipmentModalClose}
      />
      <Toaster />
    </>
  );
}
