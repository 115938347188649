import React from "react";
import { connectStats } from "react-instantsearch-dom";

const Stats = ({ processingTimeMS, nbHits, nbSortedHits, areHitsSorted }) => (
  <p className="text-xs text-indigo-500">
    {areHitsSorted && nbHits !== nbSortedHits
      ? `Showing ${nbSortedHits.toLocaleString()} relevant listings sorted out of ${nbHits.toLocaleString()}`
      : `Showing ${nbHits.toLocaleString()} listings`}
  </p>
);

export const SearchStats = connectStats(Stats);
