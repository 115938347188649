import React, { useState } from "react";
import { Card } from "./Card";
import { useStore } from "../../state/StoreProvider";
import shallow from "zustand/shallow";
import { CheckIcon } from "@heroicons/react/solid";

export const CrossSellProduct = ({ product }) => {
  const { addToImportList, allListingIds } = useStore(
    (store) => ({
      addToImportList: store.addToImportList,
      allListingIds: store.allListingIds,
    }),
    shallow
  );

  const listingId = product.listing_id;
  const isAddedToImportList = allListingIds.includes(listingId);
  const [loading, setLoading] = useState(false);

  const handleAddToImportList = async () => {
    setLoading(true);
    await addToImportList(listingId);
    setLoading(false);
  };

  return (
    <Card
      image={`https://res.cloudinary.com/proex/image/fetch/f_auto,w_1200,h_1200,c_pad,b_white,q_80,dpr_auto/${product.image}`}
    >
      <div className="">
        <h5 className="mb-2 text-xs font-medium text-gray-900">{product.title}</h5>
        <label className="text-xs">SKU</label>
        <p className="mb-4 text-sm text-indigo-500">{product.sku}</p>
      </div>

      {!isAddedToImportList && (
        <button
          disabled={loading}
          type="button"
          className={`btn ${loading && "cursor-not-allowed opacity-50"}`}
          onClick={handleAddToImportList}
        >
          {loading ? "Adding to Import List..." : "Add to Import List"}
        </button>
      )}

      {isAddedToImportList && (
        <button type="button" className="btn cursor-not-allowed opacity-50" disabled>
          <CheckIcon className="text-success-600 h-6 w-6" aria-hidden="true" />
          Added to Import List
        </button>
      )}
    </Card>
  );
};
