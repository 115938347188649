import React from "react";
import ExternalVehicleTranslationCardPropertyGroup from "./ExternalVehicleTranslationCardPropertyGroup";
import Api from "../../Application/api";

function ExternalVehicleTranslationCard({ id, onSave, onHide }) {
  const [data, setData] = React.useState({
    id: 1,
    external_source: "TecDoc",
    external_group_id: 123,
    external_id: 456789,
    make: {
      id: null,
      name: null,
    },
    model: {
      id: null,
      name: null,
    },
    series: null,
    body: {
      id: null,
      name: null,
    },
    year_from: null,
    month_from: 2,
    year_to: 2010,
    month_to: 5,
    variant: "KDJ120; KDJ125",
    engine: "1KD-FTV",
    engine_capacity: null,
    fuel_type: {
      id: null,
      name: null,
    },
    drive_type: {
      id: null,
      name: null,
    },
    transmission: {
      id: null,
      name: null,
    },
    kw_power: null,
    global_translation: {
      make: {
        id: null,
        name: null,
      },
      model: {
        id: null,
        name: null,
      },
      body: {
        id: null,
        name: null,
      },
      fuel_type: {
        id: null,
        name: null,
      },
      drive_type: {
        id: null,
        name: null,
      },
    },
    group_translation: {
      model: {
        id: null,
        name: null,
      },
      series: null,
      body: {
        id: null,
        name: null,
      },
      fuel_type: {
        id: null,
        name: null,
      },
      drive_type: {
        id: null,
        name: null,
      },
      transmission: {
        id: null,
        name: null,
      },
    },
    vehicle_translation: {
      series: null,
      body: {
        id: null,
        name: null,
      },
      year_from: null,
      month_from: null,
      year_to: null,
      month_to: null,
      variant: null,
      engine: null,
      engine_capacity: null,
      fuel_type: {
        id: null,
        name: null,
      },
      drive_type: {
        id: null,
        name: null,
      },
      transmission: {
        id: null,
        name: null,
      },
      kw_power: null,
    },
  });

  const [makes, setMakes] = React.useState([]);
  const [models, setModels] = React.useState([]);
  const [bodies, setBodies] = React.useState([]);
  const [fuel_types, setFuelTypes] = React.useState([]);
  const [drive_types, setDriveTypes] = React.useState([]);
  const [transmissions, setTransmissions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  let unmounted = false;

  const fetchTranslations = React.useCallback(async () => {
    setLoading(true);
    if (!unmounted) {
      let response = await Api.getExternalVehicleTranslations(id);
      setData(response);
    }
  }, []);

  const fetchMakes = React.useCallback(async () => {
    if (!unmounted) {
      let response = await Api.getVehicleMakes();
      setMakes(response);
    }
  }, []);

  const fetchModels = React.useCallback(async () => {
    if (!unmounted) {
      let response = await Api.getVehicleModels();
      setModels(response);
    }
  }, []);

  const fetchBodies = React.useCallback(async () => {
    if (!unmounted) {
      let response = await Api.getVehicleBodies();
      setBodies(response);
    }
  }, []);

  const fetchFuelTypes = React.useCallback(async () => {
    if (!unmounted) {
      let response = await Api.getVehicleFuelTypes();
      setFuelTypes(response);
    }
  }, []);

  const fetchDriveTypes = React.useCallback(async () => {
    if (!unmounted) {
      let response = await Api.getVehicleDriveTypes();
      setDriveTypes(response);
    }
  }, []);

  const fetchTransmissions = React.useCallback(async () => {
    if (!unmounted) {
      let response = await Api.getVehicleTransmissions();
      setTransmissions(response);
    }
    setLoading(false);
  }, []);

  React.useEffect(() => {
    fetchTranslations();
    fetchMakes();
    fetchModels();
    fetchBodies();
    fetchFuelTypes();
    fetchDriveTypes();
    fetchTransmissions();

    return () => {
      unmounted = true;
    };
  }, [
    fetchTranslations,
    fetchMakes,
    fetchModels,
    fetchBodies,
    fetchFuelTypes,
    fetchDriveTypes,
    fetchTransmissions,
  ]);

  const handlePropertyChange = (propertyName, section, value) => {
    console.log(section);
    console.log(propertyName);
    console.log(value);

    let updatedData = { ...data };
    let updatedValue = value == "" || value == null ? null : value;
    updatedData[section][propertyName] = updatedValue;
    setData(updatedData);
  };

  const cancel = () => {
    onHide();
  };

  const save = async () => {
    const response = await Api.putExternalVehicleTranslations(id, data);
    onSave();
    onHide();
  };

  return (
    <>
      {loading ? (
        <span>Loading...</span>
      ) : (
        <>
          {/* <div className="row border-bottom">
            <div className="col-4 border-right py-2"></div>
            <div className="col-6 border-right  py-2 text-primary font-weight-bold">
              Translations / Overrides
            </div>
            <div className="col-2"></div>
          </div>
          <div className="row border-bottom">
            <div className="col-2 border-right py-2"></div>
            <div className="col-2 border-right py-2 text-black font-weight-bold">
              {data.external_source}
              <small className="d-block">Original External Data</small>
            </div>
            <div className="col-2 border-right py-2 text-primary font-weight-bold">
              Global
              <small className="d-block">Least Specific</small>
            </div>
            <div className="col-2 border-right py-2 text-primary font-weight-bold">
              {data.external_source == "TecDoc" ? "K-Mod" : "Group"}{" "}
              {data.external_group_id}
              <small className="d-block">More Specific</small>
            </div>
            <div className="col-2 border-right py-2 text-primary font-weight-bold">
              {data.external_source == "TecDoc" ? "K-Type" : "Vehicle"}{" "}
              {data.external_id}
              <small className="d-block">Most Specific</small>
            </div>
            <div className="col-2 border-right py-2 text-black font-weight-bold">
              Result
              <small className="d-block">Final Vehicle Data</small>
            </div>
          </div> */}
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th colspan="2"></th>
                <th colspan="3">Translations / Overrides</th>
                <th colspan="1"></th>
              </tr>
              <tr>
                <th colspan="1"></th>
                <th>
                  {data.external_source}
                  <small className="d-block">Original External Data</small>
                </th>
                <th>
                  Global
                  <small className="d-block">Least Specific</small>
                </th>
                <th>
                  {data.external_source == "TecDoc" ? "K-Mod" : "Group"}{" "}
                  {data.external_group_id}
                  <small className="d-block">More Specific</small>
                </th>
                <th>
                  {data.external_source == "TecDoc" ? "K-Type" : "Vehicle"}{" "}
                  {data.external_id}
                  <small className="d-block">Most Specific</small>
                </th>
                <th>
                  Result
                  <small className="d-block">Final Vehicle Data</small>
                </th>
              </tr>
              <tr className="divide-x divide-gray-200">
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                >
                  Role
                </th>
              </tr>
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="model"
                propertyType="lookup"
                title="Model"
                baseValue={data.model}
                globalTranslation={data.global_translation.model}
                groupTranslation={data.group_translation.model}
                vehicleTranslation={undefined}
                onPropertyChange={handlePropertyChange}
                lookupData={models}
              />
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="series"
                propertyType="text"
                title="Series"
                baseValue={data.series}
                globalTranslation={undefined}
                groupTranslation={data.group_translation.series}
                vehicleTranslation={data.vehicle_translation.series}
                onPropertyChange={handlePropertyChange}
              />
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="body"
                propertyType="lookup"
                title="Body"
                baseValue={data.body}
                globalTranslation={data.global_translation.body}
                groupTranslation={data.group_translation.body}
                vehicleTranslation={data.vehicle_translation.body}
                onPropertyChange={handlePropertyChange}
                lookupData={bodies}
              />
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="year_from"
                propertyType="year"
                title="Year From"
                baseValue={data.year_from}
                globalTranslation={undefined}
                groupTranslation={undefined}
                vehicleTranslation={data.vehicle_translation.year_from}
                onPropertyChange={handlePropertyChange}
              />
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="month_from"
                propertyType="month"
                title="Month From"
                baseValue={data.month_from}
                globalTranslation={undefined}
                groupTranslation={undefined}
                vehicleTranslation={data.vehicle_translation.month_from}
                onPropertyChange={handlePropertyChange}
              />
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="year_to"
                propertyType="year"
                title="Year To"
                baseValue={data.year_to}
                globalTranslation={undefined}
                groupTranslation={undefined}
                vehicleTranslation={data.vehicle_translation.year_to}
                onPropertyChange={handlePropertyChange}
              />
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="month_to"
                propertyType="month"
                title="Month To"
                baseValue={data.month_to}
                globalTranslation={undefined}
                groupTranslation={undefined}
                vehicleTranslation={data.vehicle_translation.month_to}
                onPropertyChange={handlePropertyChange}
              />
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="variant"
                propertyType="text"
                title="Variant"
                baseValue={data.variant}
                globalTranslation={undefined}
                groupTranslation={undefined}
                vehicleTranslation={data.vehicle_translation.variant}
                onPropertyChange={handlePropertyChange}
              />
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="engine"
                propertyType="text"
                title="Engine"
                baseValue={data.engine}
                globalTranslation={undefined}
                groupTranslation={undefined}
                vehicleTranslation={data.vehicle_translation.engine}
                onPropertyChange={handlePropertyChange}
              />
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="engine_capacity"
                propertyType="numeric"
                propertyPrecision={3}
                propertyScale={1}
                title="Engine Capacity (L)"
                baseValue={data.engine_capacity}
                globalTranslation={undefined}
                groupTranslation={undefined}
                vehicleTranslation={data.vehicle_translation.engine_capacity}
                onPropertyChange={handlePropertyChange}
              />
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="fuel_type"
                propertyType="lookup"
                title="Fuel Type"
                baseValue={data.fuel_type}
                globalTranslation={data.global_translation.fuel_type}
                groupTranslation={data.group_translation.fuel_type}
                vehicleTranslation={data.vehicle_translation.fuel_type}
                onPropertyChange={handlePropertyChange}
                lookupData={fuel_types}
              />
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="drive_type"
                propertyType="lookup"
                title="Drivetrain"
                baseValue={data.drive_type}
                globalTranslation={data.global_translation.drive_type}
                groupTranslation={data.group_translation.drive_type}
                vehicleTranslation={data.vehicle_translation.drive_type}
                onPropertyChange={handlePropertyChange}
                lookupData={drive_types}
              />
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="transmission"
                propertyType="lookup"
                title="Transmission"
                baseValue={data.transmission}
                globalTranslation={data.global_translation.transmission}
                groupTranslation={data.group_translation.transmission}
                vehicleTranslation={data.vehicle_translation.transmission}
                onPropertyChange={handlePropertyChange}
                lookupData={transmissions}
              />
              <ExternalVehicleTranslationCardPropertyGroup
                propertyName="kw_power"
                propertyType="numeric"
                propertyPrecision={4}
                propertyScale={0}
                title="Power (kW)"
                baseValue={data.kw_power}
                globalTranslation={undefined}
                groupTranslation={undefined}
                vehicleTranslation={data.vehicle_translation.kw_power}
                onPropertyChange={handlePropertyChange}
              />
            </thead>
          </table>
        </>
      )}
      <div className="card-footer d-flex justify-content-end">
        <a className="btn btn-simple btn-secondary" onClick={cancel} href="#">
          Cancel
        </a>
        <a role="button" onClick={save} className="btn btn-simple btn-success">
          Save
        </a>
      </div>
    </>
  );
}

export default ExternalVehicleTranslationCard;
