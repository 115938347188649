import React, { useCallback } from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

import { parse } from "tldts";
import {
  faBookOpenCover,
  faCheck,
  faChevronDown,
  faStoreAlt,
  faWarehouseFull,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/light";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const apps = [
  {
    title: "Marketplace",
    subdomain: "marketplace",
    context: "marketplace",
    path: "",
    icon: faStoreAlt,
  },
  {
    title: "Catalogue",
    subdomain: "",
    context: "catalogue",
    path: "/dashboard",
    icon: faBookOpenCover,
  },
  {
    title: "Distributor",
    subdomain: "distributor",
    context: "distributor",
    path: "",
    icon: faWarehouseFull,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AppSwitch({
  app_contexts = ["marketplace", "catalogue", "distributor"],
  context = "marketplace",
}) {
  const currentUrl = new URL(window.location.origin);
  const parsedUrl = parse(currentUrl.href);
  const options = apps.filter((a) => app_contexts.includes(a.context));
  const [selected, setSelected] = useState(
    options[options.findIndex((option) => option.context === context)]
  );

  const handleChange = useCallback((option) => {
    setSelected(option);

    let appUrl = "";

    if (option.subdomain === "") {
      appUrl = `${currentUrl.protocol}//${parsedUrl.domain}${
        currentUrl.port ? `:${currentUrl.port}` : ""
      }${option.path}`;
    } else {
      appUrl = `${currentUrl.protocol}//${option.subdomain}.${parsedUrl.domain}${
        currentUrl.port ? `:${currentUrl.port}` : ""
      }${option.path}`;
    }

    window.location.href = appUrl;
  }, []);

  return (
    <Listbox value={selected} onChange={(value) => handleChange(value)}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">Select Partbot Application</Listbox.Label>
          <div className="group relative">
            <div className="inline-flex divide-x divide-indigo-200 rounded-md border border-indigo-200 transition group-hover:divide-indigo-600 group-hover:border-indigo-600">
              <div className="relative z-0 inline-flex divide-x divide-indigo-200 rounded-md transition group-hover:divide-indigo-600">
                <div className="relative inline-flex items-center rounded-l border border-transparent bg-transparent py-1 pl-3 pr-4 text-indigo-600">
                  <span className="heading-sm">{selected.title}</span>
                </div>
                {options.length > 1 && (
                  <Listbox.Button className="relative inline-flex items-center rounded-l-none rounded-r-md bg-transparent p-1 px-2 text-sm font-medium text-white focus:z-10 focus:outline-none">
                    <span className="sr-only">Change Partbot Application</span>
                    <FontAwesomeIcon icon={faChevronDown} className="h-3 w-3 text-indigo-500" />
                  </Listbox.Button>
                )}
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute left-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-700 overflow-hidden rounded-md bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-gray-800 text-white" : "text-white",
                        "relative cursor-default select-none p-4 text-sm"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <div className="flex items-center space-x-3">
                            <FontAwesomeIcon icon={option.icon} className="h-4 w-4 text-xl" />
                            <span className="">{option.title}</span>
                          </div>
                          {selected ? (
                            <span className={active ? "text-white" : "text-white"}>
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
