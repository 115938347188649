import React, { createElement, Fragment, useEffect, useRef } from "react";
import { render } from "react-dom";

import { autocomplete } from "@algolia/autocomplete-js";

export function Autocomplete(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment },
      detachedMediaQuery: false,
      debug: process.env.NODE_ENV === "development",
      classNames: {
        form: "flex w-full flex-1 bg-white border border-gray-300 flex flex-1 rounded-lg w-full shadow-sm focus-within:border-indigo-500 focus-within:ring-indigo-500 focus-within:ring-2",
        input:
          "!w-full appearance-none border-0 bg-transparent outline-0 focus:ring-0 focus:outline-0 focus:border-0",
        inputWrapper: "w-full relative order-3",
        inputWrapperPrefix: "flex items-center px-3 pt-1",
        inputWrapperSuffix: "flex items-center order-4",
        submitButton: "pointer-events-none",
        clearButton: "flex items-center text-gray-500 px-3",
        panel: "absolute z-20 mt-2 w-full bg-white shadow-xl rounded-lg p-3 border border-gray-200",
      },
      render({ children }, root) {
        render(children, root);
      },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  return <div ref={containerRef} />;
}
