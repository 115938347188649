import { faAngleLeft, faAngleRight } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const Pagination = ({ currentPage = 1, totalRows, perPage, onChangePage }) => {
  const noOfPages = Math.ceil(totalRows / perPage);
  const pages = Array.from(Array(noOfPages), (_, i) => i + 1);
  const firstItem = pages[0];
  const lastItem = pages[pages.length - 1];
  const isFirstItem = firstItem === currentPage;
  const isLastItem = lastItem === currentPage;
  const padding = 4;
  let slicedPages = [];

  if (currentPage <= padding || noOfPages <= padding * 2) {
    slicedPages = pages.slice(0, padding * 2);
  } else if (currentPage + padding * 2 > lastItem) {
    slicedPages = pages.slice(pages.length - padding * 2, pages.length);
  } else {
    slicedPages = pages.slice(currentPage - (padding + 1), currentPage + padding);
  }

  return (
    <div className="flex justify-center space-x-2">
      <button
        className={`btn btn-light no-underline ${
          isFirstItem ? "cursor-not-allowed opacity-50" : ""
        }`}
        disabled={isFirstItem}
        onClick={(event) => {
          event.preventDefault();
          onChangePage(parseInt(currentPage) - 1);
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>

      <>
        {slicedPages.map((page) => {
          const style = {
            fontWeight: currentPage === page ? "bold" : "",
          };

          return (
            <button
              key={page}
              className={currentPage === page ? "btn no-underline" : "btn btn-light no-underline"}
              style={style}
              onClick={(event) => {
                event.preventDefault();
                onChangePage(page);
              }}
            >
              {page}
            </button>
          );
        })}

        <button
          className={`btn btn-light no-underline ${
            isLastItem ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={isLastItem}
          onClick={(event) => {
            event.preventDefault();
            onChangePage(parseInt(currentPage) + 1);
          }}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </>
    </div>
  );
};
