import React, { useEffect, useState, useRef } from "react";
import ReactOnRails from "react-on-rails";

import ProductSelect from "./ProductSelect";
import IntegrationSelect from "./IntegrationSelect";
import OptionCheckbox from "./OptionCheckbox";
import VariantItem from "./VariantItem";
import ShopItem from "./ShopItem";
import { Button } from "../../UI/components/Button";
import toast, { Toaster } from "react-hot-toast";
import TextEditor from "../../UI/components/TextEditor";

import clsx from "clsx";

import axios from "axios";
import Toggle from "../../UI/components/Toggle";

export default function ShopifyListing(props) {
  const [listing, setListing] = useState(props.listing);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedIntegrations, setSelectedIntegrations] = useState([]);
  const [sharedAttributes, setSharedAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [title, setTitle] = useState("");
  const [handle, setHandle] = useState("");
  const [description, setDescription] = useState("");
  const [isPurchasable, setIsPurchasable] = useState(props.listing.is_purchasable);
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (listing.id) {
      setDescription(listing.description);
      setTitle(listing.title);
      setHandle(listing.handle);
      setIsPurchasable(listing.is_purchasable);
      setSelectedProducts(
        listing.selected_products.map((x) => {
          return {
            id: x.id,
            sku: x.sku,
            title: x.title,
            published: x.published,
            attributes: x.attributes,
            attribute_value_overrides: JSON.parse(x.attribute_overrides) || {},
          };
        })
      );

      setSelectedAttributes(listing.options.split(",").filter(String));

      setSelectedIntegrations(listing.selected_integrations);
    }
  }, []);

  const updateTitle = (e) => {
    setTitle(e.target.value);
    setHandle(makeHandle(e.target.value, true));
  };

  const updateSharedAttributes = () => {
    let all_attrs = selectedProducts.map((product) => {
      return product.attributes;
    });

    let merged_attrs = Object.assign({}, ...all_attrs);
    merged_attrs = Object.keys(merged_attrs).map((key) => key);

    setSharedAttributes(merged_attrs);
  };

  const makeHandle = (urlString, filter) => {
    // Changes, e.g., "Petty theft" to "petty_theft".
    // Remove all these words from the string before URLifying

    let removelist = [];
    if (filter) {
      removelist = ["a", "the", "tm"];
    }
    let s = urlString;
    const r = new RegExp("\\b(" + removelist.join("|") + ")\\b", "gi");
    s = s.replace(r, "");
    s = s.replace(/[^-\w\s]/g, ""); // Remove unneeded characters
    s = s.replace(/^\s+|\s+$/g, ""); // Trim leading/trailing spaces
    s = s.replace(/[-\s]+/g, "-"); // Convert spaces to hyphens
    s = s.toLowerCase(); // Convert to lowercase
    return s; // Trim to first num_chars characters
  };

  useEffect(() => {
    updateSharedAttributes();
  }, [selectedProducts]);

  const addProduct = (e) => {
    // prevent adding twice
    if (!selectedProducts.some((product) => product.id === e.id)) {
      // check product being added has all the selected_attributes
      const productToAdd = { ...e, attribute_value_overrides: {} };

      setSelectedProducts([...selectedProducts, productToAdd]);
    }
  };

  const removeProduct = (e) => {
    setSelectedProducts(selectedProducts.filter((item) => item.id !== e.id));
  };

  const setOptionValue = (product, attr_name, attr_value) => {
    const existing_products = selectedProducts;
    const product_to_update = existing_products.find((x) => x.id === product.id);

    if (!attr_value.replace(/\s/g, "").length) {
      delete product_to_update.attribute_value_overrides[attr_name];

      setSelectedProducts([...existing_products]);

      return;
    }

    if (
      product.attributes.hasOwnProperty(attr_name) &&
      product.attributes[attr_name] == attr_value
    ) {
      delete product_to_update.attribute_value_overrides[attr_name];
      setSelectedProducts([...existing_products]);
      return;
    }

    product_to_update.attribute_value_overrides = {
      ...product_to_update.attribute_value_overrides,
      [`${attr_name}`]: attr_value,
    };
    setSelectedProducts([...existing_products]);
  };

  const addIntegration = (e) => {
    // prevent adding twice
    if (!selectedIntegrations.some((item) => item.id === e.id)) {
      setSelectedIntegrations([...selectedIntegrations, e]);
    }
  };

  const removeIntegration = (e) => {
    setSelectedIntegrations(selectedIntegrations.filter((item) => item.id !== e.id));
  };

  const updateOptions = (key, checked) => {
    if (checked && !selectedAttributes.hasOwnProperty(key)) {
      // if there are already 3 options selected, don't allow any more
      if (selectedAttributes.length >= 3) {
        toast.error("You can only select a maximum of three options to define listing variants.");
        return;
      }

      setSelectedAttributes([...selectedAttributes, key]);
    } else {
      // remove attribute
      setSelectedAttributes(selectedAttributes.filter((attr) => attr !== key));
    }
  };

  const updateIntegrationOptions = (integrationId, options = {}) => {
    let items = [...selectedIntegrations];
    let index = items.findIndex((item) => item.id === integrationId);
    let item = { ...items[index] };
    for (const key in options) {
      item[key] = options[key];
    }

    items[index] = item;
    setSelectedIntegrations(items);
  };

  // Check if the product's attributes are unique compared to the other selected products
  const isProductValid = (product) => {
    const otherProducts = selectedProducts.filter((p) => p.id !== product.id);

    const filterToSelectedAttributes = (product) =>
      selectedAttributes.reduce((obj, key) => {
        obj[key] = product.attribute_value_overrides[key] || product.attributes[key];
        return obj;
      }, {});

    const allAttributesHavevalue = (attributes) => {
      for (var key in attributes) {
        if (attributes[key] === null || attributes[key] === "" || attributes[key] === undefined)
          return false;
      }
      return true;
    };

    let selected_filtered_attributes = filterToSelectedAttributes(product);
    let other_filtered_attributes = otherProducts.map((p) => filterToSelectedAttributes(p));

    return (
      !other_filtered_attributes.some((attr) => {
        return JSON.stringify(selected_filtered_attributes) === JSON.stringify(attr);
      }) && allAttributesHavevalue(selected_filtered_attributes)
    );
  };

  // Create the ShopifyListing
  const createListing = async () => {
    setIsLoading(true);
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      data: {
        title: title,
        handle: handle,
        brand_id: listing.brand.id,
        description: description,
        is_purchasable: isPurchasable,
        products: selectedProducts,
        integrations: selectedIntegrations,
        options: selectedAttributes.join(","),
      },
    };

    let result = {};
    try {
      result = await axios.post(`/b/${listing.brand.slug}/listings`, requestConfig);

      setResponse(result.data);

      setTimeout(function () {
        setIsLoading(false);
        toast.success("Listing Created");
        window.location = `${result.data.listing.id}/edit`;
      }, 1500);
    } catch (error) {
      setIsLoading(false);
      setResponse(error.response.data);
      console.log("error.response.data", error.response.data);
      toast.error(`Error Creating Listing: ${error.response.data.error}`);
    }
  };

  // Delete the Listing
  const deleteListing = async () => {
    setIsDeleting(true);
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    };

    let result = {};
    try {
      result = await axios.delete(`/b/${listing.brand.slug}/listings/${listing.id}`, requestConfig);
      console.log("deleteListing result", result.data);

      setResponse(result.data);

      setTimeout(function () {
        setIsDeleting(false);
        toast.success("Listing Deleted");

        // return to the brand listings page
        window.location = `/b/${listing.brand.slug}/listings`;
      }, 1500);
    } catch (error) {
      setIsLoading(false);
      setResponse(error.response.data);
      console.log("error.response.data", error.response.data);
      toast.error(`Error Creating Listing: ${error.response.data.error}`);
    }
  };

  const changeStatus = async () => {
    setIsUpdating(true);
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      data: {
        status: listing.status === "published" ? "archived" : "published",
      },
    };

    let result = {};
    try {
      result = await axios.patch(
        `/b/${listing.brand.slug}/listings/${listing.id}/status`,
        requestConfig
      );
      console.log("changeStatus result", result.data);

      setResponse(result.data);
      setIsUpdating(false);

      setListing(result.data.listing);

      toast.success(result.data.success || "Status Updated");
    } catch (error) {
      setIsUpdating(false);
      setResponse(error.response.data);
      console.log("error.response.data", error.response.data);
      toast.error(`Error Updating Listing: ${error.response.data.error}`);
    }
  };

  // Update the ShopifyListing
  const updateListing = async () => {
    console.log("updateListing");

    // do not proceed if selectedProducts > 1 AND selectedAttributes is empty
    if (selectedProducts.length > 1 && selectedAttributes.length === 0) {
      toast.error("You must select at least one option to define listing variants.");
      return;
    }

    // do not proceed if selectedProducts > 1 AND selectedAttributes is > 3
    if (selectedProducts.length > 1 && selectedAttributes.length > 3) {
      toast.error("You can only select a maximum of three options to define listing variants.");
      return;
    }

    setIsLoading(true);

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      data: {
        title: title,
        handle: handle,
        description: description,
        is_purchasable: isPurchasable,
        products: selectedProducts,
        integrations: selectedIntegrations,
        options: selectedAttributes.join(","),
      },
    };

    let result = {};
    try {
      result = await axios.patch(`/b/${listing.brand.slug}/listings/${listing.id}`, requestConfig);
      console.log("updateListing result", result.data);

      if (result.data.error) {
        // throw result.data.error;
        toast.error(result.data.error);
      } else {
        toast.success("Listing Updated");
      }
      if (result.data.listing) {
        console.log("result.data.listing", result.data.listing);
        setListing(result.data.listing);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log("error", error);

      toast.error("Error Updating Listing");
    }
  };

  const setMarketplaceVisibility = async (value) => {
    console.log("setMarketplaceVisibility", value);

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      data: {
        visible_on_marketplace: value,
      },
    };

    let result = {};
    try {
      result = await axios.patch(
        `/b/${listing.brand.slug}/listings/${listing.id}/visibility`,
        requestConfig
      );

      console.log("setMarketplaceVisibility result", result.data);

      if (result.data.error) {
        // throw result.data.error;
        toast.error(result.data.error);
      } else {
        toast.success(result.data.success || "Visibility Updated");
      }
      if (result.data.listing) {
        console.log("result.data.listing", result.data.listing);
        setListing(result.data.listing);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log("error", error);

      toast.error("Error Updating Listing");
    }
  };

  const getSharedAttributeOptions = () => {
    // todo: move to useEffect to prevent updating on every render

    let shared_attribute_options = sharedAttributes.map((attribute) => {
      return (
        <OptionCheckbox
          key={attribute}
          defaultChecked={selectedAttributes.includes(attribute)}
          attributeKey={attribute}
          disabled={selectedAttributes.length >= 3 && !selectedAttributes.includes(attribute)}
          handler={updateOptions}
        />
      );
    });

    if (selectedProducts.length > 1 && shared_attribute_options.length === 0) {
      shared_attribute_options = (
        <div className="bg-danger-500 text-danger-50 rounded p-5 text-sm">
          There are no shared attributes to use as variant options. You need at least one common
          attribute on each product. Please review the products you have added.
        </div>
      );
    }

    return shared_attribute_options;
  };

  return (
    <>
      {listing && (
        <div>
          <div className="relative">
            <div className="flex items-center justify-between border-b pb-5">
              <div className="flex flex-shrink items-center space-x-4">
                <h2 className="text-2xl font-bold">
                  {listing.id
                    ? `${listing.brand.name} Listing`
                    : `New ${listing.brand.name} Marketplace Listing`}
                </h2>
                {listing.id && (
                  // show the listing status
                  <span
                    title={listing.validations}
                    className={clsx(
                      "inline-flex items-center space-x-2 rounded-full px-2.5 py-0.5 text-xs font-medium uppercase",
                      listing.status === "published"
                        ? "bg-success-100 text-success-800"
                        : "bg-gray-100 text-gray-800"
                    )}
                  >
                    <span>{listing.status}</span>
                  </span>
                )}
              </div>
              <div className="flex flex-grow items-center justify-end gap-4">
                {listing.status === "published" && (
                  <label
                    className="mt-1 flex items-center gap-2 text-xs font-medium"
                    title="Toggle the visibility of the listing on the marketplace."
                  >
                    <Toggle
                      label="Visible on Marketplace"
                      checked={listing.visible_on_marketplace}
                      listingId={listing.id}
                      onChangeCallback={(value) => setMarketplaceVisibility(value)}
                      disabled={listing.status !== "published"}
                    />
                    <span>Visible on Marketplace</span>
                  </label>
                )}

                <div className="btn-group">
                  <button onClick={() => window.history.back()} className="btn btn-sm btn-neutral">
                    Cancel
                  </button>
                  {listing.id && (
                    <Button
                      label="Delete"
                      className="btn btn-sm btn-danger"
                      onClick={deleteListing}
                      showLoading={isDeleting}
                    />
                  )}
                  {listing.id && (
                    <Button
                      label={listing.status === "published" ? "Unpublish" : "Publish"}
                      className={clsx(
                        "btn btn-sm",
                        listing.status === "published" ? "btn-info" : "btn-success"
                      )}
                      onClick={changeStatus}
                      showLoading={isUpdating}
                    />
                  )}
                  {listing.id ? (
                    <Button
                      label="Save"
                      className="btn btn-sm"
                      onClick={updateListing}
                      showLoading={isLoading}
                    />
                  ) : (
                    <Button
                      label="Create Listing"
                      className="btn btn-sm"
                      onClick={createListing}
                      showLoading={isLoading}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="py-5">
              <div className="data-export-ui relative w-full overflow-hidden rounded-lg bg-white p-5 shadow-sm">
                {listing.validations && listing.validations.length > 0 && (
                  <div className="bg-warning-100 text-warning-700 mb-5 rounded-lg p-4" role="alert">
                    <p className="font-bold">Listing Validation Errors</p>
                    <ul className="mt-3 list-inside list-disc text-sm">
                      {listing.validations.map((validation, index) => (
                        <li key={index}>{validation}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="grid grid-cols-2 gap-8">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Listing Title</label>
                      <input
                        type="text"
                        className="form-control"
                        maxLength="255"
                        value={title}
                        onChange={updateTitle}
                      />
                      <div className="text-muted text-right">
                        <small>{title ? title.length : 0} / 255 characters</small>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Listing Handle</label>
                      <input
                        type="text"
                        className="form-control"
                        value={handle}
                        onChange={(e) => setHandle(e.target)}
                      />
                    </div>
                    <div className="form-group">
                      <label>Listing SKU</label>
                      <input type="text" className="form-control" disabled value={listing.sku} />
                      <div className="italix mt-1.5 text-xs text-gray-400">
                        This SKU is not editable and is only used for ecommerce platforms that
                        require a parent level SKU to be present.
                      </div>
                    </div>
                    <div className="hidden py-5">
                      <label>Settings</label>

                      <div className="relative my-3 flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            type="checkbox"
                            name="is_purchasable"
                            id="is_purchasable"
                            aria-describedby="comments-description"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            defaultChecked={isPurchasable}
                            onClick={(e) => setIsPurchasable(e.target.checked)}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="is_purchasable" className="font-medium text-gray-700">
                            Purchasable
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="py-5">
                      <label>Description</label>

                      <TextEditor
                        isEditable={selectedProducts && selectedProducts.length > 1}
                        value={listing.description}
                        onChange={setDescription}
                      />
                    </div>
                    <div className="py-5">
                      <ProductSelect onProductSelect={addProduct} brand={listing.brand} />
                    </div>
                    <div className="hidden py-5">
                      <IntegrationSelect
                        addIntegration={addIntegration}
                        removeIntegration={removeIntegration}
                        selectedIntegrations={selectedIntegrations}
                        brand={listing.brand}
                      />
                    </div>
                  </div>
                  <div className="relative">
                    {selectedProducts && selectedProducts.length > 0 && (
                      <>
                        <div className="mb-5">
                          <label>Selected Products</label>
                          <div className="overflow-hidden border bg-white shadow-sm sm:rounded-md">
                            <ul className="divide-y divide-gray-200">
                              {selectedProducts.map((product) => {
                                return (
                                  <VariantItem
                                    key={product.id}
                                    product={product}
                                    valid={isProductValid(product)}
                                    removeProduct={removeProduct}
                                    setOptionValue={setOptionValue}
                                    selectedAttributes={selectedAttributes}
                                  />
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                        <div className="mb-5">
                          <label>Available Options</label>
                          <label className="mb-5 mt-2 block text-xs text-gray-500">
                            You can select a maximum of three options to define listing variants.
                          </label>
                          {getSharedAttributeOptions()}
                        </div>
                      </>
                    )}

                    {selectedIntegrations && selectedIntegrations.length > 0 && (
                      <div className="mb-5 hidden">
                        <label>Connected Stores</label>
                        <div className="overflow-hidden border bg-white shadow-sm sm:rounded-md">
                          <ul className="divide-y divide-gray-200">
                            {selectedIntegrations.map((item) => {
                              return (
                                <ShopItem
                                  key={item.id}
                                  integration={item}
                                  integrationOptionsHandler={updateIntegrationOptions}
                                />
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      )}
    </>
  );
}
