import React from "react";

import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import AccountSettings from "../Account/AccountSettings";

export default function DistributorSettings({
  team,
  user,
  app_contexts,
  domains,
  tec_alliance_merchants,
}) {
  return (
    <>
      <Header app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8">
            <AccountSettings
              user={user}
              team={team}
              domains={domains}
              tec_alliance_merchants={tec_alliance_merchants}
            />
          </div>
        </div>
      </section>
    </>
  );
}
