import React from "react";
import { Button, Toggle } from "../../UI/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faSpinnerThird,
  faTimes,
  faTrashAlt,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { faCarAlt } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { useState, useEffect } from "react";
import { Fragment } from "react";
import clsx from "clsx";
import toast, { Toaster } from "react-hot-toast";
// TODO: rename/relocate this to a top level utl
import Api from "../../Marketplace/api";
import Select from "react-select";

const tabs = [
  { display: "GTIN", key: "gtin" },
  { display: "OEM References", key: "oem" },
  { display: "Alternate", key: "alternate" },
];

export default function ProductNumberManagement({ apiKey, productId, count }) {
  const [isOpen, setIsOpen] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [newProductNumberValue, setNewProductNumberValue] = useState("");

  const [productNumbersCount, setProductNumbersCount] = useState(count);

  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [vehicleMakeIds, setVehicleMakeIds] = useState([]);

  const [productNumbers, setProductNumbers] = useState(null);

  const api = new Api(apiKey, location);

  const onCancel = () => {
    setIsOpen(false);
  };

  // load product numbers for productId when the dialog opens
  useEffect(() => {
    if (isOpen) {
      // load alternate skus from API
      // todo: move to tab loading
      setFormLoading(true);
      api
        .getProductNumbers(productId)
        .then((response) => {
          setProductNumbers(response);
          setProductNumbersCount(response.product_numbers_count);
          setFormLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setProductNumbers(null);
          setFormLoading(false);
        });

      api.getVehicleMakes().then((response) => {
        const vehicle_make_options = response.map((make) => {
          return { value: make.id, label: make.value };
        });
        setVehicleOptions(vehicle_make_options);
      });
    }
  }, [isOpen]);

  const saveProductNumber = (value, type, params) => {
    // call API to save alternate sku
    if (value.length > 2) {
      setFormLoading(true);
      api
        .saveProductNumber(productId, value, type, params)
        .then((response) => {
          console.log(response);

          // handle response error
          if (response.error) {
            toast.error(response.messages.join(", "));
            setFormLoading(false);
            return;
          }

          setTimeout(() => {
            setNewProductNumberValue("");
            setVehicleMakeIds([]);
            setProductNumbers(response);
            setProductNumbersCount(response.product_numbers_count);
            setFormLoading(false);
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Failed to save product number");
          // setProductNumbers(null);
          setFormLoading(false);
        });
    }
  };

  const deleteProductNumber = (productNumberId, type) => {
    // call API to delete product number
    setFormLoading(true);
    api
      .deleteProductNumber(productId, productNumberId, type)
      .then((response) => {
        console.log(response);

        setTimeout(() => {
          setVehicleMakeIds([]);
          setProductNumbers(response);
          setProductNumbersCount(response.product_numbers_count);
          setFormLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
        setVehicleMakeIds([]);
        setProductNumbers(null);
        setFormLoading(false);
      });
  };

  return (
    <>
      <div className="relative">
        <Button
          size="icon"
          className="btn btn-outline btn-icon !h-[38px] !w-[38px]"
          icon={faBarcode}
          onClick={() => setIsOpen(true)}
        />
        {productNumbersCount > 0 && (
          <span className="bg-danger-500 absolute right-0 top-0 -mr-2 -mt-2 flex h-4 min-w-4 items-center justify-center rounded-full px-1 text-center text-[10px] leading-none text-white">
            <span>{productNumbersCount}</span>
          </span>
        )}
      </div>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onCancel}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div
            className={clsx(
              "fixed inset-0 overflow-hidden",
              formLoading && "pointer-events-none cursor-not-allowed"
            )}
          >
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-bold tracking-tight text-gray-900">
                            Product Number Management
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <Button
                              className="rounded-md bg-white px-2 text-gray-400 hover:text-gray-500 focus:outline-none"
                              onClick={() => onCancel()}
                              icon={faTimes}
                            ></Button>
                          </div>
                        </div>
                      </div>
                      <div className="relative px-6">
                        <Tab.Group>
                          <div className="border-b border-gray-200">
                            <Tab.List className="tabs-menu -mb-px flex space-x-8" aria-label="Tabs">
                              {tabs.map((tab) => (
                                <Tab
                                  key={tab.key}
                                  className={({ selected }) =>
                                    clsx(
                                      "flex whitespace-nowrap border-b-2 px-1 py-4 text-xs font-medium focus-visible:outline-0",
                                      selected
                                        ? "is-active border-indigo-500 text-indigo-600"
                                        : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700"
                                    )
                                  }
                                >
                                  {({ selected }) => (
                                    <>
                                      {tab.display}
                                      {productNumbers && productNumbers[tab.key].length ? (
                                        <span
                                          className={clsx(
                                            selected
                                              ? "bg-indigo-100 text-indigo-600"
                                              : "bg-gray-100 text-gray-900",
                                            "-mt-0.5 ml-1.5 hidden rounded-full px-2 py-0.5 text-[10px] font-medium md:inline-block"
                                          )}
                                        >
                                          {productNumbers[tab.key].length}
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Tab>
                              ))}
                            </Tab.List>
                          </div>

                          <Tab.Panels className="mt-2">
                            {/* GTIN */}
                            <Tab.Panel>
                              <div className="bg-secondary text-secondary-foreground mb-8 w-full rounded-md px-4 py-2 text-xs">
                                GTIN is a Global Trade Item Number that is given to a product for
                                identification, encoded in a barcode. We'll use GTIN's where
                                applicable in integrated systems.
                              </div>
                              {formLoading && !productNumbers && <Loading />}

                              {productNumbers && productNumbers.gtin && (
                                <div className="flex flex-col divide-y divide-stone-200">
                                  {productNumbers.gtin.map((gtin) => (
                                    <div
                                      key={gtin.id}
                                      className="flex items-center justify-between px-2 py-4"
                                    >
                                      <div
                                        className={clsx(
                                          "flex items-center gap-5",
                                          !gtin.active && "opacity-30"
                                        )}
                                      >
                                        <span
                                          className={clsx(
                                            "text-xs",
                                            gtin.gtin_type
                                              ? "font-bold text-gray-900"
                                              : "bg-warning-100 text-warning-700 rounded px-2 py-1.5 font-medium leading-none"
                                          )}
                                        >
                                          {gtin.gtin_type
                                            ? `${gtin.gtin_type} (${gtin.description})`
                                            : "Invalid"}
                                        </span>
                                        <span className="text-xs font-medium text-gray-900">
                                          {gtin.value}
                                        </span>
                                      </div>
                                      <div className="flex gap-3">
                                        {/* Active Switch */}
                                        <Toggle
                                          checked={gtin.active}
                                          size="sm"
                                          onChangeCallback={(checked) =>
                                            saveProductNumber(gtin.value, "gtin", {
                                              active: checked,
                                            })
                                          }
                                        />

                                        <Button
                                          icon={faTrashAlt}
                                          className="hover:text-danger-500 text-xs text-stone-500"
                                          onClick={() => {
                                            setDeletingId(gtin.id);
                                            deleteProductNumber(gtin.id, "gtin");
                                          }}
                                          showLoading={formLoading && deletingId === gtin.id}
                                          disabled={formLoading}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                  {/* GTIN Input */}
                                  <div className="flex items-center gap-2 pt-4">
                                    <input
                                      type="text"
                                      className="form-input"
                                      placeholder="Enter a new GTIN"
                                      value={newProductNumberValue}
                                      onChange={(e) => setNewProductNumberValue(e.target.value)}
                                      disabled={formLoading}
                                    />

                                    <Button
                                      className="btn btn-primary flex-shrink-0"
                                      onClick={() => {
                                        saveProductNumber(newProductNumberValue, "gtin");
                                      }}
                                      label="Add"
                                      showLoading={formLoading && !deletingId}
                                      disabled={formLoading}
                                    />
                                  </div>
                                </div>
                              )}
                            </Tab.Panel>

                            {/* OEM */}
                            <Tab.Panel>
                              <div className="bg-secondary text-secondary-foreground mb-8 w-full rounded-md px-4 py-2 text-xs">
                                OEM numbers are Original Equipment Manufacturer part numbers, or
                                'Genuine Part Numbers'. If a customer searches for an OEM number
                                we'll show them this product.
                              </div>
                              {formLoading && !productNumbers && <Loading />}

                              {productNumbers && productNumbers.oem && (
                                <div className="flex flex-col divide-y divide-stone-200">
                                  {productNumbers.oem.map((oem) => (
                                    <div
                                      key={oem.id}
                                      className="flex items-center justify-between px-2 py-4"
                                    >
                                      <div className="flex items-center gap-5">
                                        <span className="text-xs font-medium text-gray-900">
                                          {oem.reference_number}
                                        </span>
                                        <div className="flex items-center gap-2 text-xs text-gray-900">
                                          {oem.vehicle_makes?.map((make) => {
                                            return (
                                              <div
                                                key={make}
                                                className="flex items-center gap-1 rounded bg-stone-200 px-1.5 py-0.5 text-center"
                                              >
                                                <FontAwesomeIcon icon={faCarAlt} />
                                                <span>{make}</span>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                      <div className="flex gap-2">
                                        <Button
                                          icon={faTrashAlt}
                                          className="hover:text-danger-500 text-xs text-stone-500"
                                          onClick={() => {
                                            setDeletingId(oem.id);
                                            deleteProductNumber(oem.id, "oem");
                                          }}
                                          showLoading={formLoading && deletingId === oem.id}
                                          disabled={formLoading}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                  {/* OEM Product Number Input */}
                                  <div className="flex flex-col items-end gap-2 pt-4">
                                    <input
                                      type="text"
                                      className="form-input"
                                      placeholder="Enter a new OEM reference"
                                      value={newProductNumberValue}
                                      onChange={(e) => setNewProductNumberValue(e.target.value)}
                                      disabled={formLoading}
                                    />
                                    <Select
                                      className="w-full text-sm"
                                      options={vehicleOptions}
                                      placeholder="Vehicle Makes"
                                      isMulti={true}
                                      isLoading={formLoading && !deletingId}
                                      isDisabled={formLoading}
                                      value={vehicleOptions.filter((option) => {
                                        return vehicleMakeIds.includes(option.value);
                                      })}
                                      onChange={(selected) => {
                                        const vehicleMakeIds = selected.map((make) => make.value);
                                        setVehicleMakeIds(vehicleMakeIds);
                                      }}
                                    />
                                    <Button
                                      className="btn btn-primary flex-shrink-0"
                                      onClick={() => {
                                        saveProductNumber(newProductNumberValue, "oem", {
                                          vehicle_make_ids: vehicleMakeIds?.join(","),
                                        });
                                      }}
                                      label="Add"
                                      showLoading={formLoading && !deletingId}
                                      disabled={formLoading}
                                    />
                                  </div>
                                </div>
                              )}
                            </Tab.Panel>

                            {/* Alternate SKUs */}
                            <Tab.Panel>
                              <div className="bg-secondary text-secondary-foreground mb-8 w-full rounded-md px-4 py-2 text-xs">
                                Alternate numbers may represent discontinued, superseded or
                                alternate fitment product SKUs. If a customer searches for an
                                alternate number we'll show them this product.
                              </div>
                              {formLoading && !productNumbers && <Loading />}
                              {productNumbers && productNumbers.alternate && (
                                <div className="flex flex-col divide-y divide-stone-200">
                                  {productNumbers.alternate.map((alternate) => (
                                    <div
                                      key={alternate.id}
                                      className="flex items-center justify-between px-2 py-4"
                                    >
                                      <div className="flex flex-col">
                                        <span className="text-xs font-medium text-gray-900">
                                          {alternate.sku}
                                        </span>
                                      </div>
                                      <div className="flex gap-2">
                                        <Button
                                          icon={faTrashAlt}
                                          className="hover:text-danger-500 text-xs text-stone-500"
                                          onClick={() => {
                                            setDeletingId(alternate.id);
                                            deleteProductNumber(alternate.id, "alternate");
                                          }}
                                          showLoading={formLoading && deletingId === alternate.id}
                                          disabled={formLoading}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                  {/* Alternate Product Number Input */}
                                  <div className="flex items-center gap-2 pt-4">
                                    <input
                                      type="text"
                                      className="form-input"
                                      placeholder="Enter a new alternate number"
                                      value={newProductNumberValue}
                                      onChange={(e) => setNewProductNumberValue(e.target.value)}
                                      disabled={formLoading}
                                    />
                                    <Button
                                      className="btn btn-primary flex-shrink-0"
                                      onClick={() => {
                                        saveProductNumber(newProductNumberValue, "alternate");
                                      }}
                                      label="Add"
                                      showLoading={formLoading && !deletingId}
                                      disabled={formLoading}
                                    />
                                  </div>
                                </div>
                              )}
                            </Tab.Panel>
                          </Tab.Panels>
                        </Tab.Group>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Toaster />
    </>
  );
}

const Loading = () => {
  return (
    <div className="flex items-center justify-center gap-2 py-6 text-sm text-indigo-600">
      <span>Loading</span>
      <FontAwesomeIcon icon={faSpinnerThird} className="animate-spin" />
    </div>
  );
};
