import React, { Fragment, useEffect, useState, useCallback, useMemo } from "react";

import Table from "../../Application/components/Table";

import { FormModal } from "../../UI/components";

export default function ProductSelectModal({
  closeModal,
  isOpen,
  recordType = null,
  existingProductIds,
}) {
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const handleSelectedRows = useCallback((rowIds) => {
    setSelectedProductIds(rowIds);
  });

  const fetchData = useCallback(async ({ pageIndex, pageSize, sortBy, filters, globalFilter }) => {
    console.log("SORT", sortBy);
    console.log("FILTERS", filters);
    console.log("GLOBAL FILTER", globalFilter);

    let params = {
      page: pageIndex + 1,
      per: pageSize,
    };

    if (sortBy.length > 0) {
      params["sort"] = sortBy[0].id;
      params["sort_dir"] = sortBy[0].desc ? "desc" : "asc";
    }

    if (globalFilter) {
      params["query"] = globalFilter;
    }

    if (recordType) {
      console.log("RECORD TYPE", recordType);
      params["record_type"] = recordType;
    }

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      params: {
        section: "body",
      },
    };
    setLoading(true);

    const result = await fetch(
      "/products/datatable.json?" + new URLSearchParams(params),
      requestConfig
    );
    const data = await result.json();
    setData(data.data);

    setPageCount(Math.ceil(data.meta.total_count / 1));
    setTotalCount(data.meta.total_count);

    setLoading(false);
  }, []);

  const onSubmit = () => {
    closeModal(selectedProductIds);
  };

  const onCancel = () => {
    closeModal(null);
  };

  const columns = useMemo(
    () => [
      {
        Header: "SKU",
        accessor: "sku",
        disableFilters: true,
      },
      {
        Header: "Title",
        accessor: "title",
        disableFilters: true,
      },
    ],
    []
  );

  return (
    <FormModal title="Select Parts" {...{ onCancel, isOpen }} size="xl">
      <div className="my-4">
        <Table
          columns={columns}
          data={data}
          onFetchData={fetchData}
          onSelectedRows={handleSelectedRows}
          loading={loading}
          pageCount={pageCount}
          totalItems={totalCount}
          initialPageSize={10}
          useRowSelection
        />
      </div>

      <div className="btn-group mt-4 justify-end">
        <button type="button" className="btn btn-neutral" onClick={onCancel}>
          Cancel
        </button>
        <button
          disabled={selectedProductIds.length === 0}
          type="button"
          className="btn"
          onClick={onSubmit}
        >
          Add {selectedProductIds.length} Parts
        </button>
      </div>
    </FormModal>
  );
}
