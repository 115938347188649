import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "../UI/components";
import { Checkbox, DropzoneField, Select } from "../UI/components/Controls";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import SubBrandsList from "../SubBrands/SubBrandsList";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import TextEditor from "../UI/components/TextEditor";
import { Controller } from "react-hook-form";

export default function BrandEditor({ brand }) {
  const [loading, setLoading] = useState(false);

  const getBrandLogo = () => {
    return <img src={`${brand.logo}?cache=${new Date().getTime()}`} className="h-full w-auto" />;
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const formData = new FormData();

    for (const key in data) {
      if (!data[key] && data[key] !== false) {
        continue;
      }

      if (["logo"].includes(key)) {
        continue;
      }

      if (key === "logo_image_file") {
        if (data[key] && data[key].length > 0) {
          formData.append("brand[" + key + "]", data[key][0]);
        }
      } else if (key === "brand_seller_agreement_attributes") {
        formData.append("brand[" + key + "][text]", data[key].text);
        formData.append("brand[" + key + "][active]", data[key].active);
        formData.append("brand[" + key + "][max_discount_percent]", data[key].max_discount_percent);
      } else {
        // console.log("add to formData", key);
        formData.append("brand[" + key + "]", data[key]);
      }

      // console.log("KEY", key);
      // console.log("\tVALUE", data[key]);
    }

    if (brand.id) {
      // console.log("patch", `/admin/brands/${brand.id}`);

      try {
        const result = await axios({
          method: "patch",
          url: `/admin/brands/${brand.id}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });
        console.log(result);
        if (!result.data.errors) {
          toast.success("Brand updated");
        } else {
          toast.error(
            `There was an error updating the brand: ${result.data.errors.join(", ").toLowerCase()}`
          );
        }
      } catch (e) {
        console.log("ERROR", e);
        toast.error("There was an error updating the brand");
      }
    } else {
      try {
        const result = await axios({
          method: "post",
          url: `/team/brands`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });

        setLoading(false);

        console.log(result);

        // if result status is created
        if (!result.data.errors) {
          toast.success("Brand created");

          if (result.data.location) {
            // faux delay
            setTimeout(() => {
              location.replace(result.data.location);
            }, 1000);
          }
        } else {
          toast.error(
            `There was an error creating the brand: ${result.data.errors.join(", ").toLowerCase()}`
          );
        }
      } catch (e) {
        console.log("ERROR", e);
        toast.error(`There was an unknown error creating the brand.`);
      }
    }

    setLoading(false);
  };

  const updateTabRoute = (index) => {
    // get the tab object from tabs using index
    const tab = tabData[index];

    setTabIndex(index);

    // update the window.location hash to use the tab display value to lowercase and replace spaces with dashes
    window.location.hash = tab.display.toLowerCase().replace(" ", "-");
  };

  const tabData = [
    {
      display: "Details",
      disabled: false,
    },
    {
      display: "Appearance",
      disabled: false,
    },
    {
      display: "Seller Agreements",
      disabled: false,
    },
  ];

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    // check for hash in window location and set the tab index to the index of the tab with the display value matching the hash
    const hash = window.location.hash.replace("#", "");
    const tabIndex = Object.values(tabData).findIndex(
      (tab) => tab.display.toLowerCase().replace(" ", "-") == hash
    );

    if (tabIndex > -1) {
      setTabIndex(tabIndex);
    }
  }, []);

  return (
    <>
      <Form onSubmit={onSubmit} defaultValues={brand}>
        <div className="flex items-center justify-between border-b pb-5">
          <h2 className="text-2xl font-bold">{brand.id ? "Brand Settings" : "Create Brand"}</h2>
          <div className="btn-group">
            <Button label={brand.id ? "Save" : "Create"} type="submit" showLoading={loading} />
          </div>
        </div>
        <div className="py-5">
          <div className="relative w-full overflow-x-scroll rounded-lg bg-white px-5 pb-5 pt-1 shadow">
            <Tab.Group onChange={updateTabRoute} selectedIndex={tabIndex} defaultIndex={tabIndex}>
              <div className="border-b border-gray-200">
                <Tab.List className="tabs-menu -mb-px flex space-x-6" aria-label="Tabs">
                  {tabData.map((tab, index) => (
                    <Tab
                      key={index}
                      disabled={tab.disabled}
                      className={({ selected, disabled }) =>
                        clsx(
                          "flex items-center whitespace-nowrap border-b-2 px-1 py-2 text-xs font-medium focus-visible:outline-0",
                          selected
                            ? "is-active border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                          tab.disabled ? "pointer-events-none opacity-50" : ""
                        )
                      }
                    >
                      {({ selected }) => (
                        <>
                          {tab.display}
                          {tab.count ? (
                            <span
                              className={classNames(
                                selected
                                  ? "bg-indigo-100 text-indigo-600"
                                  : "bg-gray-100 text-gray-900",
                                "-mt-0.5 ml-1.5 hidden rounded-full px-2 py-0.5 text-[10px] font-medium md:inline-block"
                              )}
                            >
                              {tab.count}
                            </span>
                          ) : null}
                        </>
                      )}
                    </Tab>
                  ))}
                </Tab.List>
              </div>

              <Tab.Panels className="mt-2 py-8">
                <Tab.Panel>
                  <div className="grid grid-cols-2 gap-8">
                    <div className="space-y-12">
                      <div className="space-y-6">
                        <div className="form-group">
                          <Input name="name" label="Brand Name" type="text" required />
                        </div>
                      </div>
                      {brand.id && (
                        <div className="space-y-6">
                          <div className="form-group rounded-lg border border-dashed border-indigo-200 p-4">
                            <SubBrandsList brand={brand} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="grid grid-cols-2 gap-8">
                    <div className="space-y-5">
                      {brand.logo && (
                        <div className="w-full">
                          <label>Logo</label>
                          <div className="relative mt-1 h-[80px] w-full">{getBrandLogo()}</div>
                        </div>
                      )}
                      <div>
                        <DropzoneField
                          name="logo_image_file"
                          label="Upload Brand Logo"
                          accept="image/png, image/jpg, image/jpeg, image/svg+xml"
                          helperText="Supports JPG, JPEG, SVG and PNG"
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Panel>

                {/* Agreements */}
                <Tab.Panel>
                  <div className="grid grid-cols-2 gap-8">
                    <div>
                      <div className="form-group">
                        <Controller
                          name="brand_seller_agreement_attributes[text]"
                          defaultValue={"..."}
                          render={({ field: { value, onChange } }) => {
                            return <TextEditor value={value} onChange={onChange} />;
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="form-group rounded-lg bg-indigo-100 p-4">
                        <Checkbox name="marketplace" label="Visible on Partbot Marketplace?" />
                      </div>
                      <div className="form-group rounded-lg bg-indigo-100 p-4">
                        <Checkbox
                          name="require_merchant_application"
                          label="Require Merchants to Apply"
                        />
                      </div>
                      <div className="form-group rounded-lg bg-indigo-100 p-4">
                        <Checkbox
                          name="brand_seller_agreement_attributes[active]"
                          label="Enable Seller Agreement"
                        />
                      </div>
                      <div className="form-group">
                        <Input
                          name="brand_seller_agreement_attributes[max_discount_percent]"
                          label="Maximum Retail Discount Alert (%)"
                          type="number"
                          min={0}
                          max={100}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </Form>
      <Toaster />
    </>
  );
}
