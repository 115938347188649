import React, { Fragment, useEffect, useState, useCallback, useMemo } from "react";
import PackagingModal from "./PackagingModal";

import axios from "axios";
import ReactOnRails from "react-on-rails";
import toast from "react-hot-toast";
import { Button } from "../../UI/components";
import { faBoxAlt } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";

export default function PackagingList({ product, package_types, onUpdate }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  async function closeModal(data) {
    setIsOpen(false);

    if (data) {
      try {
        const result = await axios({
          method: "post",
          url: "/packages",
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: {
            selected_package: selectedPackage?.id,
            product_id: product.id,
            ...data,
          },
        });

        toast.success("Package added to product");
        setIsOpen(false);
        await onUpdate();
      } catch (e) {
        const message = e.response.data.message;
        toast.error(message);
        setIsOpen(false);
      }
    }
  }

  async function handleRemovePackage(id) {
    try {
      const result = await axios({
        method: "delete",
        url: `/packages/${id}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });
      toast.success("Package removed from product");

      await onUpdate();
    } catch (e) {
      console.log(e);
      toast.error("Error removing package from product");
    }
  }

  const editPackage = useCallback(
    (id) => {
      const pkg = product.packages.find((p) => p.id === id);
      if (pkg) {
        setSelectedPackage(pkg);
        setIsOpen(true);
      }
    },
    [product.packages]
  );

  async function updatePackage(data) {
    if (!selectedPackage) return;

    try {
      const result = await axios({
        method: "patch",
        url: `/packages/${selectedPackage.id}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: data,
      });

      toast.success("Package updated");
      setIsOpen(false);
      // setSelectedPackage(null);
      await onUpdate();
    } catch (e) {
      console.log(e);
      toast.error("Error updating package");
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center justify-end pb-2 pt-5">
          <Button
            label="Add Package"
            icon={faBoxAlt}
            type="button"
            onClick={openModal}
            className="btn btn-sm"
          />
        </div>

        {product.packages.length > 0 && (
          <div className="w-full">
            <div className="overflow-auto sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Container
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Identifier
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Dimensions
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Dead Weight
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Cubic Weight
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Packing
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {product.packages.map((p, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                      <td className="whitespace-nowrap px-6 py-4 text-xs font-medium text-gray-900">
                        {p.package_type}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-xs text-gray-500">
                        {p.identifier}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-right text-xs text-gray-500">
                        {p.length ? p.length : " ? "}
                        <span className="text-gray-400"> x </span>
                        {p.width ? p.width : " ? "}
                        <span className="text-gray-400"> x </span>
                        {p.height ? p.height : " ? "}
                        <span className="text-gray-400"> {p.dimensions_unit}</span>
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-left text-xs text-gray-500">
                        {p.weight ? p.weight : " ? "}
                        <span className="text-gray-400"> {p.weight_unit}</span>
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-xs text-gray-500">
                        {p.cubic_weight ? p.cubic_weight : " ? "}
                        <span className="text-gray-400"> kg</span>
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-xs text-gray-500">
                        {p.ships_separate ? "Ships As Is" : "Use Outer Box"}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-6 text-right text-xs font-medium">
                        <div className="btn-group">
                          <button
                            type="button"
                            onClick={() => {
                              editPackage(p.id);
                            }}
                            className="text-primary hover:text-primary"
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              handleRemovePackage(p.id);
                            }}
                            className="text-danger-600 hover:text-danger-900"
                          >
                            Remove
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <PackagingModal
        {...{ isOpen }}
        cancelModal={() => {
          setIsOpen(false);
          setSelectedPackage(null);
        }}
        closeModal={selectedPackage ? updatePackage : closeModal}
        options={package_types}
        selectedPackage={selectedPackage}
      />
    </>
  );
}
