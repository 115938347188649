import React, { Component, useState, useEffect } from "react";

export default function ScheduleSelect(props) {
  const [selectedTime, setSelectedTime] = useState(props.selected_time);
  const [times, setTimes] = useState([]);

  useEffect(() => {
    setTimes(
      Array(24)
        .fill()
        .map((time, index) => {
          return `${index.toString().padStart(2, "0")}:00`;
        })
    );
  }, []);

  const changeTime = (e) => {
    setSelectedTime(e.target.value);
    props.handler(e);
  };

  return (
    <select
      className="form-control schedule-select"
      data-live-search="true"
      data-style="btn btn-white border-primary m-0"
      value={selectedTime}
      onChange={changeTime}
      required={props.required}
    >
      {times.map((time, index) => {
        return (
          <option value={time} key={index}>
            {time}
          </option>
        );
      })}
    </select>
  );
}
