import React, { useState } from "react";

import axios from "axios";
import ReactOnRails from "react-on-rails";
import toast, { Toaster } from "react-hot-toast";
import BrandCombobox from "../UI/components/BrandCombobox";
import SubBrandCombobox from "../UI/components/SubBrandCombobox";
import ProductTypeCombobox from "../UI/components/ProductTypeCombobox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../UI/components/Button";

import { faTrashAlt } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";

export default function ExportTemplateList(props) {
  const [exportTemplates, setExportTemplates] = useState(props.export_templates);
  const [selectedExportTemplate, setSelectedExportTemplate] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedSubBrand, setSelectedSubBrand] = useState(null);
  const [productType, setProductType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function onBrandChange(brand) {
    setSelectedBrand(brand);
    setSelectedSubBrand("");
  }

  function onSubBrandChange(subBrand) {
    setSelectedSubBrand(subBrand);
  }

  function onProductTypeChange(productType) {
    setProductType(productType);
  }

  function onSelectExportTemplate(exportTemplate) {
    setSelectedExportTemplate(exportTemplate);
    // setSelectedExportTemplate((state) => {
    // });
    exportData(exportTemplate);
  }

  async function handleRemoveExportTemplate(id) {
    if (confirm("Do you really want to delete this Export Template?")) {
      try {
        const result = await axios({
          method: "delete",
          url: `/export_templates/${id}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
        });
        await onUpdate();
      } catch (e) {
        toast.error("Error removing Export Template");
      }
    }
  }

  const exportData = async (state) => {
    console.log(state);

    // do not proceed if no brand is selected
    if (!selectedBrand) {
      toast.error("Please select a brand");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();

    if (selectedBrand) {
      formData.append("export_template[brand_id]", selectedBrand.id);
    } else {
      formData.append("export_template[brand_id]", "");
    }

    if (selectedSubBrand) {
      formData.append("export_template[sub_brand_id]", selectedSubBrand.id);
    }

    if (productType) {
      formData.append("export_template[product_type_id]", productType.id);
    }

    try {
      const result = await axios({
        method: "post",
        url: `/export_template/export/${state.id}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: formData,
      });
      toast.success(result.data.success);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (e) {
      console.log("ERROR", e);
      toast.error("There was an error exporting the data.");
    }
  };

  async function onUpdate() {
    try {
      const result = await axios({
        method: "get",
        url: `/export_templates`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      setExportTemplates(result.data);
      toast.success("Export Template deleted successfully");
    } catch {
      toast.error("Could not refresh Media data");
    }
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center justify-between border-b pb-5">
          <h2 className="text-2xl font-bold">Export Templates</h2>
          <div className="btn-group">
            <a href="/export_templates/new" className="btn">
              Add Export Template
            </a>
          </div>
        </div>

        <div className="py-5">
          <div className="data-export-ui relative w-full rounded-lg bg-white p-5 shadow-sm">
            <div className="mb-3 grid grid-cols-3 gap-5">
              <div>
                <BrandCombobox
                  selectedOption={selectedBrand}
                  handleBrandSelect={(e) => {
                    onBrandChange(e);
                  }}
                />
              </div>
              <div>
                <SubBrandCombobox
                  brand={selectedBrand}
                  handleSubBrandSelect={(e) => {
                    onSubBrandChange(e);
                  }}
                />
              </div>
              <div>
                <ProductTypeCombobox
                  selectedOption={productType}
                  handleProductTypeSelect={(e) => {
                    onProductTypeChange(e);
                  }}
                />
              </div>
            </div>

            {exportTemplates.length > 0 && (
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="w-full">
                    <table className="datatable my-5 min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            width="60%"
                            className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Name
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {exportTemplates.map((exportTemplate, index) => (
                          <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                            <td className="heading-sm">{exportTemplate.title}</td>
                            <td className="px-6 py-4 text-right text-sm font-medium">
                              <div className="btn-group justify-end">
                                <a
                                  href={`/export_templates/${exportTemplate.id}/edit`}
                                  className="btn-sm btn-info"
                                >
                                  Edit
                                </a>
                                <Button
                                  label="Run Export"
                                  showLoading={
                                    isLoading && selectedExportTemplate.id === exportTemplate.id
                                  }
                                  onClick={async () => {
                                    await onSelectExportTemplate(exportTemplate);
                                  }}
                                  type="button"
                                  className="btn-sm"
                                />
                                <button
                                  onClick={async () => {
                                    await handleRemoveExportTemplate(exportTemplate.id);
                                  }}
                                  type="button"
                                  className="btn-sm btn-danger"
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
}
