import { ExclamationCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import React from "react";

const icons = (type) => {
  return {
    error: <XCircleIcon className={`text-danger-400 h-5 w-5`} aria-hidden="true" />,
    info: <ExclamationCircleIcon className={`text-info-400 h-5 w-5`} aria-hidden="true" />,
  }[type];
};

const variants = {
  error: "danger",
  info: "info",
};

export const NotificationBox = ({ notifications }) => {
  if (notifications.length === 0) {
    return null;
  }

  const groupedNotifications = {
    error: notifications.filter(
      (notification) => notification.type === "error" || !notification.type
    ),
    info: notifications.filter((notification) => notification.type === "info"),
  };

  const groups = Object.keys(groupedNotifications);

  return groups.map((group, index) => {
    const values = groupedNotifications[group];

    if (values.length === 0) {
      return null;
    }

    const variant = variants[group].toString();
    const icon = icons(group);

    let message = null;
    if (group === "error") {
      message =
        notifications.length === 1
          ? `There were ${notifications.length} errors with your request.`
          : `There was an error with your request.`;
    }

    return (
      <div key={index} className={`bg-${variant}-50 rounded-md p-4`}>
        <div className="flex">
          <div className="flex-shrink-0">{icon}</div>
          <div className="ml-3">
            {message && (
              <h3 className={`text-${variant}-800 mb-2 text-sm font-medium`}>{message}</h3>
            )}
            <div className={`text-${variant}-700 text-sm`}>
              <ul role="list" className="list-disc space-y-1 pl-5">
                {values.map((notification, index) => (
                  <li key={index}>
                    {notification?.message} {notification?.action && <>{notification.action}</>}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  });
};
