import React, { useState, useRef, useEffect } from "react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinnerThird,
  faSearch,
  faShoppingCart,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";

function useProductCategories(searchTerm) {
  const [productCategories, setProductCategories] = React.useState([]);

  React.useEffect(() => {
    if (searchTerm.trim() !== "") {
      let isFresh = true;
      fetchProductCategories(searchTerm).then((productCategories) => {
        if (isFresh) setProductCategories(productCategories);
      });
      return () => (isFresh = false);
    }
  }, [searchTerm]);
  return productCategories;
}

const cache = {};
async function fetchProductCategories(value) {
  if (cache[value]) {
    return Promise.resolve(cache[value]);
  }

  return fetch("/product_categories_search?term=" + value)
    .then((res) => res.json())
    .then((result) => {
      cache[value] = result;
      return result;
    });
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductCategoryCombobox({ selectedOption, handleProductCategorySelect }) {
  const [query, setQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [selectedProductCategory, setSelectedProductCategory] = useState(selectedOption);
  const [isLoading, setIsLoading] = useState(false);
  const productCategories = useProductCategories(query);

  const handleSelect = (value) => {
    console.log("handleSelect", value);
    setSelectedProductCategory(value);
    handleProductCategorySelect(value);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [productCategories]);

  return (
    <Combobox as="div" value={selectedProductCategory} onChange={(e) => handleSelect(e)}>
      <Combobox.Label className="flex w-full items-center justify-between space-x-2">
        <span>Product Category</span>
        {selectedProductCategory?.shopify_taxonomy_id && (
          <span className="flex items-center justify-center rounded-full bg-gray-100 px-2 text-[10px] text-gray-700">
            {selectedProductCategory.shopify_taxonomy_id}
          </span>
        )}
      </Combobox.Label>
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-8 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          onChange={(event) => {
            setIsLoading(true);
            setIsFocused(false);
            setQuery(event.target.value);
          }}
          onBlur={() => {
            setIsFocused(false);
            setIsLoading(false);
            setQuery("");
          }}
          displayValue={(category) => category?.title}
        />
        {isLoading ? (
          <FontAwesomeIcon
            icon={faSpinnerThird}
            className="absolute left-3 top-[13px] animate-spin text-sm text-indigo-500"
          />
        ) : query ? (
          <FontAwesomeIcon
            icon={faSearch}
            className={classNames("absolute left-3 top-[13px] text-xs text-indigo-500")}
          />
        ) : (
          <FontAwesomeIcon
            icon={faShoppingCart}
            className={classNames("absolute left-3 top-[13px] text-xs text-gray-500")}
          />
        )}

        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {productCategories.length === 0 && query !== "" ? (
            <div className="relative cursor-default select-none px-4 py-2 text-xs text-gray-700">
              Nothing found.
            </div>
          ) : (
            productCategories.map((pt) => (
              <Combobox.Option
                key={pt.id}
                value={pt}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <div className="flex items-center space-x-2" title={pt.title}>
                    <span
                      className={classNames("block truncate text-xs", selected && "font-semibold")}
                    >
                      {pt.title}
                    </span>

                    <span className="text-xs">({pt.shopify_taxonomy_id})</span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </div>
                )}
              </Combobox.Option>
            ))
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
