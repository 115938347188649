import axios from "axios";

const REQUEST_FAILED = "Request failed";
class Api {
  constructor(API_KEY, location) {
    this.headers = {
      Authorization: `Bearer ${API_KEY}`,
      "Content-Type": "application/json",
    };

    this.baseURL =
      location.protocol +
      "//" +
      (location.host.split(".").length > 2
        ? location.host.replace(/^[^.]*/, "api")
        : "api." + location.host);
  }

  async getAllSearchResultIds(payload) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.post(
        `${this.baseURL}/v1/marketplace/all-search-results`,
        payload,
        requestConfig
      );
      return result.data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async getAllFacetFilters(payload) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.post(
        `${this.baseURL}/v1/marketplace/all-facet-filters`,
        payload,
        requestConfig
      );
      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async addToImportList(listingId) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.post(
        `${this.baseURL}/v1/marketplace/create-import-listing`,
        { listing_id: listingId },
        requestConfig
      );
      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async bulkAddToImportList(listingIds) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.post(
        `${this.baseURL}/v1/marketplace/bulk-create-import-listing`,
        { listing_ids: listingIds },
        requestConfig
      );
      return result.data;
    } catch (error) {
      console.log(error.response);
      return { inserted_count: 0, total_count: 0 };
    }
  }

  async loadProductData(objectID, teamID) {
    console.log("teamID", teamID);
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.get(
        `${this.baseURL}/v1/marketplace/listing/${objectID}?team_id=${teamID}`,
        requestConfig
      );
      return result.data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async loadImportListings(page, perPage, query) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.get(
        `${this.baseURL}/v1/marketplace/import-list?page=${page}&per_page=${perPage}&query=${query}`,
        requestConfig
      );
      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async loadStores() {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.get(`${this.baseURL}/v1/marketplace/stores`, requestConfig);
      return result.data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async updateImportListing(listingId, payload) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.put(
        `${this.baseURL}/v1/marketplace/import-list/${listingId}`,
        payload,
        requestConfig
      );

      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async destroyImportListing(listingId) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.delete(
        `${this.baseURL}/v1/marketplace/import-list/${listingId}`,
        requestConfig
      );

      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async bulkRemoveImportList() {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.delete(
        `${this.baseURL}/v1/marketplace/import-list/`,
        requestConfig
      );

      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  /**    Bulk
   *    POST v1/marketplace/import-list/publish
   */
  async bulkPublishImportList(listingIds, stores) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    console.log("stores", stores);

    if (stores.length === 0) {
      console.log("stores is empty");
      return {
        data: {
          success: false,
          errors: [{ message: "Please select at least one store to publish to." }],
        },
      };
    }

    try {
      const result = await axios.post(
        `${this.baseURL}/v1/marketplace/import-list/publish`,
        { listing_ids: listingIds, stores: stores },
        requestConfig
      );

      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  /**    Bulk publish Progress
   *    GET v1/marketplace/import-list/progress
   */
  async bulkPublishProgress(jobId) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
      params: {
        job_id: jobId,
      },
    };

    try {
      const result = await axios.get(
        `${this.baseURL}/v1/marketplace/import-list/progress`,
        requestConfig
      );

      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  /**
   * Individual
   * POST v1/marketplace/import-list/:id/publish
   */
  async publishImportList(listingId, stores) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    console.log("stores", stores);

    if (stores.length === 0) {
      console.log("stores is empty");
      return {
        data: {
          success: false,
          errors: [{ message: "Please select at least one store to publish to." }],
        },
      };
    }

    try {
      const result = await axios.post(
        `${this.baseURL}/v1/marketplace/import-list/${listingId}/publish`,
        { stores: stores },
        requestConfig
      );

      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async loadMerchantProducts(page, perPage, query, store, brand, status, published) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
      params: {
        page: page,
        per_page: perPage,
        query: query,
        store: store,
        brand: brand,
        status: status,
        published: published,
      },
    };

    try {
      const result = await axios.get(
        `${this.baseURL}/v1/marketplace/merchant_products`,
        requestConfig
      );
      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async loadMerchantProductDetails(merchantProductId) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.get(
        `${this.baseURL}/v1/marketplace/merchant_products/${merchantProductId}`,
        requestConfig
      );
      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async syncAllMerchantProducts() {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.post(
        `${this.baseURL}/v1/marketplace/merchant_products/sync_all`,
        {},
        requestConfig
      );
      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async cancelSyncAllMerchantProducts() {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.delete(
        `${this.baseURL}/v1/marketplace/merchant_products/cancel_sync_all`,
        requestConfig
      );
      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async loadSyncProgress(jobIds) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
      params: {
        sync_job_ids: jobIds,
      },
    };

    try {
      const result = await axios.get(
        `${this.baseURL}/v1/marketplace/merchant_products/sync_progress`,
        requestConfig
      );
      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async saveMerchantProduct(merchantProductId) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.put(
        `${this.baseURL}/v1/marketplace/merchant_products/${merchantProductId}`,
        {},
        requestConfig
      );
      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async createMerchantProductAttribute(merchantProductId, payload) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.post(
        `${this.baseURL}/v1/marketplace/merchant_products/${merchantProductId}/merchant_product_attributes`,
        payload,
        requestConfig
      );
      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async removeMerchantProductAttribute(merchantProductId, attributeId) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.delete(
        `${this.baseURL}/v1/marketplace/merchant_products/${merchantProductId}/merchant_product_attributes/${attributeId}`,
        requestConfig
      );
      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async createMerchantProductVehicles(merchantProductId, vehicleIds) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.post(
        `${this.baseURL}/v1/marketplace/merchant_products/${merchantProductId}/merchant_product_vehicles`,
        { vehicle_ids: vehicleIds },
        requestConfig
      );
      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async removeMerchantProductVehicle(merchantProductId, vehicleId) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.delete(
        `${this.baseURL}/v1/marketplace/merchant_products/${merchantProductId}/merchant_product_vehicles/${vehicleId}`,
        requestConfig
      );
      return result;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async updateTeamDomain(domain) {
    return this.createTeamDomain(domain);
  }

  async removeTeamDomain(domain) {
    var requestOptions = {
      method: "POST",
      headers: this.headers,
      redirect: "follow",
      body: JSON.stringify({
        id: domain.id,
      }),
    };

    try {
      const response = await fetch(
        `${this.baseURL}/v1/marketplace/remove-team-domain`,
        requestOptions
      );

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      return data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async createTeamDomain(domain) {
    var requestOptions = {
      method: "POST",
      headers: this.headers,
      redirect: "follow",
      body:
        domain && domain.id
          ? JSON.stringify({
              id: domain.id,
              tld: domain.tld,
            })
          : "",
    };

    try {
      const response = await fetch(
        `${this.baseURL}/v1/marketplace/add-team-domain`,
        requestOptions
      );

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      return data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async createTecAllianceMerchant(merchant) {
    console.log("createTecAllianceMerchant", merchant);
    var requestOptions = {
      method: "POST",
      headers: this.headers,
      redirect: "follow",
      body: merchant ? JSON.stringify(merchant) : "",
    };

    try {
      const response = await fetch(
        `${this.baseURL}/v1/marketplace/add-tecalliance-merchant`,
        requestOptions
      );

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      return data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async updateTecAllianceMerchant(merchant) {
    return this.createTecAllianceMerchant(merchant);
  }

  async removeTecAllianceMerchant(merchant) {
    // return if merchant.id is empty
    if (!merchant || !merchant.id) {
      return;
    }

    var requestOptions = {
      method: "POST",
      headers: this.headers,
      redirect: "follow",
      body: JSON.stringify({
        id: merchant.id,
      }),
    };

    try {
      const response = await fetch(
        `${this.baseURL}/v1/marketplace/remove-tecalliance-merchant`,
        requestOptions
      );

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      return data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async updateUserAccount(userInfo) {
    console.log("updateUserAccount", userInfo);
    var requestOptions = {
      method: "POST",
      headers: this.headers,
      redirect: "follow",
      body:
        userInfo && userInfo.id
          ? JSON.stringify({
              id: userInfo.id,
              user: userInfo,
            })
          : "",
    };

    try {
      const response = await fetch(`${this.baseURL}/v1/marketplace/update-account`, requestOptions);

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      return data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async updateTeamInfo(teamInfo) {
    console.log(
      "updateTeamInfo",
      JSON.stringify({
        id: teamInfo.id,
        team: teamInfo,
      })
    );
    var requestOptions = {
      method: "POST",
      headers: this.headers,
      redirect: "follow",
      body:
        teamInfo && teamInfo.id
          ? JSON.stringify({
              id: teamInfo.id,
              team: teamInfo,
            })
          : "",
    };

    try {
      const response = await fetch(`${this.baseURL}/v1/marketplace/update-team`, requestOptions);

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      return data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async loadFulfillmentOrders(params) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.get(
        `${this.baseURL}/v1/fulfillments?${new URLSearchParams(params)}`,
        requestConfig
      );
      return result.data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async loadFulfillmentOrder(id) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.get(`${this.baseURL}/v1/fulfillments/${id}`, requestConfig);
      return result.data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async requestFulfillment(id) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const result = await axios.post(
        `${this.baseURL}/v1/fulfillments/${id}/request-fulfillment`,
        null,
        requestConfig
      );
      return result.data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async cancelFulfillment(id) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    console.log(requestConfig);

    try {
      const result = await axios.post(
        `${this.baseURL}/v1/fulfillments/${id}/cancel-fulfillment`,
        null,
        requestConfig
      );
      return result.data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async connectDistributorAccount(account) {
    console.log("connectDistributorAccount", account);
    var requestOptions = {
      method: "POST",
      headers: this.headers,
      redirect: "follow",
      body: account ? JSON.stringify(account) : "",
    };

    try {
      const response = await fetch(
        `${this.baseURL}/v1/marketplace/connect-distributor-account`,
        requestOptions
      );

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      return data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async disconnectDistributorAccount(account) {
    console.log("disconnectDistributorAccount", account);
    var requestOptions = {
      method: "DELETE",
      headers: this.headers,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${this.baseURL}/v1/marketplace/disconnect-distributor-account/${account.id}`,
        requestOptions
      );

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      return data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async updateTeamSetting(team, setting, value) {
    console.log("updateTeamSettings", team, setting, value);
    var requestOptions = {
      method: "POST",
      headers: this.headers,
      redirect: "follow",
      body:
        team && team.id
          ? JSON.stringify({
              team_id: team.id,
              setting: setting,
              value: value,
            })
          : "",
    };

    try {
      const response = await fetch(`${this.baseURL}/v1/marketplace/update-setting`, requestOptions);

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      return data;
    } catch (err) {
      console.error(REQUEST_FAILED, err.response);
      return err.response;
    }
  }

  async getProductNumbers(productId) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const response = await fetch(`${this.baseURL}/v1/product/${productId}/product-numbers`, {
        method: "GET",
        headers: requestConfig.headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (err) {
      console.error(REQUEST_FAILED, err);
      return err;
    }
  }

  async saveProductNumber(productId, value, type, params) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const response = await fetch(`${this.baseURL}/v1/product/${productId}/product-number`, {
        method: "POST",
        headers: requestConfig.headers,
        body: JSON.stringify({ value: value, type: type, ...params }),
      });

      if (!response.ok) {
        return Promise.reject(new Error(`HTTP error! status: ${response.status}`));
      }

      const data = await response.json();
      return data;
    } catch (err) {
      console.error(REQUEST_FAILED, err);
      return err;
    }
  }

  async deleteProductNumber(productId, productNumberId, type) {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const response = await fetch(`${this.baseURL}/v1/product/${productId}/product-number`, {
        method: "DELETE",
        headers: requestConfig.headers,
        body: JSON.stringify({ product_number_id: productNumberId, type: type }),
      });

      if (!response.ok) {
        return Promise.reject(new Error(`HTTP error! status: ${response.status}`));
      }

      const data = await response.json();
      return data;
    } catch (err) {
      console.error(REQUEST_FAILED, err);
      return err;
    }
  }

  async getVehicleMakes() {
    const requestConfig = {
      responseType: "json",
      headers: this.headers,
    };

    try {
      const response = await fetch(`${this.baseURL}/v1/vehicles/makes`, {
        method: "GET",
        headers: requestConfig.headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (err) {
      console.error(REQUEST_FAILED, err);
      return err;
    }
  }
}

export default Api;
