import React, { useState, useRef, useEffect } from "react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";

function useCurrencies(searchTerm) {
  const [currencies, setCurrencies] = React.useState([]);
  React.useEffect(() => {
    if (searchTerm.trim() !== "") {
      let isFresh = true;
      fetchCurrencies(searchTerm).then((currencies) => {
        if (isFresh) {
          const uniqueCurrencies = currencies.filter((currency, index) => {
            return index === currencies.findIndex((c) => c.value === currency.value);
          });
          setCurrencies(uniqueCurrencies);
        }
      });
      return () => (isFresh = false);
    }
  }, [searchTerm]);
  return currencies;
}

const cache = {};
async function fetchCurrencies(value) {
  if (cache[value]) {
    return Promise.resolve(cache[value]);
  }
  return fetch("/currency_search?term=" + value)
    .then((res) => res.json())
    .then((result) => {
      cache[value] = result;
      return result;
    });
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TimezoneCombobox({
  label = "Currency",
  selectedOption,
  handleCurrencySelect,
}) {
  const [query, setQuery] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(selectedOption);

  const currencies = useCurrencies(query);

  useEffect(() => {
    // if the selectedOption is just a string, set the query to selectedOption and set selectedOption to the matching result
    if (selectedOption && typeof selectedOption === "string") {
      fetchCurrencies(selectedOption).then((currencies) => {
        if (currencies.length > 0) {
          setSelectedCurrency(currencies[0]);
        }
      });
    }
  }, [selectedOption]);

  const handleSelect = (value) => {
    console.log("currency", value);
    setSelectedCurrency(value);
    handleCurrencySelect(value);
  };

  return (
    <Combobox as="div" value={selectedCurrency} onChange={(e) => handleSelect(e)}>
      <Combobox.Label className="text-gray-500">{label}</Combobox.Label>
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(c) => `${c.label} (${c.value})`}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {currencies.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {currencies.map((c) => (
              <Combobox.Option
                key={c.value}
                value={c}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames("block truncate", selected && "font-semibold")}>
                      {c.label} ({c.value})
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
