import React, { useCallback } from "react";
import { FormProvider, useForm, useFormContext, Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { classNames } from "../../../utils";
import { IdealBankElement } from "@stripe/react-stripe-js";
import clsx from "clsx";

export const Form = ({ defaultValues, children, onSubmit }) => {
  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)(e);
          e.stopPropagation();
        }}
      >
        {React.Children.map(children, (child) => {
          return child.props.name
            ? React.createElement(child.type, {
                ...{
                  ...child.props,
                  register: methods.register,
                  key: child.props.name,
                },
              })
            : child;
        })}
      </form>
    </FormProvider>
  );
};

export const ConnectForm = ({ children }) => {
  const methods = useFormContext();

  return children({ ...methods });
};

export const DropzoneField = ({
  name,
  label = "Upload",
  accept,
  helperText = "",
  maxFiles = 1,
  onUpdate,
  ...rest
}) => {
  return (
    <ConnectForm>
      {({ register, setValue, setError, clearErrors, watch, formState: { errors } }) => (
        <DragnDropInput
          {...{
            name,
            label,
            accept,
            helperText,
            maxFiles,
            register,
            setValue,
            setError,
            clearErrors,
            watch,
            errors,
            onUpdate,
          }}
        />
      )}
    </ConnectForm>
  );
};

const FilePreview = ({ file, deleteFile }) => {
  const imagesType = ["image/png", "image/jpg", "image/jpeg"];

  return imagesType.includes(file.type) ? (
    <div key={file.name} className="aspect-w-3 aspect-h-2 relative">
      <img
        src={URL.createObjectURL(file)}
        alt={file.name}
        className="h-auto w-full rounded-lg object-cover shadow-lg"
      />
      <button
        onClick={(e) => deleteFile(e, file)}
        className="absolute right-0 top-0 flex p-2 leading-none"
      >
        <i size={24} className="fas fa-times text-danger-500 cursor-pointer"></i>
      </button>
    </div>
  ) : (
    <div key={file.name} className="flex rounded-lg px-3 py-2 shadow-lg">
      <button onClick={(e) => deleteFile(e, file)} className="mr-2 flex leading-none">
        <i size={24} className="fas fa-times text-danger-500 cursor-pointer"></i>
      </button>
      {file.name}
    </div>
  );
};

export default function DragnDropInput({
  accept,
  name,
  label,
  helperText = "",
  maxFiles = 1,
  validation,
  register,
  setValue,
  setError,
  clearErrors,
  watch,
  errors,
  onUpdate,
}) {
  const files = watch(name);
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      console.log("ACCEPTED", acceptedFiles);
      console.log("REJECTED", rejectedFiles);
      if (rejectedFiles && rejectedFiles.length > 0) {
        setValue(name, []);
        setError(name, {
          type: "manual",
          message: rejectedFiles && rejectedFiles[0].errors[0].message,
        });
      } else {
        setValue(name, acceptedFiles, { shouldValidate: true });
        clearErrors(name);
        onUpdate && onUpdate(acceptedFiles);
      }
    },
    [name, setValue, setError, clearErrors]
  );

  const deleteFile = (e, file) => {
    e.preventDefault();
    const newFiles = [...files];

    newFiles.splice(newFiles.indexOf(file), 1);

    if (newFiles.length > 0) {
      setValue(name, newFiles);
    } else {
      setValue(name, []);
    }

    onUpdate && onUpdate(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    maxFiles,
  });

  return (
    <>
      <label htmlFor={name}>{label}</label>

      {files?.length >= maxFiles ? (
        <div className="mt-1 grid grid-cols-1 gap-2">
          {files.map((file) => (
            <FilePreview key={file} file={file} deleteFile={deleteFile} />
          ))}
        </div>
      ) : (
        <>
          <div
            className="mb-4 flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5"
            {...getRootProps()}
          >
            <input {...register(name, validation)} id={name} {...getInputProps()} />
            {/* <div
              className={classNames(
                "w-full p-2 bg-gray-100 border border-gray-300 border-dashed rounded cursor-pointer",
                errors[name]
                  ? "focus:ring-red-500 border-red-500 focus:border-red-500"
                  : "focus:ring-dark-400 focus:border-dark-400"
              )}
            > */}
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <p className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                  <span>Upload a file</span>
                </p>
                <p className="pl-1">or drag and drop</p>
              </div>

              <p className="text-xs text-gray-500">{helperText}</p>
              {errors[name] && <p className="text-danger-500 text-xs">{errors[name].message}</p>}
            </div>

            {!!files?.length && (
              <div className="mt-2 grid grid-cols-1 gap-1">
                {files.map((file) => (
                  <FilePreview key={file} file={file} deleteFile={deleteFile} />
                ))}
              </div>
            )}
            {/* </div> */}
          </div>
        </>
      )}
    </>
  );
}

export const Input = React.forwardRef(({ name, label, prefix, suffix, ...rest }, ref) => (
  <>
    <label>{label}</label>
    <ConnectForm>
      {({ register }) => (
        <div className="relative flex items-center">
          <input
            ref={ref}
            {...register(name)}
            {...rest}
            className={clsx("form-control", prefix && "pl-6")}
          />
          {prefix && (
            <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-sm text-gray-500">
              {prefix}
            </span>
          )}
          {suffix && (
            <span
              className={clsx(
                "pointer-events-none absolute inset-y-0 right-0 flex items-center text-sm text-gray-500",
                rest.type === "number" ? "pr-8" : "pr-3"
              )}
            >
              {suffix}
            </span>
          )}
        </div>
      )}
    </ConnectForm>
  </>
));

export const InputWithSelect = React.forwardRef(
  (
    {
      inputName,
      selectName,
      inputHtmlProps,
      selectHtmlProps,
      label,
      selectLabel,
      options,
      ...rest
    },
    ref
  ) => (
    <div>
      <label htmlFor={inputName}>{label}</label>
      <ConnectForm>
        {({ register, watch }) => (
          <div className="relative w-1/3 rounded-md shadow-sm">
            <input
              {...register(inputName)}
              {...inputHtmlProps}
              {...rest}
              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-12"
            />
            <div className="absolute inset-y-0 right-0 flex items-center">
              <label htmlFor="currency" className="sr-only">
                {selectLabel}
              </label>
              {/* select value is required when input has a value */}
              <select
                {...register(selectName)}
                {...selectHtmlProps}
                {...rest}
                className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7"
                required={watch(inputName)}
              >
                {options.map((option, index) => (
                  <option value={option.value} key={index}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </ConnectForm>
    </div>
  )
);

export const InputWithAddon = React.forwardRef(({ name, label, addon, ...rest }, ref) => (
  <>
    <label>{label}</label>
    <ConnectForm>
      {({ register }) => (
        <div className="relative rounded-md">
          <input ref={ref} {...register(name)} {...rest} className="form-control !pr-10" />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm" id="price-currency">
              {addon}
            </span>
          </div>
        </div>
      )}
    </ConnectForm>
  </>
));

export const TextArea = React.forwardRef(({ name, label, onChange, ...rest }, ref) => (
  <>
    <label>{label}</label>
    <ConnectForm>
      {({ register, setValue }) => (
        <textarea
          ref={ref}
          {...register(name)}
          {...rest}
          onChange={(e) => {
            if (e.target.value === "") {
              setValue(name, null);
            }
          }}
          className={`form-control`}
        ></textarea>
      )}
    </ConnectForm>
  </>
));

export const Select = React.forwardRef(({ name, label, options, ...rest }, ref) => (
  <>
    <label>{label}</label>
    <ConnectForm>
      {({ register }) => (
        <select ref={ref} {...register(name)} {...rest} className={`form-control`}>
          {options.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </select>
      )}
    </ConnectForm>
  </>
));

export const RadioButton = React.forwardRef(({ name, label, value, id, ...rest }, ref) => (
  <>
    <ConnectForm>
      {({ register }) => (
        <div className="flex justify-start">
          <div className="flex items-center">
            <input
              ref={ref}
              id={id}
              type="radio"
              value={value}
              {...register(name)}
              {...rest}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label htmlFor={id} className="ml-3 mt-1 block text-sm font-medium text-gray-500">
              {label}
            </label>
          </div>
        </div>
      )}
    </ConnectForm>
  </>
));

export const Checkbox = React.forwardRef(({ name, label, note, ...rest }, ref) => (
  <>
    <ConnectForm>
      {({ register }) => (
        <>
          <label className="flex h-5 items-center space-x-3">
            <input
              ref={ref}
              type="checkbox"
              {...register(name)}
              {...rest}
              className={"h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"}
            />
            <span className="whitespace-nowrap font-medium text-gray-700">{label}</span>
            {note ? (
              <p className="rounded bg-gray-50 px-1.5 py-0.5 text-xs font-normal text-gray-500">
                {note}
              </p>
            ) : (
              ""
            )}
          </label>
        </>
      )}
    </ConnectForm>
  </>
));

export const FileInput = React.forwardRef(({ name, label, ...rest }, ref) => (
  <>
    <label>{label}</label>
    <br />
    <ConnectForm>
      {({ register }) => (
        <input ref={ref} type="file" {...register(name)} {...rest} className={"form-control"} />
      )}
    </ConnectForm>
  </>
));
