import axios from "axios";
import React, { useMemo, useState, useCallback } from "react";
import Table from "../../Application/components/Table";
import { Button } from "../../UI/components/Button";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "../../Stripe/CheckoutForm";
import PaymentStatus from "../../Stripe/PaymentStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCircleCheck,
  faCircleDot,
  faCircleQuestion,
  faCircleXmark,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { Toggle } from "../../UI/components";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_API_KEY);

export default function ShopifyFulfillmentsTable({ shopify_integration_id, auto_billing = false }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const [selectedFulfilment, setSelectedFulfilment] = useState(null);

  const [stripeOptions, setStripeOptions] = useState({
    clientSecret: null,
    appearance: {},
  });

  const formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  });

  const fetchData = useCallback(async ({ pageIndex, pageSize, sortBy, filters, globalFilter }) => {
    let params = {
      page: pageIndex + 1,
      per: pageSize,
    };

    if (sortBy.length > 0) {
      params["sort"] = sortBy[0].id;
      params["sort_dir"] = sortBy[0].desc ? "desc" : "asc";
    }

    if (globalFilter) {
      params["query"] = globalFilter;
    }

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      params: {
        section: "body",
      },
    };
    setLoading(true);

    const result = await fetch(
      `fulfillments/datatable.json?` + new URLSearchParams(params),
      requestConfig
    );
    const data = await result.json();
    setData(data.data);

    setPageCount(Math.ceil(data.meta.total_count / 1));
    setTotalCount(data.meta.total_count);

    setLoading(false);
  }, []);

  const processFulfilmentPayment = async (fulfilment) => {
    setSelectedFulfilment(fulfilment);

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      data: {
        fulfilment_id: fulfilment.id,
        shopify_integration_id: shopify_integration_id,
      },
    };

    try {
      // delete all url query parameters
      window.history.replaceState({}, document.title, window.location.pathname);
      window.history.pushState(null, "", "");
      const result = await axios.post(`create-fulfilment-payment-intent`, requestConfig);
      const data = result.data;

      console.log("stripe data", data);

      setStripeOptions({
        ...stripeOptions,
        clientSecret: data.client_secret,
      });

      setIsPaymentModalOpen(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  const columns = useMemo(() => [
    {
      Header: "Fulfillment #",
      id: "shopify_order_name",
      accessor: "fulfillment_name",
      sortType: "basic",
      disableFilters: true,
      sticky: "left",
      Cell: ({ row }) => <div>{row.original.fulfillment_name}</div>,
    },
    {
      Header: "Fulfilment Status",
      accessor: "status",
      disableFilters: true,
      width: 180,
      sticky: "left",
      Cell: ({ row, value }) => {
        if (value == "complete") {
          return (
            <span className="bg-success-100 text-success-800 inline-flex items-center space-x-1 rounded-full px-2.5 py-0.5 text-xs font-medium capitalize">
              <FontAwesomeIcon icon={faCircleCheck} className="text-success-800 h-2.5 w-2.5" />
              <span>{value}</span>
            </span>
          );
        } else if (value == "cancelled") {
          return (
            <span className="bg-danger-100 text-danger-800 inline-flex items-center space-x-1 rounded-full px-2.5 py-0.5 text-xs font-medium capitalize">
              <FontAwesomeIcon icon={faCircleXmark} className="text-danger-700 h-2.5 w-2.5" />
              <span>{value}</span>
            </span>
          );
        } else if (row.original.distributor_order_id) {
          return (
            <span className="bg-info-100 text-info-800 inline-flex items-center space-x-1 rounded-full px-2.5 py-0.5 text-xs font-medium capitalize">
              <FontAwesomeIcon icon={faCircleDot} className="text-info-800 h-2.5 w-2.5" />
              <span>Processing</span>
            </span>
          );
        } else if (value == "pending") {
          return (
            <span className="bg-warning-100 text-warning-800 inline-flex items-center space-x-1 rounded-full px-2.5 py-0.5 text-xs font-medium capitalize">
              <FontAwesomeIcon icon={faCircleQuestion} className="text-warning-700 h-2.5 w-2.5" />
              <span>{value}</span>
            </span>
          );
        } else {
          return (
            <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium capitalize text-gray-500">
              {value}
            </span>
          );
        }
      },
    },
    {
      Header: "Distributor",
      accessor: "distributor",
      defaultCanSort: false,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => <div>{row.original.distributor}</div>,
    },
    {
      Header: "Account Code",
      accessor: "account_code",
      defaultCanSort: false,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => <div>{row.original.account_code}</div>,
    },
    {
      Header: "Tracking Numbers",
      accessor: "tracking_numbers",
      defaultCanSort: false,
      disableSortBy: true,
      disableFilters: true,
      width: 180,
      Cell: ({ row }) => <div className="whitespace-pre-wrap">{row.original.tracking_numbers}</div>,
    },
    {
      Header: "Subtotal",
      id: "fulfillment_subtotal",
      accessor: "subtotal",
      defaultCanSort: false,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="">{formatter.format(parseFloat(row.original.subtotal ?? 0))}</div>
      ),
    },
    {
      Header: "Shipping",
      id: "shipping",
      accessor: "charges",
      defaultCanSort: false,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="">{formatter.format(parseFloat(row.original.charges ?? 0))}</div>
      ),
    },
    {
      Header: "Tax",
      id: "fulfillment_tax",
      accessor: "tax",
      defaultCanSort: false,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="">{formatter.format(parseFloat(row.original.tax ?? 0))}</div>
      ),
    },
    {
      Header: "Total",
      accessor: "total",
      defaultCanSort: false,
      disableSortBy: true,
      disableFilters: true,
      sticky: "right",
      Cell: ({ row }) => (
        <div className="font-bold">{formatter.format(parseFloat(row.original.total ?? 0))}</div>
      ),
    },
    {
      Header: "Payment",
      id: "id",
      accessor: "status",
      sticky: "right",
      defaultCanSort: false,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row, value }) => {
        if (
          row.original.payment_status === "paid" ||
          row.original.payment_status === "direct_account"
        ) {
          return (
            <div className="">
              <span className="inline-flex items-center space-x-1 rounded-full bg-gray-200 px-2.5 py-0.5 text-xs font-medium text-gray-700">
                <FontAwesomeIcon icon={faCircle} className="h-2 w-2 text-gray-500" />
                <span>
                  {row.original.payment_status === "direct_account" ? "Direct Account" : "Paid"}
                </span>
              </span>
            </div>
          );
        } else if (row.original.payment_status == "unpaid") {
          return (
            <div className="">
              <Button
                label="Pay now"
                onClick={() => {
                  const params = new URLSearchParams(window.location.search);
                  params.forEach((key) => {
                    // delete all query params
                    params.delete(key);
                  });
                  processFulfilmentPayment(row.original);
                }}
                className="btn-sm"
              />
            </div>
          );
        } else if (row.original.payment_status == "order_cancelled") {
          return <></>;
        } else {
          return <>{row.original.payment_status}</>;
        }
      },
    },
  ]);

  const [autoBilling, setAutoBilling] = useState(auto_billing);

  const updateStoreAutoBilling = async (value) => {
    console.log("updateStoreAutoBilling", value);
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      data: {
        integration_id: shopify_integration_id,
        auto_billing: value,
      },
    };

    try {
      const result = await axios.post(`update-store-auto-billing`, requestConfig);
      const data = result.data;

      setAutoBilling(data.auto_billing);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      {selectedFulfilment && stripeOptions && stripeOptions.clientSecret && (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <CheckoutForm
            fulfilment={selectedFulfilment}
            isOpen={isPaymentModalOpen}
            closeModal={() => {
              setStripeOptions({ ...stripeOptions, clientSecret: null });
              setIsPaymentModalOpen(false);
            }}
          />
          <PaymentStatus />
        </Elements>
      )}

      <div className="flex justify-end">
        <div className="flex items-center space-x-2">
          <div className="text-sm text-gray-500">Enable auto-billing</div>
          <Toggle
            checked={autoBilling}
            label="Enable auto-billing"
            onChangeCallback={(e) => updateStoreAutoBilling(e)}
          />
        </div>
      </div>

      <Table
        className="w-full table-fixed"
        columns={columns}
        data={data}
        onFetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        totalItems={totalCount}
        initialPageSize={25}
        useRowSelection={false}
        sticky={true}
      />
    </div>
  );
}
