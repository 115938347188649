import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { faTimes } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { faMinus } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import Api from "../api";

export const DomainInput = ({ team, domain, onRemove }) => {
  const marketplaceAPI = new Api(team.api_key, location);
  const [tldValue, setTldValue] = useState(domain.tld);

  const waitTime = 1200;

  useEffect(() => {
    if (tldValue === domain.tld) {
      return;
    }
    const t = setTimeout(() => {
      if (tldValue) {
        updateDomain(tldValue);
      }
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, [tldValue]);

  const updateDomain = (value) => {
    marketplaceAPI
      .updateTeamDomain({
        id: domain.id,
        tld: value,
      })
      .then((data) => {
        console.log("data", data);
        if (data.id) {
          domain.id = data.id;
        }
      });
  };

  return (
    <div className="flex items-center space-x-3">
      <input
        type="text"
        className="flex !w-1/2"
        readOnly={domain.readonly}
        value={tldValue || ""}
        onChange={(e) => setTldValue(e.target.value)}
      />
      {!domain.readonly && (
        <button
          className="bg-danger-400 hover:bg-danger-600 flex h-5 w-5 items-center justify-center rounded-full text-xs text-white"
          onClick={() => onRemove(domain)}
        >
          <FontAwesomeIcon icon={faMinus} className="pointer-events-none" />
        </button>
      )}
    </div>
  );
};
