import React, { useState, useEffect } from "react";

export default function IntegrationSelect(props) {
  const listingIntegrations = props.selectedIntegrations;

  const [results, setResults] = useState([]);

  useEffect(() => {
    getIntegrations();
  }, []);

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     listing_integrations: nextProps.selectedIntegrations,
  //   };
  // }

  const getIntegrations = () => {
    console.log("getintegrations");
    fetch(`/b/${props.brand.slug}/listings/shops`)
      .then((response) => response.json())
      .then((data) => setResults(data.integrations));
  };

  return (
    <div className="shopify_listing__integration-select">
      <label>Select Stores</label>

      <div className="bg-white shadow-sm overflow-hidden border sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {results
            ? results.map(function (integration) {
                // if integration appears in ShopifyListing selected integrations,
                // present the Remove button.
                let button;
                if (
                  listingIntegrations &&
                  listingIntegrations.some((e) => e.id === integration.id)
                ) {
                  button = (
                    <button
                      type="button"
                      data-integration-id={integration.id}
                      className="btn btn-sm btn-danger"
                      onClick={() => props.removeIntegration(integration)}
                    >
                      Remove from Store
                    </button>
                  );
                } else {
                  button = (
                    <button
                      type="button"
                      data-integration-id={integration.id}
                      className="btn btn-sm btn-primary"
                      onClick={() => props.addIntegration(integration)}
                    >
                      Add to Store
                    </button>
                  );
                }

                return (
                  <li key={integration.id}>
                    <a href="#" className="block hover:bg-gray-50">
                      <div className="px-4 py-4 flex items-center sm:px-6">
                        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                          {integration.shop}
                        </div>
                        <div className="ml-5 flex-shrink-0">{button}</div>
                      </div>
                    </a>
                  </li>
                );
              })
            : ""}
        </ul>
      </div>
    </div>
  );
}
