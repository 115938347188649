import { useCallback, useState } from "react";

const useDocumentDownload = (deliveryDocumentFunction) => {
  const [downloadStatus, setDownloadStatus] = useState({});

  const handleDownload = useCallback(async (documentType, trackingNumber, fileNameSuffix, bookingId) => {
    setDownloadStatus((prev) => ({
      ...prev,
      [fileNameSuffix]: {
        loading: true,
        error: false,
      },
    }));

    try {
      const file = await deliveryDocumentFunction(bookingId, { document_type: documentType });
      const blob = new Blob([file], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      
      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      
      // Append to the document body
      document.body.appendChild(link);
      
      // Programmatically click the link
      link.click();
      
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error("Error downloading document", error);
      setDownloadStatus((prev) => ({
        ...prev,
        [fileNameSuffix]: {
          loading: false,
          error: true,
        },
      }));
    } finally {
      setDownloadStatus((prev) => ({
        ...prev,
        [fileNameSuffix]: {
          loading: false,
          error: prev[fileNameSuffix].error,
        },
      }));
    }
  }, [deliveryDocumentFunction]);

  return {
    downloadStatus,
    handleDownload,
  };
};

export default useDocumentDownload;