import React, { useEffect, useState } from "react";
import { connectRefinementList } from "react-instantsearch-dom";

const RefinementList = ({
  items,
  refine,
  placeholder,
  canSelectAll,
  allFacets = [],
  dependency,
}) => {
  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (dependency === "") {
      setSelected("");
      refine([]);
    }
  }, [dependency]);

  const allOptions = allFacets?.map((facet) => {
    return {
      label: facet.value,
      value: [facet.value],
    };
  });

  const allRefinedOptions = items?.map((item) => {
    return {
      label: item.label,
      value: item.value,
    };
  });

  const options = allFacets?.length > 0 ? allOptions : allRefinedOptions;

  return (
    <select
      className="flex-1"
      value={selected}
      onChange={(event) => {
        event.preventDefault();
        setSelected(event.target.value);
        refine(event.target.value);
      }}
    >
      {placeholder && (
        <option disabled={!canSelectAll} hidden={!canSelectAll} value={[]} defaultValue={[]}>
          {placeholder}
        </option>
      )}
      {options.map((option) => (
        <option key={option.label}>{option.label}</option>
      ))}
    </select>
  );
};

export const RefinementSelect = connectRefinementList(RefinementList);
