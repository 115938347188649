import React from "react";

import { getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch";

import { Autocomplete } from "./Autocomplete";
import { AutocompleteItem } from "./AutocompleteItem";

export default function SearchBar({ current_brand, brands }) {
  const appId = process.env.ALGOLIA_APP_ID;
  const apiKey = process.env.ALGOLIA_SEARCH_KEY;
  const searchClient = algoliasearch(appId, apiKey);

  const brandFilter = brands.map((b) => `brand:"${b.name}"`).join(" OR ");

  return (
    <div className="block h-full w-full border-transparent px-5 py-2 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm">
      <Autocomplete
        defaultActiveItemId={0}
        openOnFocus={false}
        getSources={({ query }) => [
          {
            sourceId: "products",
            getItemUrl({ item }) {
              return `/products/${item.slug}/edit`;
            },
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: `pbv3_${process.env.RAILS_ENV}`,
                    query,
                    params: {
                      filters: `(${brandFilter})`,
                      //numericFilters: ["published = 1"],
                    },
                  },
                ],
              });
            },
            templates: {
              item({ item, components, state }) {
                return (
                  <AutocompleteItem
                    hit={item}
                    components={components}
                    activeItemId={state.activeItemId}
                  />
                );
              },
            },
          },
        ]}
      />
    </div>
  );
}
