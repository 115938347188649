import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromDottedLine } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import React, { useEffect, useRef, useState } from "react";
import { Header } from "./components/Header";
import Api from "./api.js";

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

const FulfillmentOrder = ({ team, fulfillment_order_id, app_contexts }) => {
  const scrollRef = useRef(null);
  const scrollToTop = () => scrollRef.current.scrollIntoView();

  const marketplaceAPI = new Api(team.api_key, location);

  const [fulfillmentOrder, setFulfillmentOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const fulillmentOrderRequest = await marketplaceAPI.loadFulfillmentOrder(fulfillment_order_id);
    setFulfillmentOrder(fulillmentOrderRequest);
    setLoading(false);
  }, []);

  const handleRequestFulfillment = async () => {
    setLoading(true);
    const requestFulfillmentRequest = await marketplaceAPI.requestFulfillment(fulfillment_order_id);
    setLoading(false);
  };

  const handleCancelFulfillment = async () => {
    setLoading(true);
    const cancelFulfillmentRequest = await marketplaceAPI.cancelFulfillment(fulfillment_order_id);
    setLoading(false);
  };

  return (
    <div ref={scrollRef}>
      <Header section="Fulfillment Order" app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          {/* <div className="col-span-2">
            <Sidebar importListCount={totalRows} />
          </div> */}
          <div className="col-span-10 flex h-full flex-col space-y-3 pt-5">
            <div className="flex w-full items-center justify-between">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                Fulfillment Order
              </h2>
              <div title="bulk actions">
                {fulfillmentOrder?.status == "pending" && (
                  <button disabled={loading} className="btn" onClick={handleRequestFulfillment}>
                    <span>Request Fulfillment</span>
                    <FontAwesomeIcon icon={faArrowUpFromDottedLine} />
                  </button>
                )}
                {fulfillmentOrder?.status == "submitted" && (
                  <button
                    disabled={loading}
                    className="btn btn-danger"
                    onClick={handleCancelFulfillment}
                  >
                    <span>Request Cancellation</span>
                    <FontAwesomeIcon icon={faArrowUpFromDottedLine} />
                  </button>
                )}
              </div>
            </div>
            {fulfillmentOrder && (
              <>
                <div className="flex w-full items-center justify-between rounded-lg bg-white p-3 shadow">
                  <div className="flex items-center">
                    <div className="text-gray-900">
                      <h3 className="text-xl font-extrabold">
                        Order #{fulfillmentOrder.fulfillment_name}
                      </h3>
                      <div className="heading-sm">{fulfillmentOrder.store}</div>
                    </div>
                  </div>
                  <div className="grid h-auto grid-cols-3 justify-items-end space-x-6">
                    <div className="col-span-1 flex flex-col items-center">
                      <div className="text-right">
                        <h3 className="text-xl font-medium text-gray-700">
                          {formatter.format(fulfillmentOrder.subtotal)}
                        </h3>
                        <div className="text-right text-xs font-medium uppercase tracking-wide text-indigo-400">
                          Subtotal
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-col items-center">
                      <div className="text-right">
                        <h3 className="text-xl font-medium text-gray-700">
                          {formatter.format(fulfillmentOrder.charges)}
                        </h3>
                        <div className="text-right text-xs font-medium uppercase tracking-wide text-indigo-400">
                          Shipping & Handling
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 flex items-center">
                      <div className="text-right">
                        <h3 className="text-xl font-bold text-gray-700">
                          {formatter.format(
                            Number(fulfillmentOrder.subtotal) +
                              Number(fulfillmentOrder.charges) +
                              Number(fulfillmentOrder.tax)
                          )}
                        </h3>
                        <div className="text-right text-xs font-medium uppercase tracking-wide text-indigo-500">
                          Total
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-self-end">
                    <div className="rounded-full bg-indigo-100 px-2 py-0.5 text-xs font-semibold uppercase tracking-wider text-indigo-700">
                      {fulfillmentOrder.status}
                    </div>
                  </div>
                </div>

                <div className="grid h-auto w-full grid-cols-10 gap-6">
                  <div className="col-span-7 flex h-full flex-col space-y-3 pt-5">
                    {fulfillmentOrder.fulfillments.map((fulfillment, index) => (
                      <div key={fulfillment.id} className="rounded-lg bg-white p-3 shadow ">
                        <div className="flex w-full items-center space-x-3">
                          <div className="flex items-center">
                            <h4 className="font-display flex items-center space-x-2 font-bold text-indigo-500">
                              Fulfillment #{index + 1}
                              <span className="ml-1.5 rounded bg-purple-100 px-2 py-1 text-xs font-bold uppercase text-purple-500">
                                {fulfillment.distributor} {fulfillment.distributor_location}
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div>
                          <table className="datatable my-5 min-w-full divide-y divide-gray-200">
                            <thead>
                              <tr>
                                <th>SKU</th>
                                <th>Title</th>
                                <th>Brand</th>
                                <th className="text-right">Unit Price</th>
                                <th className="text-right">Quantity</th>
                                <th className="text-right">Tax</th>
                                <th className="text-right">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fulfillment.fulfillment_items.map((item, index) => (
                                <tr key={`${fulfillment.id}${index}`}>
                                  <td>
                                    {item.store_sku}
                                    <br />
                                    <span className="text-xs text-gray-400">
                                      {item.partbot_sku}
                                    </span>
                                  </td>
                                  <td>{item.product_title}</td>
                                  <td>{item.product_brand}</td>
                                  <td className="text-right">
                                    {formatter.format(item.price / 1.1)}
                                  </td>
                                  <td className="text-right">{item.quantity}</td>

                                  <td className="text-right">
                                    {formatter.format(
                                      item.price * item.quantity -
                                        (item.price * item.quantity) / 1.1
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {formatter.format(item.price * item.quantity)}
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan="3" className="text-right">
                                  Shipping
                                </td>
                                <td></td>
                                <td className="text-right">
                                  {formatter.format(fulfillment.shipping_charges)}
                                </td>
                                <td className="text-right">
                                  {formatter.format(fulfillment.shipping_tax)}
                                </td>
                                <td className="text-right">
                                  {formatter.format(
                                    Number(fulfillment.shipping_charges) +
                                      Number(fulfillment.shipping_tax)
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-span-3 flex h-full flex-col space-y-3 pt-5">
                    {fulfillmentOrder.history.length > 0 && (
                      <div className="rounded-lg bg-white p-3 shadow">
                        <div className="flex w-full items-center space-x-3">
                          <div className="flex items-center">
                            <h4 className="heading-sm">Timeline</h4>
                          </div>
                        </div>
                        <ul role="list" className="divide-y divide-gray-200">
                          {fulfillmentOrder.history.map((history, index) => (
                            <li key={`history-${index}`} className="py-4">
                              <div className="flex px-3">
                                <div className="flex-1 space-y-1">
                                  <div className="flex items-center justify-between">
                                    <h3 className="text-xs font-medium uppercase tracking-wide">
                                      {history.status}
                                    </h3>
                                    <p className="text-sm text-gray-500">{history.date}</p>
                                  </div>
                                  <p className="text-sm text-gray-500">{history.comment}</p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

const FulfillmentOrderContainer = ({ children, ...props }) => {
  return <FulfillmentOrder {...props}>{children}</FulfillmentOrder>;
};

export default FulfillmentOrderContainer;
