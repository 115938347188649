import React, { useState } from "react";

export default function ProductSelect({ brand, onProductSelect }) {
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const searchProducts = (e) => {
    setQuery(e.target.value);

    fetch(`/b/${brand.slug}/listings/search?query=${e.target.value}`)
      .then((response) => response.json())
      .then((data) => setSearchResults(data.products));
  };

  return (
    <div className="shopify_listing__product-select">
      <div className="mb-5">
        <label>Add Products</label>
        <label className="my-2 block text-xs text-gray-500">
          Products will appear as Variants in Shopify
        </label>
        <input
          type="text"
          value={query}
          placeholder="Search Products by SKU..."
          onChange={searchProducts}
        />
      </div>
      {searchResults.length ? (
        <div className="overflow-hidden border bg-white shadow-sm sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {searchResults.map(function (product) {
              return (
                <li key={product.id}>
                  <a href="#" className="block hover:bg-gray-50">
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="min-w-0 flex-1 text-sm sm:flex sm:items-center sm:justify-between">
                        <span>
                          <strong>{product.sku}</strong> - {product.title}
                        </span>
                      </div>
                      <div className="ml-5 flex-shrink-0">
                        <button
                          type="button"
                          data-product-id={product.id}
                          className="btn btn-sm btn-primary"
                          onClick={() => onProductSelect(product)}
                        >
                          Add to Listing
                        </button>
                      </div>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
