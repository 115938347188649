import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragHandle } from "./DragHandle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { faCheck, faHyphen } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { Button } from "../Button";

export const DraggableTableRow = ({
  row,
  rowDeleteHandler,
  rowEditHandler,
  highlightError = false,
}) => {
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  const [isDeleting, setIsDeleting] = useState(false);
  const [showErrorStyle, setShowErrorStyle] = useState(false);

  useEffect(() => {
    if (row.original.error) {
      setShowErrorStyle(true);
    } else {
      setShowErrorStyle(false);
    }
  }, [highlightError]);

  return (
    <tr
      ref={setNodeRef}
      style={style}
      {...row.getRowProps()}
      className={
        showErrorStyle
          ? "odd:!bg-danger-100/50 even:!bg-danger-100 border-danger-500 border-l-2"
          : ""
      }
    >
      {isDragging ? (
        <td colSpan={row.cells.length}>&nbsp;</td>
      ) : (
        row.cells.map((cell, i) => {
          if (i === 0) {
            return (
              <td
                {...cell.getCellProps({
                  className: cell.column.className,
                })}
                className={showErrorStyle ? "!bg-transparent" : ""}
              >
                <div className="relative flex items-center space-x-2">
                  <DragHandle {...attributes} {...listeners} />
                  <div>{cell.render("Cell")}</div>
                </div>
              </td>
            );
          }

          if (cell.value === true || cell.value === false) {
            return (
              <td
                {...cell.getCellProps({
                  className: cell.column.className,
                })}
                className={showErrorStyle ? "!bg-transparent" : ""}
              >
                {cell.value === true ? (
                  <FontAwesomeIcon icon={faCheck} className="text-success-500" />
                ) : (
                  <FontAwesomeIcon icon={faHyphen} className="text-gray-300" />
                )}
              </td>
            );
          }
          return (
            <td
              {...cell.getCellProps({
                className: cell.column.className,
              })}
              className={showErrorStyle ? "!bg-transparent" : ""}
            >
              {cell.render("Cell")}
            </td>
          );
        })
      )}

      {/* show a delete button if rowDeleteHandler function exists */}
      <td className={showErrorStyle ? "!bg-transparent" : ""}>
        <div className="btn-group justify-end">
          {/* show an edit button if rowEditHandler function exists */}
          {rowEditHandler && (
            <Button
              label="Edit"
              type="button"
              onClick={async () => {
                await rowEditHandler(row.original.id);
              }}
              className="btn-sm btn-info"
            />
          )}
          {!showErrorStyle && (
            <Button
              type="button"
              onClick={async () => {
                setIsDeleting(true);
                const result = await rowDeleteHandler(row.original.id);
                // set isDeleting to false if delete was successful
                if (result) {
                  setIsDeleting(false);
                }
              }}
              icon={faTrashAlt}
              showLoading={isDeleting}
              size="icon"
              className="btn-sm btn-danger"
            />
          )}
        </div>
      </td>
    </tr>
  );
};
