import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { useStore } from "../../state/StoreProvider";

export const StoreSelect = () => {
  const { stores, setSelectedStores } = useStore(
    (state) => ({
      stores: state.stores,
      setSelectedStores: state.setSelectedStores,
    }),
    shallow
  );

  useEffect(() => {
    // Set all stores as selected initially
    setSelectedStores(stores);
  }, [stores, setSelectedStores]);

  const [selectedStoreIds, setSelectedStoreIds] = useState(new Set());

  useEffect(() => {
    setSelectedStoreIds(new Set(stores.map((store) => store.id)));
  }, [stores]);

  const handleStoreSelection = (storeId, isSelected) => {
    setSelectedStoreIds((prevSelectedStoreIds) => {
      const newSelectedStoreIds = new Set(prevSelectedStoreIds);
      if (isSelected) {
        newSelectedStoreIds.add(storeId);
      } else {
        newSelectedStoreIds.delete(storeId);
      }
      setSelectedStores(stores.filter((store) => newSelectedStoreIds.has(store.id)));
      return newSelectedStoreIds;
    });
  };

  const handleAllStoresSelection = (isSelected) => {
    if (isSelected) {
      setSelectedStoreIds(new Set(stores.map((store) => store.id)));
      setSelectedStores(stores);
    } else {
      setSelectedStoreIds(new Set());
      setSelectedStores([]);
    }
  };

  const isAllStoresSelected = selectedStoreIds.size === stores.length;

  return (
    <div className="space-y-2">
      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            id="all-stores"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            checked={isAllStoresSelected}
            onChange={(e) => handleAllStoresSelection(e.target.checked)}
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label htmlFor="all-stores" className="font-medium text-gray-900">
            All stores
          </label>
        </div>
      </div>
      {stores &&
        stores.map((store) => (
          <div key={store.id} className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id={`store-${store.id}`}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                checked={selectedStoreIds.has(store.id)}
                onChange={(e) => handleStoreSelection(store.id, e.target.checked)}
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor={`store-${store.id}`} className="font-medium text-gray-900">
                {store.name}
              </label>
            </div>
          </div>
        ))}
    </div>
  );
};
