import React from "react";
import { faTrash, faUpFromLine } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { Button } from "../../../UI/components";
import { FulfillmentUnitTitle } from "./FulfillmentUnitTitle";

export default function ParcelItemRow({ parcelItem, onRemove, isLoading, isEven, isLocked }) {
  return (
    <tr className={isEven % 2 === 0 ? "group bg-gray-50" : "group bg-gray-50"}>
      <td className="bg-gray-50 px-3 py-1.5 text-gray-900">
        <FulfillmentUnitTitle unit={parcelItem.fulfillment_unit} />
      </td>
      <td className="whitespace-nowrap bg-gray-50 px-3 py-1.5 text-gray-500">
        {parcelItem.fulfillment_unit.product.sku}
      </td>
      <td className="whitespace-nowrap bg-gray-50 px-3 py-1.5 text-right text-gray-500">
        {parcelItem.quantity}
      </td>
      <td className="bg-gray-50">
        <div className="group- flex items-center justify-end space-x-2">
          {/* <Button
            label={"Move"}
            className={"btn btn-sm btn-light invisible group-hover:visible"}
            icon={faUpFromLine}
            disabled={true}
            // onClick={() =>
            //   moveXQuantityToAnotherParcel(
            //     parcelIndex,
            //     parcelItemIndex,
            //     0,
            //     3
            //   )
            // }
          /> */}
          <Button
            label={"Remove"}
            className={"btn btn-sm btn-danger invisible group-hover:visible"}
            icon={faTrash}
            onClick={() => onRemove(parcelItem.id)}
            disabled={isLoading || isLocked}
          />
        </div>
      </td>
    </tr>
  );
}
