import React, { useState } from "react";
import toast from "react-hot-toast";

import { faCarMirrors, faGlobe, faListUl } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import clsx from "clsx";

import { Button, TextArea } from "../../UI/components";
import ProductAttributesModal from "./ProductAttributesModal";
import VehicleSelectModal from "./VehicleSelectModal";

export default function VehicleApplicationsList({ product, attributes, onUpdate }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAttributeModalOpen, setIsAttributeModalOpen] = useState(false);
  const [vehicleApplicationId, setVehicleApplicationId] = useState(null);
  const [selectedVehicleIds, setSelectedVehicleIds] = useState([]);

  async function closeModal(data) {
    setIsOpen(false);

    if (data) {
      if (vehicleApplicationId) {
        try {
          const result = await axios({
            method: "patch",
            url: `/vehicle_applications/${vehicleApplicationId}`,
            responseType: "json",
            headers: ReactOnRails.authenticityHeaders(),
            data: {
              product_id: product.id,
              vehicles: data,
            },
          });
          toast.success("Vehicle application updated");

          console.log("vehicle application result", result.data);
          if (result.data) {
            await onUpdate();
          }
        } catch (e) {
          console.log(e);
          toast.error("Error updating vehicle application");
        }
      } else {
        try {
          const result = await axios({
            method: "post",
            url: "/vehicle_applications",
            responseType: "json",
            headers: ReactOnRails.authenticityHeaders(),
            data: {
              product_id: product.id,
              vehicles: data,
            },
          });
          toast.success("Vehicle application added to product");

          console.log("vehicle application result", result.data);
          if (result.data) {
            await onUpdate();
          }
        } catch (e) {
          console.log(e);
          toast.error("Error adding vehicle application to product");
        }
      }
    }
  }

  async function closeAttributeModal(result) {
    setIsAttributeModalOpen(false);

    if (result) {
      try {
        result = await axios({
          method: "post",
          url: "/vehicle_application_attributes",
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: {
            product_id: product.id,
            vehicle_application_id: vehicleApplicationId,
            vehicle_attribute_title_id: result.attribute_title_id,
            attribute_value: result.attribute_value,
          },
        });
        toast.success("Vehicle application attribute added");

        await onUpdate();
      } catch (e) {
        console.log(e);
        toast.error("Error adding vehicle application attribute");
      }
    }
  }

  const openModal = (vehicleApplicationId) => {
    setVehicleApplicationId(vehicleApplicationId);
    setIsOpen(true);
  };

  const openAttributeModal = (vehicleApplicationId) => {
    setVehicleApplicationId(vehicleApplicationId);
    setIsAttributeModalOpen(true);
  };

  const removeAttribute = async (attributeValueId) => {
    try {
      const result = await axios({
        method: "delete",
        url: `/vehicle_application_attributes/${attributeValueId}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });
      toast.success("Vehicle application attribute removed");

      await onUpdate();
    } catch (e) {
      console.log(e);
      toast.error("Error removing vehicle application attribute");
    }
  };

  const removeVehicles = async (vehicleIds) => {
    try {
      const result = await axios({
        method: "delete",
        url: `remove_vehicles`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: {
          vehicle_ids: vehicleIds,
        },
      });
      toast.success("Vehicle removed");

      await onUpdate();
    } catch (e) {
      console.log(e);
      toast.error("Error removing vehicle");
    }
  };

  const removeSelectedVehicles = async () => {
    await removeVehicles(selectedVehicleIds);
    setSelectedVehicleIds([]);
  };

  const removeVehicleApplication = async (vehicleApplicationId) => {
    if (confirm("Are you sure?")) {
      try {
        const result = await axios({
          method: "delete",
          url: `/vehicle_applications/${vehicleApplicationId}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
        });
        toast.success("Vehicle Application removed");

        await onUpdate();
      } catch (e) {
        console.log(e);
        toast.error("Error removing Vehicle Application");
      }
    }
  };

  const toggleSelectedVehicle = (vehicleId, selected) => {
    if (selected) {
      setSelectedVehicleIds([...selectedVehicleIds, vehicleId]);
    } else {
      setSelectedVehicleIds(selectedVehicleIds.filter((item) => item !== vehicleId));
    }
  };

  const vehcicleIds = product.vehicle_applications
    .map((va) => va.vehicles.map((v) => v.id))
    .reduce((acc, va) => [...acc, ...va], []);

  const statusDisplay = (status) => {
    const statuses = {
      archived: "text-opacity-50",
      default: "text-opacity-100",
    };
    return statuses[status] || statuses["default"];
  };

  const copyVehicleApplicationFromProduct = async (vehicleApplications, sourceProductId) => {
    let sourceProduct = sourceProductId;

    try {
      // use copy-from endpoint
      setIsLoading(true);
      // if only one vehicle application id is passed, set vehicleApplicationId
      if (vehicleApplications.length === 1) {
        setVehicleApplicationId(vehicleApplications[0].id);
      } else {
        setVehicleApplicationId(null);
      }
      console.log("copy vehicle applications", vehicleApplications, sourceProduct);

      if (!sourceProduct) {
        sourceProduct = vehicleApplications[0].product_id;
      }

      const result = await axios({
        method: "post",
        url: `copy-from/${sourceProduct}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: {
          product_id: product.id,
          vehicle_application_ids: vehicleApplications.map((va) => va.id),
        },
      });

      console.log("copy vehicle applications result", result);

      if (result.data) {
        toast.success(result.data.success);
        await onUpdate();
      } else {
        toast.error("Error copying vehicle application");
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      toast.error("Error copying vehicle application");
    }
  };

  const inheritedVehicleApplications = product.vehicle_applications.filter(
    (va) => !va.direct && va.vehicles.length > 0,
  );

  const hasInheritedVehicleApplications = inheritedVehicleApplications.length > 0;

  const addAllInheritedVehicleApplications = async () => {
    try {
      setIsLoading(true);

      await copyVehicleApplicationFromProduct(inheritedVehicleApplications);

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      toast.error("Error copying vehicle applications");
      setIsLoading(false);
    }
  };

  const [addAllButtonHover, setAddAllButtonHover] = useState(false);

  return (
    <>
      <div className="relative my-5">
        {product.universal_fitment && (
          <div className="bg-success-50 mb-5 flex items-start justify-between gap-6 rounded-md p-6">
            <div className="flex flex-shrink flex-col gap-2">
              <h3 className="text-success-700 flex items-center gap-2 font-medium">
                <FontAwesomeIcon icon={faGlobe} />
                <span>Universal Fitment</span>
              </h3>
              <div className="text-success-600 text-balance text-xs">
                This product is marked as universal fitment and will appear in all vehicle search
                results regardless of the vehicles linked below. You can change this in Settings.
              </div>
            </div>
          </div>
        )}
        {hasInheritedVehicleApplications && (
          <div className="bg-info-50 mb-5 flex items-start justify-between gap-6 rounded-md p-6">
            <div className="flex flex-shrink flex-col gap-2">
              <h3 className="text-info-700 font-medium">Inherited Vehicle Applications</h3>
              <div className="text-info-500 text-balance text-xs">
                These vehicle applications are inherited from child products. You can copy all of
                them or a single application to this assembly, which will allow you to add/remove
                the vehicles at assembly level.
              </div>
            </div>
            <div className="flex-shrink-0">
              <Button
                type="button"
                showLoading={isLoading && vehicleApplicationId === null}
                label="Add All & Edit"
                onClick={addAllInheritedVehicleApplications}
                onMouseEnter={() => setAddAllButtonHover(true)}
                onMouseLeave={() => setAddAllButtonHover(false)}
                className="btn btn-primary btn-sm"
              />
            </div>
          </div>
        )}
        <div className="grid grid-cols-2 gap-5 lg:grid-cols-3">
          {product.vehicle_applications.map((va, vaIndex) => (
            <div
              key={vaIndex}
              className={clsx(
                "bg-whiteborder flex flex-col justify-between overflow-hidden rounded-md border border-gray-100 shadow-sm transition focus:border-indigo-500 focus:ring-indigo-500",
                addAllButtonHover && "border-info-500 ring-info-500 ring",
              )}
            >
              <div className="p-5">
                <div className="flex items-start">
                  <div className="w-0 flex-1">
                    <dl>
                      <dt className="truncate text-sm font-medium text-gray-500">
                        {va.title || "Empty Vehicle Application"}
                      </dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">
                          {va.vehicles.length}
                          <span className="text-sm font-normal text-gray-400"> vehicles</span>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              {va.direct && (
                <div className="bg-gray-50 px-5 py-3">
                  <div className="flex justify-between text-sm">
                    <a
                      href={`#vehicle_application_${va.id}`}
                      className="font-medium text-indigo-700 hover:text-indigo-900"
                    >
                      Manage
                    </a>
                    <button
                      onClick={() => removeVehicleApplication(va.id)}
                      type="button"
                      className="text-danger-600 hover:text-danger-900"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )}
              {!va.direct && (
                <div className="bg-gray-50 px-5 py-3">
                  <div className="flex items-center justify-between text-sm">
                    <span className="text-xs text-gray-500">
                      Inherited from <a href={va.product_url}>{va.product_sku}</a>. Not editable.
                    </span>
                    <div className="flex-shrink-0">
                      <Button
                        type="button"
                        showLoading={isLoading && vehicleApplicationId === va.id}
                        label="Add & Edit"
                        onClick={() => {
                          copyVehicleApplicationFromProduct([va], va.product_id);
                        }}
                        size="sm"
                        className="btn btn-primary btn-sm"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}

          <button
            type="button"
            onClick={() => openModal(null)}
            className="overflow-hidden rounded-lg border-2 border-dashed border-indigo-200 bg-white hover:border-indigo-400"
          >
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex w-0 flex-1 items-center justify-center space-x-3">
                  <span className="text-sm font-medium text-indigo-700 hover:text-indigo-900">
                    Create a new Vehicle Application{" "}
                  </span>
                  <i className="fal fa-plus text-xl text-indigo-200"></i>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>

      {product.vehicle_applications
        .filter((va) => va.direct)
        .map((va, vaIndex) => (
          <div className="mb-5 rounded-md border border-gray-100 p-5 shadow-sm" key={vaIndex}>
            <div className="mb-10">
              <div className="flex justify-between">
                <h3
                  className="text-md my-2 inline-block font-medium leading-6 text-gray-700"
                  id={`vehicle_application_${va.id}`}
                >
                  {va.title || "Vehicle Application"}
                </h3>
                <div className="btn-group mb-4">
                  <Button
                    icon={faCarMirrors}
                    label="Add Vehicles"
                    type="button"
                    onClick={() => openModal(va.id)}
                    className="btn btn-sm"
                  />

                  <Button
                    icon={faListUl}
                    label="Add Attributes"
                    type="button"
                    onClick={() => openAttributeModal(va.id)}
                    className="btn btn-sm"
                  />
                </div>
              </div>
            </div>
            <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
              <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200 border-b border-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Attribute
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Value
                              </th>
                              <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {va.attributes.map((attribute, index) => (
                              <tr
                                key={index}
                                className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                              >
                                <td className="whitespace-nowrap px-6 py-2 text-xs font-medium text-gray-900">
                                  {attribute.title}
                                </td>
                                <td className="whitespace-nowrap px-6 py-2 text-xs text-gray-500">
                                  {attribute.value}
                                </td>
                                <td className="whitespace-nowrap px-6 py-2 text-right text-xs font-medium">
                                  <button
                                    onClick={() => removeAttribute(attribute.id)}
                                    type="button"
                                    className="text-danger-600 hover:text-danger-900"
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            ))}
                            {va.attributes.length == 0 && (
                              <tr>
                                <td
                                  colSpan="3"
                                  className="whitespace-nowrap px-6 py-4 text-xs text-gray-500"
                                >
                                  No Attributes
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Col 2 */}
                <div className="flex flex-col">
                  {/* Fitment Notes */}
                  <div className="mt-1 sm:mt-0">
                    <label>Fitment Notes</label>
                    <TextArea
                      name={`va_fitment_notes_${va.id}`}
                      rows={5}
                      className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      defaultValue={
                        va.fitment_notes && va.fitment_notes !== "undefined" ? va.fitment_notes : ""
                      }
                    />
                  </div>

                  {/* Document */}
                  <div className="mt-4 sm:mt-5">
                    <label>Document</label>

                    <div className="flex w-full justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only"
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">PDF up to 10MB</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Vehicles */}

              {selectedVehicleIds.length > 0 && (
                <button
                  className="btn btn-danger text-xs"
                  type="button"
                  onClick={() => removeSelectedVehicles()}
                >
                  Remove Selected Vehicles ({selectedVehicleIds.length})
                </button>
              )}

              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200 border-b border-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th></th>
                            <th></th>
                            <th
                              scope="col"
                              className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              Vehicle
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              Year Range
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              Series / Variant
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              Body
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              Engine
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              Transmission
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              Source
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {va.vehicles.map((v, index) => (
                            <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                              <td>
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  onChange={(e) =>
                                    toggleSelectedVehicle(v.id, e.currentTarget.checked)
                                  }
                                />
                              </td>
                              <td className="whitespace-nowrap px-0 py-2 text-xs text-gray-300">
                                {v.status === "archived" && (
                                  <i className="far fa-archive" title="Archived"></i>
                                )}
                                {v.upcoming_changes && (
                                  <i
                                    className="fa-duotone fa-plus-minus text-blue-500"
                                    title={Object.entries(v.changes)
                                      .map(
                                        ([key, value]) =>
                                          `${key}:\t\t${value[0] ? value[0] : ""} -> ${value[1]}`,
                                      )
                                      .join("\n")}
                                  ></i>
                                )}
                              </td>
                              <td
                                className={`whitespace-nowrap px-2 py-2 text-xs text-gray-500 ${statusDisplay(
                                  v.status,
                                )}`}
                              >
                                {v.make} {v.model}
                              </td>
                              <td
                                className={`whitespace-nowrap px-2 py-2 text-xs text-gray-500 ${statusDisplay(
                                  v.status,
                                )}`}
                              >
                                {v.month_from ? `${v.month_from}/` : ""}
                                {v.year_from ? `${v.year_from} - ` : ""}
                                {v.month_to ? `${v.month_to}/` : ""}
                                {v.year_to ? `${v.year_to}` : "on"}
                              </td>
                              <td
                                className={`whitespace-nowrap px-2 py-2 text-xs text-gray-500 ${statusDisplay(
                                  v.status,
                                )}`}
                              >
                                {v.series}
                                {v.variant ? (
                                  <span className="ml-1 text-gray-400">{v.variant}</span>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td
                                className={`whitespace-nowrap px-2 py-2 text-xs text-gray-500 ${statusDisplay(
                                  v.status,
                                )}`}
                              >
                                {v.body}
                              </td>
                              <td
                                className={`whitespace-nowrap px-2 py-2 text-xs text-gray-500 ${statusDisplay(
                                  v.status,
                                )}`}
                              >
                                {v.engine_capacity ? (
                                  <>
                                    {v.engine_capacity}
                                    <span className="text-gray-400">L</span>
                                  </>
                                ) : (
                                  ""
                                )}{" "}
                                {v.fuel_type ? <>{v.fuel_type} </> : ""}
                                {v.engine ? (
                                  <span
                                    className={`ml-1 mr-auto inline-block text-gray-400 ${statusDisplay(
                                      v.status,
                                    )}`}
                                  >
                                    {v.engine}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td
                                className={`whitespace-nowrap px-2 py-2 text-xs text-gray-500 ${statusDisplay(
                                  v.status,
                                )}`}
                              >
                                {v.drivetrain}
                                {v.transmission ? (
                                  <span className={`ml-1 text-gray-400 ${statusDisplay(v.status)}`}>
                                    {v.transmission}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td
                                className={`whitespace-nowrap px-2 py-2 text-xs text-gray-500 ${statusDisplay(
                                  v.status,
                                )}`}
                              >
                                {v.tecdoc_id ? "TecDoc " : v.autoinfo_id ? "Oscar " : "Partbot"}{" "}
                                {v.tecdoc_id || v.autoinfo_id}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-right text-sm font-medium">
                                <button
                                  onClick={() => removeVehicles([v.id])}
                                  type="button"
                                  className="text-danger-600 hover:text-danger-900"
                                >
                                  Remove
                                </button>
                              </td>
                            </tr>
                          ))}
                          {va.vehicles.length == 0 ? (
                            <tr>
                              <td
                                colSpan="14"
                                className="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                No Vehicles
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      <VehicleSelectModal
        {...{ closeModal, isOpen }}
        existingVehicleIds={vehcicleIds}
        productId={product.id}
      />
      <ProductAttributesModal
        {...{ closeModal: closeAttributeModal, isOpen: isAttributeModalOpen }}
        options={attributes}
      />
    </>
  );
}
