import axios from "axios";

class Api {
  static async getExternalVehicleTranslations(id) {
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    };

    try {
      const result = await axios.get(
        `/admin/external_vehicles/${id}/translations.json`,
        requestConfig
      );
      return result.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async putExternalVehicleTranslations(id, translations) {
    const requestConfig = {
      responseType: "json",
      authenticity_token: ReactOnRails.authenticityToken(),
      contentType: "application/json",
      data: translations,
    };

    try {
      const result = await axios.put(
        `/admin/external_vehicles/${id}/translations`,
        requestConfig
      );
      return result.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getVehicleMakes() {
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    };

    try {
      const result = await axios.get("/vehicles/makes.json", requestConfig);
      return result.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getVehicleModels(makeId) {
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      params: {
        make_id: makeId,
      },
    };

    try {
      const result = await axios.get("/vehicles/models.json", requestConfig);
      return result.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getVehicleBodies() {
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    };

    try {
      const result = await axios.get("/vehicles/bodies.json", requestConfig);
      return result.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getVehicleFuelTypes() {
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    };

    try {
      const result = await axios.get(
        "/vehicles/fuel_types.json",
        requestConfig
      );
      return result.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getVehicleDriveTypes() {
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    };

    try {
      const result = await axios.get(
        "/vehicles/drive_types.json",
        requestConfig
      );
      return result.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getVehicleTransmissions() {
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    };

    try {
      const result = await axios.get(
        "/vehicles/transmissions.json",
        requestConfig
      );
      return result.data;
    } catch (error) {
      console.log(error);
    }
  }
}

export default Api;
