import React from "react";

function ExternalVehicleTranslationCardNumericInputCell({
  cellValue,
  precision,
  scale,
  onPropertyChange,
}) {
  const handleValueChange = (e) => {
    onPropertyChange(e.target.value);
  };

  const getMinValue = (precsion, scale) => {
    return 0;
  };

  const getMaxValue = (precision, scale) => {
    return Math.pow(10, precision - scale) - 1;
  };

  const getStep = (precision, scale) => {
    return Math.pow(10, scale * -1);
  };

  return (
    <td className={`col-2 p-2 border-right border-bottom`}>
      <input
        type="number"
        min={getMinValue(precision, scale)}
        max={getMaxValue(precision, scale)}
        step={getStep(precision, scale)}
        className="form-control form-control-sm"
        value={cellValue ?? undefined}
        onChange={(e) => handleValueChange(e)}
      />
    </td>
  );
}

export default ExternalVehicleTranslationCardNumericInputCell;
