import React, { useEffect, useState } from "react";
import { formatShortNumber } from "../../../utils";

export const AnimatedCounter = ({ counter = null }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [prevCounter, setPrevCounter] = useState(null);

  useEffect(() => {
    if (prevCounter != null) {
      setIsAnimating(true);
      const timeout = setTimeout(() => {
        setIsAnimating(false);
        setPrevCounter(counter);
      }, 1000);

      return () => clearTimeout(timeout);
    }
    setPrevCounter(counter);
  }, [counter]);

  const newValue = counter - prevCounter;

  if (isAnimating) {
    return (
      <span className="relative inline-flex animate-bounce rounded-full bg-indigo-500 py-0.5 px-2 text-xs text-indigo-100">
        {newValue > 0 && "+"}
        {newValue}
      </span>
    );
  }

  return (
    <span className="ml-3 hidden rounded-full bg-indigo-100 py-0.5 px-2 text-xs font-medium text-indigo-600 md:inline-block">
      {formatShortNumber(counter)}
    </span>
  );
};
