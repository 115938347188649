export const formatPrice = (price) => {
  const formatted = new Intl.NumberFormat(`en-US`, {
    currency: `USD`,
    style: "currency",
  }).format(price);

  return formatted;
};

export const productImage = (product) => {
  return product?.image_url ? product?.image_url : null;
};

export const brandImage = (product) => {
  return product?.brand
    ? `https://res.cloudinary.com/proex/image/upload/v1/partbot/marketplace/brands/${product.brand
        .replace(/ /g, "-")
        .toLowerCase()}-logo.svg`
    : null;
};

export const formatErrors = (errors) => {
  console.log("formatErrors", errors);
  if (!errors) {
    return null;
  }

  const formatted = errors
    .map((error) => {
      return error.message;
    })
    .join(", ");

  console.log("formatted", formatted);
  return formatted;
};
