import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxTaped,
  faRulerCombined,
  faWeightHanging,
  faBoxesStacked,
  faTrash,
  faUpFromLine,
  faLock,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { Button } from "../../../UI/components/Button";
import { DebouncedInput } from "../../../UI/components/DebouncedInput";

export default function ParcelCard({
  parcel,
  boxes,
  isLoading,
  onRemove,
  onValueChange,
  isLocked,
  children,
}) {
  return (
    <div className="m-3 mt-4 rounded border bg-gray-50 p-2">
      <div className="flex items-center justify-between">
        <h3 className="heading-sm">Box #{parcel.position}</h3>
        {isLocked && <FontAwesomeIcon icon={faLock} className="mr-2 text-base text-amber-200" />}
      </div>
      <div className="mb-2 flex items-center justify-start space-x-6 py-2 text-gray-700">
        <div className="w-2/7 flex items-center">
          <FontAwesomeIcon icon={faBoxTaped} className="mr-2 text-base text-gray-300" />
          <select
            name="label_size"
            value={parcel.distributor_box_id || ""}
            onChange={(e) => console.log(e)}
            className="sm:!text-xs"
            disabled={isLocked}
          >
            {boxes.map((box) => (
              <option key={box.id} value={box.id}>
                {box.name}
              </option>
            ))}
          </select>
        </div>

        <div className="w-3/7 flex items-center space-x-4">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faRulerCombined} className="mr-2 text-base text-gray-300" />
            <div className="flex w-[5rem] items-center">
              <DebouncedInput
                type="number"
                name="length"
                className="block w-full rounded-md border-gray-300 px-[0.25rem] py-[0.2rem] text-right font-extralight text-black placeholder:[appearance:textfield] focus:border-indigo-500 focus:ring-indigo-500 sm:!text-lg [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                placeholder="10"
                defaultValue={parcel.dimensions.length}
                min={1}
                max={999}
                step={0.1}
                disabled={!parcel.dimensions.editable || isLocked}
                onChange={(value) => onValueChange(parcel.id, "length", value)}
              />
            </div>
            <span className="self-center px-1 text-xs text-gray-500">x</span>

            <div className="flex w-[5rem] items-center">
              <DebouncedInput
                type="number"
                name="width"
                className="block w-full rounded-md border-gray-300 px-[0.25rem] py-[0.2rem] text-right font-extralight text-black placeholder:[appearance:textfield] focus:border-indigo-500 focus:ring-indigo-500 sm:!text-lg [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                placeholder="10"
                defaultValue={parcel.dimensions.width}
                min={1}
                max={999}
                step={0.1}
                disabled={!parcel.dimensions.editable || isLocked}
                onChange={(value) => onValueChange(parcel.id, "width", value)}
              />
            </div>
            <span className="self-center px-1 text-xs text-gray-500">x</span>

            <div className="flex w-[5rem] items-center">
              <DebouncedInput
                type="number"
                name="height"
                className="block w-full rounded-md border-gray-300 px-[0.25rem] py-[0.2rem] text-right font-extralight text-black placeholder:[appearance:textfield] focus:border-indigo-500 focus:ring-indigo-500 sm:!text-lg [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                placeholder="10"
                defaultValue={parcel.dimensions.height}
                min={1}
                max={999}
                step={0.1}
                disabled={!parcel.dimensions.editable || isLocked}
                onChange={(value) => onValueChange(parcel.id, "height", value)}
              />
            </div>
            <span className="self-center px-1 text-sm font-medium text-gray-500">
              {" "}
              {parcel.units.dimensions}
            </span>
          </div>

          <div className="flex items-center">
            <FontAwesomeIcon icon={faWeightHanging} className="mr-2 text-base text-gray-300" />
            <div className="flex w-[5rem] items-center">
              <DebouncedInput
                type="number"
                name="weight"
                className="block w-full rounded-md border-gray-300 px-[0.25rem] py-[0.2rem] text-right font-extralight text-black placeholder:[appearance:textfield] focus:border-indigo-500 focus:ring-indigo-500 sm:!text-lg [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                placeholder="1"
                defaultValue={parcel.weight}
                min={0.1}
                max={999.9}
                step={0.1}
                disabled={isLocked}
                onChange={(value) => onValueChange(parcel.id, "weight", value)}
              />
            </div>
            <span className="self-center px-1 text-sm font-medium text-gray-500">
              {" "}
              {parcel.units.weight}
            </span>
          </div>
        </div>

        <div className="w-2/7 flex items-center justify-end space-x-2">
          {parcel.packing_method === "computed" && (
            <button type="button" className="btn btn-sm btn-light">
              <FontAwesomeIcon icon={faBoxesStacked} className="mr-1" />
              Packing Diagram
            </button>
          )}

          <Button
            label={"Delete Box"}
            className={"btn btn-sm btn-neutral"}
            icon={faTrash}
            onClick={() => onRemove(parcel.id)}
            disabled={isLoading || isLocked}
          />
        </div>
      </div>
      <div>{children && children}</div>
    </div>
  );
}
