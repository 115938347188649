import { faSearch, faTimes } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";

const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
  <form className="relative flex flex-grow" noValidate action="" role="search">
    <input
      type="text"
      value={currentRefinement}
      placeholder="Search products by SKU or keywords..."
      onChange={(event) => refine(event.currentTarget.value)}
    />

    {/* {isSearchStalled ? "My search is stalled" : ""} */}
    {currentRefinement !== "" ? (
      <button className="absolute right-3 top-1.5 text-indigo-500" onClick={() => refine("")}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    ) : (
      <FontAwesomeIcon icon={faSearch} className="absolute right-3 top-2.5 text-indigo-500" />
    )}
  </form>
);

export const SearchBar = connectSearchBox(SearchBox);
