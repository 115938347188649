import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

export const BrandSelect = ({ brands, onSubmit, defaultValue = "" }) => {
  const debounced = useDebouncedCallback((value) => {
    onSubmit(value);
  }, 500);

  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <select
      className="flex-1"
      value={selected}
      onChange={(event) => {
        event.preventDefault();
        setSelected(event.target.value);
        debounced(event.currentTarget.value);
      }}
    >
      <option value={""}>All brands</option>

      {brands.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};
