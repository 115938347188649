import React, { StrictMode } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { AXIOS_INSTANCE } from "@/apis/distributor/axios-instance";

import { TeamContext, UserContext } from "../../apps/context";
import { AuthProvider } from "../../utils/auth";
import Bookings, { loader as bookingsLoader } from "./routes/bookings/bookings";
import Shipped, { loader as shippedLoader } from "./routes/bookings/shipped";
import Dashboard, { loader as dashboardLoader } from "./routes/dashboard";
import Fulfillments, { loader as fulfillmentsLoader } from "./routes/fulfillments/fulfillments";
import Layout from "./routes/layout";
import Location, { loader as locationLoader } from "./routes/locations/location";
import Shipment from "./routes/shipments/shipment";
import Shipments, { loader as shipmentsLoader } from "./routes/shipments/shipments";

const queryClient = new QueryClient();

export default function App({ user, team, api_key }) {
  AXIOS_INSTANCE.interceptors.request.use(
    (config) => {
      config.headers = Object.assign(
        {
          Authorization: `Bearer ${api_key}`,
        },
        config.headers,
      );
      return config;
    },
    null,
    { synchronous: true },
  );
  const router = createBrowserRouter(
    [
      {
        element: <Layout />,
        children: [
          {
            path: "/",
            element: <Dashboard />,
            loader: dashboardLoader(queryClient),
          },
          {
            path: "/locations/:locationId",
            element: <Location />,
            loader: locationLoader(queryClient),
            children: [
              {
                path: "fulfillments",
                element: <Fulfillments />,
                loader: fulfillmentsLoader(queryClient),
              },
              {
                path: "shipments",
                element: <Shipments />,
                loader: shipmentsLoader(queryClient),
              },
              {
                path: "shipments/:shipmentId",
                element: <Shipment />,
              },
              {
                path: "bookings",
                element: <Bookings />,
                loader: bookingsLoader(queryClient),
              },
              {
                path: "shipped",
                element: <Shipped />,
                loader: shippedLoader(queryClient),
              },
            ],
          },
        ],
      },
    ],
    {
      basename: "/",
    },
  );

  return (
    <StrictMode>
      <UserContext.Provider value={user}>
        <TeamContext.Provider value={team}>
          <AuthProvider initialState={api_key}>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </AuthProvider>
        </TeamContext.Provider>
      </UserContext.Provider>
    </StrictMode>
  );
}
