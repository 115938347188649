import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Form, Input, Select, FormModal, Button } from "../../UI/components";
import { InputWithAddon } from "../../UI/components/Controls";

const courierOptions = [
  { value: "AusPost", label: "AusPost" },
  { value: "Followmont", label: "Followmont" },
  { value: "Sadleirs", label: "Sadleirs" },
  { value: "StarTrack", label: "StarTrack" },
  { value: "TNT", label: "TNT" },
  { value: "Toll", label: "Toll" },
];

export default function SelfManageShipmentModal({ closeModal, isOpen }) {
  const [isLoading, setIsLoading] = useState(false);

  const onCancel = () => {
    closeModal(null);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      closeModal(data);
    }, 250);
  };

  return (
    <FormModal title="Take Over Shipping" {...{ onCancel, isOpen }}>
      <Form onSubmit={onSubmit}>
        <div className="my-4">
          <p className="my-3 text-sm leading-5 text-gray-500">
            Please provide the shipping information.
            <ul className="list-inside list-disc tracking-tight">
              <li className="">You will need to arrange pickup/handover with the carrier.</li>
              <li className="">Partbot will pass the tracking details along to the customer.</li>
            </ul>
          </p>
          <div className="grid grid-cols-3 gap-3">
            <div className="col-span-3">
              {" "}
              <Input
                label="Carrier"
                name="carrier"
                type="text"
                className="my-4"
                required
                list="carriers"
              />
              <datalist id="carriers">
                {courierOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="col-span-3">
              <Input
                label="Tracking Number"
                name="tracking_number"
                type="text"
                className="my-4"
                required
              />
            </div>

            <div className="col-span-3">
              <Input
                label="Tracking URL"
                name="tracking_url"
                type="url"
                className="my-4"
                required
              />
              <p className="text-right text-xs leading-5 text-gray-500">
                eg. https://auspost.com.au/mypost/track/#/details/1234567890
              </p>
            </div>
            <div className="col-span-3">
              <InputWithAddon
                label="Cost"
                name="cost"
                type="number"
                min="1.00"
                step="0.01"
                className="my-4"
                required
                addon="$"
              />
            </div>
          </div>
        </div>
        <div className="my-4">
          <p className="my-3 text-sm leading-5 text-gray-500">
            The shipment will be marked as "Shipped" and will no longer be editable.
          </p>
        </div>
        <div className="m-0 mt-4 flex justify-end">
          <div className="btn-group">
            <Button label="Cancel" type="button" className="btn btn-neutral" onClick={onCancel} />
            <Button label="Take Over" type="submit" className="btn" showLoading={isLoading} />
          </div>
        </div>
      </Form>
    </FormModal>
  );
}
