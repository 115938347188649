import { faCheck, faHyphen } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { DragHandle } from "./DragHandle";
import { faTrashAlt } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";

export const StaticTableRow = ({ row }) => {
  return (
    <tr
      {...row.getRowProps()}
      className={
        row.original.error
          ? "odd:!bg-danger-100/50 even:!bg-danger-100 border-danger-500 border-l-2"
          : ""
      }
    >
      {row.cells.map((cell, i) => {
        if (i === 0) {
          return (
            <td
              {...cell.getCellProps({
                className: cell.column.className,
              })}
              className={row.original.error ? "!bg-transparent" : ""}
            >
              <div className="relative flex items-center space-x-2">
                <DragHandle />
                <span>{cell.render("Cell")}</span>
              </div>
            </td>
          );
        }
        if (cell.value === true || cell.value === false) {
          return (
            <td
              {...cell.getCellProps({
                className: cell.column.className,
              })}
              className={row.original.error ? "!bg-transparent" : ""}
            >
              {cell.value === true ? (
                <FontAwesomeIcon icon={faCheck} className="text-success-500" />
              ) : (
                <FontAwesomeIcon icon={faHyphen} className="text-gray-300" />
              )}
            </td>
          );
        }
        return (
          <td
            {...cell.getCellProps({
              className: cell.column.className,
            })}
            className={row.original.error ? "!bg-transparent" : ""}
          >
            {cell.render("Cell")}
          </td>
        );
      })}

      <td className={row.original.error ? "!bg-transparent" : ""}>
        <div className="flex justify-end">
          {!row.original.error && (
            <button type="button" disabled={true} className="btn-sm btn-danger">
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};
