import { faArrowUpFromDottedLine } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import algoliasearch from "algoliasearch/lite";
import React, { useEffect, useState } from "react";
import { Configure, InstantSearch, Pagination, SortBy } from "react-instantsearch-dom";
import Api from "./api";
import { Header } from "./components/Header";
import { ImportListModal } from "./components/Dashboard/ImportListModal";
import { ProductHits } from "./components/Dashboard/ProductHits";
import { ProductSearch } from "./components/Dashboard/ProductSearch";
import { RefinementSelect } from "./components/Dashboard/RefinementSelect";
import { SearchBar } from "./components/Dashboard/SearchBar";
import { SearchStats } from "./components/Dashboard/SearchStats";
import { SelectAllCheckbox } from "./components/Dashboard/SelectAllCheckbox";
import { Sidebar } from "./components/Sidebar";
import { object } from "prop-types";

const searchClient = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_SEARCH_KEY);

export default function MarketplaceDashboard({
  api_key,
  import_listings,
  merchant_products_count,
  team,
  user,
  brands,
  app_contexts,
  issue_response_days,
  published_listing_stores,
  total_store_count,
  stores,
}) {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productData, setProductData] = useState(null);
  const [selectedListings, setSelectedListings] = useState([]);
  const [allListings, setAllListings] = useState([]);
  const [searchQuery, setSearchQuery] = useState({});
  const [refinementList, setRefinementList] = useState({});
  const [currentHits, setCurrentHits] = useState([]);
  const [selectAllLoading, setSelectAllLoading] = useState(false);
  const [bulkImportLoading, setBulkImportLoading] = useState(false);
  const [importListCount, setImportListCount] = useState(import_listings);
  // const [brandFilters, setBrandFilters] = useState(brands);

  const environment = process.env.NODE_ENV === "production" ? "production" : "development";
  const MARKETPLACE_INDEX_NAME = `${process.env.ALGOLIA_INDEX_PREFIX}_marketplace_${environment}`;

  // sort orders
  const MARKETPLACE_SORT_BY_ORDERS_INDEX_NAME = `${process.env.ALGOLIA_INDEX_PREFIX}_marketplace_orders_${environment}`;
  // sort stores
  const MARKETPLACE_SORT_BY_STORES_INDEX_NAME = `${process.env.ALGOLIA_INDEX_PREFIX}_marketplace_stores_${environment}`;

  const marketplaceAPI = new Api(api_key, location);
  const HITS_PER_PAGE = 20;
  const totalStoreCount = total_store_count;
  const publishedListingStores = published_listing_stores;
  const importedListings = Object.keys(publishedListingStores);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!selectedProduct) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    marketplaceAPI.loadProductData(selectedProduct.objectID, team.id).then((product) => {
      setProductData(product);
      setIsLoading(false);
    });
  }, [selectedProduct]);

  const addToImportList = async (listingId) => {
    const { inserted_count } = await marketplaceAPI.bulkAddToImportList([listingId]);
    if (inserted_count > 0) {
      setImportListCount(importListCount + inserted_count);
    }
  };

  const bulkAddToImportList = async () => {
    setBulkImportLoading(true);
    const { inserted_count } = await marketplaceAPI.bulkAddToImportList(selectedListings);
    if (inserted_count > 0) {
      setSelectedListings([]);
      setImportListCount(importListCount + inserted_count);
    } else {
      setSelectedListings([]);
      setImportListCount(importListCount);
    }
    setBulkImportLoading(false);
  };

  const addToSelectedListings = (listing) => {
    const isSelected = selectedListings.includes(listing.objectID);
    if (isSelected) {
      const foundIndex = selectedListings.indexOf(listing.objectID);

      if (foundIndex > -1) {
        setSelectedListings([
          ...selectedListings.slice(0, foundIndex),
          ...selectedListings.slice(foundIndex + 1),
        ]);
      }
    } else {
      setSelectedListings([...selectedListings, listing.objectID]);
    }
  };

  // Fetch all listing ids from Algolia via server-side API, using the selected facets/filters
  const fetchAllListingIds = async ({ query, refinementList }) => {
    const combinedFacetFilters = convertToFacetFilters(refinementList);

    const { ids } = await marketplaceAPI.getAllSearchResultIds({
      index: MARKETPLACE_INDEX_NAME,
      query: query,
      facet_filters: combinedFacetFilters,
      team_id: teamOnly ? team.id : null,
      filters: configFilters,
    });
    return ids;
  };

  const convertToFacetFilters = (list) => {
    if (!list) {
      return;
    }

    return Object.entries(list).map(([key, value]) => {
      return `${key}:${value}`;
    });
  };

  const selectAllListings = async (e, { nbHits }) => {
    if (e.target.checked) {
      if (nbHits < HITS_PER_PAGE) {
        const ids = currentHits.map((hit) => hit.objectID);

        setSelectedListings(ids);
        setAllListings(ids);
      } else {
        if (allListings.length > 0) {
          setSelectedListings(allListings);
        } else {
          setSelectAllLoading(true);
          let ids = await fetchAllListingIds({
            query: searchQuery,
            refinementList: refinementList,
          });
          // don't filter out listings that are already imported, user may want to import to a different store
          setSelectedListings(ids);
          setAllListings(ids);
          setSelectAllLoading(false);
        }
      }
    } else {
      setAllListings([]);
      setSelectedListings([]);
    }
  };

  const handleSearchStateChange = (newSearchState) => {
    setSearchQuery(newSearchState.query);
    if (refinementList?.brand !== newSearchState.refinementList?.brand) {
      delete newSearchState.refinementList?.product_type;
    }
    if (newSearchState?.refinementList) {
      const refinementWithValues = Object.entries(newSearchState.refinementList).reduce(
        (a, [k, v]) => (v ? ((a[k] = v), a) : a),
        {}
      );
      setRefinementList(refinementWithValues);
    } else {
      setRefinementList({});
    }
    if (
      newSearchState.query !== searchQuery ||
      (newSearchState.refinementList &&
        JSON.stringify(refinementList) !== JSON.stringify(newSearchState.refinementList))
    ) {
      setAllListings([]);
      setSelectedListings([]);
    }
  };

  const bulkImportDisabledClass = bulkImportLoading ? "opacity-50 cursor-not-allowed" : "";
  const activeBrands = `${brands.map((brand) => `brand:'${brand.value}'`).join(" OR ")}`;

  const [hasDistribution, setHasDistribution] = useState(null);
  const [visibleOnMarketplace, setVisibleOnMarketplace] = useState(true);
  const [teamOnly, setTeamOnly] = useState(false);

  const [selectedStore, setSelectedStore] = useState("all");
  const [selectedListingStatus, setSelectedListingStatus] = useState("all");

  const [configFilters, setConfigFilters] = useState("");

  useEffect(() => {
    const importedListings = Object.entries(publishedListingStores).map(([id, stores]) => ({
      id,
      ...stores[0],
    }));

    const mapping = {
      all: "",
      "non-imported": importedListings
        .filter((listing) =>
          selectedStore === "all"
            ? stores.some((store) => store.name === listing.name)
            : listing.name === selectedStore
        )
        .map((listing) => `NOT objectID:${listing.id}`)
        .join(" AND "),
      imported: importedListings
        .filter((listing) =>
          selectedStore === "all"
            ? stores.some((store) => store.name === listing.name)
            : listing.name === selectedStore
        )
        .map((listing) => `objectID:${listing.id}`)
        .join(" OR "),
    };

    const objectIDsFilter = mapping[selectedListingStatus];

    selectedListings.length > 0 && setSelectedListings([]);
    allListings.length > 0 && setAllListings([]);

    setConfigFilters(
      `${activeBrands}` +
        `${
          visibleOnMarketplace !== null ? ` AND visible_on_marketplace:${visibleOnMarketplace}` : ""
        }` +
        `${hasDistribution !== null ? ` AND has_distribution:${hasDistribution}` : ""}` +
        `${objectIDsFilter ? ` AND ${objectIDsFilter}` : ""}`
    );
  }, [
    selectedStore,
    selectedListingStatus,
    publishedListingStores,
    activeBrands,
    hasDistribution,
    visibleOnMarketplace,
  ]);

  return (
    <>
      <Header app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar
              team={team}
              importListCount={importListCount}
              merchantProductsCount={merchant_products_count}
            />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-2">
            <InstantSearch searchClient={searchClient} indexName={MARKETPLACE_INDEX_NAME}>
              <ProductSearch onChange={handleSearchStateChange} />
              <Configure
                hitsPerPage={HITS_PER_PAGE}
                enablePersonalization={true}
                numericFilters={teamOnly ? [`team_id=${team.id}`] : []}
                filters={configFilters}
                distinct
              />
              <div className="flex w-full space-x-3 rounded-lg bg-white p-3 shadow">
                <SearchBar />
                <RefinementSelect
                  attribute="brand"
                  placeholder={"All Brands"}
                  limit={100}
                  canSelectAll={true}
                  allFacets={brands}
                />
                <RefinementSelect
                  attribute="product_type"
                  placeholder={"All Product Types"}
                  canSelectAll={true}
                  dependency={refinementList?.brand}
                  limit={100}
                />
                <SortBy
                  defaultRefinement={MARKETPLACE_INDEX_NAME}
                  items={[
                    {
                      value: MARKETPLACE_INDEX_NAME,
                      label: "New Arrivals",
                    },
                    {
                      value: MARKETPLACE_SORT_BY_ORDERS_INDEX_NAME,
                      label: "Best Selling",
                    },
                    {
                      value: MARKETPLACE_SORT_BY_STORES_INDEX_NAME,
                      label: "Most Popular",
                    },
                  ]}
                />
              </div>
              <div className="h-full">
                <div className="flex w-full justify-between gap-8 rounded-lg bg-white p-3 shadow">
                  <div className="flex flex-shrink-0 items-center space-x-5">
                    <SelectAllCheckbox
                      onChange={selectAllListings}
                      loading={selectAllLoading}
                      selectedCount={selectedListings.length}
                    />
                    {selectedListings.length > 0 && (
                      <button
                        className={`btn btn-sm ${bulkImportDisabledClass}`}
                        onClick={bulkAddToImportList}
                        disabled={bulkImportLoading}
                      >
                        <FontAwesomeIcon icon={faArrowUpFromDottedLine} />
                        <span>
                          {bulkImportLoading
                            ? `Adding ${selectedListings.length} products to Import List...`
                            : `Add ${selectedListings.length} products to Import List`}
                        </span>
                      </button>
                    )}
                    <SearchStats />
                  </div>
                  <div className="flex items-center space-x-5">
                    <select
                      onChange={(e) => {
                        const value = e.target.value;
                        setSelectedStore(value);
                      }}
                    >
                      <option value="all">All Stores</option>
                      {stores.map((store) => (
                        <option key={store.id} value={store.name}>
                          {store.name}
                        </option>
                      ))}
                    </select>
                    <select
                      onChange={(e) => {
                        const value = e.target.value;
                        setSelectedListingStatus(value);
                      }}
                    >
                      <option value="all">All Listings</option>
                      <option value="non-imported">Non-Imported Listings</option>
                      <option value="imported">Imported Listings</option>
                    </select>
                    <select
                      onChange={(e) => {
                        const value = e.target.value;
                        const mapping = {
                          all: {
                            visibleOnMarketplace: true,
                            teamOnly: false,
                          },
                          hidden: {
                            visibleOnMarketplace: false,
                            teamOnly: true,
                          },
                          visible: {
                            visibleOnMarketplace: true,
                            teamOnly: true,
                          },
                        };

                        if (mapping[value]) {
                          setVisibleOnMarketplace(mapping[value].visibleOnMarketplace);
                          setTeamOnly(mapping[value].teamOnly);
                          setHasDistribution(null);
                        } else {
                          setVisibleOnMarketplace(true);
                          setHasDistribution(value === "true");
                          setTeamOnly(false);
                        }
                      }}
                    >
                      <option value="all">All Listing Types</option>
                      <option value="true">Dropship Only</option>
                      <option value="false">Data Only</option>
                      <option value="visible">My Catalogued Listings</option>
                      <option value="hidden">My Hidden Listings</option>
                    </select>
                  </div>
                </div>

                <ProductHits
                  onViewMore={setSelectedProduct}
                  onSelected={addToSelectedListings}
                  selectedIds={selectedListings}
                  onCurrentHitsChange={setCurrentHits}
                  searchQuery={searchQuery}
                  publishedStores={publishedListingStores}
                  totalStoreCount={totalStoreCount}
                />

                <Pagination
                  padding={2}
                  showLast={true}
                  // className="mb-12"
                  classNames={{
                    list: "flex gap-4 items-center justify-start",
                    item: "btn btn-light no-underline",
                  }}
                />
              </div>
            </InstantSearch>
          </div>
        </div>
      </section>
      <section>
        <ImportListModal
          selectedProduct={selectedProduct}
          addToImportList={addToImportList}
          setSelectedProduct={setSelectedProduct}
          productData={productData}
          issueResponseDays={issue_response_days}
          isLoading={isLoading}
        />
      </section>
    </>
  );
}
