import React, { useEffect, useState, useCallback, useMemo } from "react";
import Table from "../../Application/components/Table";

export default function ProductIssuesTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = useCallback(async ({ pageIndex, pageSize, sortBy, filters, globalFilter }) => {
    let params = {
      page: pageIndex + 1,
      per: pageSize,
    };

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      params: {
        section: "body",
      },
    };
    setLoading(true);

    const result = await fetch(
      "issues/datatable.json?" + new URLSearchParams(params),
      requestConfig
    );
    const data = await result.json();

    console.log("DATA", data);
    setData(data.data);

    setPageCount(Math.ceil(data.meta.total_count / 1));
    setTotalCount(data.meta.total_count);

    setLoading(false);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "SKU",
        accessor: "sku",
        disableFilters: true,
        Cell: function ({ value, row }) {
          return (
            <a className="text-indigo-500" href={`${row.original.slug}/edit`}>
              {value}
            </a>
          );
        },
      },
      {
        Header: "Issue",
        accessor: "issue",
        disableFilters: true,
        width: "25%",
        maxWidth: "40%",
      },
      {
        Header: "Submitted by",
        accessor: "reporting_team_email",
        disableFilters: true,
        width: "25%",
        maxWidth: "40%",
      },
      {
        Header: "Submitted",
        accessor: "created_at",
        disableFilters: true,
        width: "25%",
        maxWidth: "40%",
      },
      // {
      //   Header: "Resolved",
      //   accessor: "resolved",
      //   disableFilters: true,
      //   Cell: ({ value }) => {
      //     if (value) {
      //       return (
      //         <span className="bg-success-100 text-success-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">
      //           Resolved
      //         </span>
      //       );
      //     } else {
      //       return (
      //         <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
      //           Open
      //         </span>
      //       );
      //     }
      //   },
      // },
      {
        Header: "",
        accessor: "id",
        disableFilters: true,
        disableSortBy: true,
        Cell: function ({ value, row }) {
          return (
            <a className="text-indigo-600 hover:text-indigo-900" href={`${row.original.slug}/edit`}>
              Edit
            </a>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <Table
        columns={columns}
        data={data}
        onFetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        totalItems={totalCount}
        showGlobalFilter={false}
      />
    </div>
  );
}
