import React from "react";
import ParcelCard from "./ParcelCard";
import {
  faPlus,
  faBarcode,
  faClipboardList,
  faSquareThisWayUp,
  faTruckRampBox,
  faPersonCarryBox,
  faTrash,
  faTicket,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import TrackingBadge from "./TrackingBadge";
import DeliveryActions from "./DeliveryActions";
import DeliveryStatusBadge from "./DeliveryStatusBadge";

const ErrorAlert = () => {
  return (
    <div className="my-4 rounded-md bg-amber-50 p-2">
      <div className="flex">
        <div className="flex-shrink-0"></div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-xs text-amber-700">
            <div className="mt-2">
              <div key={1}>
                <p className="font-semibold">Label Could Not Be Generated</p>
                <p className="mt-2">
                  We couldn't book and generate a shipping label automatically for this shipment.
                  You can <strong>Request a Shipping Label</strong>, and the label will be emailed
                  to you when it's ready.
                </p>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

const RequestAlert = () => {
  return (
    <div className="my-4 rounded-md bg-amber-100 p-2">
      <div className="flex">
        <div className="flex-shrink-0"></div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-xs text-amber-800">
            <div className="mt-2">
              <div key={1}>
                <p className="font-semibold">Label Request Pending</p>
                <p className="mt-2">
                  Partbot could not generate a label automatically for this shipment. We'll organise
                  a label shortly and it will be emailed to you when it's ready.
                </p>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

const PendingAlert = () => {
  return (
    <div className="my-4 rounded-md bg-cyan-50 p-2">
      <div className="flex">
        <div className="flex-shrink-0"></div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-xs text-cyan-700">
            <div className="mt-2">
              <div key={1}>
                <p className="font-semibold">Label Is Being Generated</p>
                <p className="mt-2">
                  The label for the shipment is being generated and will be available for download
                  shortly on the "Shipping" tab. We'll also email you the label when it's ready.
                </p>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default function DeliveryCard({
  delivery,
  onParcelAdd,
  onRemove,
  onBook,
  onRequestLabel,
  onRequestPickup,
  onHandOver,
  children,
}) {
  if (!delivery) {
    return null;
  }

  const deliveryActions = [
    [
      {
        name: "Add Box",
        icon: faPlus,
        handler: onParcelAdd,
        supported: delivery?.supported_actions?.includes("add_box"),
      },
    ],
    [
      {
        name: "Shipping Labels",
        icon: faBarcode,
        handler: () => {
          window.open(delivery.label_url, "_blank");
        },
        supported: delivery?.supported_actions?.includes("download_shipping_label"),
      },
      {
        name: "Packing Slip",
        icon: faClipboardList,
        handler: () => {
          window.open(delivery.packing_slip_url, "_blank");
        },
        supported: delivery?.supported_actions?.includes("download_packing_slip"),
      },
    ],
    [
      {
        name: "Request Label",
        icon: faTicket,
        handler: (deliveryId) => onRequestLabel(deliveryId),
        supported: delivery?.supported_actions?.includes("request_label"),
      },
      // {
      //   name: "Book Shipment",
      //   icon: faSquareThisWayUp,
      //   handler: (deliveryId) => onBook(deliveryId),
      //   supported: delivery?.supported_actions?.includes("book_shipment"),
      // },
      // {
      //   name: "Request Pickup",
      //   icon: faTruckRampBox,
      //   handler: (deliveryId) => onRequestPickup(deliveryId),
      //   supported: delivery?.supported_actions?.includes("request_pickup"),
      // },
      // {
      //   name: "Handed Over",
      //   icon: faPersonCarryBox,
      //   handler: (deliveryId) => onHandOver(deliveryId),
      //   supported: delivery?.supported_actions?.includes("manual_handover"),
      // },
    ],
    [
      {
        name: "Delete Shipment",
        icon: faTrash,
        handler: (deliveryId) => onRemove(deliveryId),
        supported: delivery?.supported_actions?.includes("delete"),
      },
    ],
  ];

  const borderColors = {
    pending: "border-yellow-200",
    packed: "border-blue-200",
    booked: "border-green-200",
    label_requested: "border-blue-200",
    label_generated: "border-green-200",
    error: "border-amber-200",
    deleted: "border-rose-200",
  };

  const mergedBorderClasses = `border-l-8 ${borderColors[delivery.delivery_status]}`;

  return (
    <div className={`${mergedBorderClasses} overflow-hidden rounded-md border bg-white shadow`}>
      <div className="grid grid-cols-12 divide-x">
        <div className="col-span-10">
          <div>
            <div className="grid grid-cols-12">
              {/* <div className="col-span-2 p-3">
                {/* <h3 className="text-sm">
                  <span className="font-medium text-gray-900">#{delivery.position}</span>
                </h3>
                <h3 className="text-xs font-bold uppercase tracking-widest text-gray-600"></h3>
              </div> */}
              {/* Tracking number badge */}
              <div className="col-span-8 flex items-start justify-start p-4">
                <dl className="flex space-x-6">
                  <div className="overflow-hidden">
                    <dt className="truncate text-sm font-medium text-gray-400">Shipment</dt>
                    <dd className="text-1xl mt-1 font-semibold tracking-tight text-gray-900">
                      {delivery.identifier}
                    </dd>
                  </div>
                  <div className="overflow-hidden">
                    <dt className="truncate text-sm font-medium text-gray-400">Items</dt>
                    <dd className="text-1xl mt-1 font-medium tracking-tight text-gray-700">
                      {delivery.total_quantity}
                    </dd>
                  </div>
                  <div className="overflow-hidden">
                    <dt className="truncate text-sm font-medium text-gray-400">Boxes</dt>
                    <dd className="text-1xl mt-1 font-medium tracking-tight text-gray-700">
                      {delivery.parcels.length}
                    </dd>
                  </div>
                  <div className="overflow-hidden">
                    <dt className="truncate text-sm font-medium text-gray-400">Dead Weight</dt>
                    <dd className="text-1xl mt-1 font-medium tracking-tight text-gray-700">
                      {delivery.formatted_weight_kg} kg{" "}
                    </dd>
                  </div>
                  <div className="overflow-hidden">
                    <dt className="truncate text-sm font-medium text-gray-400">Cubic Weight</dt>
                    <dd className="text-1xl mt-1 font-medium tracking-tight text-gray-700">
                      {delivery.formatted_cubic_weight_kg} kg{" "}
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="col-span-4 flex items-start justify-end p-4">
                <div className="flex space-x-8">
                  <div className="overflow-hidden">
                    <DeliveryStatusBadge title="Status" status={delivery.delivery_status} />
                  </div>
                  {/* <div className="overflow-hidden">
                    <DeliveryStatusBadge title="Handover" status={delivery.handover_status} />
                  </div> */}
                  <div className="overflow-hidden">
                    <div className="truncate text-sm font-medium text-gray-400">
                      {delivery.carrier ?? "Carrier"}
                    </div>
                    <TrackingBadge
                      trackingNumber={delivery.tracking_number}
                      trackingUrl={delivery.tracking_url}
                      labelStatus={delivery.label_status}
                    />
                  </div>
                </div>
              </div>
            </div>
            {delivery.label_status == "error" && (
              <div className="px-4">
                <ErrorAlert />
              </div>
            )}
            {delivery.label_status == "label_requested" && (
              <div className="px-4">
                <RequestAlert />
              </div>
            )}
            {delivery.label_status == "pending" && (
              <div className="px-4">
                <PendingAlert />
              </div>
            )}
            {children && children}
            {/* {delivery.parcels.map((parcel) => (
        <ParcelCard parcel={parcel} key={parcel.id} />
      ))} */}
          </div>
        </div>
        <div className="col-span-2">
          <DeliveryActions delivery={delivery} actions={deliveryActions} />
        </div>
      </div>
    </div>
  );
}
