import React from "react";
import { faSearch } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebouncedCallback } from "use-debounce";

export const SearchBar = ({ value, onSubmit, placeholder }) => {
  const debounced = useDebouncedCallback((value) => {
    onSubmit(value);
  }, 500);

  return (
    <form className="w-full" noValidate action="" role="search">
      <input
        type="text"
        placeholder={placeholder || "Search products by Title or SKU..."}
        onChange={(event) => debounced(event.currentTarget.value)}
      />

      <FontAwesomeIcon icon={faSearch} className="absolute right-3 top-2.5 text-indigo-500" />
    </form>
  );
};
