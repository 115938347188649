import React from "react";
import { formatPrice } from "../../utils";
import { Card } from "./Card";
import { DebouncedInput } from "./DebouncedInput";

export const Variant = ({ variant, selectedAttributes, overrides = [], onChange }) => {
  const variationAttributes = Object.entries(variant.attributes).filter(([k, _v]) => {
    return selectedAttributes.includes(k);
  });

  const overriddenPrice = overrides.find((o) => o.field === "price");
  const overriddenSKU = overrides.find((o) => o.field === "sku");
  const defaultPrice = overriddenPrice?.value || variant?.price;
  const defaultSKU = overriddenSKU?.value || variant?.sku;

  return (
    <Card id={variant.id} image={variant.image}>
      <div className="flex flex-1 justify-between">
        <div>
          <label className="text-xs">SKU</label>
          <h3 className="text-sm text-indigo-500">{variant.sku}</h3>
          {variationAttributes.map(([k, v]) => {
            return (
              <p key={k} title={k} className="mt-1 text-sm text-gray-500">
                {v}
              </p>
            );
          })}
        </div>

        <div title="RRP" className="flex-col">
          <label className="text-xs">RRP</label>
          <div className="text-sm">{formatPrice(variant.price)}</div>
        </div>
      </div>
      <label className="mt-4 text-xs">Price</label>
      <div className="relative  rounded-md shadow-sm" title="price">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm"> $ </span>
        </div>
        <DebouncedInput
          type="text"
          name="price"
          className="block w-full rounded-md border-gray-300 pl-7 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="0.00"
          defaultValue={defaultPrice}
          onChange={(value) => onChange("price", value)}
        />
      </div>
      <label className="mt-4 text-xs">SKU</label>
      <div className="relative  rounded-md shadow-sm" title="price">
        <DebouncedInput
          type="text"
          name="price"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Merchant SKU"
          defaultValue={defaultSKU}
          onChange={(value) => onChange("sku", value)}
        />
      </div>
    </Card>
  );
};
