import React, { useEffect, useState, useCallback, useMemo } from "react";
import Table from "../../Application/components/Table";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserCircle } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";

export default function ProductActivityTable({ brand }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = useCallback(async ({ pageIndex, pageSize, sortBy, filters, globalFilter }) => {
    let params = {
      page: pageIndex + 1,
      per: pageSize,
    };

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      params: {
        section: "body",
      },
    };
    setLoading(true);

    const result = await fetch(
      `/b/${brand}/products/activity/datatable.json?` + new URLSearchParams(params),
      requestConfig
    );
    const data = await result.json();

    console.log("DATA", data);
    setData(data.data);

    setPageCount(Math.ceil(data.meta.total_count / 1));
    setTotalCount(data.meta.total_count);

    setLoading(false);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "SKU",
        accessor: "sku",
        disableFilters: true,
        disableSortBy: true,
        Cell: function ({ value, row }) {
          return (
            <a className="text-indigo-500" href={row.original.url}>
              {value}
            </a>
          );
        },
      },
      {
        Header: "Activity",
        accessor: "changes",
        disableFilters: true,
        disableSortBy: true,
        width: "25%",
        maxWidth: "40%",
        Cell: function ({ value, row }) {
          // return a div that contains a span element for each item in the array created by splitting the value
          return (
            <ul className="list-disc gap-2 text-[10px]">
              {value.split(",").map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          );
        },
      },
      {
        Header: "User",
        accessor: "user",
        disableFilters: true,
        disableSortBy: true,
        Cell: function ({ value, row }) {
          return (
            <div className="flex items-center space-x-2 text-xs">
              <FontAwesomeIcon icon={faUserCircle} className="" />
              <span className="text-medium text-gray-500">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "created_at",
        disableFilters: true,
        disableSortBy: true,
        width: "25%",
        maxWidth: "40%",
      },
      {
        Header: "",
        accessor: "id",
        disableFilters: true,
        disableSortBy: true,
        Cell: function ({ value, row }) {
          return (
            <a
              className="text-right text-indigo-600 hover:text-indigo-900"
              href={`${row.original.url}#history`}
            >
              View Changes
            </a>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className={clsx(data && data.length > 0 ? "block" : "hidden")}>
        <Table
          columns={columns}
          data={data}
          onFetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          totalItems={totalCount}
          showGlobalFilter={false}
        />
      </div>

      {data && data.length === 0 && (
        <div className="mt-6 text-xs text-gray-500">
          Product changes made by you and your team will appear here.
        </div>
      )}
    </>
  );
}
