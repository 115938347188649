import React, { useState, useEffect } from "react";
import { Form, RadioButton, FormModal, Button } from "../../UI/components";
import dayjs from "dayjs";
import { useForm, useController } from "react-hook-form";

export default function HandOverModal({ closeModal, isOpen, shipments }) {
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit } = useForm();

  const onCancel = () => {
    closeModal(false);
  };

  const onSubmit = (data) => {
    // fake delay so user can see something is happening
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      closeModal(true);
    }, 250);
  };

  return (
    <FormModal title="Hand Over Shipments" {...{ onCancel, isOpen }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-4">
          <p className="my-3 text-sm leading-5 text-gray-500">
            Are you sure you want to hand over the following shipments?
            <ul className="my-4 list-inside list-disc">
              {shipments.map((shipment) => (
                <li key={shipment.id}>
                  <span className="text-indigo-500">{shipment.tracking_number}</span>{" "}
                  {shipment.carrier} {shipment.carrier_service}
                </li>
              ))}
            </ul>
            They will be marked as "Shipped" and will no longer be editable.
          </p>
        </div>
        <div className="m-0 mt-4 flex justify-end">
          <div className="btn-group">
            <Button label="Cancel" type="button" className="btn btn-neutral" onClick={onCancel} />
            <Button label="Confirm" type="submit" className="btn" showLoading={isLoading} />
          </div>
        </div>
      </form>
    </FormModal>
  );
}
