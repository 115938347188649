import React from "react";

export default function DropshipHandlingRates({
  min_rate,
  percentage_rate,
  max_rate,
  updateRates,
}) {
  return (
    <div className="grid grid-cols-3 gap-5">
      <div>
        <label>Min. Rate ($)</label>
        <input
          className="form-control"
          type="number"
          name="min_rate"
          value={min_rate}
          min={0}
          onChange={updateRates}
        />
      </div>
      <div>
        <label>Rate (%)</label>
        <input
          className="form-control"
          type="number"
          name="percentage_rate"
          value={percentage_rate}
          min={0}
          max={100}
          onChange={updateRates}
        />
      </div>
      <div>
        <label>Max. Rate ($)</label>
        <input
          className="form-control"
          type="number"
          name="max_rate"
          value={max_rate}
          min={0}
          onChange={updateRates}
        />
      </div>
    </div>
  );
}
