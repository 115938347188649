import React from "react";
export const SidebarButton = ({ children, href }) => {
  return (
    <a
      className="group flex w-full cursor-pointer items-center justify-start space-x-3 rounded-lg bg-white px-4 py-2 text-sm font-medium text-gray-600 no-underline transition hover:bg-indigo-50 hover:text-indigo-800"
      href={href}
    >
      {children}
    </a>
  );
};
