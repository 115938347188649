import React, { useRef, useState } from "react";
import { Button } from "../UI/components";
import { faKey, faTrashAlt } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { debounce } from "lodash";
import toast from "react-hot-toast";
import Api from "../Marketplace/api";

const TecAllianceSettings = ({ team, tecAllianceMerchants }) => {
  const marketplaceAPI = new Api(team.details.api_key, location);

  const [taMerchants, setTaMerchants] = useState(tecAllianceMerchants || []);

  const addTecAllianceApiKey = () => {
    const newMerchant = {
      provider_id: "",
      api_key: "",
    };

    setTaMerchants([...taMerchants, newMerchant]);
  };

  const timeoutRef = useRef(null);

  const updateMerchant = (merchant, key, value) => {
    // update the merchant and add it back to the list
    const updatedMerchant = { ...merchant, [key]: value };

    const updatedMerchants = [...taMerchants];
    const index = updatedMerchants.findIndex((m) => m === merchant);
    updatedMerchants[index] = updatedMerchant;

    setTaMerchants(updatedMerchants);

    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => saveMerchant(updatedMerchant), 1000);
  };

  const saveMerchant = debounce((merchant) => {
    // return if merchant.api_key and merchant.provider_id are empty
    if (
      !merchant.api_key ||
      !merchant.provider_id ||
      merchant?.api_key === "" ||
      merchant?.provider_id === ""
    ) {
      return;
    }

    // save the merchant to the backend
    marketplaceAPI.updateTecAllianceMerchant(merchant).then((data) => {
      if (data.success) {
        toast.success("API Key saved");

        // set taMerchants to data.merchants
        setTaMerchants(data.merchants);
      } else {
        toast.error("An error occured");
      }
    });
  }, 1000);

  const deleteTecAllianceApiKey = (merchant) => {
    // remove the merchant via API
    marketplaceAPI.removeTecAllianceMerchant(merchant).then((data) => {
      if (data && data.success) {
        toast.success("API Key deleted");

        setTaMerchants(data.merchants);
      } else {
        toast.error("An error occured");
      }
    });
  };

  return (
    <div className="space-y-8 pt-4">
      <div className="flex flex-col gap-3">
        <div className="bg-secondary text-secondary-foreground mb-3 rounded px-3 py-2 text-xs">
          Manage TecAlliance API Access. We'll use your API Keys to import brands and products from
          TecAlliance.
        </div>

        {taMerchants && taMerchants.length > 0 && (
          <div className="flex flex-col gap-5 divide-x divide-stone-100">
            <table className="mb-3 table border-b">
              <thead className="border-b">
                <tr>
                  <th className="ml-0 w-60 pl-0">Provider ID</th>
                  <th>API Key</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {taMerchants.map((merchant, index) => (
                  <tr key={index}>
                    <td className="ml-0 pl-0">
                      <input
                        type="text"
                        value={merchant.provider_id || ""}
                        pattern="[0-9]*"
                        placeholder="4444"
                        className="text-gray-900"
                        onChange={(e) => {
                          if (e.target.validity.valid) {
                            updateMerchant(merchant, "provider_id", e.target.value);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="text-gray-900"
                        value={merchant.api_key || ""}
                        onChange={(e) => updateMerchant(merchant, "api_key", e.target.value)}
                      />
                    </td>
                    <td className="text-right">
                      {/* destroy merchant */}
                      <Button
                        size="icon"
                        icon={faTrashAlt}
                        className="hover:text-danger-500"
                        onClick={() => deleteTecAllianceApiKey(merchant)}
                        disabled={!merchant.id}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div>
          <Button
            label="Add API Key"
            className="btn btn-light btn-sm"
            onClick={addTecAllianceApiKey}
            icon={faKey}
          />
        </div>
      </div>
    </div>
  );
};

export default TecAllianceSettings;
