import React from "react";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import dayjs from "dayjs";
import toast, { Toaster } from "react-hot-toast";
import DistributorFulfillment from "./DistributorFulfillment";
import FulfillmentHeader from "./components/Fulfillment/FulfillmentHeader";

export default function ManageDistributorFulfillment({
  fulfillment_order,
  fulfillment,
  boxes,
  package_types,
  app_contexts,
  distributor_location,
}) {
  return (
    <>
      <Header app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-4 pt-5">
            <FulfillmentHeader distributor_location={distributor_location} />
            <div className="flex w-full items-center justify-between">
              <div className="flex flex-grow space-x-5">
                <h2 className="m-0 text-3xl font-extrabold tracking-tight text-gray-900">
                  Fulfillment
                </h2>
              </div>
              <div className="flex flex-grow flex-col items-end justify-between space-x-5 text-gray-600">
                <div className="flex items-end justify-between space-x-2 text-sm">
                  <span>
                    Order #{" "}
                    <span className="font-medium text-gray-900">
                      {fulfillment.distributor_order_id}
                    </span>
                  </span>
                </div>
                <p className="text-sm text-gray-600">
                  Placed{" "}
                  <time
                    dateTime={fulfillment_order.created_at}
                    className="font-medium text-gray-900"
                  >
                    {dayjs(fulfillment_order.created_at).format("MMM D, YYYY")}
                  </time>
                </p>
              </div>
            </div>

            <DistributorFulfillment
              fulfillmentData={fulfillment}
              fulfillmentOrderData={fulfillment_order}
              boxes={boxes}
              package_types={package_types}
            />
          </div>
        </div>
      </section>
      <Toaster />
    </>
  );
}
