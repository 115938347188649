import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Tab } from "@headlessui/react";
import { Button } from "../../UI/components";
import toast from "react-hot-toast";
import axios from "axios";
import { faTimes } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ProductIssue = ({ productIssue, onSubmit, isLoading }) => {
  return (
    <li key={productIssue.id}>
      <div className="group relative space-y-2 rounded-md border border-gray-300 p-3">
        <div className="text-xs">{productIssue.issue}</div>
        <div className="space-y-1 text-xs text-gray-500">
          <div>Submitted by: {productIssue.reporting_team}</div>
          <div>Date: {productIssue.created_at}</div>
        </div>
        <div
          className={classNames(
            "absolute right-2 top-0 justify-end",
            isLoading ? "flex" : "hidden group-hover:flex"
          )}
        >
          <Button
            className={classNames(
              "btn btn-secondary btn-sm !m-0",
              productIssue.resolved ? "btn-neutral" : "btn-success"
            )}
            label={productIssue.resolved ? "Reopen" : "Resolve"}
            onClick={() => onSubmit(productIssue)}
            showLoading={isLoading}
          />
        </div>
      </div>
    </li>
  );
};

export default function ProductIssueList({ onClose, isOpen, productIssues, refreshProductIssues }) {
  const [formLoading, setFormLoading] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState(null);

  const onCancel = () => {
    onClose();
  };

  const onSubmit = async (productIssue) => {
    setSelectedIssue(productIssue);
    setFormLoading(true);
    const formData = new FormData();

    formData.append("product_issue[resolved]", !productIssue.resolved);

    try {
      const result = await axios({
        method: "patch",
        url: `/product-issues/${productIssue.id}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: formData,
      });

      toast.success(productIssue.resolved ? "Issue reopened" : "Issue resolved");
      await refreshProductIssues();
      setFormLoading(false);
      setSelectedIssue(null);
    } catch (e) {
      console.log("ERROR", e);
      toast.error("There was an error updating the issue");
      setFormLoading(false);
      setSelectedIssue(null);
    }
  };

  const tabs = [
    {
      display: "Open",
      count: productIssues.filter((issue) => !issue.resolved).length,
      selected: true,
    },
    {
      display: "Resolved",
      count: productIssues.filter((issue) => issue.resolved).length,
      selected: false,
    },
  ];

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div
          className={classNames(
            "fixed inset-0 overflow-hidden",
            formLoading && "pointer-events-none cursor-not-allowed"
          )}
        >
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-bold tracking-tight text-gray-900">
                          Product Issues
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <Button
                            className="rounded-md bg-white px-2 text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={() => onCancel()}
                            icon={faTimes}
                          ></Button>
                        </div>
                      </div>
                    </div>
                    <div className="relative px-6">
                      <Tab.Group>
                        <div className="border-b border-gray-200">
                          <Tab.List className="tabs-menu -mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                              <Tab
                                key={tab.display}
                                className={({ selected }) =>
                                  classNames(
                                    "flex whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium focus-visible:outline-0",
                                    selected
                                      ? "is-active border-indigo-500 text-indigo-600"
                                      : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700"
                                  )
                                }
                              >
                                {({ selected }) => (
                                  <>
                                    {tab.display}
                                    {tab.count ? (
                                      <span
                                        className={classNames(
                                          selected
                                            ? "bg-indigo-100 text-indigo-600"
                                            : "bg-gray-100 text-gray-900",
                                          "ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block"
                                        )}
                                      >
                                        {tab.count}
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Tab>
                            ))}
                          </Tab.List>
                        </div>
                        <Tab.Panels className="mt-2">
                          <Tab.Panel>
                            <div className="relative mt-3 flex-1">
                              {productIssues.filter((issue) => !issue.resolved).length > 0 ? (
                                <ul className="grid grid-cols-1 gap-y-3">
                                  {/* show productIssues that are not resolved, render using the issue component */}
                                  {productIssues
                                    .filter((issue) => !issue.resolved)
                                    .map((issue) => (
                                      <ProductIssue
                                        key={issue.id}
                                        productIssue={issue}
                                        onSubmit={onSubmit}
                                        isLoading={formLoading && issue.id === selectedIssue.id}
                                      />
                                    ))}
                                </ul>
                              ) : (
                                <div className="py-4 text-xs text-gray-500">No open issues.</div>
                              )}
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="relative mt-3 flex-1">
                              <ul className="grid grid-cols-1 gap-y-3">
                                {productIssues
                                  .filter((issue) => issue.resolved)
                                  .map((issue) => (
                                    <ProductIssue
                                      key={issue.id}
                                      productIssue={issue}
                                      onSubmit={onSubmit}
                                      isLoading={formLoading && issue.id === selectedIssue.id}
                                    />
                                  ))}
                              </ul>
                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </Tab.Group>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
