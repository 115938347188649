export default function formatShortNumber(number) {
  if (number === null || number === undefined || Number.isNaN(number) || number === 0) {
    return 0;
  }

  const units = ["k", "M", "B", "T"];
  const unitIndex = Math.floor(Math.log10(number) / 3);

  if (unitIndex === 0) {
    return number.toString();
  }

  return (number / Math.pow(1000, unitIndex)).toFixed(1) + units[unitIndex - 1];
}
