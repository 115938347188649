import React, { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import ImageEditor from "./ImageEditor";
import axios from "axios";
import toast from "react-hot-toast";
import { Form, FormModal, Input } from "../../UI/components";
import { DropzoneField } from "../../UI/components/Controls";
import { useFormContext } from "react-hook-form";
import { Button } from "../../UI/components/Button";

export default function AddMediaModal({ closeModal, isOpen, mediaType, product, onUpdate }) {
  const { register, setValue } = useFormContext();
  const [imageFile, setImageFile] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [formLoading, setFormLoading] = useState(false);

  const onCancel = () => {
    closeModal(null);
    setImageFile(null);
    setIsDisabled(true);
  };

  const renderInput = () => {
    switch (mediaType.id) {
      case "image":
        return (
          <ImageEditor
            name="media_file"
            acceptedMimeTypes={mediaType.supported_mime_types}
            limitedHeight={true}
            onUpdate={updateFile}
          />
        );
      case "graph":
        return (
          <>
            <div className="mb-2">
              <Input type="text" name="chart_title" label="Title" />
            </div>
            <DropzoneField
              name="chart_file"
              label="File"
              accept={mediaType.supported_mime_types}
              onUpdate={(acceptedFiles) => setIsDisabled(acceptedFiles.length === 0)}
            />
          </>
        );
      default:
        return (
          <DropzoneField
            name="media_file"
            label="File"
            accept={mediaType.supported_mime_types}
            onUpdate={(acceptedFiles) => setIsDisabled(acceptedFiles.length === 0)}
          />
        );
    }
  };

  const onSubmit = async (data) => {
    setFormLoading(true);
    const formData = new FormData();
    const url = mediaType.id === "graph" ? "charts" : "media";

    if (!data.chart_file && !data.media_file && !imageFile) {
      toast.error("Your file could not be uploaded. Please try again.");
      return;
    }

    if (mediaType.id === "image") {
      formData.append("product[media_file]", imageFile[0]);
      setImageFile(null);
    } else if (mediaType.id === "graph") {
      formData.append("product[chart_file]", data.chart_file[0]);
      formData.append("product[chart_title]", data.chart_title);
    } else {
      formData.append("product[media_file]", data.media_file[0]);
    }

    formData.append("product[media_type]", mediaType.id);

    try {
      const result = await axios({
        method: "post",
        url: url,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: formData,
      });

      toast.success(result.data.success);

      await onUpdate();
      setFormLoading(false);
      closeModal();
    } catch (error) {
      setFormLoading(false);
      toast.error(error?.response?.data?.message ?? "Error Uploading Product Image");
    }
  };

  const updateFile = (file) => {
    console.log("updateFile image", file);
    if (file) {
      setImageFile(file);
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setImageFile(null);
      // toast.error("Your file could not be uploaded. Please try again.");
    }
  };

  return (
    <FormModal title={`Add New ${mediaType.title}`} {...{ isOpen, onCancel }}>
      <Form onSubmit={onSubmit}>
        <div className="form-group mt-5">{renderInput()}</div>

        <div className="mt-4 flex justify-end space-x-2">
          <button type="button" className="btn btn-neutral" onClick={onCancel}>
            Cancel
          </button>
          <Button
            type="submit"
            className={isDisabled ? "btn btn-neutral" : "btn btn-dark"}
            disabled={isDisabled}
            showLoading={formLoading}
            label="Upload"
          />
        </div>
      </Form>
    </FormModal>
  );
}
