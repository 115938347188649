import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Form, Input, Select, FormModal, Button } from "../../UI/components";
import { InputWithAddon } from "../../UI/components/Controls";

export default function AllocateToParcelModal({
  closeModal,
  isOpen,
  deliveries,
  options = { maxQuantity: 0 },
}) {
  const newDelivery = {
    id: -1,
    name: "New Shipment",
    identifier: "New Shipment",
    quantity: options.maxQuantity,
    parcels: [
      {
        id: null,
        name: "New Box",
      },
    ],
  };

  const [isLoading, setIsLoading] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState(newDelivery);
  const [deliveriesData, setDeliveriesData] = useState(deliveries);

  useEffect(() => {
    const deliveriesCopy = JSON.parse(JSON.stringify(deliveries));
    console.log(deliveriesCopy);

    deliveriesCopy.push(newDelivery);
    setDeliveriesData(deliveriesCopy);

    if (deliveriesCopy.length == 0) {
      setSelectedDelivery(newDelivery);
      return;
    }

    if (deliveriesCopy[0].parcels.length == 0) {
      deliveriesCopy[0].parcels.push({
        id: null,
        name: "New Box",
      });
    }

    setSelectedDelivery(deliveriesCopy[0]);
  }, [deliveries]);

  const onCancel = () => {
    closeModal(null);
  };

  const onSubmit = (data) => {
    data["fulfillment_unit_id"] = options.id;
    // fake delay so user can see something is happening
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      closeModal(data);
    }, 250);
  };

  return (
    <FormModal title="Allocate to a Shipment" {...{ onCancel, isOpen }}>
      <Form onSubmit={onSubmit}>
        <div className="my-4">
          <div className="grid grid-cols-3 gap-3">
            <div className="col-span-3">
              {" "}
              <Select
                label="Shipment"
                name="fulfillment_delivery_id"
                options={deliveriesData.map((delivery, index) => ({
                  value: delivery.id || "null",
                  label: delivery.identifier,
                }))}
                onChange={(e) => {
                  console.log(e.target.value);
                  const delivery = deliveriesData.find((delivery) => delivery.id == e.target.value);
                  console.log(delivery);
                  setSelectedDelivery(delivery);
                }}
              />
            </div>
            <div className="col-span-1">
              <InputWithAddon
                label="Quantity"
                addon={`of ${options.maxQuantity}`}
                name="quantity"
                type="number"
                className="my-4"
                min={1}
                max={options.maxQuantity}
                onChange={(e) => {
                  console.log(e);
                }}
                defaultValue={options.maxQuantity}
                required
              />
            </div>
            <div className="col-span-2">
              {" "}
              <Select
                label="Box"
                name="fulfillment_parcel_id"
                options={selectedDelivery.parcels.map((parcel, index) => ({
                  value: parcel.id,
                  label: parcel.name,
                }))}
                onChange={(e) => {
                  console.log(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="m-0 mt-4 flex justify-end">
          <div className="btn-group">
            <Button label="Cancel" type="button" className="btn btn-neutral" onClick={onCancel} />
            <Button label="Allocate" type="submit" className="btn" showLoading={isLoading} />
          </div>
        </div>
      </Form>
    </FormModal>
  );
}
