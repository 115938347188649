import React, { Fragment, useEffect, useState, useCallback, useMemo } from "react";

import axios from "axios";
import ReactOnRails from "react-on-rails";
import ProductSelectModal from "./ProductSelectModal";
import toast from "react-hot-toast";
import { SortableTable } from "../../UI/components/Table/SortableTable";
import { Input, Toggle } from "../../UI/components";

export default function PartsList({ product, onUpdate }) {
  const [isOpen, setIsOpen] = useState(false);

  const [childProducts, setChildProducts] = useState(product.child_products);

  async function closeModal(data) {
    setIsOpen(false);

    if (data) {
      try {
        const result = await axios({
          method: "post",
          url: `parts`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: {
            product_ids: data,
          },
        });

        if (result.status === 200) {
          toast.success("Part added to product");
          console.log("result.data", result.data);
          setChildProducts([...childProducts, ...result.data.products]);
        } else {
          toast.error("Error adding part to product");
        }

        await onUpdate();
      } catch (e) {
        console.log(e);
        toast.error("Error adding part to product");
      }
    }
  }

  async function handleRemovePart(id) {
    console.log("handleRemovePart", id);
    try {
      const result = await axios({
        method: "delete",
        url: `parts/`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: {
          product_component_ids: [id],
        },
      });

      if (result.status === 200) {
        toast.success("Part removed from product");
        setChildProducts(childProducts.filter((p) => p.id !== id));
      } else {
        toast.error("Error removing part from product");
      }

      await onUpdate();
    } catch (e) {
      console.log(e);
      toast.error("Error removing part from product");
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  const columns = useMemo(() => [
    {
      Header: "Image",
      accessor: "image_url",
      Cell: function ({ value, row }) {
        return (
          <div className="h-10 w-10 overflow-clip bg-white">
            <img
              src={value}
              width="100px"
              height="auto"
              className="aspect-square rounded-md border border-gray-200 object-contain p-1"
            />
          </div>
        );
      },
    },
    {
      Header: "SKU",
      accessor: "sku",
      Cell: function ({ value, row }) {
        return (
          <a className="text-indigo-500" href={row.original.url}>
            {value}
          </a>
        );
      },
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: function ({ value, row }) {
        return (
          <a className="text-indigo-500" href={row.original.url}>
            {value}
          </a>
        );
      },
    },
    {
      Header: "Published",
      // className: "text-center",
      accessor: "published",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      // className: "text-center",
      Cell: function ({ value, row }) {
        return (
          <div className="">
            <input
              type="number"
              value={parseInt(value)}
              disabled={isLoading}
              className="max-w-20"
              onChange={(e) => {
                console.log("e.target.value", e.target.value);
                const updatedChildProducts = childProducts.map((p) => {
                  if (p.id === parseInt(row.original.id)) {
                    return { ...p, quantity: e.target.value };
                  } else {
                    return p;
                  }
                });

                setChildProducts(updatedChildProducts);
              }}
            />
          </div>
        );
      },
    },
    {
      Header: "Vehicle Primary",
      accessor: function ({ vehicle_primary }) {
        // return yes or no based on vehicle_primary true or false
        return vehicle_primary ? "Yes" : "No";
      },
      className: "text-center",
      Cell: function ({ value, row }) {
        return (
          <div className="flex items-center justify-center">
            <Toggle
              checked={value === "Yes"}
              onChangeCallback={(e) => changeVehiclePrimary(e, row.original.id)}
            />
          </div>
        );
      },
    },
  ]);

  function changeVehiclePrimary(value, id) {
    // Create a new array with the changes
    const updatedChildProducts = childProducts.map((p) => {
      if (p.id === parseInt(id)) {
        // This is the child_product that was clicked, so set its vehicle_primary to the value of the checkbox, ensuring it is a boolean
        return { ...p, vehicle_primary: !!value };
      } else {
        // This is not the child_product that was clicked, so set its vehicle_primary to false
        return { ...p, vehicle_primary: false };
      }
    });

    // Set the childProducts state to the updated array
    setChildProducts(updatedChildProducts);
  }

  const [isLoading, setIsLoading] = useState(false);
  async function updateParts() {
    try {
      // only proceed if childProducts has actually changed from the original state
      if (childProducts && childProducts === product.child_products) {
        return;
      }

      setIsLoading(true);

      const result = await axios({
        method: "put",
        url: `parts`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: {
          products: childProducts,
        },
      });

      await onUpdate();

      // show toast.success if the update is successful, after we receive the result from the server
      if (result.status === 200) {
        toast.success("Parts updated");
      } else {
        toast.error("Error updating parts");
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      toast.error("Error updating parts");
    }
  }

  useEffect(async () => {
    await updateParts();
  }, [childProducts]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center justify-end pb-2 pt-5">
          <button type="button" onClick={openModal} className="btn btn-sm btn-primary">
            Add Part
          </button>
        </div>

        {childProducts && childProducts?.length > 0 && (
          <div className="w-full">
            <div className="overflow-x-auto border-b border-gray-200">
              <SortableTable
                columns={columns}
                data={childProducts}
                setData={setChildProducts}
                rowDeleteHandler={async (id) => {
                  await handleRemovePart(id);
                }}
              ></SortableTable>
            </div>
          </div>
        )}
      </div>

      <ProductSelectModal {...{ closeModal, isOpen }} recordType="Part" />
    </>
  );
}
