import React from "react";
import ExternalVehicleTranslationCardCell from "./ExternalVehicleTranslationCardCell";
import ExternalVehicleTranslationCardInputCell from "./ExternalVehicleTranslationCardInputCell";
import ExternalVehicleTranslationCardNumericInputCell from "./ExternalVehicleTranslationCardNumericInputCell";
import ExternalVehicleTranslationCardTitleCell from "./ExternalVehicleTranslationCardTitleCell";
import ExternalVehicleTranslationCardSelectCell from "./ExternalVehicleTranslationCardSelectCell";

function ExternalVehicleTranslationCardPropertyGroup({
  propertyName,
  propertyType,
  propertyPrecision,
  propertyScale,
  title,
  baseValue,
  globalTranslation,
  groupTranslation,
  vehicleTranslation,
  lookupData,
  onPropertyChange,
}) {
  const handleGlobalPropertyChange = (value) => {
    onPropertyChange(propertyName, "global_translation", value);
  };

  const handleGroupPropertyChange = (value) => {
    onPropertyChange(propertyName, "group_translation", value);
  };

  const handleVehiclePropertyChange = (value) => {
    onPropertyChange(propertyName, "vehicle_translation", value);
  };

  const editorType = (cellValue, onChangeHandler) => {
    if (propertyType === "numeric" && cellValue !== undefined) {
      return (
        <ExternalVehicleTranslationCardNumericInputCell
          cellValue={cellValue}
          precision={propertyPrecision}
          scale={propertyScale}
          onPropertyChange={onChangeHandler}
        />
      );
    } else if (lookupData && typeof cellValue === "object") {
      return (
        <ExternalVehicleTranslationCardSelectCell
          cellValue={cellValue}
          onPropertyChange={onChangeHandler}
          lookupData={lookupData}
        />
      );
    } else if (cellValue === undefined) {
      return <ExternalVehicleTranslationCardCell cellValue={undefined} />;
    } else {
      return (
        <ExternalVehicleTranslationCardInputCell
          cellValue={cellValue}
          onPropertyChange={onChangeHandler}
        />
      );
    }
  };

  const summaryValue = () => {
    let vehicleValue = null;
    let groupValue = null;
    let globalValue = null;

    if (vehicleTranslation && typeof vehicleTranslation === "object") {
      vehicleValue = vehicleTranslation.id != null ? vehicleTranslation : null;
    } else if (vehicleTranslation) vehicleValue = vehicleTranslation;

    if (groupTranslation && typeof groupTranslation === "object") {
      groupValue = groupTranslation.id ? groupTranslation : null;
    } else if (groupTranslation) groupValue = groupTranslation;

    if (globalTranslation && typeof globalTranslation === "object") {
      globalValue = globalTranslation.id ? globalTranslation : null;
    } else if (globalTranslation) globalValue = globalTranslation;

    return vehicleValue ?? groupValue ?? globalValue ?? baseValue;
  };

  return (
    <tr className="row h-100">
      <ExternalVehicleTranslationCardTitleCell cellValue={title} />
      <ExternalVehicleTranslationCardCell cellValue={baseValue} />
      {editorType(globalTranslation, handleGlobalPropertyChange)}
      {editorType(groupTranslation, handleGroupPropertyChange)}
      {editorType(vehicleTranslation, handleVehiclePropertyChange)}
      <ExternalVehicleTranslationCardCell cellValue={summaryValue()} />
    </tr>
  );
}

export default ExternalVehicleTranslationCardPropertyGroup;
