import React from "react";
import {
  faBoxesPacking,
  faUpRightFromSquare,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TrackingBadge({ trackingNumber, trackingUrl, labelStatus }) {
  if (!trackingNumber) {
    return (
      <div className="mt-2 flex items-center text-xs">
        <span className="font-semibold tracking-tight">Awaiting Booking</span>
      </div>
    );
  }

  if (labelStatus === "generated" || labelStatus === "printed") {
    return (
      <div className="mt-2 flex items-center space-x-2 text-xs">
        <TrackingNumber trackingNumber={trackingNumber} />
        <TrackingLink trackingUrl={trackingUrl} />
      </div>
    );
  }

  return <></>;
}

const TrackingNumber = ({ trackingNumber }) => {
  return <span className="font-semibold tracking-tight">{trackingNumber}</span>;
};

const TrackingLink = ({ trackingUrl }) => {
  if (!trackingUrl) {
    return null;
  }

  return (
    <a
      href={trackingUrl}
      target="_blank"
      title="View tracking details"
      className="font-medium text-indigo-500"
    >
      <FontAwesomeIcon icon={faUpRightFromSquare} className="text-indigo-500" />
    </a>
  );
};
