import React, { Fragment, useEffect, useState, useCallback, useMemo } from "react";
import HazardsModal from "./HazardsModal";

import axios from "axios";
import ReactOnRails from "react-on-rails";
import toast from "react-hot-toast";
import { Button } from "../../UI/components";
import { faDiamondExclamation } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";

export default function HazardsList({ product, onUpdate }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedHazard, setSelectedHazard] = useState(buildInitialHazard());

  function buildInitialHazard() {
    return {
      id: "",
      un_number: "",
      hazard_identifier: "",
      proper_shipping_name: "",
      amount: "",
      amount_unit: "",
      package_id: "",
      shipping_dangerous_goods_definition_id: "",
      dangerous_goods_definition: {
        id: "",
        un_number: "",
        proper_shipping_name: "",
        hazard_identifier: "",
      },
      package_hazards_attributes: product.packages.map((p) => ({
        package_id: p.id,
        package_identifier: p.identifier,
        amount: "0",
        amount_unit: "g",
      })),
    };
  }

  async function closeModal(data) {
    setIsOpen(false);

    if (data) {
      try {
        const result = await axios({
          method: "post",
          url: "/hazards",
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: {
            selected_hazard: selectedHazard?.id,
            product_id: product.id,
            ...data,
          },
        });

        toast.success("Dangerouse goods info added to product");
        setIsOpen(false);
        await onUpdate();
      } catch (e) {
        const message = e.response.data.message;
        toast.error(message);
        setIsOpen(false);
      } finally {
        setSelectedHazard(buildInitialHazard());
      }
    }
  }

  async function handleRemoveHazard(id) {
    try {
      const result = await axios({
        method: "delete",
        url: `/hazards/${id}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });
      toast.success("Hazard removed from product");

      await onUpdate();
    } catch (e) {
      console.log(e);
      toast.error("Error removing hazard from product");
    }
  }

  const editHazard = useCallback(
    (id) => {
      const hazard = product.hazards.find((p) => p.id === id);
      if (hazard) {
        setSelectedHazard(hazard);
        setIsOpen(true);
      }
    },
    [product.hazards]
  );

  async function updateHazard(data) {
    if (!selectedHazard) return;

    try {
      const result = await axios({
        method: "patch",
        url: `/hazards/${selectedHazard.id}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: data,
      });

      toast.success("Hazard updated");
      setIsOpen(false);
      setSelectedHazard(buildInitialHazard());
      await onUpdate();
    } catch (e) {
      console.log(e);
      toast.error("Error updating dangerous goods info");
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center justify-end pb-2 pt-5">
          <Button
            label="Add Dangerous Goods Info"
            icon={faDiamondExclamation}
            type="button"
            onClick={openModal}
            className="btn btn-sm"
          />
        </div>

        {product.hazards.length > 0 && (
          <div className="w-full">
            <div className="overflow-auto sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      UN Number
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Hazard Class
                    </th>
                    <th
                      scope="col"
                      className="max-w-96 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Proper Shipping Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Amount
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {product.hazards.map((p, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                      <td className="whitespace-nowrap px-6 py-4 text-xs font-medium text-gray-900">
                        {p.un_number}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-xs text-gray-500">
                        {p.hazard_identifier}
                      </td>
                      <td
                        className="max-w-96 overflow-hidden text-ellipsis whitespace-nowrap px-6 py-4 text-xs text-gray-500"
                        title={p.proper_shipping_name}
                      >
                        {p.proper_shipping_name}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-right text-xs text-gray-500">
                        {p.amount ? p.amount : " ? "}
                        {/* <span className="text-gray-400"> {p.amount_unit}</span> */}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-6 text-right text-xs font-medium">
                        <div className="btn-group">
                          <button
                            type="button"
                            onClick={() => {
                              editHazard(p.id);
                            }}
                            className="text-primary hover:text-primary"
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              handleRemoveHazard(p.id);
                            }}
                            className="text-danger-600 hover:text-danger-900"
                          >
                            Remove
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <HazardsModal
        {...{ isOpen }}
        cancelModal={() => {
          setIsOpen(false);
          setSelectedHazard(buildInitialHazard());
        }}
        closeModal={selectedHazard.id ? updateHazard : closeModal}
        options={[]}
        selectedHazard={selectedHazard}
        packages={product.packages}
      />
    </>
  );
}
