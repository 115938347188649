import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";

import { faArchive } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import VehicleSearch from "@cartbot/vehicle-search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, FormModal } from "../../UI/components";
import Table from "../../UI/components/Table";
import {
  VehiclePropFilter,
  VehicleSourceFilter,
  VehicleStatusFilter,
} from "../../Vehicles/components/Filters";

export default function VehicleSelectModal({
  closeModal,
  isOpen,
  existingVehicleIds,
  productId,
  hiddenColumns = [],
  excludeArchived = false,
}) {
  const [selectedVehicleIds, setSelectedVehicleIds] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const handleSelectedRows = useCallback((rowIds) => {
    setSelectedVehicleIds(rowIds);
  });

  const [currentSearchState, setCurrentSearchState] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: null,
    vehicleSearchIds: [],
    vehicle: {},
  });

  useEffect(() => {
    // Define the event listener function
    const handleVehicleSearchChange = async (event) => {
      // get the vehicle_ids from the event detail
      const vehicle_ids = event.detail.vehicle_ids;

      // update currentSearchState.vehiclesSearchIds
      setCurrentSearchState((prevState) => ({
        ...prevState,
        vehicleSearchIds: vehicle_ids,
        vehicle: event.detail.vehicle,
      }));
    };

    const setupEventListener = () => {
      console.log("Adding vehicle-search-change event listener");
      document.addEventListener("vehicle-search-change", handleVehicleSearchChange);
    };

    // If document is already complete, add listener immediately
    if (document.readyState === "complete") {
      setupEventListener();
    } else {
      // Otherwise, wait for the load event
      window.addEventListener("load", setupEventListener);
    }

    // Cleanup function
    return () => {
      console.log("Removing vehicle-search-change event listener");
      document.removeEventListener("vehicle-search-change", handleVehicleSearchChange);
      window.removeEventListener("load", setupEventListener);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  // create a callback called updateData that will update the values of currentSearchState from the params only if present, and then call fetchData with the updated currentSearchState
  const updateData = useCallback(({ pageIndex, pageSize, sortBy, filters, globalFilter }) => {
    setCurrentSearchState((prevState) => ({
      ...prevState,
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
      filters: filters,
      globalFilter: globalFilter,
    }));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let params = {
        page: currentSearchState.pageIndex + 1,
        per: currentSearchState.pageSize,
      };

      if (currentSearchState.sortBy && currentSearchState.sortBy?.length > 0) {
        params["sort"] = currentSearchState.sortBy
          .map((s) => `${s.id} ${s.desc ? "desc" : "asc"}`)
          .join(", ");
      }

      if (currentSearchState.globalFilter) {
        params["query"] = currentSearchState.globalFilter;
      }

      if (excludeArchived) {
        if (!currentSearchState.filters.find((f) => f.id === "status")) {
          currentSearchState.filters = [
            ...currentSearchState.filters,
            { id: "status", value: "not_archived" },
          ];
        }
      }

      if (currentSearchState.filters && currentSearchState.filters.length > 0) {
        params["filters"] = currentSearchState.filters.map((s) => `${s.id}=${s.value}`).join(",");
      }

      if (
        currentSearchState.vehicle.model &&
        currentSearchState.vehicleSearchIds &&
        currentSearchState.vehicleSearchIds.length > 0
      ) {
        params["vehicle_ids"] = currentSearchState.vehicleSearchIds;
      }

      // if no vehicle.model then pass vehicle.make as vehicle_make param
      if (currentSearchState.vehicle.make?.value && !currentSearchState.vehicle.model?.value) {
        params["make"] = currentSearchState.vehicle.make.value;
      }

      params["product_id"] = productId;

      const requestConfig = {
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        params: {
          section: "body",
        },
      };
      setLoading(true);

      const result = await fetch(
        "/vehicles/datatable.json?" + new URLSearchParams(params),
        requestConfig,
      );
      const data = await result.json();
      setData(data.data);

      setPageCount(Math.ceil(data.meta.total_count / 1));
      setTotalCount(data.meta.total_count);

      setLoading(false);
    };

    fetchData();
  }, [currentSearchState]);

  const onSubmit = () => {
    closeModal(selectedVehicleIds);
  };

  const onCancel = () => {
    closeModal(null);
  };

  const sourceColor = (source) => {
    const sources = {
      TecDoc: {
        badge: "bg-blue-100 text-blue-800",
        dot: "text-blue-400",
      },
      OSCAR: {
        badge: "bg-rose-100 text-rose-800",
        dot: "text-rose-400",
      },
      Partbot: {
        badge: "bg-indigo-100 text-indigo-800",
        dot: "text-indigo-400",
      },
      default: {
        badge: "bg-gray-100 text-gray-100",
        dot: "text-gray-400",
      },
    };
    return sources[source] || sources["default"];
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        disableFilters: true,
        disableSortBy: true,
        disableResizing: true,
        width: 30,
        id: "status",
        accessor: "status_icon",
        Cell: function ({ value, row }) {
          return (
            (row.original.status == "archived" && (
              <span className={`inline-flex items-center text-gray-300`} title="Archived">
                <FontAwesomeIcon icon={faArchive} />
              </span>
            )) ||
            (row.original.new_vehicle && (
              <span className={`inline-flex items-center`} title="New">
                <span
                  className="bg-success-100 flex h-2.5 w-2.5 items-center justify-center rounded-full"
                  aria-hidden="true"
                >
                  <span className="bg-success-400 h-1.5 w-1.5 rounded-full"></span>
                </span>
              </span>
            )) ||
            (row.original.updated_vehicle && (
              <span className={`inline-flex items-center`} title="Recently Updated">
                <span className={`inline-flex items-center`} title="Recently Updated">
                  <span
                    className="flex h-2.5 w-2.5 items-center justify-center rounded-full bg-indigo-100"
                    aria-hidden="true"
                  >
                    <span className="h-1.5 w-1.5 rounded-full bg-indigo-400"></span>
                  </span>
                </span>
              </span>
            ))
          );
        },
      },
      {
        Header: "Make",
        accessor: "make",
        disableFilters: true,
        width: 100,
      },
      {
        Header: "Model",
        accessor: "model",
        disableFilters: true,
        width: 100,
      },
      {
        Header: "Series",
        disableFilters: true,
        accessor: "series",
        width: 90,
      },
      {
        Header: "Years",
        disableFilters: true,
        id: "year_from",
        accessor: "year_range",
        width: 130,
      },
      {
        Header: "Body",
        disableFilters: true,
        accessor: "body",
        width: 80,
      },
      {
        Header: "Variant",
        disableFilters: true,
        accessor: "variant",
        width: 80,
      },
      {
        Header: "Engine",
        disableFilters: true,
        accessor: "engine",
        width: 80,
      },
      {
        Header: "Capacity (L)",
        disableFilters: true,
        accessor: "engine_capacity",
        width: 50,
        className: "text-right",
      },
      {
        Header: "Power (kW)",
        disableFilters: true,
        accessor: "kw_power",
        width: 50,
        className: "text-right",
      },
      // {
      //   Header: "Cylinders",
      //   disableFilters: true,
      //   accessor: "cylinders",
      //   width: 50,
      //   className: "text-right",
      // },
      {
        Header: "Fuel Type",
        disableFilters: true,
        accessor: "fuel_type",
        width: 50,
      },
      {
        Header: "Drive Type",
        disableFilters: true,
        accessor: "drive_type",
        width: 50,
      },
      {
        Header: "Doors",
        disableFilters: true,
        accessor: "doors",
        width: 50,
      },
      {
        Header: "Transmission",
        disableFilters: true,
        accessor: "transmission",
        width: 50,
      },
      {
        Header: "Source",
        accessor: "source",
        disableFilters: true,
        width: 90,
        Cell: function ({ value, row }) {
          let color = sourceColor(value);

          return (
            <span className={`flex justify-start text-[11px] text-gray-500`}>
              <span className={`mr-1.5 inline-flex items-center`}>
                <svg
                  className={`mr-1.5 h-1.5 w-1.5 ${color.dot}`}
                  fill="currentColor"
                  viewBox="0 0 8 8"
                >
                  <circle cx={4} cy={4} r={3} />
                </svg>
                {value}
              </span>
              <span className="`inline-flex items-center">
                {row.original.tecdoc_id || row.original.autoinfo_id}
              </span>
            </span>
          );
        },
      },
    ],
    [],
  );

  const shownColumns = columns.filter((column) => hiddenColumns.indexOf(column.accessor) === -1);
  const showSourceFilter = hiddenColumns.indexOf("source") === -1;

  return (
    <FormModal title="Select Vehicles" {...{ onCancel, isOpen }} size="full">
      <div className="z-20 my-4">
        {/* TODO: use current team apiKey or just modify component verification to work on localhost and *.partbot.io domains without apiKey */}
        <VehicleSearch
          apiKey="pb_868d33e4423a20a949c9a229e45adf81"
          showBranding={false}
          showLabels={false}
          fields="body,series,variant,engine_capacity,engine"
          callback={false}
          modifyQueryString={false}
        />
      </div>
      <div className="my-4">
        <Table
          columns={shownColumns}
          data={data}
          onFetchData={updateData}
          onSelectedRows={handleSelectedRows}
          loading={loading}
          pageCount={pageCount}
          totalItems={totalCount}
          initialPageSize={10}
          useRowSelection
          globalFilterPlaceholder="Keyword Search..."
          isRowDisabled={(row) => existingVehicleIds.includes(row.id)}
          rowProps={(row) => ({
            className: row.original.status === "archived" ? "text-gray-300" : "",
          })}
          additionalFilters={[
            (props) => (
              <>
                <VehicleStatusFilter {...props} excludeArchived={excludeArchived} />
                {showSourceFilter && <VehicleSourceFilter {...props} />}
              </>
            ),
          ]}
        />
      </div>

      <div className="btn-group mt-4 justify-end">
        <Button label="Cancel" type="button" className="btn btn-neutral" onClick={onCancel} />
        <Button
          label={`Add ${selectedVehicleIds.length} Vehicles`}
          type="button"
          className="btn"
          onClick={onSubmit}
          disabled={selectedVehicleIds.length === 0}
        />
      </div>
    </FormModal>
  );
}
