import React, { useEffect } from "react";
import { connectStateResults } from "react-instantsearch-dom";

/**
 * TODO: Move all search logic and state in this component
 *  Dashboard should look something like:
 *  <InstantSearch>
 *   <Configure>
 *   <ProductSearch />
 *   <ProductHits />
 *   <Pagination />
 * </InstantSearch>
 */
export const ProductSearch = connectStateResults(
  ({ searchState, searchResults, props }) => {
    const { onChange } = props;
    useEffect(() => {
      onChange(searchState);
    }, [searchState]);

    return <></>;
  }
);
