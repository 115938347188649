import React from "react";
import { useState } from "react";
import toast from "react-hot-toast";

import { faUndo } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import {
  faCheckSquare,
  faPencilAlt,
  faPlus,
  faQuestion,
  faTimesSquare,
  faTrashAlt,
} from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

import { Button } from "../../UI/components";

export default function HistoryList({ product, onUpdate }) {
  function isHtml(str) {
    const regex = /<\/?[a-z][\s\S]*>/i;
    return regex.test(str);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [revertingAuditId, setRevertingAuditId] = useState(null);

  const revertAudit = async (auditId) => {
    console.log("revertAudit", auditId);

    try {
      setRevertingAuditId(auditId);
      setIsLoading(true);

      const result = await axios({
        method: "post",
        url: `audits/${auditId}/revert`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      await onUpdate();

      // show toast.success if the update is successful, after we receive the result from the server
      if (result.status === 200) {
        toast.success("Reverted change");
      } else {
        toast.error("Error reverting change");
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Unexpected error occured");
    }
  };

  console.log("product.history", product.history);

  return (
    <div className="flow-root py-5">
      <ul role="list" className="-mb-8">
        {product.history.map(
          (item, itemIdx) =>
            (item.changes.length > 0 || item.comment) && (
              <li key={itemIdx}>
                <div className="relative pb-8">
                  {itemIdx !== product.history.length - 1 ? (
                    <span
                      className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex items-start space-x-3">
                    <>
                      <div className="relative px-1">
                        <Button
                          onClick={() => revertAudit(item.id)}
                          size="icon"
                          showLoading={isLoading && revertingAuditId === item.id}
                          className="hover:bg-warning-400 flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white hover:text-white"
                          title="Revert this change"
                          icon={
                            item.action == "create"
                              ? faPlus
                              : item.action == "update"
                                ? faPencilAlt
                                : item.action == "destroy"
                                  ? faTrashAlt
                                  : faQuestion
                          }
                          hoverIcon={faUndo}
                        />
                      </div>
                      <div className="min-w-0 flex-1">
                        <div>
                          <div className="text-sm">
                            <a
                              // href={item.person.href}
                              className="font-medium text-gray-900"
                            >
                              {item.user || "Partbot API"}
                            </a>
                          </div>
                          <p className="mt-0.5 text-xs text-gray-500">on {item.created_at}</p>
                        </div>
                        <div className="mt-2 break-all text-sm text-gray-700">
                          <ul className="space-y-3">
                            {/* loop through each item.changes and return a <li> element containing the change, just {change} */}
                            <li>
                              {item.comment && (
                                <div className="bg-warning-100 text-warning-800 inline-block rounded-md px-2 py-1 text-xs tracking-wide">
                                  {item.comment}
                                </div>
                              )}
                            </li>
                            {item.changes.map((audit, index) => {
                              // check if new or old value exists, create a new constant to store the value that exists
                              const detail_value = audit.details?.new || audit.details?.old;

                              return (
                                <li key={index} className="w-full space-y-3">
                                  {audit.details?.old && audit.details?.new ? (
                                    <>
                                      <div className="flex items-center gap-3">
                                        <div className="font-bold">{audit.change}</div>
                                      </div>
                                      <div className="grid grid-cols-2 gap-2">
                                        <div className="">
                                          <div className="bg-danger-100 text-danger-800 inline-block rounded-t-lg px-3 py-2 text-xs font-bold uppercase tracking-wider">
                                            From
                                          </div>
                                          {/* check if old is html if not just render the value */}
                                          {isHtml(audit.details?.old) ? (
                                            <div
                                              className="prose prose-sm bg-danger-50 text-danger-800 w-full overflow-auto break-words rounded-lg rounded-tl-none p-4 text-xs"
                                              dangerouslySetInnerHTML={{
                                                __html: audit.details?.old,
                                              }}
                                            />
                                          ) : (
                                            <div className="prose prose-sm bg-danger-50 text-danger-800 w-full overflow-auto break-words rounded-lg rounded-tl-none p-4 text-xs">
                                              {Array.isArray(audit.details?.old)
                                                ? audit.details.old.join(", ")
                                                : audit.details?.old}
                                            </div>
                                          )}
                                        </div>
                                        <div className="">
                                          <div className="bg-success-100 text-success-800 inline-block rounded-t-lg px-3 py-2 text-xs font-bold uppercase tracking-wider">
                                            To
                                          </div>
                                          {/* check if new is html, if not just render the value */}
                                          {isHtml(audit.details?.new) ? (
                                            <div
                                              className="prose prose-sm bg-success-50 text-success-800 w-full overflow-auto rounded-lg rounded-tl-none p-4 text-xs"
                                              dangerouslySetInnerHTML={{
                                                __html: audit.details?.new,
                                              }}
                                            />
                                          ) : (
                                            <div className="prose prose-sm bg-success-50 text-success-800 w-full overflow-auto rounded-lg rounded-tl-none p-4 text-xs">
                                              {Array.isArray(audit.details?.new)
                                                ? audit.details.new.join(", ")
                                                : audit.details?.new}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    detail_value !== "" && (
                                      <>
                                        {isHtml(detail_value) ? (
                                          <>
                                            <div className="font-bold">{audit.change}</div>
                                            <div
                                              className="prose prose-sm inline-block rounded-lg bg-gray-50 p-4 text-xs text-gray-800"
                                              dangerouslySetInnerHTML={{
                                                __html: detail_value,
                                              }}
                                            />
                                          </>
                                        ) : // check if detail_value is a boolean, if true render a check, if false render an x

                                        typeof detail_value === "boolean" ? (
                                          detail_value === true ? (
                                            <div className="flex items-center space-x-3">
                                              <div className="flex items-center gap-3">
                                                <div className="font-bold">{audit.change}</div>
                                                {audit.comment && (
                                                  <div className="inline-block rounded bg-gray-100 px-3 py-1.5 text-xs text-gray-800">
                                                    {audit.comment}
                                                  </div>
                                                )}
                                              </div>
                                              <FontAwesomeIcon
                                                icon={faCheckSquare}
                                                className="text-success-600 text-lg"
                                              />
                                            </div>
                                          ) : (
                                            <div className="flex items-center space-x-3">
                                              <div className="font-bold">{audit.change}</div>
                                              <FontAwesomeIcon
                                                icon={faTimesSquare}
                                                className="text-danger-600 text-lg"
                                              />
                                            </div>
                                          )
                                        ) : (
                                          <div className="space-y-2">
                                            <div className="font-bold">{audit.change}</div>
                                            <div className="bg-info-100 text-info-800 inline-block rounded px-3 py-1.5 text-xs">
                                              {detail_value}
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </li>
            ),
        )}
      </ul>
    </div>
  );
}
