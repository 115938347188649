import React, { useEffect, useState } from "react";
import { Button, FormModal } from "../UI/components";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import AttributeCombobox from "../UI/components/AttributeCombobox";
import ProductTypeCombobox from "../UI/components/ProductTypeCombobox";
import { SortableTable } from "../UI/components/Table/SortableTable";
import { useMemo } from "react";
import ProductAttributesModal from "../Products/components/ProductAttributesModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@awesome.me/kit-989a8e6dbe/icons/classic/light";

export default function BrandTemplates({ brand, product_attributes_templates }) {
  const [currentAttributeTemplate, setCurrentAttributeTemplate] = useState(null);
  const [attributeTemplates, setAttributeTemplates] = useState(product_attributes_templates);

  const updateTabRoute = (index) => {
    // get the tab object from tabs using index
    const tab = Object.values(tabs)[index];

    // update the window.location hash to use the tab display value to lowercase and replace spaces with dashes
    console.log("tab", tab);
    window.location.hash = tab.display.toLowerCase().replace(" ", "-");
  };

  const tabData = {
    attributes: {
      display: "Product Attribute Templates",
      content: <div>Attributes</div>,
    },
  };

  useEffect(() => {
    // check for hash in window location and set the tab index to the index of the tab with the display value matching the hash
    const hash = window.location.hash.replace("#", "");
    const tabIndex = Object.values(tabs).findIndex(
      (tab) => tab.display.toLowerCase().replace(" ", "-") == hash
    );

    if (tabIndex > -1) {
      setTabIndex(tabIndex);
    }
  }, []);

  const [tabs, setTabs] = useState(tabData);
  const [tabIndex, setTabIndex] = useState(0);

  const createNewAttributeTemplate = () => {
    setCurrentAttributeTemplate({
      id: null,
      product_type: "",
      template_attributes: [],
    });
  };

  return (
    <>
      <div>
        <div className="flex items-center justify-between border-b pb-5">
          <h2 className="text-2xl font-bold">Brand Templates</h2>
        </div>
        <div className="py-5">
          <div className="relative w-full overflow-x-scroll rounded-lg bg-white px-5 pb-5 pt-1 shadow">
            <Tab.Group onChange={updateTabRoute} selectedIndex={tabIndex} defaultIndex={tabIndex}>
              <div className="border-b border-gray-200">
                <Tab.List className="tabs-menu -mb-px flex space-x-6" aria-label="Tabs">
                  {Object.keys(tabs).map((tab) => (
                    <Tab
                      key={tab}
                      disabled={tabs[tab].disabled}
                      className={({ selected, disabled }) =>
                        clsx(
                          "flex items-center whitespace-nowrap border-b-2 px-1 py-2 text-xs font-medium focus-visible:outline-0",
                          selected
                            ? "is-active border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                          tabs[tab].disabled ? "hidden" : ""
                        )
                      }
                    >
                      {({ selected }) => (
                        <>
                          {tabs[tab].display}
                          {tabs[tab].count ? (
                            <span
                              className={classNames(
                                selected
                                  ? "bg-indigo-100 text-indigo-600"
                                  : "bg-gray-100 text-gray-900",
                                "-mt-0.5 ml-1.5 hidden rounded-full px-2 py-0.5 text-[10px] font-medium md:inline-block"
                              )}
                            >
                              {tabs[tab].count}
                            </span>
                          ) : null}
                        </>
                      )}
                    </Tab>
                  ))}
                </Tab.List>
              </div>

              <Tab.Panels className="mt-2 py-8">
                <Tab.Panel>
                  <div className="">
                    {/* show a Create New Template button in the top right */}
                    <div className="mb-5 flex items-center justify-between">
                      <div>
                        {/* if no attribute_templates show the text "No templates exist, start by creating a new template" */}

                        {(!attributeTemplates ||
                          (attributeTemplates && attributeTemplates.length === 0)) && (
                          <p className="text-sm text-gray-500">
                            No templates exist, start by creating a new template
                          </p>
                        )}
                      </div>
                      <div className="btn-group">
                        <Button
                          label="Create New Template"
                          className="btn-sm"
                          onClick={() => createNewAttributeTemplate()}
                        />
                      </div>
                    </div>
                    {/* display all attribute templates, identified by product_type */}
                    {attributeTemplates &&
                      attributeTemplates.map((template) => (
                        <div key={template.id} className="flex flex-col border-t py-5">
                          <div className="flex items-center justify-between">
                            <h3 className="text-sm font-medium">{template.product_type.title}</h3>
                            <div className="btn-group">
                              <Button
                                label="Edit"
                                className="btn-sm"
                                onClick={() => {
                                  setCurrentAttributeTemplate(template);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
      <Toaster />
      <ProductAttributeTemplateEditorModal
        isOpen={currentAttributeTemplate ? true : false}
        template={currentAttributeTemplate}
        onCancel={() => {
          setCurrentAttributeTemplate(null);
        }}
        onSubmit={async ({ productType, attributes }) => {
          if (brand.id) {
            try {
              const method = currentAttributeTemplate.id ? "patch" : "post";
              const url = currentAttributeTemplate.id
                ? `/admin/brands/${brand.id}/product-attributes-template/${currentAttributeTemplate.id}`
                : `/admin/brands/${brand.id}/product-attributes-template`;

              const result = await axios({
                method: method,
                url: url,
                responseType: "json",
                headers: ReactOnRails.authenticityHeaders(),
                data: {
                  product_attribute_template_id: currentAttributeTemplate.id,
                  product_type_id: productType.id,
                  template_attributes: attributes,
                },
              });

              setAttributeTemplates(result.data.product_attributes_templates);

              toast.success(`Template ${currentAttributeTemplate.id ? "updated" : "created"}`);
              // location.reload();
            } catch (e) {
              console.log("ERROR", e);
              toast.error(`There was an error  the brand`);
            }
          }

          setCurrentAttributeTemplate(null);
        }}
      />
    </>
  );
}

// create a modal component called ProductAttributeTemplateEditorModal
// this modal will be used to create and edit product attribute templates

const ProductAttributeTemplateEditorModal = ({ template, isOpen = false, onCancel, onSubmit }) => {
  const [selectedAttributeTitle, setSelectedAttributeTitle] = useState(null);
  const [loading, setLoading] = useState(false);

  const [productType, setProductType] = useState("");

  const [attributes, setAttributes] = useState([]);
  const [showNewAttributeForm, setShowNewAttributeForm] = useState(false);

  useEffect(() => {
    if (template) {
      setLoading(false);
      setProductType(template.product_type);
      setAttributes(template.template_attributes);
    }
  }, [template]);

  const addAttribute = () => {
    console.log("addAttribute", selectedAttributeTitle);
    if (selectedAttributeTitle) {
      // do not add if already exists
      if (attributes.find((a) => a.id === selectedAttributeTitle.id)) {
        return;
      }

      setAttributes([...attributes, selectedAttributeTitle]);
      setSelectedAttributeTitle(null);
    }
  };

  const onAttributeChange = (attribute) => {
    console.log("onAttributeChange", attribute);

    setSelectedAttributeTitle(attribute);
    // if attribute.id is null, we need to create a new attribute
    if (attribute && attribute.id === null) {
      console.log("we will create a new attribute for", attribute);

      setShowNewAttributeForm(true);
      return;
    }
  };

  const columns = useMemo(() => [
    {
      Header: "Attribute",
      accessor: "name",
      Cell: function ({ value, row }) {
        return <div className="text-gray-500">{row.original.name}</div>;
      },
    },
  ]);

  const createNewAttribute = async ({ attributeTitle, dataType, values }) => {
    return fetch(`/admin/attributes`, {
      method: "POST",
      headers: {
        ...ReactOnRails.authenticityHeaders(),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        attribute_title: {
          name: attributeTitle.name,
          data_type: dataType,
          values: values,
        },
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("createNewAttribute result", result);
        setSelectedAttributeTitle(result.attribute_title);
        setShowNewAttributeForm(false);

        // add the new attribute to the attributes array
        setAttributes([...attributes, result.attribute_title]);

        return result;
      });
  };

  return (
    <>
      <FormModal title="New Attribute Template" {...{ onCancel, isOpen }}>
        <div className="my-4">
          <div className="flex items-center gap-3">
            <div className="w-full">
              <ProductTypeCombobox
                selectedOption={productType}
                handleProductTypeSelect={setProductType}
              />
            </div>
          </div>
        </div>
        <div className="mt-6 flex w-full items-end gap-3">
          <div className="flex-shrink flex-grow">
            <AttributeCombobox
              selectedOption={selectedAttributeTitle}
              handleAttributeSelect={(e) => {
                onAttributeChange(e);
              }}
            />
          </div>
          {selectedAttributeTitle && !showNewAttributeForm && (
            <div className="w-auto">
              <Button label="Add Attribute" onClick={addAttribute} />
            </div>
          )}
        </div>

        {/* Show New Atribute Form */}
        {showNewAttributeForm && (
          <div>
            <NewAttributeForm
              attribute={selectedAttributeTitle}
              onSubmit={({ data_type, values }) => {
                createNewAttribute({
                  attributeTitle: selectedAttributeTitle,
                  dataType: data_type,
                  values: values,
                });
              }}
              onCancel={() => {
                setShowNewAttributeForm(false);
              }}
            />
          </div>
        )}

        {attributes && attributes.length > 0 && (
          <div className="mt-4 w-full">
            <div
              id="sort-table-container"
              className="relative overflow-x-auto border-b border-gray-200"
            >
              <SortableTable
                columns={columns}
                data={attributes}
                setData={setAttributes}
                rowDeleteHandler={(id) => {
                  console.log("rowDeleteHandler", id);
                  setAttributes(attributes.filter((a) => a.id !== id));
                }}
              ></SortableTable>
            </div>
          </div>
        )}
        <div className="btn-group mt-8 flex justify-end">
          <Button label="Cancel" type="button" className="btn btn-neutral" onClick={onCancel} />
          <Button
            label={loading ? "Saving..." : "Save"}
            showLoading={loading}
            type="button"
            className="btn"
            onClick={() => {
              setLoading(true);
              onSubmit({ productType: productType, attributes: attributes });
            }}
          />
        </div>
      </FormModal>
    </>
  );
};

const DATA_TYPES = [
  { label: "Text", value: "alphanumeric" },
  { label: "Numeric", value: "numeric" },
  { label: "List of options", value: "lookup" },
  { label: "True or false", value: "boolean" },
  { label: "No value", value: "without value" },
];

// create a NewAttributeForm component
const NewAttributeForm = ({ onSubmit, onCancel }) => {
  const [attributeDataType, setAttributeDataType] = useState(DATA_TYPES[0].value);
  const [attributeValues, setAttributeValues] = useState([]);
  const [attributeValueInput, setAttributeValueInput] = useState("");

  const [loading, setLoading] = useState(false);

  const handleAddValue = () => {
    setAttributeValues([...attributeValues, attributeValueInput]);
    setAttributeValueInput("");
  };

  const handleRemoveValue = (value) => {
    setAttributeValues(attributeValues.filter((v) => v !== value));
  };

  const handleSubmit = () => {
    setLoading(true);
    onSubmit({
      data_type: attributeDataType,
      values: attributeValues,
    });
  };

  return (
    <div className="mt-2 rounded-md border border-indigo-300 p-3">
      <div className="mb-4">
        <label>Data Type</label>
        <select
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          value={attributeDataType}
          onChange={(event) => setAttributeDataType(event.target.value)}
        >
          {DATA_TYPES.map((dataType) => (
            <option key={dataType.value} value={dataType.value}>
              {dataType.label}
            </option>
          ))}
        </select>
      </div>

      {attributeDataType === "lookup" && (
        <div className="my-4">
          <label>Values</label>
          <div className="flex items-center">
            <input
              type="text"
              className="form-input"
              value={attributeValueInput}
              onChange={(event) => setAttributeValueInput(event.target.value)}
            />
            <button
              type="button"
              className="btn btn-primary ml-2"
              onClick={handleAddValue}
              disabled={attributeValueInput === ""}
            >
              Add
            </button>
          </div>
          <div className="my-4">
            {attributeValues.map((value) => (
              <div
                key={value}
                className="flex items-center justify-between gap-2 border-b border-gray-200 py-2"
              >
                <div className="text-xs">{value}</div>
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  onClick={() => handleRemoveValue(value)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="h-3 w-3" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="btn-group mt-4 flex justify-end">
        <button type="button" className="btn btn-sm btn-neutral" onClick={onCancel}>
          Cancel
        </button>
        <Button
          label={loading ? "Saving..." : "Create Attribute"}
          showLoading={loading}
          type="button"
          className="btn btn-sm"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
