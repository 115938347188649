import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import { faMessageExclamation } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab } from "@headlessui/react";
import axios from "axios";

import { classNames } from "../../../utils";
import { Button } from "../../UI/components/Button";
import { Form } from "../../UI/components/Controls";
import AssembliesList from "./AssembliesList";
import CrossSellProductList from "./CrossSellProductList";
import HazardsList from "./HazardsList";
import HistoryList from "./HistoryList";
import PackagingList from "./PackagingList";
import PartsList from "./PartsList";
import ProductAttributesList from "./ProductAttributesList";
import ProductDetails from "./ProductDetails";
import ProductIssueList from "./ProductIssueList";
import ProductMedia from "./ProductMedia";
import ProductSecondaryMenu from "./ProductSecondaryMenu";
import ProductSettings from "./ProductSettings";
import VehicleApplicationsList from "./VehicleApplicationsList";

export default function ProductEditor({
  initialProduct,
  product_types,
  brands,
  attributes,
  attribute_template_url,
  package_types,
  cross_sell_settings,
  user,
  apiKey,
}) {
  console.log("initialProduct", initialProduct.universal_fitment);
  const tabData = () => ({
    details: {
      display: "Details",
      handle: "details",
      content: <ProductDetails {...{ product, product_types, brands, user, apiKey }} />,
    },
    media: {
      display: "Media",
      handle: "media",
      disabled: !product.id,
      count: product.product_media.length + product.product_charts.length,
      content: <ProductMedia product={product} onUpdate={refreshMediaAndCharts} />,
    },
    attributes: {
      display: "Attributes",
      handle: "attributes",
      disabled: !product.id,
      count: product.product_attributes.length,
      content: <ProductAttributesList product={product} templateUrl={attribute_template_url} />,
    },
    vehicles: {
      display: "Vehicles",
      handle: "vehicles",
      disabled: !product.id,
      count: product.vehicle_count,
      content: (
        <VehicleApplicationsList
          product={product}
          attributes={attributes}
          onUpdate={refreshVehicleApplications}
        />
      ),
    },
    shiping_and_handling: {
      display: "Shipping & Handling",
      handle: "shipping_and_handling",
      disabled: !product.id,
      count: product.packages.length + product.hazards.length,
      content: (
        <div className="space-y-5 divide-y divide-gray-200 sm:space-y-4">
          <PackagingList
            product={product}
            package_types={package_types}
            onUpdate={refreshPackages}
          />
          <HazardsList product={product} onUpdate={refreshHazards} />
        </div>
      ),
    },
    promotional: {
      display: "Cross-sell",
      handle: "promotional",
      disabled: !product.id,
      count: product.cross_sell_products.length,
      content: (
        <CrossSellProductList
          product={product}
          cross_sell_products={product.cross_sell_products}
          onUpdate={refreshCrossSellProducts}
          settings={cross_sell_settings}
        />
      ),
    },
    parts: {
      display: "Parts",
      handle: "parts",
      disabled: product.type == "Part" || !product.id,
      count: product.child_products.length,
      content: <PartsList product={product} onUpdate={refreshParts} />,
    },
    assemblies: {
      display: "Assemblies",
      handle: "assemblies",
      disabled: product.type == "Assembly" || !product.id,
      count: product.parent_products.length,
      content: <AssembliesList product={product} />,
    },
    settings: {
      display: "Settings",
      handle: "settings",
      disabled: !product.id,
      content: <ProductSettings product={product} user={user} />,
    },
    history: {
      display: "History",
      handle: "history",
      disabled: !product.id,
      content: <HistoryList product={product} onUpdate={refreshHistory} />,
    },
  });

  // console.log("initialProduct", initialProduct);

  const [product, setProduct] = useState(initialProduct);
  const [formLoading, setFormLoading] = useState(false);
  const [productIssueSlideOpen, setProductIssueSlideOpen] = useState(false);
  const [tabs, setTabs] = useState(tabData());
  const [tabIndex, setTabIndex] = useState(0);

  const onSubmit = async (data) => {
    // Add catalogue notes to vehicle applications
    for (let i = 0; i < data["vehicle_applications"].length; i++) {
      data["vehicle_applications"][i]["fitment_notes"] =
        data[`va_fitment_notes_${data["vehicle_applications"][i].id}`];

      delete data[`va_fitment_notes_${data["vehicle_applications"][i].id}`];
    }

    setFormLoading(true);
    const formData = new FormData();

    // filter out undefined, null and NaN values
    const filteredData = Object.entries(data).filter(
      ([key, value]) =>
        value !== undefined && value !== "undefined" && value === value && value !== null,
    );

    // Add data to form data, handling arrays and nested objects, and ignoring certain keys
    filteredData.forEach(([key, value]) => {
      switch (key) {
        case "document_file":
        case "product_image_file":
          if (value && value.length > 0) {
            formData.append("product[" + key + "]", value[0]);
          }
          break;
        case "vehicle_applications":
          value.forEach((vaa, index) => {
            for (const k in vaa) {
              formData.append(`product[${key}][${index}][${k}]`, vaa[k]);
            }
          });
          break;
        case "document":
        case "history":
        case "product_image":
          break;
        case "catalogue_notes":
          formData.append("product[catalogue_notes]", value);
          break;
        default:
          formData.append("product[" + key + "]", value);
      }
    });

    if (product.id) {
      try {
        const result = await axios({
          method: "patch",
          url: `/b/${product.brand.slug}/products/${product.slug}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });

        if (result.data.errors) {
          toast.error(result.data.errors.join("\n"));
          setFormLoading(false);
          return;
        }

        toast.success("Product updated");
        location.reload();
      } catch (e) {
        if (e.response) {
          console.log(e.response.data); // Log the server's response data
          const errorMessage = `Unable to save product:\n${e.response.data.errors?.join("\n") || "Unknown error"}`;
          toast.error(errorMessage);
        }
        setFormLoading(false);
      }
    } else {
      try {
        const result = await axios({
          method: "post",
          url: `/b/${product.brand.slug}/products/`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });

        toast.success("Product created");
        location.replace(`/b/${product.brand.slug}/products/${result.data.slug}/edit`);
      } catch (e) {
        console.log("ERROR", e);
        toast.error("There was an error creating the product.");
        setFormLoading(false);
      }
    }
  };

  const refreshCrossSellProducts = async () => {
    try {
      const result = await axios({
        method: "get",
        url: "cross_sell_products",
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      setProduct((prev) => ({
        ...prev,
        cross_sell_products: result.data,
      }));
    } catch {
      toast.error("Could not refresh Cross-sell data");
    }
  };

  const refreshVehicleApplications = async () => {
    try {
      const result = await axios({
        method: "get",
        url: "vehicle_applications",
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      // setProducts by updating the title with result.data.product_title and the vehicle_applications with result.data.vehicle_applications, but update the entire product object
      setProduct((prev) => ({
        ...prev,
        title: result.data.product_title,
        vehicle_applications: result.data.vehicle_applications,
      }));

      console.log("updated product with", result.data.product_title);
    } catch {
      toast.error("Could not refresh Vehicle Application data");
    }
  };

  const refreshProductAttributes = async () => {
    try {
      const result = await axios({
        method: "get",
        url: "product_attributes",
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      setProduct((prev) => ({
        ...prev,
        product_attributes: result.data,
      }));
    } catch {
      toast.error("Could not refresh Attribute data");
    }
  };

  const refreshPackages = async () => {
    try {
      const result = await axios({
        method: "get",
        url: "packages",
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      setProduct((prev) => ({
        ...prev,
        packages: result.data,
      }));
    } catch {
      toast.error("Could not refresh Packages data");
    }

    await refreshHazards();
  };

  const refreshHazards = async () => {
    try {
      const result = await axios({
        method: "get",
        url: "hazards",
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      setProduct((prev) => ({
        ...prev,
        hazards: result.data,
      }));
    } catch {
      toast.error("Could not refresh Dangerous goods data");
    }
  };

  const refreshParts = async () => {
    try {
      const result = await axios({
        method: "get",
        url: "parts",
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      setProduct((prev) => ({
        ...prev,
        child_products: result.data,
      }));
    } catch {
      toast.error("Could not refresh Parts data");
    }
  };

  const refreshMedia = async () => {
    try {
      const result = await axios({
        method: "get",
        url: `media`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      setProduct((prev) => ({
        ...prev,
        product_media: result.data.product_media,
      }));
    } catch {
      toast.error("Could not refresh Media data");
    }
  };

  const refreshCharts = async () => {
    try {
      const result = await axios({
        method: "get",
        url: `charts`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      setProduct((prev) => ({
        ...prev,
        product_charts: result.data.product_charts,
      }));
    } catch {
      toast.error("Could not refresh Charts data");
    }
  };

  const refreshMediaAndCharts = async () => {
    await Promise.allSettled([refreshMedia(), refreshCharts()]);
  };

  const refreshProductIssues = async () => {
    try {
      const result = await axios({
        method: "get",
        url: `/product-issues/${product.id}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      setProduct((prev) => ({
        ...prev,
        product_issues: result.data.product_issues,
      }));
    } catch {
      toast.error("Could not refresh Product Issues");
    }
  };

  const refreshHistory = async () => {
    try {
      const result = await axios({
        method: "get",
        url: `audits`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      setProduct((prev) => ({
        ...prev,
        history: result.data,
      }));
    } catch (e) {
      console.log("Error refreshing history", e);
      toast.error("Could not refresh history");
    }
  };

  useEffect(() => {
    setTabs(tabData());
  }, [product]);

  const pages = [
    {
      name: "Products",
      href: `/b/${product.brand.slug}/products`,
      current: false,
    },
    {
      name: product.id ? product.sku : "New",
      href: product.id ? `edit` : `new`,
      current: true,
    },
  ];

  const onCloseSlide = async () => {
    setProductIssueSlideOpen(false);
  };

  const updateTabRoute = (index) => {
    // get the tab object from tabs using index
    const tab = Object.values(tabs)[index];

    // update the window.location hash to use the tab display value to lowercase
    window.location.hash = tab.handle.toLowerCase();
  };

  useEffect(() => {
    // check for hash in window location and set the tab index to the index of the tab with the display value matching the hash
    const hash = window.location.hash.replace("#", "");
    const tabIndex = Object.values(tabs).findIndex((tab) => tab.handle.toLowerCase() == hash);

    if (tabIndex > -1) {
      setTabIndex(tabIndex);
    }
  }, [tabs]);

  // listen for hash changes and update the tab index
  useEffect(() => {
    window.addEventListener("hashchange", () => {
      const hash = window.location.hash.replace("#", "");
      const tabIndex = Object.values(tabs).findIndex((tab) => tab.handle.toLowerCase() == hash);

      if (tabIndex > -1) {
        setTabIndex(tabIndex);
      }
    });
  }, []);

  return (
    <>
      <Form onSubmit={onSubmit} defaultValues={product}>
        <div className="relative">
          <div className="flex items-center justify-between border-b pb-5">
            <nav className="flex" aria-label="Breadcrumb">
              <ol role="list" className="flex items-center space-x-4">
                <li>
                  <div>
                    <a href="/dashboard" className="text-gray-400 hover:text-gray-500">
                      <i className="fas fa-home h-5 w-5 flex-shrink-0" aria-hidden="true"></i>
                      <span className="sr-only">Home</span>
                    </a>
                  </div>
                </li>
                {pages.map((page) => (
                  <li key={page.name}>
                    <div className="flex items-center">
                      <svg
                        className="h-5 w-5 flex-shrink-0 text-gray-300"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <a
                        href={page.href}
                        className={`ml-4 text-sm font-medium ${
                          page.current
                            ? "text-indigo-500 hover:text-indigo-700"
                            : "text-gray-500 hover:text-gray-700"
                        }`}
                        aria-current={page.current ? "page" : undefined}
                      >
                        {page.name}
                      </a>
                    </div>
                  </li>
                ))}
                {product.product_issues.length > 0 && (
                  <li>
                    <div>
                      <a
                        onClick={() => setProductIssueSlideOpen(true)}
                        className={
                          product.product_issues.filter((x) => x["resolved"] === false).length > 0
                            ? "text-danger-500 hover:text-danger-700"
                            : "text-gray-400 hover:text-gray-500"
                        }
                      >
                        <FontAwesomeIcon icon={faMessageExclamation} className="mt-1 h-5 w-5" />
                        <span className="sr-only">Product Issues</span>
                      </a>
                    </div>
                  </li>
                )}
              </ol>
            </nav>

            <div className="flex items-center">
              <div className="mx-2">
                {product.published ? (
                  <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-600">
                    <svg
                      className="text-success-400 mr-1.5 h-2 w-2"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    PUBLISHED
                  </span>
                ) : (
                  <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-400">
                    <svg
                      className="mr-1.5 h-2 w-2 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    UNPUBLISHED
                  </span>
                )}
              </div>
              <div className="btn-group flex items-center">
                <ProductSecondaryMenu
                  product={product}
                  onUpdate={[
                    refreshCrossSellProducts,
                    refreshProductAttributes,
                    refreshVehicleApplications,
                    refreshPackages,
                    refreshHazards,
                    refreshMedia,
                    refreshCharts,
                    refreshProductIssues,
                  ]}
                />
                <a href={`/b/${product.brand.slug}/products`} className="btn btn-neutral">
                  Cancel
                </a>
                <Button label="Save" type="submit" className="btn" showLoading={formLoading} />
              </div>
            </div>
          </div>
          <div className="py-5">
            <div className="relative w-full overflow-x-scroll rounded-lg bg-white px-5 pb-5 pt-1 shadow">
              <Tab.Group onChange={updateTabRoute} selectedIndex={tabIndex} defaultIndex={tabIndex}>
                <div className="border-b border-gray-200">
                  <Tab.List className="tabs-menu -mb-px flex space-x-6" aria-label="Tabs">
                    {Object.keys(tabs).map((tab) => (
                      <Tab
                        key={tab}
                        disabled={tabs[tab].disabled}
                        className={({ selected, disabled }) =>
                          classNames(
                            "flex items-center whitespace-nowrap border-b-2 px-1 py-2 text-xs font-medium focus-visible:outline-0",
                            selected
                              ? "is-active border-indigo-500 text-indigo-600"
                              : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                            tabs[tab].disabled ? "hidden" : "",
                          )
                        }
                      >
                        {({ selected }) => (
                          <>
                            {tabs[tab].display}
                            {tabs[tab].count ? (
                              <span
                                className={classNames(
                                  selected
                                    ? "bg-indigo-100 text-indigo-600"
                                    : "bg-gray-100 text-gray-900",
                                  "-mt-0.5 ml-1.5 hidden rounded-full px-2 py-0.5 text-[10px] font-medium md:inline-block",
                                )}
                              >
                                {tabs[tab].count}
                              </span>
                            ) : null}
                          </>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>

                <Tab.Panels className="mt-2">
                  {Object.keys(tabs).map((tab) => (
                    <Tab.Panel key={tab}>{tabs[tab].content}</Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
        <div className="btn-group flex justify-end pb-16">
          <a href={`/b/${product.brand.slug}/products`} className="btn btn-neutral">
            Cancel
          </a>
          <Button label="Save" type="submit" className="btn" showLoading={formLoading} />
        </div>
      </Form>
      <ProductIssueList
        onClose={onCloseSlide}
        isOpen={productIssueSlideOpen}
        productIssues={product.product_issues}
        refreshProductIssues={refreshProductIssues}
      />
      <Toaster />
    </>
  );
}
