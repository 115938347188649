import React, { useMemo, useState, useCallback, useRef } from "react";
import { Toggle, FormModal } from "../UI/components";
import ProductSelect from "../UI/components/ProductSelect";
import toast from "react-hot-toast";
import axios from "axios";
import { saveAs } from "file-saver";

export default function AmazonListings({ listings }) {
  const [amazonListings, setAmazonListings] = useState(listings);
  const [selectedProduct, setSelectedProduct] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);

  const createListing = async (e) => {
    e.preventDefault();

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      data: {
        product_id: selectedProduct.id,
      },
    };

    let result;

    try {
      result = await axios.post("amazon_listings", requestConfig);
      toast.success(result.data.success);
      setAmazonListings([...amazonListings, result.data.amazon_listing]);
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Error Creating Amazon Listing");
    }
    setIsOpen(false);
  };

  const deleteListing = async (id) => {
    // e.preventDefault();
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      data: {
        product_id: id,
      },
    };

    let result;
    try {
      result = await axios.delete(`amazon_listings/${id}`, requestConfig);
      let filtered = amazonListings.filter((l) => {
        return l.id !== id;
      });

      toast.success(result.data.success);
      setAmazonListings(filtered);
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message ?? "Error Deleting Amazon Listing");
    }
  };

  const toggleActiveListing = async (id, active) => {
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      data: {
        product_id: id,
        active: active,
      },
    };

    let result;

    try {
      result = await axios.patch(`amazon_listings/${id}`, requestConfig);
      toast.success(result.data.success);
      // setAmazonListings([...amazonListings, result.data.amazon_listing]);
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Error Updating Amazon Listing");
    }
  };

  const onModalClose = async (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  const exportCsv = async () => {
    console.log("export amazon listing csv file");
    setCsvLoading(true);

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      data: {},
    };

    let result;
    try {
      result = await axios.post(`amazon_listings/export`, requestConfig);
      toast.success(result.data.success);
      console.log("csv", result.data);

      // download CSV automatically
      if (result.data.csv_url) saveAs(result.data.csv_url, "amazon-listings.csv");
      setCsvLoading(false);
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message ?? "Error Exporting Amazon Listings");
      setCsvLoading(false);
    }
  };

  return (
    <>
      <div className="relative">
        <div className="flex items-center justify-between border-b pb-5">
          <h2 className="text-2xl font-bold">Amazon Listings</h2>
          <div className="btn-group">
            <button className="btn btn-info" onClick={() => exportCsv()}>
              Export CSV{" "}
              <i
                className={
                  csvLoading ? "fal fa-spinner-third fa-spin ml-2" : "fal fa-file-download ml-2"
                }
              ></i>
            </button>
            <button className="btn" onClick={() => setIsOpen(true)}>
              Add New Amazon Listing
            </button>
          </div>
        </div>
        <div className="py-5">
          <div className="data-export-ui relative w-full rounded-lg bg-white p-5 shadow-sm">
            <table className="datatable my-5 min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th></th>
                  <th>SKU</th>
                  <th>Title</th>
                  <th>Brand</th>
                  <th className="text-center">Valid</th>
                  <th className="text-center">Active</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {amazonListings.map((listing) => {
                  return (
                    <tr key={listing.id} id={`listing-${listing.id}`}>
                      <td>
                        <img src={listing.product.image} width="60px" height="auto" />
                      </td>
                      <td>
                        <a href={listing.product.url} target="_blank" className="link">
                          {listing.product.sku}
                        </a>
                      </td>
                      <td>{listing.product.title}</td>
                      <td>{listing.brand}</td>
                      <td className="text-center">
                        {listing.valid.length > 0 ? (
                          <div className="bg-danger-100 text-danger-500 inline-block rounded py-1 px-2">
                            <i className="fas fa-times text-danger-500 mr-2"></i>
                            <span className="text-xs">{listing.valid.join(", ")}</span>
                          </div>
                        ) : (
                          <i className="fas fa-check text-success-500 block"></i>
                        )}
                      </td>
                      <td className="text-center">
                        {listing.valid.length === 0 ? (
                          <Toggle
                            disabled
                            label="Listing Active"
                            checked={listing.active}
                            onChangeCallback={(enabled) => toggleActiveListing(listing.id, enabled)}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <div className="flex items-center justify-end">
                          {listing.id ? (
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => deleteListing(listing.id)}
                            >
                              Delete
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <FormModal title="Select Product" isOpen={isOpen} onCancel={onModalClose}>
        <form onSubmit={createListing}>
          <div className="form-group mt-5">
            <label className="sr-only">SKU</label>
            <ProductSelect onProductSelect={(p) => setSelectedProduct(p)}></ProductSelect>
            <input
              className="form-control"
              type="text"
              value={selectedProduct ? selectedProduct.sku : ""}
              readOnly={true}
            />
          </div>
          <div className="mt-4 flex justify-end space-x-2">
            <button type="button" className="btn btn-neutral" onClick={onModalClose}>
              Cancel
            </button>
            <button type="submit" className="btn btn-dark">
              Add
            </button>
          </div>
        </form>
      </FormModal>
    </>
  );
}
