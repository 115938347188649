import React from "react";

function ExternalVehicleTranslationCardTitleCell({ cellValue }) {
  return (
    <td className="col-2 p-2 d-flex border-right border-bottom">
      <strong className="align-self-center">{cellValue}</strong>
    </td>
  );
}

export default ExternalVehicleTranslationCardTitleCell;
