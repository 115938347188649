import React from "react";

export default function ParcelItemsTable({ children }) {
  return (
    <table className="min-w-full divide-y divide-gray-200 !text-xs">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="w-2/5 bg-gray-50 px-3 py-1.5 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Item
          </th>
          <th
            scope="col"
            className="w-1/5 bg-gray-50 px-3 py-1.5 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            SKU
          </th>
          <th
            scope="col"
            className="w-1/5 bg-gray-50 px-3 py-1.5 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Quantity
          </th>
          <th scope="col" className="relative w-1/5 bg-gray-50 px-6 py-1.5">
            <span className="sr-only" />
          </th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}
