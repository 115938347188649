import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import { faChevronDown } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import {
  faCarAlt,
  faCheckCircle,
  faImagePolaroid,
  faList,
  faMinusCircle,
  faSpinnerThird,
  faTimesCircle,
} from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import { createConsumer } from "@rails/actioncable";
import axios from "axios";
import clsx from "clsx";
import { format } from "date-fns";

import { Button } from "../../UI/components";
import Table from "../../UI/components/Table";
import { ProductTableAddFilter } from "./Filters/ProductTableFilter";

const formatDate = (date) => format(new Date(date), "dd/MM/yy");

export default function ProductsTable({ team, brand }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [bulkOperationProcessing, setBulkOperationProcessing] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectedRows = (rows) => {
    setSelectedRows(rows);
  };

  const [bulkProductsCount, setBulkProductsCount] = useState(0);
  const [productDataChanged, setProductDataChanged] = useState(null);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const getWebsocketServerUrl = () => {
    const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
    const hostParts = window.location.host.split(".");
    const mainAppDomain =
      hostParts.length > 2 ? hostParts.slice(-2).join(".") : window.location.host;
    const path = "/cable";
    return `${protocol}//${mainAppDomain}${path}`;
  };

  useEffect(() => {
    // Replace the URL with your WebSocket server URL
    const cable = createConsumer(getWebsocketServerUrl());

    const subscription = cable.subscriptions.create(
      { channel: "ProductsChannel", team_id: team.id },
      {
        connected: () => {
          // Send a ping message every 15 seconds
          setInterval(() => {
            subscription.send({ type: "ping" });
          }, 15000);
        },
        disconnected: () => {
          console.log("Disconnected from ProductsChannel");
        },
        received: (data) => {
          console.log("Received data from ProductsChannel", data);

          setErrorOccurred(false);

          if (data.errors) {
            setTimeout(() => {
              toast.error(data.errors);
              console.log("ERRORS", data.errors);
              setBulkOperationProcessing(false);
              setErrorOccurred(true);
            }, 1000);

            return;
          }

          if (data.bulkProductsCount && data.bulkProductsCount > 0) {
            setBulkProductsCount(data.bulkProductsCount);
            setProductDataChanged(data.seed);
            setBulkOperationProcessing(true);
          }

          if (data.productDataChange) {
            setProductDataChanged(data.seed);
          }

          // if job_id is present but null, show complete message via toast
          if (data.bulkProductsCount && !data.job_id) {
            setTimeout(() => {
              toast.success("Bulk action completed");
              setBulkOperationProcessing(false);
            }, 1000);
          }
        },
        error: (error) => {
          console.log("Error connecting to server:", error);
        },
      },
    );

    return () => {
      console.log("Unsubscribing from ProductsChannel");
      cable.subscriptions.remove(subscription);
    };
  }, []);

  const fetchData = useCallback(async ({ pageIndex, pageSize, sortBy, filters, globalFilter }) => {
    let params = {
      page: pageIndex + 1,
      per: pageSize,
    };

    if (sortBy.length > 0) {
      params["sort"] = sortBy[0].id;
      params["sort_dir"] = sortBy[0].desc ? "desc" : "asc";
    }

    if (filters.length > 0) {
      params["filters"] = filters;
    }

    if (globalFilter) {
      params["query"] = globalFilter;
    }

    const requestConfig = {
      responseType: "json",
      headers: {
        ...ReactOnRails.authenticityHeaders(),
        "Content-Type": "application/json", // Add this line
      },
      method: "POST",
      body: JSON.stringify(params), // Add this line
    };
    setLoading(true);

    const result = await fetch("products/datatable.json", requestConfig);
    const data = await result.json();
    setData(data.data);

    setPageCount(Math.ceil(data.meta.total_count / 1));
    setTotalCount(data.meta.total_count);

    setLoading(false);
  }, []);

  const viewMarketplaceListing = (listing_id) => {
    const listingUrl = `${window.location.href.split("products")[0]}/listings/${listing_id}/edit`;

    window.open(listingUrl, "_blank");
  };

  const createMarketplaceListing = async (slug) => {
    try {
      const result = await axios({
        method: "post",
        url: `products/${slug}/create-marketplace-listing`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });
      toast.success("Listing created succesfully");

      const listingUrl = `${window.location.href.split("products")[0]}/listings/${
        result["data"]["id"]
      }/edit`;

      window.open(listingUrl, "_blank");
    } catch (e) {
      console.log(e);
      toast.error(`Couldn't create listing: ${e.response.data.messages.join(", ")}`);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "SKU",
        accessor: "sku",
        disableFilters: true,
        width: 110,
        Cell: function ({ value, row }) {
          return (
            <a className="text-indigo-500" href={`products/${row.original.slug}/edit`}>
              {value}
            </a>
          );
        },
      },
      {
        Header: "Type",
        accessor: "record_type",
        disableFilters: true,
        width: 80,
      },
      {
        Header: "Title",
        accessor: "title",
        disableFilters: true,
      },
      {
        Header: "Product Type",
        id: "product_types.title",
        disableFilters: true,
        accessor: "product_type.title",
        Cell: ({ value }) => {
          if (value) {
            return value;
          } else {
            return <span className="text-danger-500 text-xs">Unassigned</span>;
          }
        },
      },
      {
        Header: "Published",
        accessor: "published",
        disableFilters: true,
        width: 89,
        Cell: ({ value }) => {
          if (value) {
            return (
              <span className="bg-success-100 text-success-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">
                Published
              </span>
            );
          } else {
            return (
              <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                Unpublished
              </span>
            );
          }
        },
      },
      {
        Header: "Last Updated",
        accessor: "updated_at",
        disableFilters: true,
        width: 120,
        Cell: ({ value }) => {
          return <span>{formatDate(value)}</span>;
        },
      },
      {
        Header: "Listing",
        accessor: "has_listing",
        disableFilters: true,
        disableSortBy: true,
        width: 80,
        Cell: ({ value, row }) => {
          return (
            // create listing link
            <Button
              label={value ? "View" : "Create"}
              type="button"
              onClick={() => {
                value && row.original.listing_id && row.original.listing_id != null
                  ? viewMarketplaceListing(row.original.listing_id)
                  : createMarketplaceListing(row.original.slug);
              }}
              className="link"
            />
          );
        },
      },
      {
        Header: "Listing Status",
        accessor: "listing_status",
        disableFilters: true,
        disableSortBy: true,
        width: 120,
        Cell: ({ value, row }) => {
          if (row.original.has_listing == false) {
            return null;
          }
          if (value === "published") {
            return (
              <span className="bg-success-100 text-success-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">
                Published
              </span>
            );
          } else if (value === "invalid") {
            return (
              <span className="bg-warning-100 text-warning-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">
                Invalid
              </span>
            );
          } else if (value === "archived") {
            return (
              <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                Archived
              </span>
            );
          } else if (value === "draft") {
            return (
              <span className="bg-info-100 text-info-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">
                Draft
              </span>
            );
          } else {
            return null;
          }
        },
      },
      {
        Header: (
          <FontAwesomeIcon
            title="Attributes"
            icon={faList}
            className="h-3 w-3 text-center text-gray-500"
          />
        ),
        accessor: "has_attributes",
        disableFilters: true,
        disableSortBy: true,
        visible: false,
        width: 30,
        Cell: ({ value }) => {
          if (value) {
            return <FontAwesomeIcon icon={faCheckCircle} className="text-success-500 h-4 w-4" />;
          } else {
            return <FontAwesomeIcon icon={faMinusCircle} className="text-danger-400 h-4 w-4" />;
          }
        },
      },
      {
        Header: (
          <FontAwesomeIcon
            title="Vehicles"
            icon={faCarAlt}
            className="h-3 w-3 text-center text-gray-500"
          />
        ),
        accessor: "has_vehicles",
        disableFilters: true,
        disableSortBy: true,
        visible: false,
        width: 30,
        Cell: ({ value }) => {
          if (value) {
            return <FontAwesomeIcon icon={faCheckCircle} className="text-success-500 h-4 w-4" />;
          } else {
            return <FontAwesomeIcon icon={faMinusCircle} className="text-danger-400 h-4 w-4" />;
          }
        },
      },
      {
        Header: (
          <FontAwesomeIcon
            title="Media"
            icon={faImagePolaroid}
            className="h-3 w-3 text-center text-gray-500"
          />
        ),
        accessor: "has_media",
        disableFilters: true,
        disableSortBy: true,
        visible: false,
        width: 30,
        Cell: ({ value }) => {
          if (value) {
            return <FontAwesomeIcon icon={faCheckCircle} className="text-success-500 h-4 w-4" />;
          } else {
            return <FontAwesomeIcon icon={faTimesCircle} className="text-danger-400 h-4 w-4" />;
          }
        },
      },
      {
        Header: "",
        accessor: "slug",
        disableFilters: true,
        disableSortBy: true,
        disableResizing: true,
        width: 55,
        Cell: function ({ value }) {
          return (
            <div className="flex justify-end px-3">
              <a className="link text-xs" href={`products/${value}/edit`}>
                Edit
              </a>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Table
        columns={columns}
        data={data}
        onFetchData={fetchData}
        onSelectedRows={handleSelectedRows}
        loading={loading}
        pageCount={pageCount}
        totalItems={totalCount}
        initialPageSize={50}
        useRowSelection
        globalFilterPlaceholder="Keyword Search..."
        globaleFilterSize="xs"
        setFilter={fetchData}
        refresh={productDataChanged}
        bulkActionsComponent={
          selectedRows.length > 0 && (
            <BulkActionsMenu
              bulkOperationProcessing={bulkOperationProcessing}
              selectedRows={selectedRows}
              errors={errorOccurred}
            />
          )
        }
        additionalFilters={[
          (props) => (
            <>
              <ProductTableAddFilter {...props} />
            </>
          ),
        ]}
      />
      <Toaster />
    </>
  );
}

const BulkActionsMenu = ({ selectedRows, bulkOperationProcessing, errors }) => {
  const [isLoading, setIsLoading] = useState(bulkOperationProcessing || false);

  useEffect(() => {
    if (!bulkOperationProcessing) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [bulkOperationProcessing, errors]);

  const options = [
    {
      label: "Publish product",
      value: "publish_products",
      action: async () => {
        try {
          setIsLoading(true);
          const response = await fetch("products/bulk-publish", {
            method: "POST",
            responseType: "json",
            headers: {
              ...ReactOnRails.authenticityHeaders(),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              product_ids: selectedRows,
            }),
          });

          if (response.status === 200) {
            const result = await response.json();
            toast.success("Publishing selected products");
          } else {
            toast.error("Something went wrong");
            setIsLoading(false);
          }
        } catch (error) {
          // Handle the error
          console.log(error);
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      },
    },
    {
      label: "Unpublish products",
      value: "unpublish_products",
      action: async () => {
        try {
          setIsLoading(true);
          const response = await fetch("products/bulk-unpublish", {
            method: "POST",
            responseType: "json",
            headers: {
              ...ReactOnRails.authenticityHeaders(),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              product_ids: selectedRows,
            }),
          });

          if (response.status === 200) {
            const result = await response.json();
            toast.success("Unpublishing selected products");
          } else {
            toast.error("Something went wrong");
            setIsLoading(false);
          }
        } catch (error) {
          // Handle the error
          console.log(error);
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      },
    },
    {
      label: "Create listings",
      value: "create_listings",
      action: async () => {
        try {
          setIsLoading(true);
          const response = await fetch("products/bulk-create-listings", {
            method: "POST",
            responseType: "json",
            headers: {
              ...ReactOnRails.authenticityHeaders(),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              product_ids: selectedRows,
            }),
          });

          if (response.status === 200) {
            const result = await response.json();
            toast.success("Creating listings for selected products");
          } else {
            console.log(response);
            toast.error("Something went wrong");
            setIsLoading(false);
          }
        } catch (error) {
          // Handle the error
          console.log(error);
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      },
    },
    {
      label: "Publish listings",
      value: "publish_listings",
      action: async () => {
        try {
          setIsLoading(true);
          const response = await fetch("products/bulk-publish-listings", {
            method: "POST",
            responseType: "json",
            headers: {
              ...ReactOnRails.authenticityHeaders(),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              product_ids: selectedRows,
              publish: true,
            }),
          });

          if (response.status === 200) {
            const result = await response.json();
            toast.success("Publishing listings for selected products");
          } else {
            console.log(response);
            toast.error("Something went wrong");
            setIsLoading(false);
          }
        } catch (error) {
          // Handle the error
          console.log(error);
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      },
    },
    {
      label: "Unpublish listings",
      value: "unpublish_listings",
      action: async () => {
        try {
          setIsLoading(true);
          const response = await fetch("products/bulk-publish-listings", {
            method: "POST",
            responseType: "json",
            headers: {
              ...ReactOnRails.authenticityHeaders(),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              product_ids: selectedRows,
              publish: false,
            }),
          });

          if (response.status === 200) {
            const result = await response.json();
            toast.success("Unpublishing listings for selected products");
          } else {
            console.log(response);
            toast.error("Something went wrong");
            setIsLoading(false);
          }
        } catch (error) {
          // Handle the error
          console.log(error);
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      },
    },
  ];

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className={"btn btn-light btn-sm"} disabled={isLoading}>
          Bulk Actions
          <FontAwesomeIcon
            icon={isLoading ? faSpinnerThird : faChevronDown}
            className={clsx("ml-2", isLoading && "animate-spin")}
          />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {options.map((option) => {
                return (
                  <Menu.Item key={option.value}>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={() => {
                          option.action();
                        }}
                        className={clsx(
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm",
                        )}
                      >
                        {option.label}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
