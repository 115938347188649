import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

export function VehicleStatusFilter({ filters, setFilter, excludeArchived = false }) {
  const [value, setValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setFilter("status", value || undefined);
  }, 200);

  const options = [
    { value: "new", text: "New" },
    { value: "updated", text: "Updated" },
    { value: "archived", text: "Archived" },
  ];

  const renderedOptions = options.filter((option) => {
    if (excludeArchived) {
      return option.value !== "archived";
    }

    return true;
  });

  return (
    <select
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      type="text"
      placeholder={`Search...`}
      className=" mb-5 max-w-fit rounded-md border border-gray-300 py-2 pl-3 pr-10 text-sm shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
    >
      <option value="">Status</option>
      {renderedOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
}
