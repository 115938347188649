import React, { useRef, useEffect, useState } from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";

export const LocationAutocomplete = ({ callback, defaultLocation }) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ["geometry", "name", "formatted_address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      callback(placeAutocomplete.getPlace());
    });
  }, [callback, placeAutocomplete]);

  return (
    <div className="autocomplete-container">
      <input type="text" ref={inputRef} placeholder={defaultLocation} />
    </div>
  );
};
