import React from "react";

export const JobProgressBar = ({
  progress = 0.0,
  total = null,
  at = null,
  onCancel = null,
  resourceName = "products",
  actionName = "Sync",
  actionPast = "synced",
}) => {
  const width = `${progress}%`;

  return (
    <>
      <div className="mt-6" aria-hidden="true">
        <div className="mb-2 flex items-center justify-between">
          <p className="text-xs text-indigo-500">
            <span>{actionName} in progress... </span>
            {at && total && (
              <span>
                {at} of {total} {resourceName} {actionPast}
              </span>
            )}
          </p>
          {onCancel && (
            <button className={`btn btn-sm btn-danger opacity-90`} onClick={onCancel}>
              Cancel
            </button>
          )}
        </div>
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div className="h-2 rounded-full bg-indigo-600" style={{ width: width }} />
        </div>
      </div>
    </>
  );
};
