import {
  faArrowsRotate,
  faUser,
  faArrowRightFromBracket,
  faBell,
  faCircleQuestion,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import AppSwitch from "../../UI/components/AppSwitch";
import Logo from "/app/assets/images/partbot-logo.svg";

export const Header = ({ team, app_contexts }) => {
  useEffect(() => {
    Canny("initChangelog", {
      appID: "6291b73d3b4f26603a1c6ead",
      position: "bottom",
      align: "right",
    });
  }, []);

  return (
    <div className="mb-3 flex h-[100] w-full items-center justify-between py-4">
      <div className="flex items-center space-x-4">
        <img src={Logo} width="130" height="auto" />
        <div className="flex items-center space-x-2">
          <AppSwitch context="marketplace" app_contexts={app_contexts} />
        </div>
      </div>
      <div className="flex items-center justify-end space-x-6">
        <a href="/" className="mp-nav-link">
          Products
        </a>
        {/* <a href="/fulfillments" className="mp-nav-link">
          Fulfillments
        </a> */}
        <a href="/stores" className="mp-nav-link">
          Stores
        </a>
        <a href="/billing" className="mp-nav-link">
          Billing
        </a>

        <div className="flex items-center space-x-3">
          <a
            href="https://help.partbot.io"
            title="Help Center"
            target="_blank"
            className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full p-2 text-indigo-500 transition hover:bg-indigo-600 hover:text-white"
          >
            <FontAwesomeIcon icon={faCircleQuestion} />
          </a>

          <button
            title="Notifications"
            data-canny-changelog
            className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full p-2 text-indigo-500 transition hover:bg-indigo-600 hover:text-white"
          >
            <FontAwesomeIcon icon={faBell} />
          </button>
          <a
            href="/logout"
            title="Logout"
            className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full p-2 text-indigo-500 transition hover:bg-indigo-600 hover:text-white"
          >
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
          </a>
          <a
            href="/account"
            title="Account"
            className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-white p-2 text-indigo-500 shadow-lg transition hover:bg-indigo-600 hover:text-white"
          >
            <FontAwesomeIcon icon={faUser} />
          </a>
        </div>
      </div>
    </div>
  );
};
