import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Form,
  Input,
  Select,
  FormModal,
  Button,
  InputWithSelect,
} from "../../UI/components";
import DangerousGoodsDefinitionSelect from "../../UI/components/DangerousGoodsDefinitionSelect";
import { ConnectForm } from "../../UI/components/Controls";

export default function HazardsModal({
  closeModal,
  cancelModal,
  isOpen,
  options,
  selectedHazard = {},
  packages = [],
}) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsLoading(false);
    }

    return () => {
      setIsLoading(false);
    };
  }, [isOpen]);

  const onCancel = () => {
    cancelModal();
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    setTimeout(() => {
      // setIsLoading(false);
      data.shipping_dangerous_goods_definition_id = selectedHazard?.dangerous_goods_definition?.id;
      closeModal(data);
    }, 1000);
  };

  return (
    <FormModal title="Dangerous Goods Info" {...{ onCancel, isOpen }}>
      <Form onSubmit={onSubmit} defaultValues={selectedHazard}>
        <div className="my-4">
          <div className="my-4">
            <DangerousGoodsDefinitionSelect
              label=""
              onSelect={(d) => {
                selectedHazard.dangerous_goods_definition = d;
              }}
              initialValue={selectedHazard?.dangerous_goods_definition}
            />
            <div className="my-4">
              <Input
                label="Supplemental Info"
                name="supplemental_info"
                type="text"
                className="my-4"
              />
            </div>
          </div>
          <hr className="" />
          <div className="my-4">
            <span className="my-4 text-sm font-semibold">Packages Hazard Info</span>
            <p className="text-xs text-gray-500">
              Add the amount of the selected hazardous material that is contained in each package
            </p>
          </div>
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-1">
            {/* Packages Hazard Info List with amount inputs */}

            {packages && packages.length > 0 && (
              <>
                {selectedHazard.package_hazards_attributes.map((pkg, index) => (
                  <div key={index} className="my-1">
                    <ConnectForm>
                      {({ register }) => (
                        <>
                          <input
                            type="hidden"
                            {...register(`package_hazards_attributes[${index}][package_id]`)}
                            className="hidden"
                          />
                          <input
                            type="hidden"
                            {...register(`package_hazards_attributes[${index}][id]`)}
                            className="hidden"
                          />
                        </>
                      )}
                    </ConnectForm>

                    <InputWithSelect
                      inputName={`package_hazards_attributes[${index}][amount]`}
                      selectName={`package_hazards_attributes[${index}][amount_unit]`}
                      label={pkg.package_identifier}
                      selectLabel="Unit of Measure"
                      inputHtmlProps={{
                        type: "number",
                        step: 0.01,
                        min: 0,
                        placeholder: 0,
                        required: true,
                      }}
                      selectHtmlProps={{
                        required: true,
                        placeholder: "unit",
                      }}
                      options={[
                        { value: "g", label: "g" },
                        { value: "kg", label: "kg" },
                        { value: "ml", label: "mL" },
                        { value: "l", label: "L" },
                      ]}
                    />
                  </div>
                ))}
              </>
            )}
            {packages.length === 0 && (
              <div className="my-4">
                <p className="text-sm text-gray-500">No packages available</p>
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-1"></div>
        </div>
        <div className="mt-4 flex justify-end">
          <div className="btn-group">
            <Button label="Cancel" type="button" className="btn btn-neutral" onClick={onCancel} />
            <Button
              label={selectedHazard.id ? "Update" : "Add"}
              type="submit"
              className="btn"
              showLoading={isLoading}
            />
          </div>
        </div>
      </Form>
    </FormModal>
  );
}
