import React from "react";
import { Button, Form, Input } from "../UI/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpenCover,
  faCheck,
  faCopy,
  faPlus,
  faGlobe,
  faTimes,
  faUserPlus,
  faTrashAlt,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { faCheckCircle, faTimesCircle } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";
import clsx from "clsx";
import CopyToClipboard from "react-copy-to-clipboard";
import { useState } from "react";
import Api from "../Marketplace/api";
import InviteTeamMemberModal from "../Marketplace/components/InviteTeamMemberModal";
import toast, { Toaster } from "react-hot-toast";
import { DomainInput } from "../Marketplace/components/DomainInput";
import axios from "axios";

import CountryCombobox from "../UI/components/CountryCombobox";
import TimezoneCombobox from "../UI/components/TimezoneCombobox";
import CurrencyCombobox from "../UI/components/CurrencyCombobox";
import BusinessIds from "./BusinessIds";
import TecAllianceSettings from "./TecAllianceSettings";
import ButtonCopyToClipboard from "../UI/components/ButtonCopyToClipboard";

const tabs = [
  { id: 0, name: "My Profile", href: "#", current: true },
  { id: 1, name: "Team Members", href: "#", current: false },
  // { id: 2, name: "Notifications", href: "#", current: false },
  { id: 3, name: "API Keys", href: "#", current: false },
  { id: 4, name: "Domains", href: "#", current: false },
  { id: 5, name: "Company", href: "#", current: false },
  { id: 6, name: "TecAlliance", href: "#", current: false },
];

export default function AccountSettings({ user, team, domains, tec_alliance_merchants }) {
  const marketplaceAPI = new Api(team.details.api_key, location);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({
    id: user.id,
    name: user.name,
    email: user.email,
    admin: user.admin,
  });

  const [teamInfo, setTeamInfo] = useState(team.details);
  const [teamUsers, setTeamUsers] = useState(team.users);

  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [userDomains, setUserDomains] = useState(domains);
  const [isDeletingMember, setIsDeletingMember] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState(null);

  const [tecAllianceMerchants, setTecAllianceMerchants] = useState(tec_alliance_merchants);

  const addDomainRow = () => {
    if (!userDomains.find((domain) => domain.id === "")) {
      marketplaceAPI.createTeamDomain().then((data) => {
        if (data && data.id) {
          setUserDomains([...userDomains, data]);
        } else {
          console.log("An error occured", data);
          toast.error("An error occured");
        }
      });
    }
  };

  const removeDomain = (domain) => {
    if (domain.readonly) {
      return;
    }

    marketplaceAPI.removeTeamDomain(domain).then((data) => {
      setUserDomains(userDomains.filter((d) => d.id !== domain.id));
    });
  };

  const onAccountFormSubmit = (e) => {
    e.preventDefault();

    setIsUpdating(true);

    marketplaceAPI.updateUserAccount(userInfo).then((data) => {
      if (data?.success) {
        toast.success("Account details updated");
      } else {
        toast.error("An error occured");
      }

      setIsUpdating(false);
    });
  };

  const onCompanyFormSubmit = (e) => {
    e.preventDefault();

    setIsUpdating(true);

    marketplaceAPI.updateTeamInfo(teamInfo).then((data) => {
      if (data.success) {
        toast.success("Company details updated");
      } else {
        toast.error("An error occured");
      }

      setIsUpdating(false);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserInfo((userInfo) => ({
      ...userInfo,
      [name]: value,
    }));
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onUpdate = async () => {
    try {
      const result = await axios({
        method: "get",
        url: "/team",
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      setTeamInfo(result.data.team.details);
    } catch {
      toast.error("Could not refresh Team Members data");
    }
  };

  const onTeamMemberDelete = async (teamMemberId) => {
    if (confirm("This team member will be removed! Do you really want to proceed?")) {
      setDeletingUserId(teamMemberId);

      try {
        setIsDeletingMember(true);
        const result = await axios({
          method: "delete",
          url: `/remove-team-member/${teamMemberId}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
        });

        toast.success(result.data.success);
        await onUpdate();
        setIsDeletingMember(false);
      } catch (error) {
        console.error(error);
        toast.error("Could not remove team member");
        setIsDeletingMember(false);
      }
    }
  };

  return (
    <>
      <section>
        <div className="flex items-center justify-between border-b pb-5">
          <h2 className="text-2xl font-bold">Account Settings</h2>
          <div className="btn-group">{/* nothing yet */}</div>
        </div>
        <div className="py-5">
          <div className="relative w-full overflow-x-auto rounded-lg bg-white px-5 pb-5 pt-1 shadow">
            <div className="prose-sm p-4">
              <h4>Your account has the following services enabled:</h4>
              <div className="my-5 grid grid-cols-3 gap-4">
                <div className="flex items-center justify-between gap-2 rounded-md bg-gray-100 p-4">
                  <span className="font-bold">Catalogue</span>
                  <FontAwesomeIcon
                    icon={
                      teamInfo.catalogue_platform_enabled === true ? faCheckCircle : faTimesCircle
                    }
                    className={clsx(
                      teamInfo.catalogue_platform_enabled === true
                        ? "text-success-500"
                        : "text-danger-500",
                      "text-lg"
                    )}
                  />
                </div>
                <div className="flex items-center justify-between gap-2 rounded-md bg-gray-100 p-4">
                  <span className="font-bold">Marketplace</span>
                  <FontAwesomeIcon
                    icon={
                      teamInfo.marketplace_platform_enabled === true ? faCheckCircle : faTimesCircle
                    }
                    className={clsx(
                      teamInfo.marketplace_platform_enabled === true
                        ? "text-success-500"
                        : "text-danger-500",
                      "text-lg"
                    )}
                  />
                </div>
                <div className="flex items-center justify-between gap-2 rounded-md bg-gray-100 p-4">
                  <span className="font-bold">Distributor</span>
                  <FontAwesomeIcon
                    icon={
                      teamInfo.distributor_platform_enabled === true ? faCheckCircle : faTimesCircle
                    }
                    className={clsx(
                      teamInfo.distributor_platform_enabled === true
                        ? "text-success-500"
                        : "text-danger-500",
                      "text-lg"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-y-5 overflow-x-auto rounded-lg bg-white px-8 pb-8 pt-1 shadow">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden w-full sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  onClick={() => setCurrentTab(tab)}
                  className={clsx(
                    tab === currentTab
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 py-4 text-xs font-medium"
                  )}
                  aria-current={tab === currentTab ? "page" : undefined}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div className="my-8">
          {(() => {
            switch (currentTab.id) {
              case 0:
                return (
                  <form onSubmit={onAccountFormSubmit}>
                    <div className="flex flex-col space-y-8">
                      <div className="grid grid-cols-5 items-center gap-8">
                        <label className="text-gray-500">Name</label>
                        <input
                          type="text"
                          name="name"
                          className="col-span-4"
                          onChange={handleChange}
                          value={userInfo.name}
                          required
                        />
                        <label className="text-gray-500">Email</label>
                        <input
                          type="text"
                          name="email"
                          className="col-span-4"
                          onChange={handleChange}
                          defaultValue={userInfo.email}
                          required
                        />
                      </div>
                      <div className="flex justify-end">
                        <Button
                          label="Update"
                          type="submit"
                          className="btn btn-light"
                          showLoading={isUpdating}
                        />
                      </div>
                    </div>
                  </form>
                );
              case 1:
                return (
                  <div className="space-y-5 divide-y pt-4">
                    <table className="mb-3 table border-b">
                      <thead>
                        <tr>
                          <th className="text-xs">Name</th>
                          <th className="text-xs">Email</th>
                          <th className="text-center text-xs">Admin</th>
                          <th className="text-center text-xs">Editor</th>
                          <th className="text-center text-xs">Billing</th>
                          <th className="text-center text-xs">Viewer</th>
                          {userInfo.admin && (
                            <th className="text-center text-xs">
                              <span className="sr-only">Remove User from Team</span>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {teamUsers.map((user) => {
                          return (
                            <tr key={user.id} className="">
                              <td className="text-xs text-gray-500">{user.name}</td>
                              <td className="text-xs text-gray-500">
                                <span className="mr-3 block whitespace-nowrap">{user.email}</span>
                              </td>
                              <td
                                className={clsx(
                                  user.admin ? "text-success-500" : "text-danger-500",
                                  "text-center text-sm"
                                )}
                              >
                                <FontAwesomeIcon icon={user.admin ? faCheck : faTimes} />
                              </td>
                              <td
                                className={clsx(
                                  user.editor ? "text-success-500" : "text-danger-500",
                                  "text-center text-xs"
                                )}
                              >
                                <FontAwesomeIcon icon={user.editor ? faCheck : faTimes} />
                              </td>
                              <td
                                className={clsx(
                                  user.billing ? "text-success-500" : "text-danger-500",
                                  "text-center text-xs"
                                )}
                              >
                                <FontAwesomeIcon icon={user.billing ? faCheck : faTimes} />
                              </td>
                              <td
                                className={clsx(
                                  user.viewer ? "text-success-500" : "text-danger-500",
                                  "text-center text-xs"
                                )}
                              >
                                <FontAwesomeIcon icon={user.viewer ? faCheck : faTimes} />
                              </td>
                              {/* only show if user is admin */}
                              {userInfo.admin && (
                                <td className="text-center text-xs">
                                  <Button
                                    icon={faTrashAlt}
                                    className="hover:text-danger-500"
                                    onClick={() => onTeamMemberDelete(user.id)}
                                    showLoading={isDeletingMember && deletingUserId == user.id}
                                  />
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {user.admin && (
                      <Button
                        label="Invite Team Member"
                        className="btn btn-sm btn-light"
                        onClick={() => handleOpenModal()}
                        icon={faUserPlus}
                      />
                    )}
                  </div>
                );
              case 2:
                return (
                  <div className="prose prose-sm text-gray-500">
                    <p>Available soon.</p>
                  </div>
                );
              case 3:
                return (
                  <div className="grid grid-cols-2 gap-8">
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col space-y-2">
                        <label>Publishable Key</label>
                        <div className="flex items-center justify-between gap-2">
                          <input type="text" value={teamInfo.publishable_key} readOnly />
                          <ButtonCopyToClipboard text={teamInfo.publishable_key} />
                        </div>
                      </div>
                      <div className="bg-success-100 text-success-700 mb-5 rounded-lg p-4 text-xs">
                        Use the publishable key to make API calls from the client side. This key is
                        safe to expose to the public. Some API endpoints are not available with the
                        publishable key.
                      </div>
                      <div>
                        <a
                          href="https://docs.partbot.io"
                          target="_blank"
                          className="btn btn-sm btn-light"
                        >
                          <span>View API Documentation</span>
                          <FontAwesomeIcon icon={faBookOpenCover} />
                        </a>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col space-y-2">
                        <label>Secret Key</label>
                        <div className="flex items-center justify-between gap-2">
                          <input type="password" value={teamInfo.secret_key} readOnly />
                          <ButtonCopyToClipboard text={teamInfo.secret_key} />
                        </div>
                      </div>
                      <div className="bg-danger-100 text-danger-700 mb-5 rounded-lg p-4 text-xs">
                        Use the secret key to make API calls from the server side. Keep this key
                        secret and never use it in client side javascript.
                      </div>
                    </div>
                  </div>
                );
              case 4:
                return (
                  <div className="space-y-8 pt-4">
                    <div className="flex flex-col space-y-3">
                      <div className="text-sm text-gray-500">
                        Manage the domain names permitted to display Partbot plugins and make API
                        calls.
                      </div>
                      {userDomains &&
                        userDomains.map((domain) => {
                          return (
                            <div key={domain.id}>
                              <DomainInput
                                domain={domain}
                                team={teamInfo}
                                onRemove={removeDomain}
                              />
                            </div>
                          );
                        })}
                      <div className="pt-5">
                        <Button
                          label="Add Domain"
                          onClick={addDomainRow}
                          className="btn btn-sm btn-light !m-0"
                          icon={faGlobe}
                        />
                      </div>
                    </div>
                  </div>
                );
              case 5:
                return (
                  <form onSubmit={onCompanyFormSubmit}>
                    <div className="flex flex-col space-y-8">
                      <div className="grid max-w-lg grid-cols-1 items-center gap-8">
                        <div>
                          <label className="text-gray-500">Legal Business Name</label>
                          <input
                            type="text"
                            name="legal_business_name"
                            className="col-span-4"
                            onChange={(e) =>
                              setTeamInfo({ ...teamInfo, legal_business_name: e.target.value })
                            }
                            value={teamInfo.legal_business_name || ""}
                            required
                          />
                        </div>

                        <div>
                          <CountryCombobox
                            selectedOption={teamInfo.region}
                            handleCountrySelect={(country) =>
                              setTeamInfo({ ...teamInfo, region: country.value })
                            }
                          />
                        </div>
                        <div>
                          <TimezoneCombobox
                            selectedOption={teamInfo.timezone}
                            handleTimezoneSelect={(timezone) =>
                              setTeamInfo({ ...teamInfo, timezone: timezone.value })
                            }
                          />
                        </div>
                        <div>
                          <CurrencyCombobox
                            selectedOption={teamInfo.default_currency_code}
                            handleCurrencySelect={(currency) =>
                              setTeamInfo({ ...teamInfo, default_currency_code: currency.value })
                            }
                          />
                        </div>
                        <div>
                          <BusinessIds
                            defaultBusinessIds={teamInfo.legal_business_ids}
                            country={teamInfo.region}
                            onChange={(taxIds) => {
                              // set teamInfo.legal_business_ids to the new taxIds
                              setTeamInfo({ ...teamInfo, legal_business_ids: taxIds });
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <Button
                          label="Update"
                          type="submit"
                          className="btn btn-light"
                          showLoading={isUpdating}
                        />
                      </div>
                    </div>
                  </form>
                );

              case 6:
                return (
                  <TecAllianceSettings team={team} tecAllianceMerchants={tecAllianceMerchants} />
                );
              default:
                return null;
            }
          })()}
        </div>
      </section>

      <Toaster position="bottom left" />
      <InviteTeamMemberModal
        team={teamInfo}
        isOpen={isModalOpen}
        closeModal={closeModal}
        onUpdate={onUpdate}
      />
    </>
  );
}
