import React from "react";
import ListStatusBadge from "./ListStatusBadge";

const titleize = (str) => {
  if (!str) {
    return "";
  }

  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export default function DeliveryStatusBadge({ title, status }) {
  return (
    <>
      <div className="text-sm font-medium text-gray-400">{title}</div>
      <span className="mt-2 flex items-center space-x-2 text-xs">
        <ListStatusBadge status={status} type="delivery" />
      </span>
    </>
  );
}
