import React, {useState, useRef, useEffect} from "react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";

export default function SubBrandCombobox({ selectedOption, handleSubBrandSelect, brand }) {
  const [query, setQuery] = useState("");
  const [selectedBrand, setSelectedBrand] = useState(brand);
  const [selectedSubBrand, setSelectedSubBrand] = useState(selectedOption);

  function useSubBrands(searchTerm) {
    const [subBrands, setSubBrands] = React.useState([]);
    React.useEffect(() => {
      if (searchTerm.trim() !== "") {
        let isFresh = true;
        fetchSubBrands(searchTerm).then((subBrands) => {
          if (isFresh) setSubBrands(subBrands);
        });
        return () => (isFresh = false);
      }
    }, [searchTerm]);
    return subBrands;
  }

  useEffect(() => {
    setSelectedSubBrand(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    setSelectedBrand(brand);
  }, [brand])

  const cache = {};
  function fetchSubBrands(value) {
    if (cache[value]) {
      return Promise.resolve(cache[value]);
    }

    if (selectedBrand) {
      return fetch(`/sub_brand_search?brand=${brand.id}&term=${value}`)
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          cache[value] = result;
          return result;
        });
    }
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const subBrands = useSubBrands(query);

  const handleSelect = (value) => {
    setSelectedSubBrand(value);
    handleSubBrandSelect(value);
  };

  return (
    <Combobox as="div" value={selectedSubBrand} onChange={(e) => handleSelect(e)} disabled={selectedBrand == null}>
      <Combobox.Label>Sub-Brand</Combobox.Label>
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={
            (subBrand) => subBrand ? subBrand.name : null
          }

        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {subBrands.length > 0 && (
          <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {subBrands.map((subBrand) => (
              <Combobox.Option
                key={subBrand.id}
                value={subBrand}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames("block truncate", selected && "font-semibold")}>
                      {subBrand.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
