import PropTypes from "prop-types";
import React from "react";
import request from "axios";

import ExternalVehicleCard from "./ExternalVehicleCard";

const ExternalVehicleLinkingView = ({
  vehicleLinking: initialVehicleLinking,
}) => {
  const [vehicleLinking, setVehicleLinking] = React.useState(
    initialVehicleLinking
  );
  const [isDirty, setDirty] = React.useState(false);

  const handleExternalVehicleOverride = async (override) => {
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    };

    return request
      .post(
        "external_override.json",
        {
          external_vehicle_link_id: override.external_vehicle_link_id,
          attribute_name: override.attribute_name,
          use_attribute: override.use_attribute,
        },
        requestConfig
      )
      .then((result) => {
        setVehicleLinking(result.data);
        setDirty(true);
      })
      .catch((error) => {
        console.log(error);
        window.alert("could not link vehicle");
      });
  };

  const handleExternalVehicleLink = async (link) => {
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    };

    return request
      .post(
        "external_link.json",
        { external_vehicle_link_id: link },
        requestConfig
      )
      .then((result) => {
        console.log(result.data);
        setVehicleLinking(result.data);
        setDirty(true);
      })
      .catch((error) => {
        console.log(error);
        window.alert("could not link vehicle");
      });
  };

  const handleExternalVehicleUnlink = async (unlink) => {
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    };

    return request
      .post(
        "external_unlink.json",
        { external_vehicle_link_id: unlink },
        requestConfig
      )
      .then((result) => {
        setVehicleLinking(result.data);
        setDirty(true);
      })
      .catch((error) => {
        console.log(error);
        window.alert("could not unlink vehicle");
      });
  };

  const handleUpdate = async () => {
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    };

    return request
      .post("reindex.json", {}, requestConfig)
      .then((result) => {
        setDirty(false);
      })
      .catch((error) => {
        console.log(error);
        window.alert("could not reindex vehicle");
      });
  };

  const getOverride = (propertyName) => {
    const link = vehicleLinking.external_vehicle_links.find(
      (x) => x["use_" + propertyName]
    );

    if (link) return link.vehicle[propertyName];
    else return "";
  };

  const getDecoration = (propertyName) => {
    if (getOverride(propertyName)) return "text-muted";
    else return "font-weight-bold";
  };

  const externalVehicles = vehicleLinking.external_vehicle_links
    .sort(
      (a, b) =>
        b.approved - a.approved ||
        parseFloat(b.match_score) - parseFloat(a.match_score)
    )
    .map((link) => (
      <ExternalVehicleCard
        key={link.id}
        link={link}
        partbotVehicle={vehicleLinking.vehicle}
        handleOverride={handleExternalVehicleOverride}
        handleLink={handleExternalVehicleLink}
        handleUnlink={handleExternalVehicleUnlink}
      />
    ));

  return (
    <div className="row">
      <div className="col-4">
        <div className="card border-primary">
          <div className="card-body">
            <h3>
              {vehicleLinking.vehicle.make} {vehicleLinking.vehicle.model}{" "}
              <span className="float-right text-primary"> Partbot </span>
            </h3>
            <div className="card-text">
              <div className="row py-1 border-bottom">
                <div className="col-4">Series</div>
                <div className={`col-4 ${getDecoration("series")}`}>
                  {vehicleLinking.vehicle.series}
                </div>
                <div className="col-4 font-weight-bold text-success">
                  {getOverride("series")}
                </div>
              </div>
              <div className="row py-1 border-bottom">
                <div className="col-4">Variant</div>
                <div className={`col-4 ${getDecoration("variant")}`}>
                  {vehicleLinking.vehicle.variant}
                </div>
                <div className="col-4 font-weight-bold text-success">
                  {getOverride("variant")}
                </div>
              </div>
              <div className="row py-1 border-bottom">
                <div className="col-4">Body</div>
                <div className={`col-4 ${getDecoration("body")}`}>
                  {vehicleLinking.vehicle.body}
                </div>
                <div className="col-4 font-weight-bold text-success">
                  {getOverride("body")}
                </div>
              </div>
              <div className="row py-1 border-bottom">
                <div className="col-4">Year From</div>
                <div className={`col-4 ${getDecoration("year_from")}`}>
                  {vehicleLinking.vehicle.year_from}
                </div>
                <div className="col-4 font-weight-bold text-success">
                  {getOverride("year_from")}
                </div>
              </div>
              <div className="row py-1 border-bottom">
                <div className="col-4">Month From</div>
                <div className={`col-4 ${getDecoration("month_from")}`}>
                  {vehicleLinking.vehicle.month_from}
                </div>
                <div className="col-4 font-weight-bold text-success">
                  {getOverride("month_from")}
                </div>
              </div>
              <div className="row py-1 border-bottom">
                <div className="col-4">Year To</div>
                <div className={`col-4 ${getDecoration("year_to")}`}>
                  {vehicleLinking.vehicle.year_to}
                </div>
                <div className="col-4 font-weight-bold text-success">
                  {getOverride("year_to")}
                </div>
              </div>
              <div className="row py-1 border-bottom">
                <div className="col-4">Month To</div>
                <div className={`col-4 ${getDecoration("month_to")}`}>
                  {vehicleLinking.vehicle.month_to}
                </div>
                <div className="col-4 font-weight-bold text-success">
                  {getOverride("month_to")}
                </div>
              </div>
              <div className="row py-1 border-bottom">
                <div className="col-4">Engine</div>
                <div className={`col-4 ${getDecoration("engine")}`}>
                  {vehicleLinking.vehicle.engine}
                </div>
                <div className="col-4 font-weight-bold text-success">
                  {getOverride("engine")}
                </div>
              </div>
              <div className="row py-1 border-bottom">
                <div className="col-4">Engine (L)</div>
                <div className={`col-4 ${getDecoration("engine_capacity")}`}>
                  {vehicleLinking.vehicle.engine_capacity}
                </div>
                <div className="col-4 font-weight-bold text-success">
                  {getOverride("engine_capacity")}
                </div>
              </div>
              <div className="row py-1 border-bottom">
                <div className="col-4">Fuel</div>
                <div className={`col-4 ${getDecoration("fuel_type")}`}>
                  {vehicleLinking.vehicle.fuel_type}
                </div>
                <div className="col-4 font-weight-bold text-success">
                  {getOverride("fuel_type")}
                </div>
              </div>
              <div className="row py-1 border-bottom">
                <div className="col-4">Drivetrain</div>
                <div className={`col-4 ${getDecoration("drive_type")}`}>
                  {vehicleLinking.vehicle.drive_type}
                </div>
                <div className="col-4 font-weight-bold text-success">
                  {getOverride("drive_type")}
                </div>
              </div>
              <div className="row py-1 border-bottom">
                <div className="col-4">Cylinders</div>
                <div className={`col-4 ${getDecoration("cylinders")}`}>
                  {vehicleLinking.vehicle.cylinders}
                </div>
                <div className="col-4 font-weight-bold text-success">
                  {getOverride("cylinders")}
                </div>
              </div>
              <div className="row py-1 border-bottom">
                <div className="col-4">Power (kW)</div>
                <div className={`col-4 ${getDecoration("kw_power")}`}>
                  {vehicleLinking.vehicle.kw_power}
                </div>
                <div className="col-4 font-weight-bold text-success">
                  {getOverride("kw_power")}
                </div>
              </div>
            </div>
          </div>
          <div className={`card-footer bg-secondary`}>
            <div className="row align-items-center h-100">
              <div className="col-4 ">
                <span>
                  {isDirty ? (
                    <span className="badge badge-lg badge-warning">
                      Needs Reindexing
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="col-8 text-right">
                <button
                  type="button"
                  onClick={(e) => handleUpdate()}
                  className="btn btn-primary"
                  disabled={!isDirty}
                >
                  Update Vehicle
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-8"
        style={{ boxShadow: "10 10 2rem 0 rgba(136, 152, 170, 0.15)" }}
      >
        <div className="w-100" style={{ overflowX: "scroll" }}>
          <div className="row flex-row flex-nowrap">{externalVehicles}</div>
        </div>
      </div>
    </div>
  );
};

export default ExternalVehicleLinkingView;
