import React from "react";
import { AutocompleteHighlight } from "./AutocompleteHighlight";
import { clsx } from "clsx";

export function AutocompleteItem({ hit, components, activeItemId }) {
  const isActive = hit.__autocomplete_id == activeItemId;
  const fallbackImgUrl =
    "https://res.cloudinary.com/proex/image/asset/c_fill,h_56,w_56,y_0/v1507011580/unavailable-417eefe89f50f3adf3d1434820535d1d.png";

  const imgUrl = hit.image_url
    ? `https://res.cloudinary.com/proex/image/fetch/f_auto,w_56,h_56,c_pad,b_white,q_90,dpr_2/${hit.image_url}`
    : fallbackImgUrl;

  const addDefaultSrc = (e) => {
    e.target.src = fallbackImgUrl;
  };

  return (
    <a href={`/products/${hit.slug}/edit`} className="group w-full">
      <div
        className={clsx(
          "w-full rounded-lg transition group-hover:bg-indigo-50",
          isActive ? "bg-indigo-50" : "bg-transparent"
        )}
      >
        <div className="flex w-full items-center p-4">
          <div className="mr-5 flex-none">
            <img
              src={imgUrl}
              width="56px"
              height="56px"
              className="h-14 w-14 rounded"
              onError={addDefaultSrc}
            />
          </div>
          <div className="flex-grow">
            <div className={`text-sm ${hit.published ? "text-gray-600" : "text-gray-400"}`}>
              <AutocompleteHighlight hit={hit} attribute="title" />
            </div>
            <div
              className={`flex min-w-20 items-center text-sm font-bold ${
                hit.published ? "text-gray-900" : "text-gray-700"
              }`}
            >
              <AutocompleteHighlight hit={hit} attribute="sku" className="block flex-none" />
              {hit.alternate_skus.length > 0 && (
                <span className="ml-2 block flex-grow text-xs font-normal italic text-gray-400">
                  Supersedes: {hit.alternate_skus.join(", ")}
                </span>
              )}
            </div>
          </div>
          <div className="ml-5 flex-none">
            {hit.published !== undefined &&
              (hit.published ? (
                <span className="inline-flex items-center rounded-full bg-white px-2.5 py-0.5 text-xs font-medium text-gray-600">
                  <svg
                    className="text-success-400 mr-1.5 h-2 w-2"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                  PUBLISHED
                </span>
              ) : (
                <span className="inline-flex items-center rounded-full bg-white px-2.5 py-0.5 text-xs font-medium text-gray-400">
                  <svg
                    className="mr-1.5 h-2 w-2 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                  UNPUBLISHED
                </span>
              ))}
          </div>
          <div className="ml-5 flex-none">
            <span
              className={`rounded-md border border-indigo-300 px-1 py-1 text-xs leading-none text-indigo-500 sm:block ${
                isActive ? "opacity-100" : "opacity-0"
              }`}
            >
              <span className="sr-only">Press </span>
              <kbd title="Return" className="font-sans no-underline">
                ⏎
              </kbd>
              <span className="sr-only"> to go</span>
            </span>
          </div>
        </div>
      </div>
    </a>
  );
}
