import React from "react";

function ExternalVehicleTranslationCardInputCell({
  cellValue,
  onPropertyChange,
}) {
  const handleValueChange = (e) => {
    onPropertyChange(e.target.value);
  };

  return (
    <td className={`col-2 p-2 border-right border-bottom`}>
      <input
        type="text"
        className="form-control form-control-sm"
        value={cellValue ?? undefined}
        onChange={(e) => handleValueChange(e)}
      />
    </td>
  );
}

export default ExternalVehicleTranslationCardInputCell;
