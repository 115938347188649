import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { useState } from "react";

export const Button = ({
  label,
  icon,
  hoverIcon,
  iconClass,
  type,
  className,
  showLoading,
  onClick,
  size = "sm",
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      onClick={onClick}
      className={className || "btn"}
      type={type || "button"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      {size && size !== "icon" && <span>{label}</span>}
      {icon && !showLoading && (
        <FontAwesomeIcon icon={isHovered && hoverIcon ? hoverIcon : icon} className={iconClass} />
      )}
      {showLoading && <FontAwesomeIcon icon={faSpinnerThird} className="animate-spin" />}
    </button>
  );
};
