import React from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
export default function VariantItem({
  product,
  selectedAttributes,
  valid,
  removeProduct,
  setOptionValue,
}) {
  const is_attribute_overriden = (attr) => {
    return (
      product.attributes.hasOwnProperty(attr) &&
      product.attribute_value_overrides.hasOwnProperty(attr) &&
      product.attributes[attr] != product.attribute_value_overrides[attr]
    );
  };

  console.log("product", product);

  return (
    <li>
      <div
        className={clsx(
          "block",
          !product.published ? "bg-danger-50 hover:bg-danger-100" : "hover:bg-gray-50"
        )}
      >
        <div className="flex items-start justify-between px-4 py-4 sm:px-6">
          <div className="pr-8 text-xs">
            <a href={`/products/${product.id}/edit`} className="flex flex-col gap-2">
              <span className="font-medium">{product.sku}</span>
              <span>{product.title}</span>
              <span>
                {product.published ? (
                  <span className="text-success-500">Published</span>
                ) : (
                  <span className="text-danger-500">Unpublished</span>
                )}
              </span>
            </a>
            {selectedAttributes.map((attr, index) => {
              let valid_class = valid ? "text-success" : "text-danger";

              return (
                <div key={index}>
                  <div className={`my-3 flex`}>
                    <div className={`text-sm ${valid_class}`}>{attr}:</div>
                    <div className="text-sm">
                      {!product.attributes.hasOwnProperty(attr) ? (
                        <span className="pl-3 text-gray-500">N/A</span>
                      ) : (
                        <span></span>
                      )}
                      {is_attribute_overriden(attr) ? (
                        <del className="pl-3 text-gray-500">{product.attributes[attr]}</del>
                      ) : (
                        <span className={`pl-3 ${valid_class}`}>{product.attributes[attr]}</span>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-4 small"></div>
                    <div className="col-8 small">
                      <input
                        type="text"
                        placeholder="Custom Value ..."
                        required={!product.attributes.hasOwnProperty(attr)}
                        className={`form-control-plaintext form-control-sm ${valid_class} border bg-white px-2`}
                        value={product.attribute_value_overrides[attr] || ""}
                        onChange={(e) => setOptionValue(product, attr, e.currentTarget.value)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <button
            type="button"
            data-product-id={product.id}
            onClick={() => removeProduct(product)}
            className="btn btn-sm btn-danger"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </div>
      </div>
    </li>
  );
}
