import React, { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBold } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import {
  faBlockQuote,
  faItalic,
  faListUl,
  faListOl,
  faHeading,
  faStrikethrough,
  faParagraph,
  faTextSlash,
  faUndo,
  faRedo,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";

/**
 * Examples: https://tiptap.dev/examples/formatting
 *
 */
const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="flex items-center justify-start border-b">
      <div className="mx-2 my-2 inline-flex shadow-md hover:shadow-lg focus:shadow-lg" role="group">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          title="Bold"
          className={`editor-menu-btn left ${editor.isActive("bold") ? "active" : ""}`}
        >
          <FontAwesomeIcon icon={faBold} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          title="Italic"
          className={`editor-menu-btn ${editor.isActive("italic") ? "active" : ""}`}
        >
          <FontAwesomeIcon icon={faItalic} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          title="Strikethrough"
          className={`editor-menu-btn right ${editor.isActive("strike") ? "active" : ""}`}
        >
          <FontAwesomeIcon icon={faStrikethrough} />
        </button>
      </div>
      <div className="mx-2 inline-flex shadow-md hover:shadow-lg focus:shadow-lg" role="group">
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          title="Bullet list"
          className={`editor-menu-btn left ${editor.isActive("bulletList") ? "active" : ""}`}
        >
          <FontAwesomeIcon icon={faListUl} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          title="Ordered list"
          className={`editor-menu-btn ${editor.isActive("orderedList") ? "active" : ""}`}
        >
          <FontAwesomeIcon icon={faListOl} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          title="Blockquote"
          className={`editor-menu-btn right ${editor.isActive("blockquote") ? "active" : ""}`}
        >
          <FontAwesomeIcon icon={faBlockQuote} />
        </button>
      </div>

      <div className="mx-2 inline-flex shadow-md hover:shadow-lg focus:shadow-lg" role="group">
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          title="Paragraph"
          className={`editor-menu-btn left ${editor.isActive("paragraph") ? "active" : ""}`}
        >
          <FontAwesomeIcon icon={faParagraph} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          title="Heading"
          className={`editor-menu-btn ${editor.isActive("heading", { level: 1 }) ? "active" : ""}`}
        >
          <FontAwesomeIcon icon={faHeading} />
        </button>
        <button
          onClick={() => editor.chain().focus().clearNodes().run()}
          title="Clear formatting"
          className={`editor-menu-btn right`}
        >
          <FontAwesomeIcon icon={faTextSlash} />
        </button>
      </div>

      <div className="inline-flex shadow-md hover:shadow-lg focus:shadow-lg" role="group">
        <button
          onClick={() => editor.chain().focus().undo().run()}
          title="Undo"
          className="editor-menu-btn left"
        >
          <FontAwesomeIcon icon={faUndo} />
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          title="Redo"
          className="editor-menu-btn right"
        >
          <FontAwesomeIcon icon={faRedo} />
        </button>
      </div>
    </div>
  );
};

const TextEditor = ({ value, onChange, isEditable = true }) => {
  // TODO:
  // - Import only the extensions we need, eg: https://tiptap.dev/api/nodes/heading#usage
  // - Decide on a mininmum height?
  // - Add a "Upload attachment" button?
  // - Add a "Insert image" button?

  const editor = useEditor({
    editable: isEditable,
    extensions: [StarterKit],
    content: value,
    editorProps: {
      attributes: {
        class: "prose prose-sm p-5 focus:outline-none h-[270px] w-full overflow-y-scroll",
      },
    },
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onChange(html);
    },
  });

  useEffect(() => {
    if (!editor) {
      return undefined;
    }

    editor.setEditable(isEditable);
  }, [editor, isEditable]);

  if (!editor) {
    return null;
  }

  return (
    <div className={`rounded border shadow-sm ${!isEditable && "cursor-not-allowed opacity-50"}`}>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  );
};

export default TextEditor;
