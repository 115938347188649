import React, { useMemo, useState, useCallback, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { Button } from "../UI/components/Button";
import { Form, Input } from "../UI/components";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import axios from "axios";

export default function MarketplaceSignup({ account }) {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const onSubmit = async (data) => {
    setLoading(true);
    console.log("data", data);
    const formData = new FormData();

    for (const key in data) {
      if (!data[key] && data[key] !== false) {
        continue;
      }

      if (key === "user") {
        formData.append("account[user][name]", data[key].name);
        formData.append("account[user][email]", data[key].email);
      }

      if (key === "team") {
        formData.append("account[team][name]", data[key].name);
      }

      console.log("KEY", key);
      console.log("\tVALUE", data[key]);
    }

    if (account.user.id) {
      try {
        const result = await axios({
          method: "post",
          url: `/onboard/${account.user.id}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });

        console.log("result", result);

        setTimeout(() => {
          toast.success("Account updated");
          setLoading(false);
          location.href = result.data.url;
        }, 1500);
      } catch (e) {
        console.log("ERROR", e);
        toast.error("There was an error updating your account");
      }
    }
  };

  return (
    <>
      <Form onSubmit={onSubmit} defaultValues={account}>
        <div className="w-full">
          <h3 className="heading mb-6 text-3xl">Account Setup</h3>
          <div className="my-5 flex w-full flex-col space-y-1">
            <div className="grid w-2/3 gap-5">
              <div className="">
                <Input name="user.name" label="Your Name" type="text" required />
              </div>
              <div className="">
                <Input name="team.name" label="Company Name" type="text" required />
              </div>
              <div className="">
                <Input name="user.email" label="Email Address" type="email" required />
              </div>
            </div>
            <div className="pt-12">
              <Button
                type="submit"
                showLoading={loading}
                label="Continue"
                icon={faLongArrowRight}
                className="btn btn-lg animate !m-0"
              />
            </div>
          </div>
        </div>
      </Form>
      <Toaster />
    </>
  );
}
