import React, { useEffect, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useAsyncDebounce,
  useRowSelect,
  useBlockLayout,
} from "react-table";
import { Transition } from "@headlessui/react";
import { useSticky } from "react-table-sticky";

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <input
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      type="text"
      placeholder={`Search...`}
      className="mb-5 max-w-sm text-sm"
    />
  );
}

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <input
      type="text"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search...`}
      className="my-1 py-1 text-xs"
    />
  );
}

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input
        type="checkbox"
        ref={resolvedRef}
        {...rest}
        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
      />
    </>
  );
});

export default function Table({
  columns,
  data,
  onFetchData,
  onSelectedRows = () => {},
  loading,
  refresh,
  pageCount: controlledPageCount,
  totalItems = 0,
  useRowSelection = false,
  initialPageSize = 20,
  rowProps = (row) => {},
  sticky = false,
  showGlobalFilter = true,
}) {
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      // And also our default editable cell
      // Cell: EditableCell,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, sortBy, filters, globalFilter, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: initialPageSize },
      manualSortBy: true,
      manualFilters: true,
      manualPagination: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
      autoResetSelectedRows: false,
      getRowId: (row) => row.id,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    sticky && useBlockLayout,
    sticky && useSticky,
    (hooks) => {
      if (useRowSelection) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            width: "30px",
            disableSortBy: true,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  // When these table states change, fetch new data
  useEffect(() => {
    onFetchData({ pageIndex, pageSize, sortBy, filters, globalFilter });
  }, [onFetchData, pageIndex, pageSize, sortBy, filters, globalFilter, refresh]);

  useEffect(() => {
    onSelectedRows(Object.keys(selectedRowIds));
  }, [selectedRowIds]);

  // Render the UI for your table
  return (
    <div className="flex flex-col">
      <div className="">
        <div className="inline-block w-full min-w-full align-middle">
          <div className="">
            {showGlobalFilter && (
              <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
            )}
            <div className="w-full overflow-x-auto">
              <table className="w-full table-fixed divide-y divide-gray-200" {...getTableProps()}>
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps({
                            className: column.collapse
                              ? "px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider collapse"
                              : "px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-white",
                            // width: column.width,
                            style: {
                              minWidth: column.minWidth,
                              width: column.width,
                              maxWidth: column.maxWidth,
                            },
                          })}
                        >
                          <div
                            className="flex flex-wrap content-start justify-between"
                            {...column.getSortByToggleProps()}
                          >
                            <span className="mr-2 inline-block">{column.render("Header")}</span>
                            {column.disableSortBy ? (
                              <i></i>
                            ) : (
                              <i
                                className={`fad ${
                                  column.isSorted
                                    ? column.isSortedDesc
                                      ? "fa-sort-down"
                                      : "fa-sort-up"
                                    : "fa-sort"
                                }`}
                              ></i>
                            )}
                          </div>
                          {/* Render the columns filter UI */}
                          <div>{column.canFilter ? column.render("Filter") : null}</div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {loading && (
                    <tr>
                      <td colSpan={columns.length}>Loading ...</td>
                    </tr>
                  )}
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps(rowProps(row))} className="border-b">
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps({
                                className: cell.column.collapse
                                  ? "px-3 py-3 text-xs text-gray-500 collapse break-words"
                                  : "px-3 py-3 text-xs text-gray-500 break-words whitespace-normal",
                              })}
                            >
                              <span>{cell.render("Cell")}</span>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <nav
              className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="px-1 text-sm text-gray-700">
                  Showing <span className="font-medium">{pageIndex * pageSize + 1}</span> to{" "}
                  <span className="font-medium">
                    {Math.min(totalItems, (pageIndex + 1) * pageSize)}
                  </span>{" "}
                  of <span className="font-medium">{totalItems}</span> results
                </p>
              </div>
              <div className="flex flex-1 justify-between sm:justify-end">
                <button
                  type="button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Previous
                </button>
                <button
                  type="button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Next
                </button>
              </div>
            </nav>
            {/* <div className="flex justify-between items-center pt-4">
              <div className="pagination">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>{" "}
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </button>{" "}
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  {">"}
                </button>{" "}
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>{" "}
                <span className="text-sm text-indigo-500">
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
              </div>
              <select
                className="pl-4 py-3 inline-block w-auto rounded-lg pr-8"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
