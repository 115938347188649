import React from "react";
import { parseAlgoliaHitHighlight } from "@algolia/autocomplete-preset-algolia";

export function AutocompleteHighlight({ hit, attribute }) {
  return (
    <>
      {parseAlgoliaHitHighlight({
        hit,
        attribute,
      }).map(({ value, isHighlighted }, index) => {
        if (isHighlighted) {
          return (
            <mark key={index} className="bg-indigo-100 text-indigo-700">
              {value}
            </mark>
          );
        }

        return <React.Fragment key={index}>{value}</React.Fragment>;
      })}
    </>
  );
}
