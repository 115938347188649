import {
  faArrowsRotate,
  faUser,
  faArrowRightFromBracket,
  faBell,
  faCircleQuestion,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import AppSwitch from "../../UI/components/AppSwitch";
import Logo from "/app/assets/images/partbot-logo.svg";

export const Header = ({ app_contexts }) => {
  return (
    <div className="mb-3 flex h-[100] w-full items-center justify-between py-4">
      <div className="flex items-center space-x-4">
        <img src={Logo} width="130" height="auto" />
        <div className="flex items-center space-x-2">
          <AppSwitch context="distributor" app_contexts={app_contexts} />
        </div>
      </div>
      <div className="flex items-center justify-end space-x-6">
        <a href="/" className="mp-nav-link">
          Dashboard
        </a>

        <div className="flex items-center space-x-3">
          <a
            href="/logout"
            title="Logout"
            className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full p-2 text-indigo-500 transition hover:bg-indigo-600 hover:text-white"
          >
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
          </a>
          <a
            href="/account"
            title="Account"
            className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-white p-2 text-indigo-500 shadow-lg transition hover:bg-indigo-600 hover:text-white"
          >
            <FontAwesomeIcon icon={faUser} />
          </a>
        </div>
      </div>
    </div>
  );
};
