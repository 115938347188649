import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

export function VehicleSourceFilter({ filters, setFilter }) {
  const [value, setValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setFilter("source", value || undefined);
  }, 200);

  return (
    <select
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      type="text"
      placeholder={`Search...`}
      className=" mb-5 max-w-fit rounded-md border border-gray-300 py-2 pl-3 pr-10 text-sm shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
    >
      <option value="">Source</option>
      <option value="oscar">OSCAR</option>
      <option value="tecdoc">TecDoc</option>
      <option value="partbot">Partbot</option>
    </select>
  );
}
