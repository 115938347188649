import React from 'react';
import ReactDOM from 'react-dom';
import ExternalVehicleTranslationCard from "./ExternalVehicleTranslationCard";
import styles from "./ExternalVehicleModal.module.css"

const ExternalVehicleModal = ({ isShowing, hide, save, id }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>     
    <div className={styles.pb_modal_overlay}/>
    <div className={styles.pb_modal_wrapper} aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className={styles.pb_modal}>
        <div className={styles.pb_modal_header}>
          <button type="button" className={styles.pb_modal_close_button} data-dismiss={styles.pb_modal} aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ExternalVehicleTranslationCard id={id} onHide={hide} onSave={save} />
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default ExternalVehicleModal;