import React from "react";
import { useDebouncedCallback } from "use-debounce";

export const DebouncedInput = ({ defaultValue, onChange, debounceTime = 1000, ...props }) => {
  const debounced = useDebouncedCallback((value) => {
    onChange(value);
  }, debounceTime);

  return (
    <input defaultValue={defaultValue} onChange={(e) => debounced(e.target.value)} {...props} />
  );
};
