import React, { useState } from "react";

import axios from "axios";
import ReactOnRails from "react-on-rails";
import toast from "react-hot-toast";
import { faPencil, faTrashCan } from "@awesome.me/kit-989a8e6dbe/icons/classic/light";
import SubBrandEditor from "./SubBrandEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../UI/components";

export default function SubBrandsList({ brand }) {
  const [isSubBrandEditorOpen, setIsSubBrandEditorOpen] = useState(false);
  const [selectedSubBrand, setSelectedSubBrand] = useState(null);

  async function closeSubBrandModal() {
    setIsSubBrandEditorOpen(false);
  }

  async function openSubBrandModal() {
    setIsSubBrandEditorOpen(true);
  }

  async function handleRemoveSubBrand(id) {
    if (confirm("Do you really want to delete this Sub-Brand?")) {
      try {
        const result = await axios({
          method: "delete",
          url: `/admin/sub_brands/${id}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
        });
        toast.success("Sub-Brand removed from Brand");

        location.reload();
      } catch (e) {
        console.log(e);
        toast.error("Error removing Sub-Brand from Brand");
      }
    }
  }

  async function handleUpdateSubBrand(subBrand) {
    setSelectedSubBrand(subBrand);
    await openSubBrandModal();
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="grid grid-cols-2">
          <div>
            <label>Sub-Brands</label>
          </div>
          <div>
            <div className="flex items-center justify-end">
              <Button
                label="Add Sub-Brand"
                onClick={openSubBrandModal}
                className="btn btn-sm btn-light"
              />
            </div>
          </div>
        </div>
        {brand.sub_brands && brand.sub_brands.length > 0 && (
          <div className="mt-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
              <div>
                <table className="table-sm table w-full min-w-full divide-gray-200">
                  <tbody>
                    {brand.sub_brands.map((subBrand, index) => (
                      <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                        <td className="whitespace-normal break-words px-6 py-4 text-sm text-gray-500">
                          {subBrand.name}
                        </td>
                        <td className="px-6 py-4 text-right text-sm font-medium">
                          <button
                            onClick={() => {
                              handleUpdateSubBrand(subBrand);
                            }}
                            type="button"
                            className="text-info-600 hover:text-danger-900"
                          >
                            <FontAwesomeIcon icon={faPencil} />
                          </button>
                          <span className="px-4" />
                          <button
                            onClick={() => {
                              handleRemoveSubBrand(subBrand.id);
                            }}
                            type="button"
                            className="text-danger-600 hover:text-danger-900"
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>

      <SubBrandEditor
        closeModal={closeSubBrandModal}
        isOpen={isSubBrandEditorOpen}
        subBrand={selectedSubBrand}
        brand={brand}
      />
    </>
  );
}
