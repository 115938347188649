import React from "react";

export const FulfillmentUnitTitle = ({ unit }) => (
  <>
    <div className="flex">
      <span className="py-1 text-sm text-indigo-500">{unit.identifier}</span>
    </div>
    <div className="flex space-x-1.5 text-xs text-gray-400">
      <span className="">{unit.formatted_dimensions}</span>
      <span className="text-xs text-gray-400">•</span>
      <span className="">{unit.formatted_weight} each</span>
    </div>
  </>
);
