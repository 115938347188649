import React, { useEffect } from "react";
import { connectHits } from "react-instantsearch-dom";
import { motion } from "framer-motion";
import ProductCard from "./ProductCard";

export const ProductHits = connectHits(
  ({
    hits,
    onViewMore,
    onSelected,
    selectedIds,
    onCurrentHitsChange,
    searchQuery,
    publishedStores,
    totalStoreCount,
  }) => {
    useEffect(() => {
      onCurrentHitsChange(hits);
    }, [hits.length]);

    return (
      <div className="mt-3 grid gap-3 pb-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
        {hits.map((hit) => {
          const isSelected = selectedIds.includes(hit.objectID);
          const isExactMatch =
            !!searchQuery &&
            hit["variants"].map((v) => v.toLowerCase()).includes(searchQuery.toLowerCase());

          const importedStores = publishedStores[hit.objectID] || [];
          const isImported = !!importedStores.length && importedStores.length === totalStoreCount;
          const isPartiallyImported =
            !!importedStores.length && importedStores.length !== totalStoreCount;

          return (
            <motion.div key={hit.objectID} className="h-full w-full">
              <motion.div key={hit.objectID} className="h-full w-full" layoutId={hit.objectID}>
                <ProductCard
                  product={hit}
                  onViewMore={onViewMore}
                  onSelected={onSelected}
                  isSelected={isSelected}
                  isExactMatch={isExactMatch}
                  isImported={isImported}
                  isPartiallyImported={isPartiallyImported}
                  importedStores={importedStores}
                />
              </motion.div>
            </motion.div>
          );
        })}
      </div>
    );
  }
);
