import React, { useEffect, useState } from "react";
import { Button, Form, FormModal, TextArea } from "../../UI/components";
import toast from "react-hot-toast";
import axios from "axios";

export default function ProductIssueModal({
  closeModal,
  isOpen,
  selectedProduct,
  issueResponseDays,
}) {
  const [formLoading, setFormLoading] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [isIssueSent, setIsIssueSent] = useState(false);

  const onCancel = () => {
    closeModal(null);
  };

  useEffect(() => {
    if (selectedProduct) {
      setBrandName(selectedProduct.brand);
    }
  }, [selectedProduct]);

  const onSubmit = async (data) => {
    setFormLoading(true);
    const formData = new FormData();

    formData.append("product_issue[listing_id]", selectedProduct.listing_id);
    formData.append("product_issue[issue]", data.issue);

    try {
      const result = await axios({
        method: "post",
        url: `/product-issues`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: formData,
      });

      toast.success("Issue reported successfully");
      setFormLoading(false);
      setIsIssueSent(true);
    } catch (e) {
      console.log("ERROR", e);
      toast.error("There was an error reporting the issue");
      setFormLoading(false);
    }
  };

  return (
    <>
      <FormModal title="Report Issue" zIndex="50" size="lg" {...{ isOpen, onCancel }}>
        {!isIssueSent && (
          <Form onSubmit={onSubmit}>
            <p className="mt-1 text-sm text-gray-500">Send a product issue report to {brandName}</p>
            <div className="pt-3">
              <div className="grid grid-cols-1">
                <div className="space-y-6">
                  <div className="form-group">
                    <TextArea name="issue" label="Details" rows={5} required />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end">
              <div className="btn-group flex items-center space-x-2">
                <Button
                  type="button"
                  className="btn btn-neutral"
                  onClick={onCancel}
                  label="Cancel"
                />
                <Button type="submit" label="Submit" className="btn" showLoading={formLoading} />
              </div>
            </div>
          </Form>
        )}

        {isIssueSent && (
          <div className="pt-5">
            <p className="text-sm text-gray-500">
              Thank you for submitting an Issue Report. If your issue remains unresolved after{" "}
              {issueResponseDays} days, please contact us at support@partbot.io or via the chat
              feature.
            </p>
            <div className="mt-5 flex items-center justify-end">
              <div className="btn-group flex items-center space-x-2">
                <Button type="button" className="btn" onClick={onCancel} label="Close" />
              </div>
            </div>
          </div>
        )}
      </FormModal>
    </>
  );
}
