import React, { Fragment, useState, useEffect, useRef } from "react";
import Avatar, { ConfigProvider } from "react-avatar";
import { Dialog, Menu, Transition, FocusTrap } from "@headlessui/react";
import { SearchIcon, StarIcon } from "@heroicons/react/outline";
import { SelectorIcon, StarIcon as SolidStarIcon } from "@heroicons/react/solid";
import axios from "axios";
import ReactOnRails from "react-on-rails";
import { classNames } from "../../../utils";
import { Button } from "./Button";

export default function BrandSwitch({ current_brand, brands, favourite_brands, user_id }) {
  // return if current_brand is nil
  if (!current_brand) {
    return null;
  }

  const [mergedBrands, setMergedBrands] = useState([]);
  const [favouriteBrands, setFavouriteBrands] = useState(favourite_brands);
  const [query, setQuery] = useState("");
  const [brandsList, setBrandsList] = useState([]);

  useEffect(() => {
    setMergedBrands(
      brands.map((x) => ({
        id: x.id,
        name: x.name,
        slug: x.slug,
        favourite: favouriteBrands.filter((f) => f.id === x.id).length > 0,
        code: x.code,
      }))
    );
  }, [favouriteBrands]);

  useEffect(() => {
    if (query) {
      setBrandsList(
        mergedBrands.filter((x) => x.name.toLowerCase().includes(query.toLocaleLowerCase()))
      );
    } else {
      setBrandsList(mergedBrands);
    }
  }, [query, mergedBrands]);

  const switchBrand = async (brand_id) => {
    try {
      const result = await axios({
        url: `/users/${user_id}/brand`,
        method: "post",
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: {
          brand_id: brand_id,
        },
      });

      location.replace("/dashboard");
    } catch (e) {
      console.log(e);
    }
  };

  const favouriteBrand = async (e, brandId) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const result = await axios({
        url: `/users/${user_id}/favourite-brand`,
        method: "post",
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: {
          brand_id: brandId,
        },
      });

      setFavouriteBrands((prev) => [...prev, { id: brandId }]);
    } catch (e) {
      console.log(e);
    }
  };

  const unfavouriteBrand = async (e, brandId) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const result = await axios({
        url: `/users/${user_id}/favourite-brand`,
        method: "delete",
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: {
          brand_id: brandId,
        },
      });

      setFavouriteBrands((prev) => [...prev.filter((x) => x.id !== brandId)]);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Menu as="div" className="w-100 relative text-left">
      <div>
        <Menu.Button className="group w-full rounded-md bg-white px-2 py-2 text-left text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">
          <span className="flex w-full items-center justify-between">
            <span className="flex min-w-0 items-center justify-between space-x-3">
              <ConfigProvider
                colors={[
                  "#EF4444",
                  "#F59E0B",
                  "#10B981",
                  "#3B82F6",
                  "#6366F1",
                  "#8B5CF6",
                  "#EC4899",
                ]}
              >
                {/* use first two letters of brand if one word, or the first letter of the first two words if more than one word name */}
                <Avatar
                  initials={(name) =>
                    name && name.split(" ").length > 1
                      ? name.split(" ")[0][0] + name.split(" ")[1][0]
                      : name.slice(0, 2)
                  }
                  name={current_brand.name}
                  maxInitials={3}
                  size={40}
                  className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                />
              </ConfigProvider>
              <span className="flex min-w-0 flex-1 flex-col">
                <span className="truncate text-sm font-medium text-gray-900">
                  {current_brand.name}
                </span>
                {/* <span className="truncate text-sm text-gray-500">@{current_brand.slug}</span> */}
              </span>
            </span>
            <SelectorIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as="div"
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          as="div"
          className="absolute left-0 right-0 z-10 mt-1 max-h-screen origin-top divide-y divide-gray-200 overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          {mergedBrands.length > 3 && (
            <div className="p-1">
              <div className="relative ">
                <input
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.currentTarget.value)}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </div>
          )}
          <div className="max-h-64 overflow-y-scroll py-1">
            <Menu.Item as="div">
              <div className="p-2">
                <Button
                  size="sm"
                  className="btn btn-sm btn-light w-full flex-grow"
                  onClick={() => location.replace("/team/brands/new")}
                  label="Create New Brand"
                />
              </div>
            </Menu.Item>
          </div>
          {favouriteBrands && favouriteBrands.length > 0 && (
            <div className="max-h-64 overflow-y-scroll py-1">
              <span className="px-2 py-4 text-xs font-medium uppercase tracking-wider text-gray-400">
                Favourites
              </span>
              {mergedBrands
                .filter((x) => x.favourite)
                .map((item) => (
                  <Menu.Item as="div" key={`favs-${item.name}`}>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "flex items-center justify-between"
                        )}
                      >
                        <button
                          type="button"
                          onClick={() => switchBrand(item.id)}
                          className={classNames(
                            active ? " text-gray-900" : "text-gray-700",
                            "flex-grow px-3 py-2.5 text-left text-sm font-medium leading-tight"
                          )}
                        >
                          {item.name}
                        </button>
                        {item.favourite && (
                          <SolidStarIcon
                            className={classNames(
                              active ? " text-indigo-700" : "text-indigo-500",
                              "mx-3 h-5 w-5 cursor-pointer hover:text-indigo-900"
                            )}
                            onClick={(e) => unfavouriteBrand(e, item.id)}
                            aria-hidden="true"
                          />
                        )}
                      </div>
                    )}
                  </Menu.Item>
                ))}
            </div>
          )}
          <div className="max-h-64 overflow-y-scroll py-1">
            <span className="px-2 py-4 text-xs font-medium uppercase tracking-wider text-gray-400">
              My Brands
            </span>

            {brandsList.map((item) => (
              <Menu.Item as="div" className="" key={`brands-${item.name}`}>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "flex items-center justify-between"
                    )}
                  >
                    <button
                      type="button"
                      onClick={() => switchBrand(item.id)}
                      className={classNames(
                        active ? " text-gray-900" : "text-gray-700",
                        "flex-grow px-3 py-2.5 text-left text-sm font-medium leading-tight"
                      )}
                    >
                      {item.name}
                    </button>
                    {item.favourite && (
                      <SolidStarIcon
                        className={classNames(
                          active ? " text-indigo-700" : "text-indigo-500",
                          "mx-3 h-5 w-5 cursor-pointer hover:text-indigo-900"
                        )}
                        onClick={(e) => unfavouriteBrand(e, item.id)}
                        aria-hidden="true"
                      />
                    )}
                    {!item.favourite && (
                      <StarIcon
                        className={classNames(
                          active ? " text-gray-300" : "text-gray-200",
                          "mx-3 h-5 w-5 cursor-pointer hover:text-indigo-500"
                        )}
                        onClick={(e) => favouriteBrand(e, item.id)}
                        aria-hidden="true"
                      />
                    )}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
