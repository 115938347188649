import React from "react";
import { productImage, brandImage } from "../../utils";
import useMerchantProductLabels from "../../hooks/useMerchantProductLabels";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkSimple,
  faStore,
  faArrowsRotate,
  faLinkSimpleSlash,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import ProductImage from "../../../UI/components/ProductImage";
import clsx from "clsx";

const statusIcon = (status) => {
  if (status === "Linked") {
    return faLinkSimple;
  } else if (status === "Synced") {
    return faArrowsRotate;
  } else return faLinkSimpleSlash;
};

export const Card = ({ product, currentProductLoading, onViewMore, isExactMatch = false }) => {
  const imgSrc = productImage(product);
  const brandImgSrc = brandImage(product);

  const { storeTitle, statusTextClass, statusBgClass } = useMerchantProductLabels(product);

  return (
    <div className="group relative h-full w-full">
      <div className="z-10 h-full min-h-max">
        <div
          className={`relative flex h-full w-full flex-col justify-between space-y-3 rounded-lg border-4  bg-white p-2 shadow transition hover:shadow-xl hover:shadow-indigo-900/20 group-hover:z-20 ${
            isExactMatch ? "border-indigo-500" : "border-white"
          }`}
        >
          <div>
            <div className="h-50 relative aspect-square overflow-hidden">
              <ProductImage src={imgSrc} />
              <div
                onClick={() => onViewMore(product)}
                className="absolute bottom-0 left-0 right-0 top-0 mx-auto flex cursor-pointer items-center justify-center opacity-0 transition duration-300 group-hover:opacity-100"
              >
                <button className="btn">
                  {currentProductLoading ? "Loading..." : "View Details"}
                </button>
              </div>
            </div>
            {brandImgSrc && (
              <div className="mt-1 flex text-xs font-bold uppercase tracking-tight text-gray-800">
                <img src={brandImgSrc} className="h-[16px] w-auto" alt={product.brand} />
              </div>
            )}

            <h5 className="mt-3 block text-xs font-medium text-gray-500">{product.title}</h5>
            {/* show listing sku if it exists */}
            {product.sku && (
              <div className="mt-2 flex items-center space-x-1">
                <FontAwesomeIcon icon={faStore} className="text-xs text-gray-400" />
                <span className="text-[9px] text-xs font-medium text-gray-400">{product.sku}</span>
              </div>
            )}
            {/* list skus, if variants exist */}
            {product.variants && product.variants.length > 0 && (
              <div className="mt-2 flex flex-col space-y-0.5">
                {product.variants.map((variant) => (
                  <div key={variant.sku} className="flex items-center space-x-1">
                    <span className="text-[9px] text-xs font-medium text-gray-400">
                      {variant.sku}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="grid">
            <div className="flex flex-wrap items-center gap-1">
              <div
                className={`rounded ${statusBgClass} space-x-1 px-2 py-1 text-[10px] ${statusTextClass} capitalize`}
                title={storeTitle}
              >
                <FontAwesomeIcon icon={statusIcon(product.status)} />
                <span>{product.status}</span>
              </div>

              <div
                className={clsx(
                  "space-x-1 rounded px-2 py-1 text-[10px]",
                  product.published
                    ? "bg-emerald-50 text-emerald-700"
                    : "bg-stone-50 text-stone-500"
                )}
              >
                {product.published ? "Published" : "Unpublished"}
              </div>
            </div>
            <div>
              <div
                className="inline-flex w-auto space-x-1 rounded bg-indigo-50 px-2 py-1 text-[10px] text-indigo-500"
                title={storeTitle}
              >
                <FontAwesomeIcon icon={faStore} />
                <span>{product?.store?.name}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
