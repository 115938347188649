import React, { useState, useRef } from "react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";

function useBrands(searchTerm) {
  const [brands, setBrands] = React.useState([]);
  React.useEffect(() => {
    if (searchTerm.trim() !== "") {
      let isFresh = true;
      fetchBrands(searchTerm).then((brands) => {
        if (isFresh) setBrands(brands);
      });
      return () => (isFresh = false);
    }
  }, [searchTerm]);
  return brands;
}

const cache = {};
function fetchBrands(value) {
  if (cache[value]) {
    return Promise.resolve(cache[value]);
  }
  return fetch("/brand_search?term=" + value)
    .then((res) => res.json())
    .then((result) => {
      cache[value] = result;
      return result;
    });
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BrandCombobox({ selectedOption, handleBrandSelect }) {
  const [query, setQuery] = useState("");
  const [selectedBrand, setSelectedBrand] = useState(selectedOption);
  const brands = useBrands(query);

  const handleSelect = (value) => {
    setSelectedBrand(value);
    handleBrandSelect(value);
  };

  return (
    <Combobox as="div" value={selectedBrand} onChange={(e) => handleSelect(e)}>
      <Combobox.Label>Brand</Combobox.Label>
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(brand) => (brand === null ? "" : brand.name)}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {brands.length > 0 && (
          <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {brands.map((brand) => (
              <Combobox.Option
                key={brand.id}
                value={brand}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames("block truncate", selected && "font-semibold")}>
                      {brand.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
