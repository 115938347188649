import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Form, Input, Select, FormModal, Button } from "../../UI/components";
import { InputWithAddon } from "../../UI/components/Controls";

export default function BoxModal({ closeModal, isOpen, options }) {
  const [isLoading, setIsLoading] = useState(false);
  const THICKNESS = parseFloat(0.2);

  const label_options = [
    { value: "0", label: "4x6", min_width: 11, min_length: 16 },
    { value: "1", label: "A5", min_width: 16, min_length: 22 },
    { value: "2", label: "A4", min_width: 22, min_length: 29 },
  ];
  const [currentLabel, setCurrentLabel] = useState(label_options[0]);
  const [currentOuterDimensions, setCurrentOuterDimensions] = useState({
    length: label_options[0].min_length,
    width: label_options[0].min_width,
    height: 1,
  });

  const [currentInternalDimensions, setCurrentInternalDimensions] = useState({
    length: label_options[0].min_length - THICKNESS,
    width: label_options[0].min_width - THICKNESS,
    height: 1 - THICKNESS,
  });

  // usecallback and change the minimum value of width when the currentLabel changes
  const handleLabelChange = (value) => {
    const label = label_options.find((l) => l.value === value);

    // check if outer dimensions are smaller than selected label width and height
    console.log(currentOuterDimensions.length < label.min_length);
    if (currentOuterDimensions.length < label.min_length) {
      // set the outer dimensions to the minimum width
      setCurrentOuterDimensions({
        ...currentOuterDimensions,
        length: label.min_length,
      });
    }

    if (currentOuterDimensions.width < label.min_width) {
      // set the outer dimensions to the minimum width
      setCurrentOuterDimensions({
        ...currentOuterDimensions,
        width: label.min_width,
      });
    }

    setCurrentLabel(label);
  };

  useEffect(() => {
    if (
      parseFloat(currentInternalDimensions.length) + THICKNESS >
      parseFloat(currentOuterDimensions.length)
    ) {
      setCurrentInternalDimensions({
        ...currentInternalDimensions,
        length: (parseFloat(currentOuterDimensions.length) - THICKNESS).toFixed(1),
      });
    }

    if (
      parseFloat(currentInternalDimensions.width) + THICKNESS >
      parseFloat(currentOuterDimensions.width)
    ) {
      setCurrentInternalDimensions({
        ...currentInternalDimensions,
        width: (parseFloat(currentOuterDimensions.width) - THICKNESS).toFixed(1),
      });
    }

    if (
      parseFloat(currentInternalDimensions.height) + THICKNESS >
      parseFloat(currentOuterDimensions.height)
    ) {
      setCurrentInternalDimensions({
        ...currentInternalDimensions,
        height: (parseFloat(currentOuterDimensions.height) - THICKNESS).toFixed(1),
      });
    }
  }, [currentOuterDimensions]);

  const onCancel = () => {
    closeModal(null);
  };

  const onSubmit = (data) => {
    // fake delay so user can see something is happening
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      closeModal(data);
    }, 1000);
  };

  return (
    <FormModal title="Add Box" {...{ onCancel, isOpen }}>
      <Form onSubmit={onSubmit}>
        <div className="my-4">
          <div className="grid grid-cols-3 gap-3">
            <div className="form-group col-span-2">
              <Input label="Identifier / Name" name="name" type="text" className="my-4" required />
            </div>
            <div className="form-group">
              <Select
                label="Label Size"
                name="label_size"
                options={label_options}
                onChange={(e) => handleLabelChange(e.target.value)}
              />
            </div>
          </div>

          <div className="mt-1 grid grid-cols-3 gap-3">
            <div className="heading-sm col-span-3">Outer Dimensions</div>
            <div className="form-group">
              <InputWithAddon
                label="Length"
                addon="cm"
                name="length"
                type="number"
                className="my-4"
                step={0.1}
                min={currentLabel.min_length}
                onChange={(e) =>
                  setCurrentOuterDimensions({ ...currentOuterDimensions, length: e.target.value })
                }
                value={currentOuterDimensions.length}
                required
              />
            </div>

            <div className="form-group">
              <InputWithAddon
                label="Width"
                addon="cm"
                name="width"
                type="number"
                className="my-4"
                step={0.1}
                min={currentLabel.min_width}
                onChange={(e) =>
                  setCurrentOuterDimensions({ ...currentOuterDimensions, width: e.target.value })
                }
                value={currentOuterDimensions.width}
                required
              />
            </div>
            <div className="form-group">
              <InputWithAddon
                label="Height"
                addon="cm"
                name="height"
                type="number"
                className="my-4"
                step={0.1}
                min={1}
                onChange={(e) =>
                  setCurrentOuterDimensions({ ...currentOuterDimensions, height: e.target.value })
                }
                value={currentOuterDimensions.height}
                required
              />
            </div>
          </div>

          <div className="mt-1 grid grid-cols-3 gap-3">
            <div className="heading-sm col-span-3">Internal Dimensions</div>
            <div className="form-group">
              <InputWithAddon
                label="Length"
                addon="cm"
                name="internal_length"
                type="number"
                className="my-4"
                step={0.1}
                min={1}
                max={currentOuterDimensions.length - THICKNESS}
                onChange={(e) =>
                  setCurrentInternalDimensions({
                    ...currentInternalDimensions,
                    length: e.target.value,
                  })
                }
                value={currentInternalDimensions.length}
                required
              />
            </div>

            <div className="form-group">
              <InputWithAddon
                label="Width"
                addon="cm"
                name="internal_width"
                type="number"
                className="my-4"
                step={0.1}
                min={1}
                max={currentOuterDimensions.width - THICKNESS}
                onChange={(e) =>
                  setCurrentInternalDimensions({
                    ...currentInternalDimensions,
                    width: e.target.value,
                  })
                }
                value={currentInternalDimensions.width}
                required
              />
            </div>
            <div className="form-group">
              <InputWithAddon
                label="Height"
                addon="cm"
                name="internal_height"
                type="number"
                className="my-4"
                step={0.1}
                min={0.8}
                max={currentOuterDimensions.height - THICKNESS}
                onChange={(e) =>
                  setCurrentInternalDimensions({
                    ...currentInternalDimensions,
                    height: e.target.value,
                  })
                }
                value={currentInternalDimensions.height}
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-3 gap-3">
            <div className="form-group">
              <InputWithAddon
                label="Weight Limit"
                addon="kg"
                name="weight_limit"
                type="number"
                className="my-4"
                step={0.001}
                min={0.001}
                required
              />
            </div>
            <div className="form-group">
              <InputWithAddon
                label="Box Weight"
                addon="kg"
                name="weight"
                type="number"
                className="my-4"
                step={0.001}
                min={0.001}
                required
              />
            </div>
            <div className="form-group">
              <InputWithAddon
                label="Internal Buffer"
                addon="cm"
                name="internal_buffer"
                type="number"
                className="my-4"
                step={0.001}
                min={0.001}
                required
              />
            </div>
          </div>
        </div>
        <div className="m-0 mt-4 flex justify-end">
          <div className="btn-group">
            <Button label="Cancel" type="button" className="btn btn-neutral" onClick={onCancel} />
            <Button label="Save" type="submit" className="btn" showLoading={isLoading} />
          </div>
        </div>
      </Form>
    </FormModal>
  );
}
