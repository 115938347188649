import React from "react";
import axios from "axios";
import Table from "../../Application/components/Table";

function ExternalVehicleTranslation() {
  const [data, setData] = React.useState({
    translations: [],
    internal_terms: [],
    external_terms: [],
  });
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);

  const fetchData = React.useCallback(async () => {
    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      params: {
        section: "body",
      },
    };
    setLoading(true);

    const result = await axios.get(
      "/admin/external_vehicles/global_translation",
      requestConfig
    );
    setData(result.data);

    setPageCount(Math.ceil(result.data.count / 1));

    setLoading(false);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Source",
        accessor: "external_vehicle_source.name",
      },
      {
        Header: "External Term",
        accessor: "term",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Partbot Term",
        accessor: "vehicle_body.name",
      },
      {
        Header: " ",
        sortable: false,
      },
    ],
    []
  );

  return (
    <>
      <Table
        columns={columns}
        data={data.translations}
        onFetchData={fetchData}
        loading={loading}
        pageCount={1}
      />
    </>
  );
}

export default ExternalVehicleTranslation;
