import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";
import React, { useState } from "react";

const ProductImage = ({ src, className }) => {
  const [currentSrc, setCurrentSrc] = useState(src);
  const [imageError, setImageError] = useState(false);

  const handleError = () => {
    setImageError(true);
  };

  const defaultClasses =
    "h-full w-full rounded-lg bg-white object-contain object-center group-hover:opacity-30 lg:h-full lg:w-full";

  const finalClassName = className ? `${defaultClasses} ${className}` : defaultClasses;

  if (imageError || currentSrc === null) {
    return (
      <div className={finalClassName}>
        <div className="flex h-full w-full flex-col items-center justify-center space-y-4">
          <FontAwesomeIcon icon={faImage} className="text-3xl text-gray-500" />
          <div className="text-center text-[9px] text-gray-500">Image unavailable</div>
        </div>
      </div>
    );
  }

  return <img src={currentSrc} onError={handleError} className={finalClassName} />;
};

export default ProductImage;
