import { faArrowsRotate } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";
import { faCheck, faTimes } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, FormModal } from "../UI/components";
import Api from "./api";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MarketplaceDistributors({
  team,
  import_listings_count,
  merchant_products_count,
  app_contexts,
  distributors,
  accounts,
  stores,
}) {
  const marketplaceAPI = new Api(team.api_key, location);

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disconnectLoading, setDisconnectLoading] = useState(false);
  const [distributorAccounts, setDistributorAccounts] = useState(accounts);

  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState({
    id: null,
    distributor_id: null,
    store_id: null,
    account_code: "",
    team_id: team.id,
  });

  const hasAccount = (distributor, store) => {
    if (!distributor) {
      return false;
    }
    if (!store) {
      return false;
    }
    return distributorAccounts.some(
      (acc) => acc.distributor_id === distributor.id && acc.store_id === store.id
    );
  };

  const accountFor = (distributor, store) => {
    if (!distributor) {
      return null;
    }
    if (!store) {
      return null;
    }
    return distributorAccounts.find(
      (acc) => acc.distributor_id === distributor.id && acc.store_id === store.id
    );
  };

  const configureDistributorAccount = (distributor, store) => {
    setSelectedDistributor(distributor);
    setSelectedStore(store);

    let account = distributorAccounts.find(
      (acc) => acc.distributor_id === distributor.id && acc.store_id === store.id
    );
    if (!account) {
      account = {
        id: null,
        distributor_id: distributor.id,
        store_id: store.id,
        account_code: "",
        team_id: team.id,
      };
    }

    setSelectedAccount(account);
    setOpenModal(true);
  };

  const connectDistributor = async () => {
    setLoading(true);
    const connectDistributorAccount = await marketplaceAPI.connectDistributorAccount(
      selectedAccount
    );
    // add physical delay, as instant response feels broken
    setTimeout(() => {
      setDistributorAccounts(connectDistributorAccount.accounts);

      setLoading(false);
      setOpenModal(false);
    }, 2000);
  };

  const handleDisconnection = async () => {
    setDisconnectLoading(true);

    const disconnectDistributorAccount = await marketplaceAPI.disconnectDistributorAccount(
      selectedAccount
    );

    setTimeout(() => {
      const adjAccounts = distributorAccounts.filter((acc) => acc.id !== selectedAccount.id);
      setDistributorAccounts(adjAccounts);
      setDisconnectLoading(false);
      setOpenModal(false);
    }, 2000);
  };

  return (
    <>
      <Header section="Distributors" app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar
              team={team}
              importListCount={import_listings_count}
              merchantProductsCount={merchant_products_count}
            />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Distributors</h2>
            <div className="prose flex flex-col space-y-5 text-gray-500">
              <p>
                If you have direct trade accounts with our Distributors you can link your account
                here.
              </p>
            </div>
            {stores.map((store) => (
              <div className="flexjustify-between rounded-lg bg-white p-4 shadow-md" key={store.id}>
                <div className="mb-3 space-y-0 leading-4">
                  <h3 className="mb-0 block text-sm font-extrabold leading-none tracking-tight text-indigo-600">
                    {store.name}
                  </h3>
                  <span className="-mt-1 text-xs text-gray-400">{store.platform}</span>
                </div>

                <div className="grid grid-cols-2 gap-5 md:grid-cols-4 xl:grid-cols-5">
                  {distributors.map((distributor) => (
                    <div
                      className={`relative flex flex-col items-start justify-between gap-3 rounded-lg p-4 ${
                        hasAccount(distributor, store)
                          ? "border-success-300 border-2 bg-white"
                          : "bg-gray-50"
                      }`}
                      key={distributor.id}
                    >
                      {hasAccount(distributor, store) && (
                        <div className="bg-success-50 text-success-600 absolute right-2 top-2 z-10 flex  h-6 items-center justify-center rounded-full p-2 text-[10px]">
                          <span className="ml-1 mr-2">
                            {accountFor(distributor, store)?.account_code}
                          </span>

                          <FontAwesomeIcon icon={faCheck} className="h-3 w-3" />
                        </div>
                      )}
                      <div className="inset-0 flex h-full rounded-md bg-white p-6">
                        {distributor.logo && (
                          <img
                            src={distributor.logo}
                            className="h-auto w-full object-contain object-center p-2"
                          />
                        )}
                      </div>
                      <div className="flex w-full flex-col items-start justify-start gap-6">
                        <div className="space-y-0 leading-4">
                          <h3 className="mb-0 block text-sm font-extrabold leading-none tracking-tight">
                            {distributor.name}
                          </h3>
                          <span className="-mt-1 text-xs text-gray-400">{distributor.website}</span>
                        </div>
                        <button
                          onClick={() => configureDistributorAccount(distributor, store)}
                          className={classNames(
                            "btn btn-sm !m-0",
                            hasAccount(distributor, store) ? "btn-light" : "btn-light"
                          )}
                        >
                          {hasAccount(distributor, store) ? "Configure" : "Connect"}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <FormModal
        title={`Distributor Account (${selectedStore?.name})`}
        isOpen={openModal}
        onCancel={setOpenModal}
        size="xl"
      >
        <p className="mt-3 text-sm text-gray-500">
          Providing your account code here will fetch your account level pricing and all purchases
          will be processed by the distributor according to your account payment terms.
        </p>
        <div className="form-group mt-5">
          <label>Account Code</label>
          <input
            type="text"
            value={selectedAccount.account_code || ""}
            onChange={(e) => {
              setSelectedAccount({ ...selectedAccount, ...{ account_code: e.target.value } });
            }}
            required
          />
        </div>
        <div className="btn-group">
          {hasAccount(selectedDistributor, selectedStore) && (
            <Button
              label="Disconnect"
              icon={faTimes}
              onClick={handleDisconnection}
              showLoading={disconnectLoading}
              className="btn btn-lg btn-danger !m-0 w-full"
            />
          )}

          <Button
            showLoading={loading}
            onClick={connectDistributor}
            label={`Connect to ${selectedDistributor?.name}`}
            icon={faArrowsRotate}
            className="btn btn-lg !m-0 w-full"
          />
        </div>
      </FormModal>
    </>
  );
}
