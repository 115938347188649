import React from 'react'

function ExternalVehicleAttribute({attributeTitle, externalValue, partbotValue, useExternalValue, handleUseExternalValue, isApproved}) {

    const toTitleCase = (title) => {
        return title.replace("_", " ").replace(/([a-z])([A-Z])/g, (allMatches, firstMatch, secondMatch) => {
            return firstMatch + " " + secondMatch
        })
            .toUpperCase()
            .replace(/\b([\w])([\w]+)\b/g,  (allMatches, firstMatch, secondMatch) => {
            return firstMatch.toUpperCase() + secondMatch.toLowerCase()
        })
    }

    const handleExternalValueChange = (e) => {
        handleUseExternalValue({attribute: attributeTitle, use: e.currentTarget.checked})
    }

    return (   
        <div className={`row py-1 border-bottom ${useExternalValue ? 'border-success shadow-sm' : ''}`}>
            <div className={`col-5 ${useExternalValue ? 'text-dark' : 'text-muted'} `}><strong>{toTitleCase(attributeTitle)}</strong></div> 
            <div className={`col-6 ${(externalValue && externalValue.toString().toLowerCase()) == (partbotValue && partbotValue.toString().toLowerCase()) ? '' : 'text-danger'}`}>{externalValue}</div>
            <div className="col-1"><input type="checkbox" disabled={!isApproved} checked={useExternalValue} onChange={e => handleExternalValueChange(e)}/></div>
        </div>
    )
}

export default ExternalVehicleAttribute