import React, {useState} from "react";
import {Form, FormModal, Input, TextArea} from "../UI/components";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

export default function SubBrandEditor({ closeModal, isOpen, subBrand = null, brand }) {

  const [formLoading, setFormLoading] = useState(false);

  const onCancel = () => {
    closeModal(null);
  };

  const onSubmit = async (data) => {
    console.log("data", data);
    const formData = new FormData();

    formData.append("sub_brand[name]", data.name);
    formData.append("sub_brand[brand_id]", brand.id);
    if (subBrand) {
      console.log("formData", formData);

      try {
        const result = await axios({
          method: "patch",
          url: `/admin/sub_brands/${subBrand.id}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });

        toast.success("Sub-Brand updated");
        location.reload();
      } catch (e) {
        console.log("ERROR", e);
        toast.error("There was an error updating the Sub-Brand");
      }
    } else {
      try {
        const result = await axios({
          method: "post",
          url: `/admin/sub_brands`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });

        console.log(result);

        toast.success("Sub-Brand created");
        location.reload();
      } catch (e) {
        console.log("ERROR", e);
        toast.error("There was an error creating the Sub-Brand.");
      }
    }
  };

  return (
    <>
      <FormModal title="Add New Sub-Brand" {...{ isOpen, onCancel }}>
        <Form onSubmit={onSubmit} defaultValues={subBrand}>
          <div className="py-5">
            <div className="grid grid-cols-1">
              <div className="space-y-6">
                <div className="form-group">
                  <Input name="name" label="Name" type="text" required />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end pb-5">
            <div className="btn-group flex items-center space-x-2">
              <button type="button" className="btn btn-neutral" onClick={onCancel}>
                Cancel
              </button>
              <button type="submit" className="btn">
                Save
              </button>
            </div>
          </div>
        </Form>
      </FormModal>
    </>
  );
}
