import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import DropshipHandlingRates from "./DropshipHandlingRates";
import { Button, Checkbox, Form, Input, Toggle } from "../../UI/components";
import { Disclosure } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronDown,
  faLocationDot,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { LocationAutocomplete } from "../../UI/components/LocationAutocomplete";
import { Toaster, toast } from "react-hot-toast";
import dayjs from "dayjs";
import { AdvancedMarker, Map } from "@vis.gl/react-google-maps";
var objectSupport = require("dayjs/plugin/objectSupport");
dayjs.extend(objectSupport);

const LocationEditor = ({ distributor, location }) => {
  const [shipping_and_handling, setShippingAndHandling] = useState({
    dispatch_freight_rates: [],
    dropship_handling_rates: {
      min_rate: location.location_dropship_handling_rate?.min_rate || 0,
      percentage_rate: location.location_dropship_handling_rate?.percentage_rate || 0,
      max_rate: location.location_dropship_handling_rate?.max_rate || 0,
    },
    dropship_product_types: location.dropship_product_types,
  });

  const [loc, setLoc] = useState(location);
  const [formLoading, setFormLoading] = useState(false);

  const addressRef = useRef(location.address);

  // if loc.pickup_hours is empty, set default values, use dayjs to add all seven days of the week, sunday to saturday, 9am-5pm monday to friday
  useEffect(() => {
    if (!loc.pickup_hours) {
      let days = [];
      for (let i = 0; i < 7; i++) {
        days.push({
          day: dayjs().day(i).format("dddd"),
          closed: i === 0 || i === 6, // Closed on Sunday (0) and Saturday (6)
          from: i === 0 || i === 6 ? "" : "09:00",
          to: i === 0 || i === 6 ? "" : "17:00",
        });
      }
      setLoc((loc) => ({ ...loc, ...{ pickup_hours: days } }));
    }
  }, [loc]);

  const onSubmit = async (data) => {
    setFormLoading(true);
    const formData = new FormData();

    for (const key in data) {
      if (!data[key] && data[key] !== false) {
        continue;
      }

      formData.append("distributor_location[" + key + "]", data[key]);
    }

    formData.append("rates", JSON.stringify(shipping_and_handling));
    formData.append("pickup_hours", JSON.stringify(loc.pickup_hours));
    formData.append("distributor_location[location]", loc.location);

    if (loc.id) {
      try {
        const result = await axios({
          method: "patch",
          url: `/distributors/${distributor.id}/locations/${loc.id}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });

        setFormLoading(false);
        toast.success("Location updated");

        // set locations to the updated locations
        if (result.data) {
          setLoc(result.data);
        }
      } catch (e) {
        console.log("ERROR", e);
        toast.error("There was an error updating the location");
        setFormLoading(false);
      }
    } else {
      try {
        const result = await axios({
          method: "post",
          url: `/distributors/${distributor.id}/locations`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });

        setFormLoading(false);
        toast.success("Location created");

        // set locations to the updated locations
        if (result.data) {
          setLoc(result.data);
        }
      } catch (e) {
        console.log("ERROR", e);
        toast.error("There was an error creating the location");
        setFormLoading(false);
      }
    }
  };

  /* Handle Dropship Handling Rates */
  const updateDropshipHandingRates = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    const data = { ...shipping_and_handling };
    data.dropship_handling_rates[property] = value;
    setShippingAndHandling(data);
  };

  const onActiveChange = async (active) => {
    const formData = new FormData();
    formData.append("distributor_location[active]", active);
    const result = await axios({
      method: "patch",
      url: `/distributors/${distributor.id}/locations/${loc.id}`,
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      data: formData,
    });

    toast.success(active ? "Location Active" : "Location Inactive");
  };

  const updateAvailability = (day, closed, from, to) => {
    console.log("updateAvailability", day, closed);

    let dayChange = day;
    dayChange.closed = closed;
    dayChange.from = from;
    dayChange.to = to;

    console.log("dayChange", dayChange);

    let pickup_hours = loc.pickup_hours;
    let foundIndex = loc.pickup_hours.findIndex((day) => day.day == dayChange.day);
    pickup_hours[foundIndex] = dayChange;

    setLoc((loc) => ({ ...loc, ...{ pickup_hours: pickup_hours } }));
  };

  return (
    <div className="rounded-lg bg-white p-5 shadow">
      <Form onSubmit={onSubmit} defaultValues={loc}>
        <Disclosure defaultOpen={!loc.id}>
          {({ open, close }) => (
            <>
              <div className="flex w-full items-start justify-between">
                <div className="space-y-2">
                  <div className="font-display flex items-center space-x-2 font-bold text-indigo-500">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <span>
                      {loc.name}
                      {loc.code && (
                        <span className="ml-1.5 rounded bg-purple-100 px-2 py-1 text-xs font-bold uppercase text-purple-500">
                          {loc.code}
                        </span>
                      )}
                    </span>
                  </div>
                  {loc.location && (
                    <div className="rounded bg-gray-100 px-2 py-1.5 text-xs text-gray-600">
                      {loc.location}
                    </div>
                  )}
                </div>
                <div className="flex items-center justify-end space-x-5">
                  {loc.id && (
                    <div className="flex flex-shrink-0 items-center space-x-3">
                      <span className="m-0 text-sm text-gray-500">Active</span>
                      <Toggle
                        label="Active"
                        checked={loc.active}
                        onChangeCallback={(enabled) => {
                          setLoc((loc) => ({
                            ...loc,
                            ...{ active: enabled },
                          }));
                          onActiveChange(enabled);
                        }}
                      />
                    </div>
                  )}
                  {!open && (
                    <Disclosure.Button as={"div"} className="w-full">
                      <Button
                        type="button"
                        className="btn btn-light"
                        icon={faChevronDown}
                        label="Settings"
                        showLoading={formLoading}
                      />
                    </Disclosure.Button>
                  )}

                  {open && (
                    <div className="w-full">
                      <Button
                        type="submit"
                        className="btn btn-success"
                        icon={faCheck}
                        label={"Save Changes"}
                        showLoading={formLoading}
                        onClick={(e) => {
                          setTimeout(() => {
                            close();
                          }, 500);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <Disclosure.Panel static={false}>
                <div className="mt-4 grid grid-cols-2 gap-12 border-t px-3 pb-4 pt-8">
                  <div className="space-y-8">
                    <div className="space-y-3">
                      <h3 className="heading-sm">Contact Info</h3>
                      <div>
                        <div className="grid grid-cols-2 gap-3">
                          <div className="form-group">
                            <Input
                              name="contact_person"
                              type="text"
                              required
                              label="Contact Person"
                            />
                          </div>
                          <div className="form-group">
                            <Input
                              name="contact_phone"
                              type="text"
                              required
                              label="Contact Phone"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <Input name="contact_email" type="text" required label="Contact Email" />
                        </div>
                        <div className="form-group">
                          <Input
                            name="consignment_email"
                            type="text"
                            required
                            label="Consignment Email"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="space-y-3">
                      <h3 className="heading-sm">Location</h3>
                      <div>
                        <div className="form-group">
                          <Input
                            name="name"
                            type="text"
                            required
                            label="Location Name"
                            onChange={(e) =>
                              setLoc((loc) => ({ ...loc, ...{ name: e.target.value } }))
                            }
                          />
                        </div>
                        <div className="flex items-start gap-3">
                          <div className="form-group flex-grow">
                            <Input
                              type="hidden"
                              name="location"
                              value={addressRef}
                              required
                              label="Pickup Address"
                            />

                            <LocationAutocomplete
                              defaultLocation={loc.location}
                              callback={(address) => {
                                console.log("Address:", address.formatted_address);
                                setLoc((loc) => ({
                                  ...loc,
                                  ...{
                                    address: address.formatted_address,
                                    location: address.formatted_address,
                                    latitude: address.geometry.location.lat(),
                                    longitude: address.geometry.location.lng(),
                                  },
                                }));
                                addressRef.current = address.formatted_address;
                              }}
                            />
                          </div>
                          <div className="form-group w-32 flex-shrink-0">
                            <Input
                              name="code"
                              type="text"
                              required
                              label="Code / ERP ID"
                              onChange={(e) =>
                                setLoc((loc) => ({ ...loc, ...{ code: e.target.value } }))
                              }
                            />
                          </div>
                        </div>
                        {loc.latitude && loc.longitude && (
                          <div className="h-[200px] w-full overflow-clip rounded-lg">
                            <Map
                              mapId={"d2be3fe110b995e4"}
                              center={{ lat: loc.latitude, lng: loc.longitude }}
                              zoom={15}
                              disableDoubleClickZoom={true}
                              disableDefaultUI={true}
                            >
                              <AdvancedMarker
                                position={{ lat: loc.latitude, lng: loc.longitude }}
                              />
                            </Map>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* RIGHT COLUMN */}
                  <div className="space-y-12">
                    <div className="relative space-y-3">
                      <h3 className="heading-sm">Dropship Handling Rates</h3>

                      <DropshipHandlingRates
                        {...shipping_and_handling.dropship_handling_rates}
                        updateRates={updateDropshipHandingRates}
                      />
                    </div>

                    <div className="relative space-y-3">
                      <h4 className="heading-sm">Pickup Availability</h4>
                      <p className="text-sm text-gray-500">
                        Set the availble time windows for couriers to make pickups from this
                        location.
                      </p>
                      <table className="w-full py-5">
                        <thead>
                          <tr>
                            <th>Day</th>
                            <th>Hours Available</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loc.pickup_hours &&
                            loc.pickup_hours.map((day) => {
                              return (
                                <tr key={day.day}>
                                  <td>
                                    <Checkbox
                                      checked={!day.closed}
                                      label={day.day}
                                      name={day.day}
                                      onChange={(e) =>
                                        updateAvailability(day, !e.target.checked, day.from, day.to)
                                      }
                                    />
                                  </td>
                                  <td>
                                    {!day.closed && (
                                      <div className="flex items-center space-x-2">
                                        <TimeSelect
                                          selectedTime={day.from}
                                          onChange={(e) =>
                                            updateAvailability(
                                              day,
                                              day.closed,
                                              e.target.value,
                                              day.to
                                            )
                                          }
                                        />
                                        <span className="flex-shrink-0">to</span>
                                        <TimeSelect
                                          selectedTime={day.to}
                                          onChange={(e) =>
                                            updateAvailability(
                                              day,
                                              day.closed,
                                              day.from,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                    {day.closed && (
                                      <span className="rounded bg-gray-200 px-2 py-1 text-sm font-medium text-gray-700">
                                        Closed
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Form>
      <Toaster />
    </div>
  );
};

const TimeSelect = ({ selectedTime, onChange }) => {
  let hours = [];
  for (let hour = 0; hour < 24; hour++) {
    hours.push({
      time: dayjs({ hour: hour }).format("h:mm A"),
      value: dayjs({ hour: hour }).format("HH:mm"),
    });
    hours.push({
      time: dayjs({ hour: hour, minute: 30 }).format("h:mm A"),
      value: dayjs({ hour: hour, minute: 30 }).format("HH:mm"),
    });
  }
  return (
    <select value={selectedTime} onChange={onChange}>
      {hours.map((h) => {
        return (
          <option key={h.value} value={h.value}>
            {h.time}
          </option>
        );
      })}
    </select>
  );
};

export default LocationEditor;
