import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Form, Input, Button, Select } from "../UI/components";
import VehicleFieldCombobox from "./components/VehicleFieldCombobox";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import CountryCombobox from "../UI/components/CountryCombobox";

export default function VehicleForm({ title, subtitle, vehicle }) {
  const [selectedMake, setSelectedMake] = useState(vehicle?.vehicle_make?.id ?? 0);
  const [selectedModelName, setSelectedModelName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);

    const formData = new FormData();

    for (const key in data) {
      if (!data[key] && data[key] !== false) {
        data[key] = "";
      }

      // adjust default market object to country code string
      if (key === "market" && data[key]?.value) {
        data[key] = data[key].value;
      }

      if (key === "vehicle_model_id" && data[key] === -1) {
        formData.append("vehicle[new_vehicle_model_name]", selectedModelName);
      }

      formData.append("vehicle[" + key + "]", data[key]);
    }

    if (vehicle.id) {
      try {
        const result = await axios({
          method: "patch",
          url: `/admin/vehicles/${vehicle.id}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });

        toast.success("Vehicle updated");
        setIsLoading(false);
        location.reload();
      } catch (e) {
        console.log("ERROR", e);
        toast.error("There was an error updating the vehicle");
      }
    } else {
      try {
        const result = await axios({
          method: "post",
          url: `/admin/vehicles`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });

        toast.success("Vehicle created");
        setIsLoading(false);
        location.replace(`/admin/vehicles/${result.data.swd_id}/edit`);
      } catch (e) {
        console.log("ERROR", e);
        toast.error("There was an error creating the vehicle.");
      }
    }
  };

  console.log("vehicle.vehicle_drive_type", vehicle.vehicle_drive_type);

  return (
    <>
      <Form onSubmit={onSubmit} defaultValues={vehicle}>
        <div className="flex items-center justify-between border-b pb-5">
          <div>
            <h2 className="text-2xl font-bold">{title}</h2>
            {subtitle && <h3 className="heading-sm">{subtitle}</h3>}
          </div>

          <div className="btn-group">
            <a href={`/admin/vehicles`} className="btn btn-neutral">
              Cancel
            </a>
            <Button type="submit" label="Save" className="btn" showLoading={isLoading} />
          </div>
        </div>

        <div className="py-5">
          <div className="flex flex-col divide-y divide-gray-200 rounded-lg bg-white p-5 shadow">
            <div className="grid grid-cols-5 gap-5 pb-5">
              <div className="form-group">
                <Controller
                  name="vehicle_make_id"
                  render={({ field: { onChange, value } }) => (
                    <VehicleFieldCombobox
                      required
                      label="Make"
                      field="make"
                      selectedOption={vehicle.vehicle_make}
                      handleVehicleFieldSelect={(e) => {
                        onChange(e.id);
                        setSelectedMake(e.id);
                      }}
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <Controller
                  name="vehicle_model_id"
                  render={({ field: { onChange, value } }) => (
                    <VehicleFieldCombobox
                      required
                      label="Model"
                      selectedOption={vehicle.vehicle_model}
                      field="model"
                      field_restriction="make"
                      field_restriction_id={selectedMake}
                      allowCreate={true}
                      handleVehicleFieldSelect={(e) => {
                        setSelectedModelName(e.name);
                        onChange(e.id);
                      }}
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <Input name="series" label="Series" type="text" required />
              </div>
              <div className="form-group">
                <Input name="variant" label="Variant" type="text" />
              </div>
              <div className="form-group">
                <Controller
                  name="vehicle_body_id"
                  render={({ field: { onChange, value } }) => (
                    <VehicleFieldCombobox
                      required
                      label="Body"
                      field="body"
                      selectedOption={vehicle.vehicle_body}
                      handleVehicleFieldSelect={(e) => {
                        onChange(e.id);
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="grid grid-cols-5 gap-5 py-5">
              <div className="form-group">
                <Input name="month_from" label="Month From" type="number" required />
              </div>
              <div className="form-group">
                <Input name="year_from" label="Year From" type="number" required />
              </div>

              <div className="form-group">
                <Input name="month_to" label="Month To" type="number" />
              </div>
              <div className="form-group">
                <Input name="year_to" label="Year To" type="number" />
              </div>
            </div>

            <div className="grid grid-cols-5 gap-5 py-5">
              <div className="form-group">
                <Input name="engine" label="Engine Code" type="text" />
              </div>
              <div className="form-group">
                <Input name="cylinders" label="Cylinders" type="number" required />
              </div>
              <div className="form-group">
                <Input
                  name="engine_capacity"
                  label="Engine Capacity (L)"
                  type="number"
                  step={0.1}
                  required
                />
              </div>
              <div className="form-group">
                <Input name="kw_power" label="Power (kW)" type="number" />
              </div>
            </div>

            <div className="grid grid-cols-5 gap-5 py-5">
              <div className="form-group">
                <Controller
                  name="vehicle_transmission_id"
                  render={({ field: { onChange, value } }) => (
                    <VehicleFieldCombobox
                      required
                      label="Transmission"
                      field="transmission"
                      selectedOption={vehicle.vehicle_transmission}
                      handleVehicleFieldSelect={(e) => {
                        onChange(e.id);
                      }}
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <Controller
                  name="vehicle_drive_type_id"
                  render={({ field: { onChange, value } }) => (
                    <VehicleFieldCombobox
                      required
                      label="Drive Type"
                      field="drive_type"
                      selectedOption={vehicle.vehicle_drive_type}
                      handleVehicleFieldSelect={(e) => {
                        onChange(e.id);
                      }}
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <Controller
                  name="vehicle_fuel_type_id"
                  render={({ field: { onChange, value } }) => (
                    <VehicleFieldCombobox
                      required
                      label="Fuel Type"
                      field="fuel_type"
                      selectedOption={vehicle.vehicle_fuel_type}
                      handleVehicleFieldSelect={(e) => {
                        onChange(e.id);
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="grid grid-cols-5 gap-5 py-5">
              <div className="form-group">
                <Input name="tecdoc_id" label="KType" type="number" />
              </div>
              <div className="form-group">
                <Input name="autoinfo_id" label="Autoinfo ID" type="text" />
              </div>
              <div className="form-group">
                <Controller
                  name="market"
                  render={({ field: { onChange } }) => (
                    <CountryCombobox
                      required
                      label="Market"
                      selectedOption={vehicle.market}
                      handleCountrySelect={(e) => {
                        onChange(e.value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
      <Toaster />
    </>
  );
}
