import React, { useEffect, useState, useCallback, useMemo } from "react";
import ProductAttributesModal from "./ProductAttributesModal";

import axios from "axios";
import ReactOnRails from "react-on-rails";
import toast from "react-hot-toast";
import { Button } from "../../UI/components";
import { faListUl } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTableList,
  faSquarePlus,
  faPenToSquare,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { SortableTable } from "../../UI/components/Table/SortableTable";
import { faArrowRotateLeft } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";

export default function ProductAttributesList({ product, templateUrl }) {
  const [isOpen, setIsOpen] = useState(false);
  const [productAttributes, setProductAttributes] = useState([]);
  const [outOfSequence, setOutOfSequence] = useState(false);
  const [showLoadingRow, setShowLoadingRow] = useState(false);
  const [selectedProductAttribute, setSelectedProductAttribute] = useState(null);

  const allAttributes = Array.from(
    new Set([
      ...product.product_attributes.map((x) => x.title),
      ...product.child_products
        .map((x) => x.product_attributes)
        .flat()
        .map((x) => x.title),
    ])
  ).sort();

  useEffect(() => {
    combineTemplateAttributes(product.product_attributes);
  }, [product.product_attributes]);

  // when productAttributes change, call api to save their sequence order
  useEffect(async () => {
    if (productAttributes.length > 0) {
      const sequence = productAttributes.map((x) => x.id);
      await axios({
        method: "post",
        url: `/reorder_product_attributes`,
        headers: ReactOnRails.authenticityHeaders(),
        responseType: "json",
        data: { product_attributes: sequence, product_id: product.id },
      });

      if (!product.product_attributes_template) {
        return;
      }

      // if productAttributes is in a different sequence than product.product_attributes_template (ignoring any attributes not in the product_attributes_template), set outOfSequence to true. Compare using the sequence property.

      const templateSequence = product.product_attributes_template.map((x) => ({
        id: x.id,
        sequence: x.sequence,
      }));

      const attributeSequence = productAttributes.map((x, index) => ({
        id: x.title_id,
        sequence: index + 1, // mock the sequence value, because drag and drop doesn't update the sequence property
      }));

      let isOutOfSequence = false;

      // Iterate over templateSequence
      for (let templateItem of templateSequence) {
        // Find the corresponding item in attributeSequence by id
        const attributeItem = attributeSequence.find((attr) => attr.id === templateItem.id);

        // If the item exists in attributeSequence and its sequence doesn't match the template's sequence, set isOutOfSequence to true and break out of the loop
        if (attributeItem && attributeItem.sequence !== templateItem.sequence) {
          isOutOfSequence = true;
          break;
        }
      }

      setOutOfSequence(isOutOfSequence);
    }
  }, [productAttributes]);

  // Save Attribute
  async function saveAttribute(data) {
    setIsOpen(false);

    if (data) {
      console.log("data.attribute_value", data.attribute_value);
      try {
        setShowLoadingRow(true);
        // get the sequence of the attribute from productAttributes where title_id == data.attribute_title_id
        const sequence =
          productAttributes.find((x) => x.title_id == data.attribute_title_id)?.sequence ?? null;

        const result = await axios({
          method: "post",
          url: "/product_attributes",
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: {
            product_id: product.id,
            attribute_title_id: data.attribute_title_id,
            attribute_value: data.attribute_value,
            sequence: sequence,
          },
        });
        combineTemplateAttributes(result.data.product_attributes);
        setShowLoadingRow(false);
        toast.success("Product attribute added");
      } catch (e) {
        console.log(e);
        toast.error("Error creating product attribute");
      }
    }
  }

  const combineTemplateAttributes = useCallback(
    (data) => {
      // return data if product.product_attributes_template is null
      if (!product.product_attributes_template) {
        setProductAttributes(data);
        return;
      }

      let combinedAttributes = data;

      console.log("product.product_attributes_template", product.product_attributes_template);

      const missingAttributes = product.product_attributes_template.filter(
        (x) => !combinedAttributes.find((y) => y.title_id == x.id)
      );

      if (missingAttributes.length > 0) {
        const missingAttributesWithBlankValue = missingAttributes.map((x) => ({
          id: x.id,
          title: x.name,
          title_id: x.id,
          code: x.code,
          value: "",
          data_type: x.data_type,
          sequence: x.sequence,
        }));

        combinedAttributes = [...combinedAttributes, ...missingAttributesWithBlankValue];
        combinedAttributes.sort((a, b) => a.sequence - b.sequence);
      }

      setProductAttributes(combinedAttributes);
    },
    [product.product_attributes_template]
  );

  const resetSequenceToTemplate = () => {
    // reorder productAttributes to match product.product_attributes_template sequence, any attributes not in the template move to the end, setProductAttributes to new order

    const reorderedAttributes = product.product_attributes_template
      .map((x) => productAttributes.find((y) => y.title_id == x.id))
      .filter((x) => x);

    const missingAttributes = productAttributes.filter(
      (x) => !reorderedAttributes.find((y) => y.title_id == x.title_id)
    );

    setProductAttributes([...reorderedAttributes, ...missingAttributes]);
  };

  async function handleRemoveAttribute(id) {
    try {
      const result = await axios({
        method: "delete",
        url: `/product_attributes/${id}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      combineTemplateAttributes(result.data.product_attributes);
      toast.success("Product attribute removed");
    } catch (e) {
      console.log(e);
      toast.error("Error removing product attribute");
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  const columns = useMemo(
    () => [
      {
        Header: "Attribute",
        accessor: "title",
        Cell: function ({ value, row }) {
          return (
            <div className="flex items-center gap-2 font-medium text-gray-900">
              {/* if row.original.title_id is also found in product.product_attributes_template (id) then display the faTableList icon */}
              {product.product_attributes_template &&
                (() => {
                  const isTemplateAttribute = product.product_attributes_template.find(
                    (x) => x.id == row.original.title_id
                  );
                  const title = isTemplateAttribute
                    ? "Template Attribute"
                    : "Non-template Attribute";
                  const icon = isTemplateAttribute ? faTableList : faPenToSquare;

                  return (
                    <div title={title}>
                      <FontAwesomeIcon icon={icon} className="h-3 w-3 text-gray-800" />
                    </div>
                  );
                })()}

              <span>{row.original.title}</span>
              {row.original.code && (
                <span className="rounded-md bg-indigo-200/50 px-1.5 text-[10px] text-indigo-600">
                  {row.original.code}
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: "Value",
        accessor: "value",
        Cell: function ({ value, row }) {
          // set an error property on the row if value is null or empty (missing value) make sure not to set error on rows that have a value of false

          row.original.error =
            row.original.data_type !== "without value" && (value === null || value === "");

          return !row.original.error ? (
            <div className="text-gray-500">{row.original.value}</div>
          ) : (
            <div className="text-danger-500 border-danger-500 inline-flex rounded border border-dotted px-2 py-1 text-center">
              Missing value
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center justify-between pb-2 pt-5">
          <div className="flex items-center gap-2">
            {product.product_attributes_template ? (
              <>
                <a href={templateUrl} className="btn btn-sm btn-light">
                  <span>Edit Template</span>
                  <FontAwesomeIcon icon={faTableList} />
                </a>
                {outOfSequence && (
                  <Button
                    label="Reset sequence to template"
                    className="btn btn-sm btn-warning"
                    icon={faArrowRotateLeft}
                    onClick={resetSequenceToTemplate}
                  />
                )}
              </>
            ) : (
              <a href={templateUrl} className="btn btn-sm btn-light">
                <span>Create Template</span>
                <FontAwesomeIcon icon={faTableList} />
              </a>
            )}
          </div>
          <Button
            label="Add Attribute"
            icon={faListUl}
            type="button"
            onClick={openModal}
            className="btn btn-sm"
          />
        </div>
        {productAttributes && productAttributes.length > 0 && (
          <div className="w-full">
            <div className="inline-block min-w-full py-2">
              <div className="overflow-hidden">
                <SortableTable
                  columns={columns}
                  data={productAttributes}
                  setData={setProductAttributes}
                  rowDeleteHandler={(id) => {
                    handleRemoveAttribute(id);
                  }}
                  rowEditHandler={(id) => {
                    const prA = productAttributes.find((x) => x.id == id);
                    setSelectedProductAttribute(prA);
                    setIsOpen(true);
                  }}
                ></SortableTable>
              </div>
            </div>
          </div>
        )}
        {showLoadingRow && (
          <div className="-mt-2 flex items-center justify-center">
            <div className="h-12 w-full animate-pulse bg-gray-100"></div>
          </div>
        )}

        {product.child_products.length > 0 && (
          <div className="mt-5 border-b border-gray-200 text-xs">
            <h4 className="text-xs font-bold uppercase tracking-wide text-gray-800">
              Component Attributes
            </h4>
            <div className="text-info-500 bg-info-50 my-2 inline-flex flex-grow-0 flex-col gap-2 rounded-md px-3 py-2 text-xs">
              <p>
                Component attributes are combined at the kit level. Any kit level attributes
                assigned will override component attributes.
              </p>
              <p>
                Attributes will be displayed in component sequence followed by attribute sequence.
              </p>
            </div>
            <div className="flex divide-x divide-gray-200 border-b border-gray-200">
              <div className="flex-1 p-2 font-medium uppercase tracking-wider text-gray-500"></div>
              <div className="flex-1 p-2 font-medium uppercase tracking-wider text-gray-900">
                {product.sku}
              </div>
              {product.child_products.map((p, index) => (
                <div
                  className="flex-1 p-2 font-medium uppercase tracking-wider text-gray-500"
                  key={index}
                >
                  {p.sku}
                </div>
              ))}
            </div>
            {allAttributes.map((a, aIndex) => (
              <div
                key={aIndex}
                className={`flex ${
                  aIndex % 2 == 0 ? "bg-gray-50" : "bg-white"
                } divide-x divide-gray-200`}
              >
                <div className="flex-1 p-2 font-medium text-gray-900">{a}</div>
                <div className="flex-1 p-2 text-gray-500">
                  {product.product_attributes.find((x) => x.title == a)?.value}
                </div>
                {product.child_products.map((p, pIndex) => (
                  <div className="flex-1 p-2 text-gray-500" key={pIndex}>
                    {p.product_attributes.find((x) => x.title == a)?.value}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>

      <ProductAttributesModal
        productAttribute={selectedProductAttribute}
        closeModal={saveAttribute}
        {...{ isOpen }}
      />
    </>
  );
}
