import React, { useMemo, useState, useCallback, useRef } from "react";
import Table from "../../Application/components/Table";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faTimes,
  faTimesCircle,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";

export default function ShopifyListingsTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const fetchIdRef = useRef(0);
  // const { isShowing, toggle } = useModal();
  const [currentId, setCurrentId] = useState(null);
  const [refresh, setRefresh] = useState(0);

  const fetchData = useCallback(async ({ pageIndex, pageSize, sortBy, filters, globalFilter }) => {
    console.log("SORT", sortBy);
    console.log("FILTERS", filters);
    console.log("GLOBAL FILTER", globalFilter);

    let params = {
      page: pageIndex + 1,
      per: pageSize,
    };

    if (sortBy.length > 0) {
      params["sort"] = sortBy[0].id;
      params["sort_dir"] = sortBy[0].desc ? "desc" : "asc";
    }

    if (globalFilter) {
      params["query"] = globalFilter;
    }

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      params: {
        section: "body",
      },
    };
    setLoading(true);

    const result = await fetch(
      "listings/datatable.json?" + new URLSearchParams(params),
      requestConfig
    );
    const data = await result.json();
    setData(data.data);

    setPageCount(Math.ceil(data.meta.total_count / 1));
    setTotalCount(data.meta.total_count);

    setLoading(false);
  }, []);

  const columns = useMemo(() => [
    {
      Header: "Title",
      accessor: "title",
      sortType: "basic",
      disableFilters: true,
      Cell: ({ row }) => <div>{row.original.title}</div>,
    },
    {
      Header: "Products",
      accessor: "product_count",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => <div>{row.original.products.map((x) => x.sku).join(",")}</div>,
    },
    {
      Header: "Status",
      accessor: "status",
      disableFilters: true,
      Cell: ({ value }) => {
        if (value == "published") {
          return (
            <span className="bg-success-100 text-success-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">
              {value?.toUpperCase()}
            </span>
          );
        } else if (value == "draft") {
          return (
            <span className="bg-info-100 text-info-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">
              {value?.toUpperCase()}
            </span>
          );
        } else {
          return (
            <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-500">
              {value?.toUpperCase()}
            </span>
          );
        }
      },
    },
    {
      Header: "",
      accessor: "id",
      disableFilters: true,
      disableSortBy: true,
      Cell: function ({ value }) {
        return (
          <a className="text-indigo-600 hover:text-indigo-900" href={`listings/${value}/edit`}>
            Edit
          </a>
        );
      },
    },
  ]);

  return (
    <div>
      <Table
        columns={columns}
        data={data}
        onFetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        totalItems={totalCount}
        initialPageSize={25}
        useRowSelection={false}
      />
    </div>
  );
}
