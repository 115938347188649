import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faBoxTaped,
  faBoxesPacking,
  faCheck,
  faClock,
  faGripDots,
  faTicket,
  faTicketSimple,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";

const titleize = (str) => {
  if (!str) {
    return "";
  }

  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const styleMappings = {
  delivery: {
    pending: "bg-yellow-200 text-yellow-900",
    packed: "bg-blue-200 text-blue-900",
    booked: "bg-blue-200 text-blue-900",
    pickup_requested: "bg-sky-200 text-sky-900",
    awating_pickup: "bg-blue-100 text-blue-800",
    shipped: "bg-emerald-200 text-emerald-900",
    error: "bg-red-100 text-red-800",
  },
  fulfillment: {
    pending: "bg-amber-100 text-amber-800",
    in_progress: "bg-blue-100 text-blue-800",
    cancelled: "bg-gray-100 text-gray-800",
    complete: "bg-success-100 text-success-800",
  },
  handover: {
    pending: "bg-amber-200 text-amber-900",
    executing: "bg-blue-200 text-blue-200",
    requested: "bg-indigo-200 text-indigo-900",
    complete: "bg-green-200 text-green-900",
  },
  label: {
    not_created: "bg-gray-100 text-gray-800",
    error: "bg-rose-100 text-rose-800",
    label_requested: "bg-indigo-100 text-indigo-800",
    generated: "bg-green-100 text-green-800",
    printed: "bg-green-100 text-green-800",
  },
};

const iconMappings = {
  delivery: {
    pending: faBoxOpen,
    packed: faBoxTaped,
    booked: faBoxTaped,
    pickup_requested: faClock,
    awating_pickup: faClock,
    shipped: faCheck,
    error: faBoxTaped,
  },
  fulfillment: {
    pending: null,
    in_progress: null,
    cancelled: null,
    complete: null,
  },
  handover: {
    pending: faBoxOpen,
    executing: faBoxTaped,
    requested: faBoxesPacking,
    complete: faCheck,
  },
  label: {
    not_created: faTicket,
    error: faTicket,
    label_requested: faTicket,
    generated: faTicket,
    printed: faTicket,
  },
};

export default function ListStatusBadge({ type, status }) {
  return (
    <>
      <span
        className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${styleMappings[type][status]}`}
      >
        <FontAwesomeIcon icon={iconMappings[type][status]} className="mr-2 text-xs" />
        {titleize(status)}
      </span>
    </>
  );
}
