import {
  faDashboard,
  faWarehouse,
  faBoxTaped,
  faShelves,
  faShippingFast,
  faTag,
  faTags,
  faMoneyBillTransfer,
  faEnvelopeOpenDollar,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AnimatedCounter } from "./AnimatedCounter";
import { SidebarButton } from "./SidebarButton";

export const Sidebar = ({ fulfilmentCount = 0 }) => {
  return (
    <div className="sticky top-6 rounded-xl bg-white p-4 py-8 shadow-xl">
      <div className="flex flex-col space-y-2">
        <SidebarButton href="/">
          <FontAwesomeIcon
            icon={faDashboard}
            className="w-4 text-center text-gray-400 transition group-hover:text-indigo-800"
          />
          <span>Dashboard</span>
        </SidebarButton>
        <SidebarButton href="/capture">
          <FontAwesomeIcon
            icon={faBoxTaped}
            className="w-4 text-center text-gray-400 transition group-hover:text-indigo-800"
          />
          <span>Packaging</span>
        </SidebarButton>
        <SidebarButton href="/locations">
          <FontAwesomeIcon
            icon={faWarehouse}
            className="w-4 text-center text-gray-400 transition group-hover:text-indigo-800"
          />
          <span>Locations</span>
        </SidebarButton>
        <SidebarButton href="/inventory">
          <FontAwesomeIcon
            icon={faShelves}
            className="w-4 text-center text-gray-400 transition group-hover:text-indigo-800"
          />
          <span>Inventory</span>
        </SidebarButton>
        <SidebarButton href="/pricing">
          <FontAwesomeIcon
            icon={faTags}
            className="w-4 text-center text-gray-400 transition group-hover:text-indigo-800"
          />
          <span>Pricing</span>
        </SidebarButton>
        <SidebarButton href="/payments">
          <FontAwesomeIcon
            icon={faEnvelopeOpenDollar}
            className="w-4 text-center text-gray-400 transition group-hover:text-indigo-800"
          />
          <span>Payments</span>
        </SidebarButton>
      </div>
    </div>
  );
};
