import React, { useEffect, useState } from "react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";

function useVehicleFields(field, searchTerm, field_restriction, field_restriction_id) {
  const [vehicleFields, setVehicleFields] = React.useState([]);

  React.useEffect(() => {
    let isFresh = true;

    fetchVehicleDriveTypes(field, searchTerm, field_restriction, field_restriction_id).then(
      (vehicleFields) => {
        if (isFresh) setVehicleFields(vehicleFields);
      }
    );

    return () => (isFresh = false);
  }, [searchTerm, field_restriction, field_restriction_id]);
  return vehicleFields;
}

function fetchVehicleDriveTypes(field, value, field_restriction, field_restriction_id) {
  return fetch(
    `/vehicle_field_search?field=vehicle_${field}&term=${value}${
      field_restriction && field_restriction_id
        ? `&restriction=${field_restriction}&restriction_id=${field_restriction_id}`
        : ""
    }`
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function VehicleFieldCombobox({
  selectedOption,
  field,
  label,
  field_restriction,
  field_restriction_id,
  handleVehicleFieldSelect,
  allowCreate = false,
}) {
  const [query, setQuery] = useState("");
  const [fieldOption, setFieldOption] = useState(selectedOption);
  const [initialFieldRestricitonId, setInitialFieldRestrictionId] = useState(field_restriction_id);

  const vehicleFieldOptions = useVehicleFields(
    field,
    query,
    field_restriction,
    field_restriction_id
  );

  useEffect(() => {
    if (field_restriction !== undefined && field_restriction_id !== initialFieldRestricitonId) {
      setFieldOption(null);
    }
  }, [vehicleFieldOptions]);

  const handleSelect = (id) => {
    let value = null;
    if (id == -1) {
      value = { id: id, name: query };
    } else {
      value = vehicleFieldOptions.find((option) => option.id === id);
    }
    setFieldOption(value);
    handleVehicleFieldSelect(value);
  };

  return (
    <Combobox
      as="div"
      value={fieldOption?.id}
      onChange={(e) => handleSelect(e)}
      disabled={field_restriction !== undefined && field_restriction_id === 0}
    >
      {({ disabled }) => (
        <>
          <Combobox.Label>{label}</Combobox.Label>

          <div className="relative">
            <Combobox.Input
              className={({ disabled }) =>
                classNames(
                  "w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 shadow-sm",
                  disabled
                    ? "bg-gray-100"
                    : "bg-white focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                )
              }
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(id) =>
                vehicleFieldOptions.find((option) => option.id === id)?.name ?? fieldOption?.name
              }
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>

            {vehicleFieldOptions && (
              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {allowCreate && query.length > 0 && (
                  <Combobox.Option
                    value={-1}
                    className={({ active }) =>
                      classNames(
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                        active ? "bg-indigo-600 text-white" : "text-gray-900"
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <span className={classNames("block truncate", selected && "font-semibold")}>
                          (Create) {query}
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                              active ? "text-white" : "text-indigo-600"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                )}
                {vehicleFieldOptions.map((option) => (
                  <Combobox.Option
                    key={option.id}
                    value={option.id}
                    className={({ active }) =>
                      classNames(
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                        active ? "bg-indigo-600 text-white" : "text-gray-900"
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <span className={classNames("block truncate", selected && "font-semibold")}>
                          {option.name}
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                              active ? "text-white" : "text-indigo-600"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </div>
        </>
      )}
    </Combobox>
  );
}
