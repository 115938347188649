import React, { useState } from "react";
import PackagingModal from "../Products/components/PackagingModal";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import AllocateToParcelModal from "./components/AllocateToParcelModal";
import RequestPickupModal from "./components/RequestPickupModal";
import {
  DeliveryCard,
  LineItemsTable,
  LineItemRow,
  ParcelCard,
  ParcelItemsTable,
  ParcelItemRow,
} from "./components/Fulfillment";
import { Button } from "../UI/components";

const NewComponent = () => (
  <div className="rounded-md bg-blue-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0"></div>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <p className="text-xs text-blue-700">
          Shipping organized by Partbot supports multiple boxes per shipment only when the following
          conditions are met:
          <ul className="m-4 list-disc">
            <li>Length, width or, height per box do not exceed 180cm</li>
            <li>Dead weight per box does not exceed 25kg</li>
            <li>Cubic weight per box does not exceed 40kg</li>
            <li>Cubic weight of all boxes does not exceed 150kg</li>
          </ul>
          You can still ship multiple boxes per shipment, but you will need to organize shipping
          yourself, and provide tracking details to Partbot.
        </p>
      </div>
    </div>
  </div>
);

const errorCodesToMessages = {
  "unit.unallocated": "Please allocate to a box",
  "unit.partially_allocated": "Please allocate all quantities to a box",
  "unit.missing_dimensions": "Please correct the dimensions",
  "unit.missing_weight": "Please correct the weight",
  "parcel.empty": "Please add items to the box, or remove the box",
  "delivery.empty": "Please add boxes to the shipment, or remove the shipment",
};

const ErrorAlert = ({ errors }) => {
  if (errors.length === 0) {
    return null;
  }

  return (
    <div className="my-4 rounded-md bg-rose-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0"></div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-xs text-rose-700">
            <div className="">
              {errors.map((error) => (
                <div key={error.source_id}>
                  <p className="font-semibold">{error.label}</p>
                  <ul className="mx-4 mb-4 mt-1 list-disc">
                    {error.codes.map((code) => (
                      <li key={code}>{errorCodesToMessages[`${error.error_source}.${code}`]}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default function DistributorFulfillment({
  fulfillmentOrderData,
  fulfillmentData,
  boxes,
  package_types,
}) {
  // const checkbox = useRef();
  // const [checked, setChecked] = useState(false);
  // const [indeterminate, setIndeterminate] = useState(false);
  // const [selectedFulfilments, setSelectedFulfilments] = useState([]);
  // const [requestingPickup, setRequestingPickup] = useState(false);
  // const [openPackagingModal, setOpenPackagingModal] = useState(false);
  // const [activeFulfilment, setActiveFulfilment] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isAllocateToParcelModalOpen, setIsAllocateToParcelModalOpen] = useState(false);
  const [isProductPackageModalOpen, setIsProductPackageModalOpen] = useState(false);
  const [requestPickupLoading, setRequestPickupLoading] = useState(false);
  const [isRequestPickupModalOpen, setIsRequestPickupModalOpen] = useState(false);

  const [selectedFulfillmentUnitOptions, setSelectedFulfillmentUnitOptions] = useState({
    id: null,
    index: null,
    maxQuantity: 0,
  });
  const [requestPickupOptions, setRequestPickupOptions] = useState({
    courier: "Courier",
    slots: [],
    deliveryId: null,
    selectedSlot: { id: null, start_time: null, end_time: null },
  });

  const fulfillmentOrder = fulfillmentOrderData;
  const [fulfillment, setFulfillment] = useState(fulfillmentData);

  const getFulfillmentUnit = (id) => {
    return fulfillment.line_items.flatMap((item) => item.units).find((unit) => unit.id === id);
  };

  const handleResolve = (fulfillmentUnitId) => {
    const fulfillmentUnit = getFulfillmentUnit(fulfillmentUnitId);
    setSelectedFulfillmentUnitOptions({
      id: fulfillmentUnit.id,
      linkedPackage: fulfillmentUnit.linked_product_package,
    });
    setIsProductPackageModalOpen(true);
  };

  const onAllocateToParcelModalClose = async (data) => {
    if (data) {
      const parcelId = parseInt(data.fulfillment_parcel_id);
      const deliveryId = parseInt(data.fulfillment_delivery_id);
      const unitId = parseInt(data.fulfillment_unit_id);
      const quantity = parseInt(data.quantity);

      await allocateItemToParcel(deliveryId, parcelId, unitId, quantity);
    }

    setIsAllocateToParcelModalOpen(false);
  };

  const onProductPackageModalClose = async (data) => {
    if (data) {
      try {
        const package_params = {
          ...data,
        };

        const currentFulfillmentUnit = getFulfillmentUnit(selectedFulfillmentUnitOptions.id);
        const existingPackage = selectedFulfillmentUnitOptions.linkedPackage;
        let packageId = null;

        if (existingPackage) {
          const result = await axios({
            method: "patch",
            url: `/packages/${existingPackage.id}`,
            responseType: "json",
            headers: ReactOnRails.authenticityHeaders(),
            data: package_params,
          });

          packageId = existingPackage.id;
        } else {
          package_params["distributor_id"] = fulfillment.distributor_location.distributor_id;
          const result = await axios({
            method: "post",
            url: "/packages",
            responseType: "json",
            headers: ReactOnRails.authenticityHeaders(),
            data: {
              product_id: currentFulfillmentUnit.product.id,
              ...package_params,
            },
          });
          // get highest id from packages array in reesult data
          packageId = result.data.packages.reduce(
            (max, p) => (p.id > max ? p.id : max),
            result.data.packages[0].id
          );
        }

        const fulfillmentResult = await axios({
          method: "post",
          url: `/fulfillments/${fulfillment.id}/units/${currentFulfillmentUnit.id}/update_from_package/${packageId}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
        });

        setFulfillment(fulfillmentResult.data);

        toast.success("Package details updated");
      } catch (e) {
        console.log(e);
        toast.error("Error updating package details");
      }
    }

    setIsProductPackageModalOpen(false);
  };

  const onRequestPickupModalClose = async (data) => {
    if (data) {
      const requestedSlot = data.selectedSlot;
      const deliveryId = requestPickupOptions.deliveryId;

      setIsLoading(true);
      try {
        const result = await axios({
          method: "post",
          url: `/deliveries/${deliveryId}/pickup`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: {
            pickup: requestedSlot,
          },
        });

        toast.success("Pickup requested");
      } catch (e) {
        console.log(e);
        toast.error("Error requesting pickup");
      } finally {
        setIsLoading(false);
      }
    }
    setIsRequestPickupModalOpen(false);
  };

  const calculatePackagingStatus = () => {
    const units = fulfillment.line_items.flatMap((item) => item.units);

    const unallocated = units.filter((item) => item.packaging.status === "unallocated");
    const partially_allocated = units.filter(
      (item) => item.packaging.status === "partially_allocated"
    );
    const allocated = units.filter((item) => item.packaging.status === "allocated");

    if (unallocated.length > 0) {
      return "unallocated";
    } else if (partially_allocated.length > 0) {
      return "partially_allocated";
    } else if (allocated.length > 0) {
      return "allocated";
    } else {
      return "unknown";
    }
  };

  const handleBoxChange = (index, e) => {
    const { value } = e.target;
    const box = boxes.find((box) => box.value === parseInt(value));
    const newFulfillmentParcel = { ...fulfillment.fulfillment_parcels[index] };

    newFulfillmentParcel.distributor_box_id = box.value;
    newFulfillmentParcel.dimensions = {
      ...box.dimensions,
    };

    const newFulfillmentParcels = [...fulfillment.fulfillment_parcels];
    newFulfillmentParcels[index] = newFulfillmentParcel;

    setFulfillment({ ...fulfillment, fulfillment_parcels: newFulfillmentParcels });
  };

  const handleParcelValueChange = async (parcelId, name, value) => {
    const result = await updateParcel(parcelId, { [name]: value });

    if (result) {
      console.log("RESULT", result);
      setFulfillment(result);
    }
  };

  const handleDeliveryHandOver = async (deliveryId) => {
    setIsLoading(true);
    console.log("HANDOVER", deliveryId);
    ssetIsLoading(false);
  };

  const handleDeliveryBook = async (deliveryId) => {
    setIsLoading(true);
    const result = await axios({
      method: "post",
      url: `/deliveries/${deliveryId}/booking`,
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    });
    setIsLoading(false);

    if (result) {
      setFulfillment(result.data);
    }
  };

  const handleLabelRequest = async (deliveryId) => {
    setIsLoading(true);
    const result = await axios({
      method: "post",
      url: `/deliveries/${deliveryId}/label`,
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    });
    setIsLoading(false);

    if (result) {
      console.log("RESULT", result);
      setFulfillment(result.data);
    }
  };

  const handleDeliveryRequestPickup = async (deliveryId) => {
    setIsLoading(true);
    const result = await axios({
      method: "get",
      url: `/deliveries/${deliveryId}/pickup`,
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    });
    setIsLoading(false);

    // response example
    // {
    //   "carrier_service":"Couriers Please",
    //   "pickup_slots":[
    //       {
    //         "date":"2023-01-31",
    //         "time_slots":[

    //         ]
    //       },
    //       {
    //         "date":"2023-02-01",
    //         "time_slots":[
    //             {
    //               "time_slot_id":"51877171-4782-41c1-b518-59499a730346",
    //               "start_time":"08:00",
    //               "end_time":"17:00"
    //             },
    //               {
    //               "time_slot_id":"51877171-4782-41c1-b518-59499a730346",
    //               "start_time":"09:00",
    //               "end_time":"14:00"
    //             }
    //         ]
    //       },
    //       {
    //         "date":"2023-03-01",
    //         "time_slots":[
    //             {
    //               "time_slot_id":"51877171-4782-41c1-b518-59499a730346",
    //               "start_time":"08:00",
    //               "end_time":"17:00"
    //             },
    //               {
    //               "time_slot_id":"51877171-4782-41c1-b518-59499a730346",
    //               "start_time":"09:00",
    //               "end_time":"14:00"
    //             }
    //         ]
    //       }
    //   ]
    // }

    if (result.data) {
      const pickUpSlots = result.data.pickup_slots;

      const pickUpSlotsWithTimeSlots = pickUpSlots.filter((slot) => {
        return slot.time_slots.length > 0;
      });

      const mappedSlots = pickUpSlotsWithTimeSlots.map((slot) => {
        return {
          date: new Date(slot.date),
          windows: slot.time_slots.map((time_slot) => {
            return {
              slot_id: time_slot.time_slot_id,
              start: new Date(`${slot.date}T${time_slot.start_time}`),
              end: new Date(`${slot.date}T${time_slot.end_time}`),
            };
          }),
        };
      });

      setRequestPickupOptions({
        carrier_service: result.data.carrier_service,
        deliveryId: deliveryId,
        slots: mappedSlots,
      });
      setIsRequestPickupModalOpen(true);
    }
  };

  const updateParcel = async (parcelId, data) => {
    try {
      const result = await axios({
        method: "patch",
        url: `/delivery_parcels/${parcelId}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: { fulfillment_parcel: data },
      });

      toast.success("Parcel updated");

      return result.data;
    } catch (e) {
      console.log(e);
      toast.error("Error updating parcel");
    }

    return false;
  };

  const handleShip = async () => {
    setIsLoading(true);
    try {
      const result = await axios({
        method: "post",
        url: `${fulfillment.id}/ship`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      const newFulfillment = result.data;

      setFulfillment(newFulfillment);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const handleParcelAdd = async (deliveryId) => {
    setIsLoading(true);
    const result = await axios({
      method: "post",
      url: `/deliveries/${deliveryId}/parcels`,
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
    });

    const newFulfillment = result.data;

    setFulfillment(newFulfillment);

    setIsLoading(false);
  };

  const handleDeliveryRemove = async (deliveryId) => {
    setIsLoading(true);

    try {
      const result = await axios({
        method: "delete",
        url: `/deliveries/${deliveryId}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      const newFulfillment = result.data;

      setFulfillment(newFulfillment);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const handleParcelRemove = async (parcelId) => {
    setIsLoading(true);

    try {
      const result = await axios({
        method: "delete",
        url: `/delivery_parcels/${parcelId}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      const newFulfillment = result.data;

      setFulfillment(newFulfillment);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const handleParcelItemRemove = async (parcelItemId) => {
    setIsLoading(true);

    try {
      const result = await axios({
        method: "delete",
        url: `/parcel_items/${parcelItemId}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      const newFulfillment = result.data;

      setFulfillment(newFulfillment);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const handleAllocateUnit = async (unit) => {
    setSelectedFulfillmentUnitOptions({
      id: unit.id,
      maxQuantity: unit.quantity - unit.quantity_packed,
    });
    setIsAllocateToParcelModalOpen(true);
  };

  const allocateItemToParcel = async (deliveryId, parcelId, unitId, quantityToAllocate) => {
    setIsLoading(true);
    try {
      const result = await axios({
        method: "post",
        url: `/fulfillments/${fulfillment.id}/units/${unitId}/allocate`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
        data: {
          fulfillment_unit: {
            fulfillment_delivery_id: deliveryId,
            fulfillment_parcel_id: parcelId,
            quantity: quantityToAllocate,
          },
        },
      });

      toast.success("Allocated item to package");

      setFulfillment(result.data);
    } catch (e) {
      console.log(e);
      toast.error("Error allocating item to package");
    } finally {
      setIsLoading(false);
    }

    return false;
  };

  const moveParcelItem = (parcelIndex, itemIndex, newParcelIndex) => {
    const newFulfillmentParcels = [...fulfillment.fulfillment_parcels];

    const item = newFulfillmentParcels[parcelIndex].items.splice(itemIndex, 1)[0];

    newFulfillmentParcels[newParcelIndex].items.push(item);

    setFulfillment({ ...fulfillment, fulfillment_parcels: newFulfillmentParcels });
  };

  const moveXQuantityToAnotherParcel = (parcelIndex, itemIndex, newParcelIndex, quantityToMove) => {
    if (quantityToMove <= 0) return;

    if (quantityToMove > fulfillment.fulfillment_parcels[parcelIndex].items[itemIndex].quantity) {
      quantityToMove = fulfillment.fulfillment_parcels[parcelIndex].items[itemIndex].quantity;
    }

    const newFulfillmentParcels = [...fulfillment.fulfillment_parcels];

    const item = newFulfillmentParcels[parcelIndex].items[itemIndex];

    const newQuantity = item.quantity - quantityToMove;

    if (newQuantity > 0) {
      item.quantity = newQuantity;
    } else {
      newFulfillmentParcels[parcelIndex].items.splice(itemIndex, 1);
    }

    const newItem = { ...item };
    newItem.quantity = quantityToMove;

    newFulfillmentParcels[newParcelIndex].items.push(newItem);

    setFulfillment({ ...fulfillment, fulfillment_parcels: newFulfillmentParcels });
  };

  return (
    <>
      <div className="overflow-hidden rounded-lg bg-white shadow-xl">
        <div className="px-4 py-5 text-sm sm:px-6 sm:py-3">
          {/* <NewComponent /> */}
          <div className="pt-3 lg:col-span-5 lg:mt-0">
            <dl className="grid grid-cols-4 gap-x-6 text-sm text-gray-700">
              <div>
                <dt className="text-sm font-medium text-gray-500">Order</dt>
                <dd className="mt-1">{fulfillment.distributor_location.name}</dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500">Store</dt>
                <dd className="mt-1">
                  <span className="block font-semibold">{fulfillmentOrder.store.name}</span>
                  <span className="block">{fulfillmentOrder.store_order_reference}</span>
                </dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500">Shipping</dt>
                <dd className="mt-1">
                  <span className="block">{fulfillmentOrder.shipping_address.name}</span>
                  <span className="block">{fulfillmentOrder.shipping_address.address1}</span>
                  <span className="block">{fulfillmentOrder.shipping_address.address2}</span>
                  <span className="block">
                    {fulfillmentOrder.shipping_address.city}{" "}
                    {fulfillmentOrder.shipping_address.state_code}{" "}
                    {fulfillmentOrder.shipping_address.postcode}
                  </span>
                </dd>
              </div>
              <div>
                {/* <dt className="font-medium text-gray-900">Shipping</dt>
                <dd className="mt-3 space-y-3">
                  <p>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "AUD",
                    }).format(fulfillment.shipping_charge)}
                  </p>
                  <p>
                    <span className="font-semibold">{fulfillment.shipping_company}</span>{" "}
                    {fulfillment.shipping_service}
                  </p>
                  <p>
                    <a href={fulfillment.tracking_url} target="_blank" rel="noreferrer">
                      {fulfillment.tracking_number}
                    </a>{" "}
                  </p>
                </dd> */}
              </div>
            </dl>
          </div>
          {fulfillment.supported_actions.includes("ready_to_ship") && (
            <Button
              onClick={() => handleShip()}
              disabled={isLoading}
              label="Ready To Ship"
              showLoading={isLoading}
            />
          )}
          <ErrorAlert errors={fulfillment.error_messages} />
        </div>
        <div className="" key={fulfillment.id}>
          <div className="p-4">
            <LineItemsTable>
              {fulfillment.line_items.map((lineItem, lineItemIndex) =>
                lineItem.units.map((unit, unitIndex) => (
                  <LineItemRow
                    key={unit.id}
                    unit={unit}
                    lineItem={lineItem}
                    isEven={unitIndex % 2}
                    onAllocate={handleAllocateUnit}
                    onResolve={handleResolve}
                  />
                ))
              )}
            </LineItemsTable>
          </div>

          <div className="border-t border-t-gray-200 bg-gray-50 px-3 py-2 sm:px-5 sm:py-5">
            <div className="space-y-5">
              {fulfillment.deliveries.map((delivery) => (
                <DeliveryCard
                  delivery={delivery}
                  key={delivery.id}
                  onParcelAdd={handleParcelAdd}
                  onRemove={handleDeliveryRemove}
                  onBook={handleDeliveryBook}
                  onRequestLabel={handleLabelRequest}
                  onRequestPickup={handleDeliveryRequestPickup}
                  onHandOver={handleDeliveryHandOver}
                >
                  {delivery.parcels.map((parcel) => (
                    <div className="divide-y divide-gray-200" key={parcel.id}>
                      <ParcelCard
                        parcel={parcel}
                        boxes={boxes}
                        isLoading={isLoading}
                        isLocked={!delivery.supported_actions.includes("edit")}
                        onRemove={handleParcelRemove}
                        onValueChange={handleParcelValueChange}
                      >
                        <ParcelItemsTable>
                          {parcel.items.map((parcelItem, parcelItemIndex) => (
                            <ParcelItemRow
                              key={parcelItem.id}
                              parcelItem={parcelItem}
                              isEven={parcelItemIndex % 2}
                              isLoading={isLoading}
                              isLocked={!delivery.supported_actions.includes("edit")}
                              onRemove={handleParcelItemRemove}
                            />
                          ))}
                        </ParcelItemsTable>
                      </ParcelCard>
                    </div>
                  ))}
                </DeliveryCard>
              ))}
            </div>
          </div>
        </div>
      </div>
      <AllocateToParcelModal
        isOpen={isAllocateToParcelModalOpen}
        closeModal={onAllocateToParcelModalClose}
        deliveries={fulfillment.deliveries}
        options={selectedFulfillmentUnitOptions}
      />
      <PackagingModal
        isOpen={isProductPackageModalOpen}
        cancelModal={() => {
          setSelectedFulfillmentUnitOptions({});
          setIsProductPackageModalOpen(false);
        }}
        closeModal={onProductPackageModalClose}
        options={package_types}
        selectedPackage={selectedFulfillmentUnitOptions.linkedPackage}
      />
      <RequestPickupModal
        isOpen={isRequestPickupModalOpen}
        closeModal={onRequestPickupModalClose}
        pickupSlots={requestPickupOptions.slots}
        courier={requestPickupOptions.carrier_service}
      />
    </>
  );
}
