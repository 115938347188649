import React from "react";

function ExternalVehicleTranslationCardCell({ cellValue }) {
  return (
    <td
      className={`col-2 p-2 d-flex border-right border-bottom text-sm ${
        cellValue !== undefined ? "" : "bg-secondary"
      }`}
    >
      <span className="align-self-center">
        {cellValue && typeof cellValue === "object"
          ? cellValue.name
          : cellValue}
      </span>
    </td>
  );
}

export default ExternalVehicleTranslationCardCell;
