import React, { useState, useEffect, useMemo } from "react";
import { Button, Checkbox, Form, FormModal, Input, TextArea } from "../UI/components";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import ExportTemplateFieldsEditor from "./ExportTemplateFieldsEditor";
import ReactOnRails from "react-on-rails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";

import { SortableTable } from "../UI/components/Table/SortableTable";

export default function ExportTemplateEditor(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [exportTemplate, setExportTemplate] = useState(props.export_template);
  const [exportTemplateFields, setExportTemplateFields] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNew, setIsNew] = useState(true);

  const columns = useMemo(
    () => [
      {
        Header: "Column Title",
        accessor: "column_title",
      },
      {
        Header: "Original Field",
        accessor: "original_column",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Strip HTML",
        accessor: "strip_html",
        className: "text-center",
      },
      {
        Header: "Character Limit",
        accessor: "character_limit",
      },
      {
        Header: "Uppercase",
        accessor: "uppercase",
      },
    ],
    []
  );

  useEffect(() => {
    setExportTemplate(props.export_template);
    if (exportTemplate.id !== null) {
      setExportTemplateFields(exportTemplate.export_template_fields);
      setIsNew(false);
    }
  }, [props]);

  async function closeModal() {
    setIsModalOpen(false);
  }

  const onSubmit = async (data) => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append("export_template[title]", data.title);
    formData.append("export_template[team_id]", props.team_id);
    formData.append(
      "export_template[export_template_fields]",
      JSON.stringify(exportTemplateFields)
    );

    if (exportTemplate.id) {
      try {
        const result = await axios({
          method: "patch",
          url: `/export_templates/${exportTemplate.id}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });

        toast.success("Export Template Updated");
        setTimeout(() => {
          setIsLoading(false);
          location.replace(`/export_templates`);
        }, 2000);
      } catch (e) {
        console.log("ERROR", e);
        toast.error("There was an error updating the Export Template");
      }
    } else {
      try {
        const result = await axios({
          method: "post",
          url: `/export_templates`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: formData,
        });

        toast.success("Export Template Created");
        setTimeout(() => {
          location.replace(`/export_templates/${result.data["export_template"]["id"]}/edit`);
        }, 2000);
      } catch (e) {
        console.log("ERROR", e);
        toast.error("There was an error creating the Export Template.");
      }
    }
  };

  async function handleRemoveExportTemplateField(id) {
    if (confirm("Do you really want to delete this Export Template Field?")) {
      try {
        const result = await axios({
          method: "delete",
          url: `/export_template_fields/${id}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
        });

        // find and remove the export template field using the id parameter from the array and update the state
        const newExportTemplateFields = exportTemplateFields.filter(
          (exportTemplateField) => exportTemplateField.id !== id
        );
        setExportTemplateFields(newExportTemplateFields);

        toast.success("Export Template Field Deleted");
        // await onUpdate();
      } catch (e) {
        toast.error("Error removing Export Template Field");
      }
    }
  }

  async function onUpdate() {
    try {
      const result = await axios({
        method: "get",
        url: `/export_templates/${exportTemplate.id}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      // only append the new fields in result.data["export_template_fields"] to the current exportTemplateFields
      const newExportTemplateFields = result.data["export_template_fields"].filter((field) => {
        return !exportTemplateFields.some((f) => f.id === field.id);
      });
      setExportTemplateFields([...exportTemplateFields, ...newExportTemplateFields]);
    } catch {
      toast.error("Could not refresh Export Template data");
    }
  }

  return (
    <>
      <Form onSubmit={onSubmit} defaultValues={exportTemplate}>
        <div className="flex items-center justify-between border-b pb-5">
          <h2 className="text-2xl font-bold">Export Template</h2>
          <div className="btn-group">
            <a href={`/export_templates`} className="btn btn-neutral">
              Back
            </a>
            <Button type="submit" label="Save" className="btn" showLoading={isLoading} />
          </div>
        </div>

        <div className="py-5">
          <div className="data-export-ui relative w-full space-y-5 rounded-lg bg-white p-5 shadow-sm">
            <div className="w-2/4">
              <div className="form-group">
                <Input
                  name="title"
                  label="Export Template Title"
                  type="text"
                  defaultValue={exportTemplate.title}
                  required
                />
              </div>
            </div>

            <>
              {!isNew && (
                <div className="border-t border-gray-200">
                  <div className="block min-w-full">
                    <div className="overflow-hidden sm:rounded-lg">
                      <div className="flex items-center justify-between border-b py-4">
                        <div className="heading-sm">Columns</div>
                        <button type="button" className="btn" onClick={() => setIsModalOpen(true)}>
                          Add Column
                        </button>
                      </div>
                      <SortableTable
                        columns={columns}
                        data={exportTemplateFields}
                        setData={setExportTemplateFields}
                        rowDeleteHandler={async (id) => {
                          await handleRemoveExportTemplateField(id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </Form>
      <ExportTemplateFieldsEditor
        exportTemplate={exportTemplate}
        isOpen={isModalOpen}
        closeModal={closeModal}
        typesOptions={props.types}
        originalColumnOptions={props.original_columns}
        onUpdate={onUpdate}
      />
      <Toaster />
    </>
  );
}
