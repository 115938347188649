import React, { useState } from "react";

const Tab = ({ label, activeTab, onClick }) => {
  return (
    <button
      className={`tab-label ${activeTab === label ? "active" : ""}`}
      onClick={() => onClick(label)}
    >
      {label}
    </button>
  );
};

export const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children?.[0]?.props?.label);
  const onClickTabItem = (label) => {
    setActiveTab(label);
  };

  const tabs = children.filter((child) => !!child?.props?.label);

  return (
    <div>
      <div className="mb-6 flex w-full items-center justify-between space-x-6 border-b border-gray-200">
        <ol className="-mb-px flex items-center space-x-6">
          {tabs.map((tab) => {
            const { label } = tab.props;

            return <Tab activeTab={activeTab} key={label} label={label} onClick={onClickTabItem} />;
          })}
        </ol>
      </div>
      <div>
        {tabs.map((tab) => {
          if (tab.props.label !== activeTab) return null;
          return tab.props.children;
        })}
      </div>
    </div>
  );
};
