import { useCallback, useState } from "react";

// Custom hook to copy text to clipboard
const useCopyToClipboard = (timeoutDuration = 3000) => {
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(null);

  const copyToClipboard = useCallback(
    async (text) => {
      try {
        if ("clipboard" in navigator) {
          console.log("navigator.clipboard.writeText", text);
          await navigator.clipboard.writeText(text);
        } else if (document.queryCommandSupported("copy")) {
          var textarea = document.createElement("textarea");
          textarea.textContent = text;
          textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
          document.body.appendChild(textarea);
          textarea.select();
          try {
            document.execCommand("copy"); // Security exception may be thrown by some browsers.
          } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
          } finally {
            document.body.removeChild(textarea);
          }
        }

        setCopied(true);
        setError(null);
        setTimeout(() => setCopied(false), timeoutDuration);
        console.log("Copied to clipboard:", text);
      } catch (err) {
        console.error("Failed to copy:", err);
        setError(err instanceof Error ? err : new Error("Failed to copy text"));
      }
    },
    [timeoutDuration]
  );

  return { copied, error, copyToClipboard };
};

export default useCopyToClipboard;
