import React, { useState, useEffect } from "react";
import axios from "axios";
import ProductSelect from "../../UI/components/ProductSelect";
import toast from "react-hot-toast";

import { FormModal } from "../../UI/components";

function CrossSellProductEditor({
  id,
  settings,
  product,
  cross_sell_product,
  relationship_type,
  catalogue_notes,
  closeModal,
  isOpen,
  onUpdate,
}) {
  const [selected_id, setSelectedId] = useState(id);
  const [selected_cross_sell_product, setSelectedCrossSellProduct] = useState(cross_sell_product);
  const [selected_relationship_type, setSelectedRelationshipType] = useState(relationship_type);
  const [selected_catalogue_notes, setSelectedCatalogueNotes] = useState(catalogue_notes);

  useEffect(() => {
    setSelectedId(id);
  }, [id]);

  useEffect(() => {
    setSelectedCrossSellProduct(cross_sell_product);
  }, [cross_sell_product]);

  useEffect(() => {
    setSelectedRelationshipType(relationship_type);
  }, [relationship_type]);

  useEffect(() => {
    setSelectedCatalogueNotes(catalogue_notes);
  }, [catalogue_notes]);

  const isNew = !selected_id;

  const onCancel = () => {
    closeModal(null);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      data: {
        product_id: product.id,
        product_type: product.type,
        related_product_id: selected_cross_sell_product.id,
        related_product_type: selected_cross_sell_product.type,
        relationship_type: selected_relationship_type
          ? selected_relationship_type
          : Object.entries(relationshipTypeContext)[0][0],
        catalogue_notes: selected_catalogue_notes,
      },
    };

    let result;
    if (selected_id) {
      try {
        result = await axios.patch(`/cross_sell_products/${selected_id}`, requestConfig);
        toast.success(result.data.success);

        await onUpdate();
        closeModal();
      } catch (error) {
        toast.error(error?.response?.data?.message ?? "Error Updating Cross-sell");
      }
    } else {
      try {
        result = await axios.post("/cross_sell_products", requestConfig);
        setSelectedId(result.data.cross_sell_product.id);
        toast.success(result.data.success);
        await onUpdate();
        closeModal();
      } catch (error) {
        toast.error(error?.response?.data?.message ?? "Error Creating Cross-sell");
      }
    }
  };

  const onRelationshipTypeChange = (e) => {
    setSelectedRelationshipType(e.target.value);
  };

  const onCatalogueNotesChange = (e) => {
    setSelectedCatalogueNotes(e.target.value);
  };

  let _options = JSON.parse(settings);
  const option_html = Object.entries(_options.relationship_types).map((r, index) => {
    return <option key={index}>{r[0]}</option>;
  });

  const relationshipTypeContext = {};
  Object.entries(_options.relationship_types).forEach((r) => {
    relationshipTypeContext[r[1].value] = r[1].help;
  });

  return (
    <FormModal title={isNew ? "Add Cross-sell" : "Update Cross-sell"} {...{ isOpen, onCancel }}>
      <form onSubmit={onSubmit}>
        <div className="form-group mt-5">
          <label className="sr-only">SKU</label>
          <ProductSelect
            onProductSelect={(p) => setSelectedCrossSellProduct(p)}
            excludeSkus={[product.sku]}
          />
          <input
            className="form-control"
            type="text"
            value={selected_cross_sell_product.sku}
            readOnly={true}
          />
        </div>
        <div className="form-group">
          <label>Type</label>
          <select
            className="form-control"
            defaultValue={
              selected_relationship_type
                ? selected_relationship_type
                : Object.entries(relationshipTypeContext)[0][0]
            }
            onChange={(e) => onRelationshipTypeChange(e)}
          >
            {option_html}
          </select>
          <div className="flex items-start pt-3 text-xs text-gray-500">
            <i className="fa fa-question-circle-o mr-2 text-sm"></i>{" "}
            {selected_relationship_type
              ? relationshipTypeContext[selected_relationship_type]
              : Object.entries(relationshipTypeContext)[0][1]}
          </div>
        </div>
        <div className="form-group">
          <label>Catalogue Notes</label>
          <textarea
            className="form-control"
            rows="3"
            placeholder="eg. This part only fits using top two mounting holes"
            value={selected_catalogue_notes}
            onChange={(e) => onCatalogueNotesChange(e)}
          ></textarea>
        </div>
        <div className="mt-4 flex justify-end space-x-2">
          <button type="button" className="btn btn-neutral" onClick={onCancel}>
            Cancel
          </button>
          <button type="submit" className="btn btn-dark">
            {isNew ? "Add" : "Update"}
          </button>
        </div>
      </form>
    </FormModal>
  );
}

export default CrossSellProductEditor;
