import React, { useState } from "react";

import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import AccountSettings from "../Account/AccountSettings";
import { Button } from "../UI/components";
import { faCcStripe } from "@awesome.me/kit-989a8e6dbe/icons/classic/brands";

export default function DistributorPayments({ team, user, distributor, app_contexts }) {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Header app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8 pb-24 pt-5">
            <div className="flex w-full items-center justify-between">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Payments</h2>
            </div>

            <div className="overflow-visible rounded-lg bg-white p-5 shadow">
              {distributor.stripe_connect_onboarded ? (
                <div className="prose prose-sm">
                  <h3 className="text-success-500">Your Stripe Connect account is linked.</h3>
                  <div className="text-gray-500">
                    <p>
                      You can now start receiving payouts from Partbot when merchants request
                      dropship fulfilments from you.
                    </p>
                  </div>
                  <Button
                    className="btn btn-emerald"
                    icon={faCcStripe}
                    label="View Stripe Payouts"
                    onClick={() => {
                      window.open("/payments/link", "_blank");
                    }}
                  />
                </div>
              ) : (
                <div className="prose prose-sm">
                  <h3>Setup</h3>
                  <div className="text-gray-500">
                    <p>
                      You need to connect your Stripe account to start receiving payouts from
                      Partbot when merchants request dropship fulfilments from you.
                    </p>
                    <Button
                      className="btn btn-emerald"
                      icon={faCcStripe}
                      label="Connect to Stripe"
                      showLoading={loading}
                      onClick={() => {
                        setLoading(true);
                        window.location.href = "/payments/link";
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
