import React, { useState, useEffect } from "react";
import ExportRow from "./ExportRow";
import axios from "axios";

export default function DataExport(props) {
  const [exports, setExports] = useState(props.exports);
  const [productTypes, setProductTypes] = useState(props.product_types);
  const [brands, setBrands] = useState(props.brands);
  const [exportRows, setExportRows] = useState([]);

  const createExportRow = () => {
    const new_export = {
      account_code: "",
      email: "",
    };

    setExports([...exports, new_export]);
  };

  const deleteExportRow = async (id) => {
    const result = await axios.delete(`/admin/exports/${id}`);

    setExports(result.data.exports);
  };

  useEffect(() => {
    setExportRows(
      exports.map((exp, index) => {
        return (
          <ExportRow
            key={index}
            exp={exp}
            product_types={productTypes}
            brands={brands}
            delete_handler={deleteExportRow}
          />
        );
      })
    );
  }, [exports]);

  return (
    <div className="relative">
      <div className="flex justify-between items-center border-b pb-5">
        <h2 className="text-2xl font-bold">Data Exports</h2>
        <button className="btn" onClick={createExportRow}>
          Add New Export
        </button>
      </div>
      <div className="py-5">
        <div className="data-export-ui relative w-full p-5 bg-white rounded-lg shadow-sm">
          <table className="min-w-full my-5 divide-y divide-gray-200 datatable">
            <thead>
              <tr>
                <th>Account Code</th>
                <th>Email</th>
                <th>Product Types</th>
                <th>Brands</th>
                <th>Schedule</th>
                <th>Active</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{exportRows}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
