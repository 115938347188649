import React, { useState, useEffect } from "react";
import { Checkbox, Form, Input, Select, FormModal, Button } from "../../UI/components";

export default function PackagingModal({
  closeModal,
  cancelModal,
  isOpen,
  options,
  selectedPackage = {},
}) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsLoading(false);
    }

    return () => {
      setIsLoading(false);
    };
  }, [isOpen]);

  const onCancel = () => {
    cancelModal();
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    setTimeout(() => {
      // setIsLoading(false);
      closeModal(data);
    }, 1000);
  };

  return (
    <FormModal title="Add Package" {...{ onCancel, isOpen }}>
      <Form onSubmit={onSubmit} defaultValues={selectedPackage}>
        <div className="my-4">
          <div className="my-4">
            <Select
              label="Container Type"
              name="package_type_id"
              options={options}
              // defaultValue={selectedPackage?.package_type_id}
            />
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
            <div className="my-4">
              <Input
                label="Length"
                name="length"
                type="number"
                required
                className="my-4"
                step={0.1}
                min={0.1}
                // defaultValue={selectedPackage?.length}
              />
            </div>

            <div className="my-4">
              <Input
                label="Width"
                name="width"
                required
                type="number"
                className="my-4"
                step={0.1}
                min={0.1}
                // defaultValue={selectedPackage?.width}
              />
            </div>

            <div className="my-4">
              <Input
                label="Height"
                name="height"
                required
                type="number"
                className="my-4"
                step={0.1}
                min={0.1}
                // defaultValue={selectedPackage?.height}
              />
            </div>

            <div className="my-4">
              <Select
                label="Unit"
                name="dimensions_unit"
                required
                options={[
                  { value: "cm", label: "cm" },
                  { value: "m", label: "m" },
                  { value: "mm", label: "mm" },
                  { value: "in", label: "in" },
                  { value: "ft", label: "ft" },
                  { value: "yd", label: "yd" },
                ]}
                // defaultValue={selectedPackage?.dimensions_unit}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
            <div className="my-4">
              <Input
                label="Weight"
                name="weight"
                type="number"
                className="my-4"
                step={0.001}
                min={0.001}
                // defaultValue={selectedPackage?.weight}
              />
            </div>
            <div className="my-4">
              <Select
                label="Unit"
                name="weight_unit"
                required
                options={[
                  { value: "kg", label: "kg" },
                  { value: "lb", label: "lb" },
                  { value: "g", label: "g" },
                  { value: "oz", label: "oz" },
                ]}
                // defaultValue={selectedPackage?.weight_unit}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div className="my-4">
              <Input
                label="Label"
                name="label"
                type="text"
                className="my-4"
                // defaultValue={selectedPackage?.label}
              />
            </div>
          </div>

          <div className="my-4">
            <Checkbox
              label="Ships As Is"
              name="ships_separate"
              // checked={selectedPackage?.ships_separate}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <div className="btn-group">
            <Button label="Cancel" type="button" className="btn btn-neutral" onClick={onCancel} />
            <Button
              label={selectedPackage ? "Update" : "Add"}
              type="submit"
              className="btn"
              showLoading={isLoading}
            />
          </div>
        </div>
      </Form>
    </FormModal>
  );
}
