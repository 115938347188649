import React, { useState, useEffect } from "react";
import { Form, RadioButton, FormModal, Button } from "../../UI/components";
import dayjs from "dayjs";
import { useForm, useController } from "react-hook-form";

export default function RequestPickupModal({ closeModal, isOpen, pickupSlots, courier }) {
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {},
  });

  const { field } = useController({
    control,
    name: "pickup_slot",
    rules: { required: true },
  });
  const [pickUpSlot, setPickUpSlot] = React.useState(null);

  const onCancel = () => {
    closeModal(null);
  };

  const onRadioChange = (e) => {
    const value = e.target.value;
    console.log(value);
    setPickUpSlot(value);
    setValue("pickup_slot", value);
  };

  const onSubmit = (data) => {
    const flattenedSlots = pickupSlots.flatMap((x) =>
      x.windows.map((y) => {
        return {
          pickup_window_start: y.start,
          pickup_window_finish: y.end,
          pickup_window_id: y.slot_id,
        };
      })
    );

    const selectedSlot = flattenedSlots.find((slot) => slot.pickup_window_id === data.pickup_slot);
    data["selectedSlot"] = selectedSlot;
    // fake delay so user can see something is happening
    console.log(data);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      closeModal(data);
    }, 250);
  };

  const options = ["John", "Rob", "Linda"];

  return (
    <FormModal title="Request Pickup" {...{ onCancel, isOpen }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-4">
          <p className="my-3 text-sm leading-5 text-gray-500">
            Available pickup slots for {courier}
          </p>

          {pickupSlots.length > 0 && (
            <>
              {pickupSlots.map((option, index) => (
                <div
                  className={`flex items-start rounded p-3 ${
                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                  }`}
                  key={`pickup-slot-${index}`}
                >
                  <div className="w-6/12 pt-1 text-sm font-medium text-gray-700">
                    {dayjs(option.date).format("ddd, D MMMM")}
                  </div>
                  <div className="flex w-6/12 flex-col justify-end space-y-2">
                    {option.windows.map((window, wIndex) => (
                      <div key={wIndex} className="flex items-center">
                        <input
                          onChange={(e) => onRadioChange(e)}
                          key={`time-slot-${index}-${wIndex}`}
                          type="radio"
                          checked={pickUpSlot === window.slot_id}
                          id={`time-slot-${index}-${wIndex}`}
                          value={window.slot_id}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                          htmlFor={`time-slot-${index}-${wIndex}`}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {`${dayjs(window.start).format("hh:mm A")} - ${dayjs(window.end).format(
                            "hh:mm A"
                          )}`}
                        </label>
                      </div>

                      // <RadioButton
                      //   key={`time-slot-${index}-${wIndex}`}
                      //   name="pickup_slot"
                      //   id={`time-slot-${index}-${wIndex}`}
                      //   label={`${dayjs(window.start).format("hh:mm A")} - ${dayjs(
                      //     window.end
                      //   ).format("hh:mm A")}`}
                      //   value={window.slot_id}
                      //   required
                      //   defaultChecked={
                      //     window.slot_id === pickupSlots.flatMap((o) => o.windows)[0].slot_id
                      //   }
                      // />
                    ))}
                  </div>
                </div>
              ))}
            </>
            //   )}
            // />
          )}
        </div>
        <div className="m-0 mt-4 flex justify-end">
          <div className="btn-group">
            <Button label="Cancel" type="button" className="btn btn-neutral" onClick={onCancel} />
            <Button label="Submit" type="submit" className="btn" showLoading={isLoading} />
          </div>
        </div>
      </form>
    </FormModal>
  );
}
