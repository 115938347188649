import React from "react";

export const Grid = ({ children }) => {
  return (
    <div className="mt-6 grid grid-cols-1 gap-3 md:grid-cols-3 lg:grid-cols-5">
      {children.map((child, index) => {
        return (
          <div key={index} className="flex flex-col">
            {child}
          </div>
        );
      })}
    </div>
  );
};
