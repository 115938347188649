import { useStore } from "../state/StoreProvider";
import { shallow } from "zustand/shallow";

export const useMarketplaceImportState = () => {
  return useStore(
    (state) => ({
      importList: state.importList,
      importListLoading: state.importListLoading,
      totalRows: state.totalRows,
      currentPage: state.currentPage,
      perPage: state.perPage,
      isBulkPublishModalOpen: state.isBulkPublishModalOpen,
      publishLoading: state.publishLoading,
      publishErrors: state.publishErrors,
      isPublishSuccessful: state.isPublishSuccessful,
      publishedStores: state.publishedStores,
      setPublishSuccessModal: state.setPublishSuccessModal,
      setBulkPublishModal: state.setBulkPublishModal,
      fetchImportListings: state.fetchImportListings,
      fetchStores: state.fetchStores,
      publishSingleListing: state.publishSingleListing,
      publishBulkListings: state.publishBulkListings,
      removeBulkListings: state.removeBulkListings,
      updateImportListing: state.updateImportListing,
      allImportListingIds: state.allImportListingIds,
      brandSellerAgreements: state.brandSellerAgreements,
      merchantProductsCount: state.merchantProductsCount,
      bulkPublishJobId: state.bulkPublishJobId,
      fetchBulkPublishProgress: state.fetchBulkPublishProgress,
      bulkPublishProgress: state.bulkPublishProgress,
      bulkPublishTotal: state.bulkPublishTotal,
      bulkPublishAt: state.bulkPublishAt,
      searchQuery: state.searchQuery,
    }),
    shallow
  );
};
