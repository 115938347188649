import React from "react";

export default function ShopItem(props) {
  return (
    <li>
      <div className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="items start flex min-w-0 flex-col">
            <p className="mb-4">
              <strong>{props.integration.shop}</strong>
            </p>
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id={`overwrite_properties_${props.integration.id}`}
                  name={`overwrite_properties_${props.integration.id}`}
                  defaultChecked={props.integration.overwrite_properties}
                  onChange={(e) =>
                    props.integrationOptionsHandler(props.integration.id, {
                      overwrite_properties: e.target.checked,
                    })
                  }
                  aria-describedby="overwrite-properties"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor={`overwrite_properties_${props.integration.id}`}
                  className="font-medium text-gray-700"
                >
                  Overwrite Listing Properties
                </label>
                <p id="overwrite-properties" className="text-gray-500">
                  Replaces listing title, description, price, variant images etc. every sync.
                </p>
              </div>
            </div>
          </div>
          {props.integration.shopify_id && (
            <div className="ml-5 flex-shrink-0">
              <a
                className="btn btn-sm btn-info"
                href={`https://${props.integration.shop}.myshopify.com/admin/products/${props.integration.shopify_id}/`}
                target="_blank"
              >
                View on Shopify
              </a>
            </div>
          )}
        </div>
      </div>
    </li>
  );
}
