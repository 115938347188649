import { useStore } from "../state/StoreProvider";
import { shallow } from "zustand/shallow";

export const useMarketplaceProductState = () => {
  return useStore(
    (state) => ({
      importListingCount: state.importListingCount,
      merchantProductsCount: state.merchantProductsCount,
      team: state.team,
      totalRows: state.totalRows,
      totalHits: state.totalHits,
      currentPage: state.currentPage,
      perPage: state.perPage,
      products: state.products,
      productsLoading: state.productsLoading,
      productsError: state.productsError,
      currentProduct: state.currentProduct,
      currentProductLoading: state.currentProductLoading,
      currentProductError: state.currentProductError,
      fetchProducts: state.fetchProducts,
      setCurrentProduct: state.setCurrentProduct,
      saveMerchantProduct: state.saveMerchantProduct,
      createMerchantProductAttribute: state.createMerchantProductAttribute,
      removeMerchantProductAttribute: state.removeMerchantProductAttribute,
      createMerchantProductVehicles: state.createMerchantProductVehicles,
      removeMerchantProductVehicle: state.removeMerchantProductVehicle,
      searchQuery: state.searchQuery,
      syncAllProducts: state.syncAllProducts,
      syncProgress: state.syncProgress,
      syncTotal: state.syncTotal,
      syncAt: state.syncAt,
      fetchSyncProgress: state.fetchSyncProgress,
      fetchProductDetails: state.fetchProductDetails,
      cancelSyncAllProducts: state.cancelSyncAllProducts,
      resetSyncProgress: state.resetSyncProgress,
      stores: state.stores,
    }),
    shallow
  );
};
