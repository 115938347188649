import React from "react";
import { Button } from "./Button";
import useCopyToClipboard from "../../../utils/hooks/useCopyToClipboard";
import { faCopy, faCheck } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import clsx from "clsx";

const ButtonCopyToClipboard = ({ text, ...props }) => {
  const { copied, copyToClipboard } = useCopyToClipboard();

  return (
    <Button
      size="icon"
      className={clsx("btn btn-icon", copied ? "btn-light-emerald" : "btn-outline")}
      icon={copied ? faCheck : faCopy}
      onClick={() => {
        copyToClipboard(text);
      }}
      {...props}
    />
  );
};

export default ButtonCopyToClipboard;
