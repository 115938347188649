import clsx from "clsx";
import React from "react";
import { useAsyncDebounce } from "react-table";

// Define a default UI for filtering
export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  placeholder,
  size,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <input
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      type="text"
      placeholder={placeholder || "Search..."}
      className={clsx("mx-1 max-w-md", size === "xs" ? "!py-1.5 !text-xs" : "text-sm")}
    />
  );
}
