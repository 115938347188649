import VehicleSelectModal from "../../../Products/components/VehicleSelectModal.js";
import React, { useState } from "react";
import { useMarketplaceProductState } from "../../hooks/useMarketplaceProductState";
import PartbotLogo from "/app/assets/images/partbot-logo.svg";

export const VehiclesTab = () => {
  const {
    currentProduct,
    createMerchantProductVehicles,
    removeMerchantProductVehicle,
    currentProductLoading,
  } = useMarketplaceProductState();

  const [vehicleApplications, setVehicleApplications] = useState(
    currentProduct?.vehicle_applications || []
  );

  const [vehicles, setVehicles] = useState(currentProduct?.vehicles || []);
  const [vehiclesModalOpen, setVehiclesModalOpen] = useState(false);

  const vehiclesIds = vehicles.map((vehicle) => vehicle.id);

  const poweredByPartbot = currentProduct?.status === "Synced";

  const handleAddVehicles = async (vehicleIds) => {
    setVehiclesModalOpen(false);
    if (vehicleIds) {
      const response = await createMerchantProductVehicles(currentProduct.id, vehicleIds);

      console.log("response", response);

      if (response.status === 201) {
        // setVehicles([...vehicles, ...response.data.data]);
        setVehicleApplications(response.data.vehicle_applications);
      }
    }
  };

  const handleRemoveVehicle = async (vehicleId) => {
    if (vehicleId) {
      const response = await removeMerchantProductVehicle(currentProduct.id, vehicleId);

      console.log("response", response);

      if (response.status === 200) {
        // setVehicles(vehicles.filter((vehicle) => vehicle.id !== vehicleId));
        setVehicleApplications(response.data.vehicle_applications);
      }
    }
  };

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="text-xs text-gray-700">
            {poweredByPartbot
              ? "Manufacturer managed vehicles cannot be changed."
              : "Add Partbot vehicles to this product."}
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          {poweredByPartbot ? (
            <div className="flex space-x-1.5">
              <span className="text-xs text-gray-500">Powered by</span>
              <img src={PartbotLogo} className="h-4 w-auto" alt="Partbot" />
            </div>
          ) : (
            <>
              <button
                onClick={() => setVehiclesModalOpen(true)}
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Add Vehicle
              </button>

              <VehicleSelectModal
                isOpen={vehiclesModalOpen}
                closeModal={handleAddVehicles}
                existingVehicleIds={vehiclesIds}
                productId={currentProduct?.id}
                hiddenColumns={["source"]}
                excludeArchived={true}
              />
            </>
          )}
        </div>
      </div>
      {vehicleApplications && vehicleApplications.length > 0 && (
        <div className="mt-8 flex flex-col">
          <div className="-my-2 w-full">
            <div className="w-full py-2">
              <div className="overflow-clip rounded-lg border border-gray-200 shadow">
                {vehicleApplications.map((vehicleApplication) => (
                  <table
                    key={vehicleApplication.id}
                    className="w-full table-auto divide-y divide-gray-300 rounded-lg text-xs"
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-2.5 pl-4 pr-3 text-left font-semibold text-gray-900 sm:pl-6"
                        >
                          Vehicles
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-2.5 text-left font-semibold text-gray-900"
                        >
                          Year Range
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-2.5 text-left font-semibold text-gray-900"
                        >
                          Series
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-2.5 text-left font-semibold text-gray-900"
                        >
                          Body
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-2.5 text-left font-semibold text-gray-900"
                        >
                          Engine
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-2.5 text-left font-semibold text-gray-900"
                        >
                          Transmission
                        </th>
                        {!poweredByPartbot && (
                          <th scope="col" className="relative py-2.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Remove</span>
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      <tr>
                        <td
                          colSpan="7"
                          className="py-2.5 pl-4 pr-3 font-medium text-gray-900 sm:pl-6"
                        >
                          {vehicleApplication.title}
                        </td>
                      </tr>
                      {vehicleApplication.vehicles.map((vehicle) => (
                        <tr key={vehicle.id}>
                          <td className="py-2.5 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
                            {vehicle.title}
                          </td>
                          <td className="px-3 py-2.5 text-gray-500">{vehicle.year_range}</td>
                          <td className="px-3 py-2.5 text-gray-500">{vehicle.series}</td>
                          <td className="px-3 py-2.5 text-gray-500">{vehicle.body}</td>
                          <td className="px-3 py-2.5 text-gray-500">{vehicle.engine}</td>
                          <td className="px-3 py-2.5 text-gray-500">{vehicle.transmission}</td>
                          {!poweredByPartbot && (
                            <td className="relative py-2.5 pl-3 pr-4 text-right font-medium sm:pr-6">
                              <a
                                href="#"
                                className="text-danger-600 hover:text-danger-900"
                                onClick={() => handleRemoveVehicle(vehicle.id)}
                              >
                                Remove<span className="sr-only">, {vehicle.name}</span>
                              </a>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
