import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const urlPath = window.location.pathname;

export default function FulfillmentHeader({ active, distributor_location }) {
  return (
    <div>
      <div className="block">
        <div className="flex items-center justify-between border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <a
              href={`/l/${distributor_location.id}/fulfillments`}
              className={classNames(
                urlPath.includes("fulfillments")
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
              )}
            >
              Packing
              {distributor_location.pending_count ? (
                <span
                  className={classNames(
                    urlPath.includes("fulfillments")
                      ? "bg-indigo-100 text-indigo-600"
                      : "bg-gray-100 text-gray-900",
                    "ml-1.5 -mt-0.5 hidden rounded-full py-0.5 px-2 text-[10px] font-medium md:inline-block"
                  )}
                >
                  {distributor_location.pending_count}
                </span>
              ) : null}
            </a>

            <a
              href={`/l/${distributor_location.id}/shipments`}
              className={classNames(
                urlPath.includes("shipments")
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
              )}
            >
              Shipping{" "}
              {distributor_location.deliveries_count ? (
                <span
                  className={classNames(
                    urlPath.includes("shipments")
                      ? "bg-indigo-100 text-indigo-600"
                      : "bg-gray-100 text-gray-900",
                    "ml-1.5 -mt-0.5 hidden rounded-full py-0.5 px-2 text-[10px] font-medium md:inline-block"
                  )}
                >
                  {distributor_location.deliveries_count}
                </span>
              ) : null}
            </a>

            <a
              href={`/l/${distributor_location.id}/handovers`}
              className={classNames(
                urlPath.includes("handovers")
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
              )}
            >
              Awaiting Handover
            </a>
          </nav>
          <div>
            <span className="truncate text-sm font-medium text-gray-500">
              {distributor_location.name}
            </span>

            <span className="ml-2 rounded bg-indigo-100 p-1 text-xs font-semibold uppercase text-indigo-600">
              {distributor_location.code}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
