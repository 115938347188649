import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
// import Api from "./api";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import BrandCombobox from "../UI/components/BrandCombobox";
import ProductSelect from "../UI/components/ProductSelect";
import { Button } from "../UI/components/Button";
import { faArrowUpFromDottedLine } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { faBoxAlt, faBoxOpen, faBoxTaped } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";
import PackagingModal from "../Products/components/PackagingModal";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";
import { classNames } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import BoxModal from "../Products/components/BoxModal";

export default function DistributorCapture({ distributor, app_contexts, package_types, boxes }) {
  // const marketplaceAPI = new Api(api_key, location);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBoxModalOpen, setIsBoxModalOpen] = useState(false);
  const [boxToRemove, setBoxToRemove] = useState(null);
  const [currentBrand, setCurrentBrand] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentPackages, setCurrentPackages] = useState(null);
  const [currentBoxes, setCurrentBoxes] = useState(boxes);
  const [packageToRemove, setPackageToRemove] = useState(null);

  const onProductSelect = (product) => {
    setCurrentProduct(product);
    setCurrentPackages(product.packages);
  };

  const onModalClose = async (data) => {
    if (data) {
      try {
        const package_params = { ...data, distributor_id: distributor.id };

        const result = await axios({
          method: "post",
          url: "/packages",
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: {
            product_id: currentProduct.id,
            ...package_params,
          },
        });

        setCurrentPackages(result.data.packages);

        toast.success("Package added to product");
      } catch (e) {
        console.log(e);
        toast.error("Error adding package to product");
      }
    }

    setIsModalOpen(false);
  };

  const removePackage = async (package_id) => {
    try {
      const result = await axios({
        method: "delete",
        url: `/packages/${package_id}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      setTimeout(() => {
        setCurrentPackages(result.data.packages);
        setPackageToRemove(null);
      }, 1000);

      toast.success("Package removed from product");
    } catch (e) {
      console.log(e);
      toast.error("Error removing package from product");
    }
  };

  const onBoxModalClose = async (data) => {
    if (data) {
      try {
        const box_params = {
          box: {
            ...data,
            distributor_id: distributor.id,
            dimensions_unit: "cm",
            weight_unit: "kg",
          },
        };

        const result = await axios({
          method: "post",
          url: "/box",
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: box_params,
        });

        setCurrentBoxes(result.data.boxes);

        toast.success("Box saved");
      } catch (e) {
        console.log(e);
        toast.error("Error creating box");
      }
    }

    setIsBoxModalOpen(false);
  };

  const removeBox = async (box_id) => {
    try {
      const result = await axios({
        method: "delete",
        url: `/box/${box_id}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      setTimeout(() => {
        setCurrentBoxes(result.data.boxes);
        setBoxToRemove(null);
      }, 1000);

      toast.success("Box removed");
    } catch (e) {
      console.log(e);
      toast.error("Error removing box");
    }
  };

  const tabData = [
    {
      key: "boxes",
      display: "Shipping Boxes",
    },
    {
      key: "packaging",
      display: "Product Weights & Dimensions",
    },
  ];

  return (
    <>
      <Header app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8">
            <div className="flex w-full items-end justify-between">
              <div className="max-w-prose space-y-3">
                <h2 className="m-0 text-3xl font-extrabold tracking-tight text-gray-900">
                  Packaging
                </h2>
              </div>
              <div>
                <Button
                  label="Bulk Import"
                  disabled
                  title="Coming Soon"
                  icon={faArrowUpFromDottedLine}
                />
              </div>
            </div>

            <div className="w-full space-y-5 rounded-lg bg-white p-6 shadow">
              <Tab.Group>
                <div className="border-b border-gray-200">
                  <Tab.List className="tabs-menu -mb-px flex space-x-8" aria-label="Tabs">
                    {tabData.map((tab) => (
                      <Tab
                        key={tab.key}
                        disabled={tab.disabled}
                        className={({ selected, disabled }) =>
                          classNames(
                            "flex whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium focus-visible:outline-0",
                            selected
                              ? "is-active border-indigo-500 text-indigo-600"
                              : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                            tab.disabled ? "hidden" : ""
                          )
                        }
                      >
                        {({ selected }) => (
                          <>
                            {tab.display}
                            {tab.count ? (
                              <span
                                className={classNames(
                                  selected
                                    ? "bg-indigo-100 text-indigo-600"
                                    : "bg-gray-100 text-gray-900",
                                  "ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block"
                                )}
                              >
                                {tab.count}
                              </span>
                            ) : null}
                          </>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
                <Tab.Panels className="mt-2">
                  <Tab.Panel>
                    <div className="w-full">
                      <div className="flex w-full items-center justify-between gap-12">
                        <div className="inline-block rounded-md bg-indigo-50 px-4 py-3 text-xs text-indigo-500">
                          Add the boxes used to ship products from your warehouses. Partbot will use
                          these boxes to determine the best packing methods and optimal shipping
                          rates.
                        </div>
                        <div>
                          <Button label={"Add Box"} onClick={() => setIsBoxModalOpen(true)} />
                        </div>
                      </div>
                      <div className="mt-8 w-full">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-200 !text-xs">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                      Identifier/Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                      Length (cm)
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                      Width (cm)
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                      Height (cm)
                                    </th>

                                    <th scope="col" className="relative px-6 py-3">
                                      <span className="sr-only">Edit</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!currentBoxes || !currentBoxes.length ? (
                                    <tr>
                                      <td
                                        className="whitespace-nowrap px-6 py-4 text-gray-500"
                                        colSpan={7}
                                      >
                                        No Boxes
                                      </td>
                                    </tr>
                                  ) : (
                                    currentBoxes?.map((box, index) => (
                                      <tr
                                        key={box.id}
                                        className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                                      >
                                        <td className="flex items-center space-x-3 whitespace-nowrap px-6 py-4 font-medium capitalize text-gray-900">
                                          <FontAwesomeIcon
                                            icon={faBoxTaped}
                                            className="text-base text-indigo-500"
                                          />
                                          <span className="font-medium text-indigo-500">
                                            {box.name}
                                          </span>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-gray-500">
                                          {box.length}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-gray-500">
                                          {box.width}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-gray-500">
                                          {box.height}
                                        </td>

                                        <td className="whitespace-nowrap px-6 py-4 text-right text-xs font-medium">
                                          {box.id && (
                                            <Button
                                              label="Remove"
                                              type="button"
                                              showLoading={box.id === boxToRemove}
                                              onClick={() => {
                                                setBoxToRemove(box.id);
                                                removeBox(box.id);
                                              }}
                                              className="text-danger-600 hover:text-danger-900 space-x-2"
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className="grid grid-cols-3 gap-8">
                      <div className="col-span-3">
                        <div className="inline-block rounded-md bg-indigo-50 px-4 py-3 text-xs text-indigo-500">
                          Add packaging info, weights and dimensions to products. This will override
                          any packages specified by the manufacturer.
                        </div>
                      </div>
                      <div className="form-group">
                        <BrandCombobox
                          selectedOption={currentBrand}
                          handleBrandSelect={(e) => setCurrentBrand(e)}
                        />
                      </div>
                      <div>
                        <ProductSelect
                          label="Select Product"
                          brand={currentBrand}
                          onProductSelect={(e) => onProductSelect(e)}
                        />
                      </div>
                    </div>
                    {currentProduct && (
                      <div className="flex flex-col">
                        <div className="mb-4 flex items-center justify-between">
                          <div className="space-x-3">
                            <span className="rounded-md bg-purple-100 px-2 py-1 text-sm font-bold text-purple-500">
                              {currentProduct.sku}
                            </span>
                            <span className="text-xs text-gray-500">{currentProduct.title}</span>
                          </div>
                          <div>
                            <Button label="Add Package" onClick={() => setIsModalOpen(true)} />
                          </div>
                        </div>

                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-200 !text-xs">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                      Container
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                      Weight (kg)
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                      Length (cm)
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                      Width (cm)
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                      Height (cm)
                                    </th>

                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                      Labels
                                    </th>
                                    <th scope="col" className="relative px-6 py-3">
                                      <span className="sr-only">Edit</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!currentPackages || !currentPackages.length ? (
                                    <tr>
                                      <td
                                        className="whitespace-nowrap px-6 py-4 text-gray-500"
                                        colSpan={7}
                                      >
                                        No Packages
                                      </td>
                                    </tr>
                                  ) : (
                                    currentPackages?.map((p, index) => (
                                      <tr
                                        key={index}
                                        className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                                      >
                                        <td className="whitespace-nowrap px-6 py-4 font-medium capitalize text-gray-900">
                                          {p.package_type?.name}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-gray-500">
                                          {p.weight}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-gray-500">
                                          {p.length}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-gray-500">
                                          {p.width}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-gray-500">
                                          {p.height}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-gray-500">
                                          {p.visible_labels ? "Yes" : "No"}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-right text-xs font-medium">
                                          {p.distributor_id ? (
                                            <Button
                                              label="Remove"
                                              type="button"
                                              showLoading={p.id === packageToRemove}
                                              onClick={() => {
                                                setPackageToRemove(p.id);
                                                removePackage(p.id);
                                              }}
                                              className="text-danger-600 hover:text-danger-900 space-x-2"
                                            />
                                          ) : (
                                            <div>
                                              <span className="inline-block rounded-md bg-gray-200 px-2 py-1 text-gray-500">
                                                Manufacturer Package
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
      </section>
      <PackagingModal isOpen={isModalOpen} options={package_types} closeModal={onModalClose} />
      <BoxModal isOpen={isBoxModalOpen} closeModal={onBoxModalClose} />
      <Toaster />
    </>
  );
}
