import { faSpinnerThird } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";

export default function ProductSelect({
  label,
  placeholder,
  brand,
  limit = 10,
  onProductSelect = () => {},
  excludeSkus = [],
  keepOpen = false,
}) {
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const inputRef = useRef(null);
  const [searching, setSearching] = useState(false);

  const searchProducts = (e) => {
    if (e.target.value.length === 0) {
      setQuery("");
      setSearchResults([]);
      return;
    }

    // return if query is the same
    if (query === e.target.value) {
      return;
    }

    setQuery(e.target.value);

    // return if the user is typing too fast
    if (searching) {
      return;
    }

    // return if query is less than 3 characters
    if (e.target.value.length < 3) {
      return;
    }

    setSearching(true);

    fetch(
      `/search?query=${e.target.value}${
        brand ? `&brand_id=${brand}` : ""
      }&exclude=${excludeSkus.join(",")}&limit=${limit}`
    )
      .then((response) => response.json())
      .then((data) => {
        setSearchResults(data.products);
        setSearching(false);
      });
  };

  const addProduct = (product) => {
    onProductSelect(product);
    if (!keepOpen) {
      setQuery("");
      setSearchResults([]);
    }
  };

  return (
    <div className="shopify_listing__product-select">
      <div className="form-group">
        <label>{label || "Add Products"}</label>

        <div className="relative flex items-center">
          <input
            ref={inputRef}
            type="text"
            value={query}
            placeholder={placeholder || "Search Products by SKU..."}
            onChange={searchProducts}
          />
          {query && (
            <div className="absolute right-2 flex items-center justify-end gap-2">
              {searching && (
                <div className="text-xs text-indigo-500">
                  <FontAwesomeIcon icon={faSpinnerThird} className="animate-spin" />
                </div>
              )}
              <button
                type="button"
                className="rounded bg-gray-100 px-2 py-1 text-xs transition hover:bg-gray-200"
                onClick={() => {
                  setQuery("");
                  setSearchResults([]);
                  inputRef.current.focus();
                }}
              >
                Clear
              </button>
            </div>
          )}
        </div>
      </div>
      {searchResults.length ? (
        <div className="mb-3 overflow-hidden border bg-white shadow-sm sm:rounded-md">
          <ul className="max-h-60 divide-y divide-gray-200 overflow-y-auto">
            {searchResults.map(function (product) {
              return (
                <li key={product.id}>
                  <a href="#" className="group block hover:bg-gray-50">
                    <div className="flex items-center px-4 py-4">
                      <div className="flex min-w-0 flex-1 items-center justify-between text-xs">
                        <span>
                          <strong>{product.sku}</strong> - {product.title}
                        </span>
                      </div>
                      <div className="ml-5 flex-shrink-0 opacity-0 transition group-hover:opacity-100">
                        <button
                          type="button"
                          data-product-id={product.id}
                          className="btn btn-sm btn-primary"
                          onClick={() => addProduct(product)}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
