import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

export function VehiclePropFilter({ filters, setFilter, title, prop }) {
  const [value, setValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setFilter(prop, value || undefined);
  }, 200);

  return (
    <input
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      type="text"
      placeholder={title}
      className="mb-5 w-24 max-w-fit text-sm"
    />
  );
}
