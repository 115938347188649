import {
  faArrowUpFromDottedLine,
  faBox,
  faGear,
  faSearch,
  faWarehouseAlt,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { AnimatedCounter } from "./AnimatedCounter";
import { SidebarButton } from "./SidebarButton";
import { createConsumer } from "@rails/actioncable";

export const Sidebar = ({ team, importListCount = null, merchantProductsCount = null }) => {
  const [productsCount, setProductsCount] = useState(merchantProductsCount);

  const getWebsocketServerUrl = () => {
    const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
    const hostParts = window.location.host.split(".");
    const mainAppDomain =
      hostParts.length > 2 ? hostParts.slice(-2).join(".") : window.location.host;
    const path = "/cable";
    return `${protocol}//${mainAppDomain}${path}`;
  };

  useEffect(() => {
    // Replace the URL with your WebSocket server URL
    const cable = createConsumer(getWebsocketServerUrl());

    const subscription = cable.subscriptions.create(
      { channel: "MerchantProductsChannel", team_id: team.id },
      {
        connected: () => {
          // Send a ping message every 15 seconds
          setInterval(() => {
            subscription.send({ type: "ping" });
          }, 15000);
        },
        disconnected: () => {
          console.log("Disconnected from MerchantProductsChannel");
        },
        received: (data) => {
          if (data.merchantProductsCount) {
            setProductsCount(data.merchantProductsCount);
          }
        },
        error: (error) => {
          console.log("Error connecting to server:", error);
        },
      }
    );

    return () => {
      console.log("Unsubscribing from MerchantProductsChannel");
      cable.subscriptions.remove(subscription);
    };
  }, []);

  return (
    <div className="sticky top-6 rounded-xl bg-white p-4 py-8 shadow-xl">
      <div className="flex flex-col space-y-2">
        <SidebarButton href="/">
          <FontAwesomeIcon
            icon={faSearch}
            className="w-4 text-center text-gray-400 transition group-hover:text-indigo-800"
          />
          <span>Source Products</span>
        </SidebarButton>
        <SidebarButton href="/import">
          <FontAwesomeIcon
            icon={faArrowUpFromDottedLine}
            className="w-4 text-center text-gray-400 transition group-hover:text-indigo-800"
          />
          <span>Import List</span>
          <AnimatedCounter counter={importListCount} />
        </SidebarButton>
        <SidebarButton href="/products">
          <FontAwesomeIcon
            icon={faBox}
            className="w-4 text-center text-gray-400 transition group-hover:text-indigo-800"
          />
          <span>My Products</span>
          <AnimatedCounter counter={productsCount} />
        </SidebarButton>
        <SidebarButton href="/distributors">
          <FontAwesomeIcon
            icon={faWarehouseAlt}
            className="w-4 text-center text-gray-400 transition group-hover:text-indigo-800"
          />
          <span>Distributors</span>
        </SidebarButton>
        <SidebarButton href="/settings">
          <FontAwesomeIcon
            icon={faGear}
            className="w-4 text-center text-gray-400 transition group-hover:text-indigo-800"
          />
          <span>Settings</span>
        </SidebarButton>
      </div>
    </div>
  );
};
