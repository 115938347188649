import React from "react";

import ExternalVehicleAttribute from "./ExternalVehicleAttribute";

function ExternalVehicleCard({
  link,
  partbotVehicle,
  handleOverride,
  handleLink,
  handleUnlink,
  loading,
}) {

  const handleUseExternalValue = ({ attribute, use }) => {
    console.log({ attribute, use });
    handleOverride({
      external_vehicle_link_id: link.id,
      attribute_name: attribute,
      use_attribute: use,
    });
  };

  const handleClickLink = (e, external_vehicle_link_id) => {
    e.preventDefault();
    handleLink(external_vehicle_link_id);
  };

  const handleClickUnlink = (e, external_vehicle_link_id) => {
    e.preventDefault();
    handleUnlink(external_vehicle_link_id);
  };

  const attributes = [
    "series",
    "variant",
    "body",
    "year_from",
    "month_from",
    "year_to",
    "month_to",
    "engine",
    "engine_capacity",
    "fuel_type",
    "drive_type",
    "cylinders",
    "kw_power"
  ]

  return (
    <div className="col-6">
      <div
        className={`card ${
          link.approved
            ? "border-success"
            : link.approved === null
            ? ""
            : "border-danger"
        }`}
      >
        <div className="card-body">
          <h3>
            {link.vehicle.make} {link.vehicle.model}{" "}
            <span className="float-right">
              {" "}
              {link.source} |{" "}
              <span className="text-success"> {link.match_score * 100}%</span>{" "}
              Match
            </span>
          </h3>
          <div className="card-text">
            {
              attributes.map((attribute, index) => {
                return (
                  <ExternalVehicleAttribute
                    key={index}
                    attributeTitle={attribute}
                    externalValue={link.vehicle[attribute]}
                    partbotValue={partbotVehicle[attribute]}
                    useExternalValue={link[`use_${attribute}`]}
                    handleUseExternalValue={handleUseExternalValue}
                    isApproved={link.approved}
                  />
                )
              })
            }

            <div className="row py-1 border-bottom">
              <div className="col-5 text-muted">
                <strong>Vehicle ID</strong>
              </div>
              <div className="col-7">{link.vehicle.external_id}</div>
            </div>

            <div className="row py-1 border-bottom">
              <div className="col-5 text-muted">
                <strong>Model/Series ID</strong>
              </div>
              <div className="col-7">{link.vehicle.external_group_id}</div>
            </div>
          </div>
        </div>

        <div
          className={`card-footer ${
            link.approved ? "bg-success" : "bg-secondary"
          }`}
        >
          <div className="row align-items-center h-100">
            <div className="col-4 ">
              <span>
                {link.approved ? (
                  <span className="badge badge-lg badge-success text-white">
                    Linked
                  </span>
                ) : link.approved === false ? (
                  <span className="badge  badge-lg  badge-danger text-danger">
                    Declined
                  </span>
                ) : (
                  <span className="badge  badge-lg  badge-primary text-primary">
                    Unconfirmed
                  </span>
                )}
              </span>
            </div>
            <div className="col-8 text-right">
              <div className="btn-group">
                <button
                  type="submit"
                  onClick={(e) => handleClickUnlink(e, link.id)}
                  className="btn btn-danger"
                  disabled={link.approved === false}
                >
                  Decline
                </button>
                <button
                  type="button"
                  onClick={(e) => handleClickLink(e, link.id)}
                  className="btn btn-success"
                  disabled={link.approved}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExternalVehicleCard;
