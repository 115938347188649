import React, { useState } from "react";
import PackagingModal from "../Products/components/PackagingModal";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import AllocateToParcelModal from "./components/AllocateToParcelModal";
import RequestPickupModal from "./components/RequestPickupModal";
import {
  DeliveryCard,
  LineItemsTable,
  LineItemRow,
  ParcelCard,
  ParcelItemsTable,
  ParcelItemRow,
} from "./components/Fulfillment";
import { Checkbox, Form, Input, Select, FormModal, Button } from "../UI/components";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { InputWithAddon } from "../UI/components/Controls";

const courierOptions = [
  { value: "AusPost", label: "AusPost" },
  { value: "Followmont", label: "Followmont" },
  { value: "Sadleirs", label: "Sadleirs" },
  { value: "StarTrack", label: "StarTrack" },
  { value: "TNT", label: "TNT" },
  { value: "Toll", label: "Toll" },
];

export default function DistributorManualShipment({
  distributor,
  distributor_location,
  delivery,
  team,
  app_contexts,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data) => {
    if (data) {
      setIsLoading(true);
      try {
        const result = await axios({
          method: "post",
          url: `/deliveries/${delivery.id}/manual`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
          data: {
            carrier: data.carrier,
            tracking_number: data.tracking_number,
            tracking_url: data.tracking_url,
            booked_cost: data.cost,
            label_url: data.label_url,
          },
        });

        toast.success("Manual booking successful");

        window.location.href = `/label_requests`;
      } catch (e) {
        console.log(e);
        toast.error("Error converting shipment to Manual");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Header app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8 pt-5 pb-24">
            <h2 className="m-0 text-3xl font-extrabold tracking-tight text-gray-900">
              Manual Booking
            </h2>
            <div className="overflow-hidden rounded-lg bg-white shadow-xl">
              <div className="px-4 py-5 text-sm sm:px-6 sm:py-3">
                {/* <NewComponent /> */}
                <div className="pt-3 lg:col-span-5 lg:mt-0">
                  <dl className="grid grid-cols-4 gap-x-6 text-sm text-gray-700">
                    <div>
                      <dt className="text-sm font-medium text-gray-500">Order</dt>
                      <dd className="mt-1">
                        <span className="block">{delivery.fulfillment.distributor_order_id}</span>
                      </dd>
                      <dt className="mt-2 text-sm font-medium text-gray-500">Pickup Address</dt>
                      <dd className="mt-1">
                        <span className="block">
                          {delivery.fulfillment.distributor_location.pickup_address.name}
                        </span>
                        <span className="block">
                          {delivery.fulfillment.distributor_location.pickup_address.address1}
                        </span>
                        <span className="block">
                          {delivery.fulfillment.distributor_location.pickup_address.address2}
                        </span>
                        <span className="block">
                          {delivery.fulfillment.distributor_location.pickup_address.city}{" "}
                          {delivery.fulfillment.distributor_location.pickup_address.state_code}{" "}
                          {delivery.fulfillment.distributor_location.pickup_address.postcode}
                        </span>
                      </dd>
                    </div>
                    <div>
                      <dt className="text-sm font-medium text-gray-500">Store</dt>
                      <dd className="mt-1">
                        <span className="font-semibold">
                          {delivery.fulfillment.merchant_order.store.name}
                        </span>
                        <span className="mx-2">
                          {delivery.fulfillment.merchant_order.store_order_reference}
                        </span>
                      </dd>
                      <dt className="mt-2 text-sm font-medium text-gray-500">Shipping Address</dt>
                      <dd className="mt-1">
                        <span className="block">
                          {delivery.fulfillment.merchant_order.shipping_address.name}
                        </span>
                        <span className="block">
                          {delivery.fulfillment.merchant_order.shipping_address.address1}
                        </span>
                        <span className="block">
                          {delivery.fulfillment.merchant_order.shipping_address.address2}
                        </span>
                        <span className="block">
                          {delivery.fulfillment.merchant_order.shipping_address.city}{" "}
                          {delivery.fulfillment.merchant_order.shipping_address.state_code}{" "}
                          {delivery.fulfillment.merchant_order.shipping_address.postcode}
                        </span>
                      </dd>
                    </div>
                    <div>
                      <dt className="text-sm font-medium text-gray-500">Boxes</dt>
                      <dd className="mt-1">
                        <span className="block">{delivery.parcels.length}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="" key={delivery.id}>
                <div className="p-4">
                  {delivery.parcels.map((parcel) => (
                    <div className="divide-y divide-gray-200">
                      <ParcelCard
                        parcel={parcel}
                        key={parcel.id}
                        boxes={[]}
                        isLoading={false}
                        isLocked={true}
                        onRemove={() => {}}
                        onValueChange={() => {}}
                      >
                        <ParcelItemsTable>
                          {parcel.items.map((parcelItem, parcelItemIndex) => (
                            <ParcelItemRow
                              key={parcelItem.id}
                              parcelItem={parcelItem}
                              isEven={parcelItemIndex % 2}
                              isLoading={false}
                              isLocked={true}
                              onRemove={() => {}}
                            />
                          ))}
                        </ParcelItemsTable>
                      </ParcelCard>
                    </div>
                  ))}
                </div>
                <div className="px-8 pb-8">
                  <Form onSubmit={onSubmit}>
                    <div className="my-4">
                      {/* <p className="my-3 text-sm leading-5 text-gray-500">
                        Please provide the shipping information.
                        <ul className="list-inside list-disc tracking-tight">
                          <li className="">
                            You will need to arrange pickup/handover with the carrier.
                          </li>
                          <li className="">
                            Partbot will pass the tracking details along to the customer.
                          </li>
                        </ul>
                      </p> */}
                      <div className="grid grid-cols-3 gap-3">
                        <div className="col-span-1">
                          {" "}
                          <Input
                            label="Carrier"
                            name="carrier"
                            type="text"
                            className="my-4"
                            required
                            list="carriers"
                          />
                          <datalist id="carriers">
                            {courierOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </datalist>
                        </div>
                        <div className="col-span-2">
                          <Input
                            label="Label URL"
                            name="label_url"
                            type="url"
                            className="my-4"
                            required
                          />
                        </div>
                        <div className="col-span-1">
                          <Input
                            label="Tracking Number"
                            name="tracking_number"
                            type="text"
                            className="my-4"
                            required
                          />
                        </div>

                        <div className="col-span-2">
                          <Input
                            label="Tracking URL"
                            name="tracking_url"
                            type="url"
                            className="my-4"
                            required
                          />
                          <p className="text-right text-xs leading-5 text-gray-500">
                            eg. https://auspost.com.au/mypost/track/#/details/1234567890
                          </p>
                        </div>
                        <div className="col-span-1">
                          <InputWithAddon
                            label="Cost"
                            name="cost"
                            type="number"
                            min="1.00"
                            step="0.01"
                            className="my-4"
                            required
                            addon="$"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="my-4">
                      <p className="my-3 text-sm leading-5 text-gray-500">
                        The shipment will be marked as "Shipped" and will no longer be editable.
                      </p>
                    </div> */}
                    <div className="m-0 mt-4 flex justify-end">
                      <div className="btn-group">
                        <Button
                          label="Take Over"
                          type="submit"
                          className="btn"
                          showLoading={isLoading}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
