import React, { Fragment, useEffect, useState, useCallback, useMemo } from "react";
import PartsModal from "./PartsModal";

import axios from "axios";
import ReactOnRails from "react-on-rails";
import { identity } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHyphen } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";

export default function AssembliesList({ product, package_types }) {
  return (
    <>
      {product.parent_products?.length > 0 && (
        <div className="flex flex-col">
          <div className="w-full">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200 border-b border-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      SKU
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Title
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Published
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {product.parent_products.map((p, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                      <td className="whitespace-nowrap px-6 py-4 text-xs font-medium">
                        <a href={p.url} className="text-indigo-600 hover:text-indigo-900">
                          {p.sku}
                        </a>
                      </td>
                      <td className="whitespace-normal px-6 py-4 text-xs text-gray-500">
                        {p.title}
                      </td>
                      <td className="whitespace-normal px-6 py-4 text-xs text-gray-500">
                        {p.published === true ? (
                          <FontAwesomeIcon icon={faCheck} className="text-success-500" />
                        ) : (
                          <FontAwesomeIcon icon={faHyphen} className="text-gray-300" />
                        )}
                      </td>
                      <td className="whitespace-normal px-6 py-4 text-right text-xs font-medium"></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
