import React from "react";
import useMerchantProductLabels from "../../hooks/useMerchantProductLabels";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkSimple,
  faStore,
  faArrowsRotate,
  faLinkSimpleSlash,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import clsx from "clsx";

const statusIcon = (status) => {
  if (status === "Linked") {
    return faLinkSimple;
  } else if (status === "Synced") {
    return faArrowsRotate;
  }
  return faLinkSimpleSlash;
};

export const ListItem = ({ product, currentProductLoading, onViewMore, isExactMatch }) => {
  const { storeTitle, statusTextClass, statusBgClass } = useMerchantProductLabels(product);

  return (
    <div
      className={`group relative cursor-pointer space-y-3 rounded-lg border-4 bg-white p-2 shadow transition hover:shadow-md hover:shadow-indigo-900/20 group-hover:z-20 ${
        isExactMatch ? "border-indigo-500" : "border-white"
      }`}
      onClick={() => onViewMore(product)}
    >
      <div className="z-10 flex items-center justify-between">
        <h5 className="pr-12 text-xs font-medium text-gray-500">{product.title}</h5>

        <div className="flex items-center space-x-1 text-[10px]">
          <div
            className={`rounded ${statusBgClass} space-x-1 px-2 py-1 ${statusTextClass} whitespace-nowrap capitalize`}
            title={storeTitle}
          >
            <FontAwesomeIcon icon={statusIcon(product.status)} />
            <span>{product.status}</span>
          </div>

          <div
            className={clsx(
              "space-x-1 rounded px-2 py-1 text-[10px]",
              product.published ? "bg-emerald-50 text-emerald-700" : "bg-stone-50 text-stone-500"
            )}
          >
            {product.published ? "Published" : "Unpublished"}
          </div>

          <div
            className="flex items-center space-x-1 whitespace-nowrap rounded bg-indigo-50 px-2 py-1 text-indigo-500"
            title={storeTitle}
          >
            <FontAwesomeIcon icon={faStore} />
            <span>{product?.store?.name}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
