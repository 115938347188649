import { faExternalLink } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { faEngineWarning } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";
import { faCheck, faArrowRightLong, faStore } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@headlessui/react";
import * as dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Header } from "./components/Header";
import { Button } from "../UI/components/Button";
import { Dialog, Transition } from "@headlessui/react";
import TecDocInsideBadge from "../../packs/images/tecdoc-inside.png";
import clsx from "clsx";

import { faShopify, faWordpress } from "@awesome.me/kit-989a8e6dbe/icons/classic/brands";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ANNUAL = "ANNUAL";
const EVERY_30_DAYS = "EVERY_30_DAYS";

export default function MarketplaceBilling({
  products,
  billing_url,
  team,
  stores,
  listings,
  has_catalogued_products,
  stripe_subscription,
  plate_search_subscription,
  plate_search_usage,
  fulfilments,
  revenue_share_charges,
  app_contexts,
  plate_search_prices,
  integrations,
  shopify_app_store_url,
}) {
  if (plate_search_prices === null) {
    return;
  }

  const [changeShopifyPlanModalOpen, setChangeShopifyPlanModalOpen] = useState(false);
  const [cancelPlanModalOpen, setCancelPlanModalOpen] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);

  const lsShowShopifyWarning = localStorage.getItem("showShopifyWarning");

  const [showShopifyWarning, setShowShopifyWarning] = useState(
    (stores === 0 && lsShowShopifyWarning === null) || lsShowShopifyWarning === "true"
  );

  // check if query params contains shop and find the integration with the same shop value
  const urlParams = new URLSearchParams(window.location.search);
  const shop = urlParams.get("shop");
  const queryIntegration = integrations.find((i) => i.shop === shop);

  const [selectedIntegration, setSelectedIntegration] = useState(
    queryIntegration || integrations[0]
  );

  const transform_plate_search_usage =
    plate_search_subscription && plate_search_subscription.plan.transform_usage !== null;

  const plate_search_plan_each = plate_search_prices.data.find(
    (plan) => plan.metadata.unit_description === "each"
  );

  const plate_search_plan_pack = plate_search_prices.data.find(
    (plan) => plan.metadata.unit_description === "pack"
  );

  const [annualBilling, setAnnualBilling] = useState(
    selectedIntegration?.billing_cycle !== EVERY_30_DAYS || true
  );

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Basic ${team.api_key}`);
  myHeaders.append("Content-Type", "application/json");

  const comparePlans = (currentPlan, plan) => {
    // i hate this
    if (!currentPlan || !plan) {
      return 0;
    }
    const plans = products.map((product) => product.name);

    // check if the plan is in the list of products, and if the plan names are the same return 0
    // if the plan name is not the same, check the prices and return 1 if the new plan is more expensive and -1 if it's cheaper

    if (plans.includes(currentPlan.name) && plans.includes(plan.name)) {
      return currentPlan.name === plan.name ? 0 : plan.price < currentPlan.price ? 1 : -1;
    } else {
      // raise error
      throw new Error("Plan not found");
    }
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "//unpkg.com/@cartbot/plate-search@latest/build/dist/js/plate-search.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const subscribeToPlan = (plan, price, usd_price) => {
    setSelectedPlan(plan);
    setLoadingPlan(true);
    async function getSubscriptionUrl(plan, price, usd_price) {
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
          plan: price.id,
          integration_id: selectedIntegration?.id,
          price: usd_price
            ? usd_price
            : annualBilling
            ? price.unit_amount / 100
            : price.unit_amount / 100,
          name: plan.name,
          annual_billing: annualBilling,
        }),
      };

      const api_url = location.protocol + "//" + location.host.replace(/^[^.]*/, "api");

      const response = await fetch(`${api_url}/v1/billing/get-plan-url`, requestOptions);

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        toast.error("An error occured. Please contact support.");
        throw new Error(message);
      }
      const data = await response.json();
      return data;
    }

    getSubscriptionUrl(plan, price, usd_price).then((data) => {
      location.href = data.url;
    });
  };

  const addPlateSearch = (priceId) => {
    // create ImportListing
    async function addToSubscription(price) {
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
          price: price,
        }),
      };

      const api_url = location.protocol + "//" + location.host.replace(/^[^.]*/, "api");

      const response = await fetch(`${api_url}/v1/billing/add-plate-search`, requestOptions);

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();

      return data;
    }

    addToSubscription(priceId).then((data) => {
      if (data.error) {
        toast.error(data.error);
      }

      if (data.url) {
        location.href = data.url;
      } else {
        location.reload();
      }
    });
  };

  const cancelShopifySubscription = async () => {
    setCancellingSubscription(true);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({
        integration_id: selectedIntegration.id,
      }),
    };

    const api_url = location.protocol + "//" + location.host.replace(/^[^.]*/, "api");

    const response = await fetch(`${api_url}/v1/billing/cancel-shopify-plan`, requestOptions);

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      toast.error("An error occured. Please contact support.");
      setCancellingSubscription(false);
      setCancelPlanModalOpen(false);
      throw new Error(message);
    } else {
      const data = await response.json();

      if (data.integration) {
        setSelectedIntegration(data.integration);
        toast.success("Subscription cancelled.");
        location.reload();
      }
    }
  };

  const getIntegrationIcon = (integration) => {
    if (integration.type === "ShopifyIntegration") {
      return faShopify;
    } else if (integration.type === "WoocommerceIntegration") {
      return faWordpress;
    } else {
      return faStore;
    }
  };

  const hasSubscriptionPlan = () => {
    if (selectedIntegration && selectedIntegration.type === "ShopifyIntegration") {
      return (
        (selectedIntegration.plan_id !== null && selectedIntegration.plan_status === "active") ||
        (!selectedIntegration.plan_id &&
          team.plan &&
          team.stripe_customer_id &&
          team.subscription_active)
      );
    }

    // Stripe Users
    return team.plan && team.stripe_customer_id && team.subscription_active;
  };

  return (
    <>
      <Toaster />
      <Header section="Billing" app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="mx-auto h-auto w-full max-w-7xl">
          <div className="flex w-full flex-col space-y-4 p-12">
            <div className="flex items-center justify-between border-b pb-5">
              <h2 className="text-2xl font-bold">Billing</h2>
              {integrations.length > 1 && (
                <div className="flex items-center space-x-3">
                  <FontAwesomeIcon
                    icon={getIntegrationIcon(selectedIntegration)}
                    className="h-6 w-6"
                  />
                  <select
                    defaultValue={selectedIntegration.shop}
                    onChange={(e) => {
                      setSelectedIntegration(
                        integrations.find((integration) => integration.shop === e.target.value)
                      );
                    }}
                  >
                    {integrations.map((integration) => (
                      <option key={integration.shop} value={integration.shop}>
                        {integration.shop}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            {/* Show Plan Selection */}
            {!hasSubscriptionPlan() && (
              <>
                {/* Show Subscription Cancelled Notice */}
                {selectedIntegration && selectedIntegration.plan_status === "cancelled" && (
                  <div className="bg-danger-100 text-medium text-danger-600 mb-8 mt-3 flex w-full items-center justify-center space-x-3 rounded-lg px-3 py-3 text-center text-sm">
                    <FontAwesomeIcon icon={faEngineWarning} className="h-8 w-8" />
                    <span>
                      <strong>Subscription Cancelled:</strong> Your store will be disconnected and
                      all products removed unless your resubscribe before{" "}
                      <span className="underline">
                        {dayjs(selectedIntegration.current_period_end).format("DD MMMM, YYYY")}
                      </span>
                      .
                    </span>
                  </div>
                )}
                {products && products.length > 0 && (
                  <>
                    <div className="space-y-5 pb-12">
                      <div className="prose text-gray-500">
                        <p>
                          Get started by selecting a subscription plan.{" "}
                          <a
                            className="link inline-flex items-center space-x-2 no-underline"
                            href="https://use.partbot.io/pricing"
                            target="_blank"
                          >
                            <span>View Platform Pricing</span>{" "}
                            <FontAwesomeIcon className="text-sm" icon={faExternalLink} />
                          </a>
                        </p>
                      </div>
                      <Switch.Group as="div" className="flex items-center">
                        <Switch
                          checked={annualBilling}
                          onChange={setAnnualBilling}
                          className={classNames(
                            annualBilling ? "bg-emerald-600" : "bg-gray-200",
                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              annualBilling ? "translate-x-5" : "translate-x-0",
                              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            )}
                          />
                        </Switch>
                        <Switch.Label as="span" className="ml-3 flex items-center space-x-2">
                          <span className="text-base font-medium text-gray-900">
                            {annualBilling ? "Annual" : "Monthly"} billing
                          </span>
                          <span className="ml-2 text-xs text-gray-500">
                            AUD Prices include GST - Shopify plans will be billed in USD
                          </span>
                        </Switch.Label>
                      </Switch.Group>
                    </div>
                    <div className="mb-12 grid grid-cols-3 gap-8 pb-8">
                      {products.map((plan) => {
                        const price = plan.prices.data.find((p) =>
                          annualBilling
                            ? p.recurring.interval === "year"
                            : p.recurring.interval === "month"
                        );
                        return (
                          <div
                            key={plan.id}
                            className={clsx(
                              "relative flex min-h-[300px] w-full flex-col justify-between rounded bg-white p-6 shadow",
                              plan.metadata.pim_access === "false" &&
                                has_catalogued_products &&
                                "pointer-events-none opacity-50"
                            )}
                            disabled={
                              plan.metadata.pim_access === "false" && has_catalogued_products
                            }
                          >
                            {plan.metadata.featured && (
                              <div className="absolute -top-[44px] left-0 flex h-[44px] w-full items-center justify-center rounded-t-lg bg-emerald-100 px-2 py-1 text-center text-sm font-bold text-emerald-500">
                                Most Popular
                              </div>
                            )}
                            <div>
                              <div className="flex items-start justify-between">
                                <div>
                                  <h3 className="heading-sm !text-emerald-600">{plan.name}</h3>

                                  <div className="heading mt-2 flex items-center text-3xl">
                                    {new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "AUD",
                                      currencyDisplay: "narrowSymbol",
                                      minimumFractionDigits: 0,
                                    }).format(price.unit_amount / 100)}
                                    <span className="ml-2 text-sm font-bold leading-3 text-emerald-500">
                                      per
                                      <br />
                                      {annualBilling ? "year" : "month"}
                                    </span>
                                  </div>
                                  <div className="mt-3 inline-flex rounded-md bg-emerald-50 px-2 py-1 text-[10px] font-bold text-emerald-500">
                                    {new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                      currencyDisplay: "code",
                                      minimumFractionDigits: 0,
                                    }).format(
                                      annualBilling
                                        ? plan.metadata.yearly_usd
                                        : plan.metadata.monthly_usd
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="prose prose-sm mt-6 text-gray-500">
                                {plan.description}
                              </div>
                              <ul className="my-3 divide-y divide-gray-100">
                                {plan.metadata.key_features &&
                                  plan.metadata.key_features.split("|").map((feature) => (
                                    <li
                                      key={feature}
                                      className="flex items-center space-x-2 py-2 text-xs text-emerald-600"
                                    >
                                      <FontAwesomeIcon icon={faCheck} />
                                      <span>{feature}</span>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                            <div className="mt-8">
                              <Button
                                disabled={
                                  plan.metadata.pim_access === "false" && has_catalogued_products
                                }
                                label="Select Plan"
                                onClick={(e) =>
                                  subscribeToPlan(
                                    plan,
                                    price,
                                    annualBilling
                                      ? plan.metadata.yearly_usd
                                      : plan.metadata.monthly_usd
                                  )
                                }
                                className={`${
                                  plan.metadata.featured
                                    ? "btn !bg-emerald-600 hover:!bg-emerald-700"
                                    : "btn btn-light-emerald !bg-emerald-200 !text-emerald-700"
                                } btn-lg !m-0 w-full`}
                                showLoading={loadingPlan && selectedPlan.id === plan.id}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </>
            )}
            {/* Show Shopify Subscription Details */}
            <div className="grid grid-cols-3 gap-8">
              <div>
                {hasSubscriptionPlan() &&
                  selectedIntegration?.plan_id &&
                  selectedIntegration?.plan_status !== "cancelled" && (
                    <ShopifyPlanDetails
                      integration={selectedIntegration}
                      onChangeSelectedPlan={() => setChangeShopifyPlanModalOpen(true)}
                    />
                  )}

                {hasSubscriptionPlan() &&
                  !selectedIntegration?.plan_id &&
                  billing_url &&
                  stripe_subscription && (
                    <StripePlanDetails
                      subscription={stripe_subscription}
                      billing_url={billing_url}
                    />
                  )}

                {!hasSubscriptionPlan() && (
                  <>
                    <div className="border-warning-500 bg-warning-200/10 text-warning-500 rounded-lg border border-dashed p-6 text-sm">
                      {team.subscription_active
                        ? "You are currently on a trail plan with limited usage. Select a subscription plan to continue using Partbot."
                        : "You don't have an active subscription. Get started by selecting a subscription plan above."}
                    </div>
                  </>
                )}
              </div>
              <div className="col-span-2 space-y-4">
                <PlanUsageStats
                  stores={stores}
                  listings={listings}
                  fulfilments={fulfilments}
                  total_listings={team.listings_limit}
                />

                <RevenueShareCharges revenue_share_charges={revenue_share_charges} />

                {hasSubscriptionPlan() && (
                  <PlateSearchCharges
                    team={team}
                    plate_search_subscription={plate_search_subscription}
                    plate_search_usage={plate_search_usage}
                    plate_search_plan_each={plate_search_plan_each}
                    plate_search_plan_pack={plate_search_plan_pack}
                    transform_plate_search_usage={transform_plate_search_usage}
                    addPlateSearch={addPlateSearch}
                  />
                )}
              </div>
            </div>

            {/* Cancel Shopify App Subscription */}
            {hasSubscriptionPlan() &&
              selectedIntegration?.plan_id &&
              selectedIntegration?.plan_status !== "cancelled" && (
                <>
                  <div className="grid grid-cols-3 gap-12">
                    <div className="col-span-2 col-start-2">
                      <div className="space-y-5 rounded-lg bg-white p-8 shadow">
                        <h3 className="text-danger-600 text-xl font-bold">Danger Zone</h3>
                        <div className="bg-danger-50 border-danger-100 rounded-md p-6">
                          <p className="text-danger-600 mb-5 block text-xs">
                            Cancelling your active subscription will result in the removal of synced
                            product listings from your store and search plugins will stop working at
                            the end of your current billing period.
                          </p>
                          <Button
                            label="Cancel Subscription"
                            onClick={() => setCancelPlanModalOpen(true)}
                            className="btn btn-sm btn-danger !m-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Transition.Root show={cancelPlanModalOpen} as={Fragment}>
                    <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={() => {
                        setCancelPlanModalOpen(false);
                      }}
                    >
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                      </Transition.Child>

                      <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
                          {/* This element is to trick the browser into centering the modal contents. */}
                          <span
                            className="hidden sm:inline-block sm:h-screen sm:align-middle"
                            aria-hidden="true"
                          >
                            &#8203;
                          </span>
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          >
                            <Dialog.Panel className="relative my-8 inline-block w-full max-w-md transform overflow-hidden rounded-lg bg-white p-8 text-left align-bottom shadow-xl transition-all sm:align-middle">
                              <div>
                                <h4 className="text-xl font-bold">Cancel Subscription</h4>
                                <div className="mt-3 text-sm text-gray-500">
                                  Are you certain you want to cancel your subscription? Product
                                  listings will be removed from your store and search plugins will
                                  stop working at the end of your billing period.
                                </div>
                                <div className="mt-8 flex items-center justify-end space-x-2">
                                  <button
                                    className="btn btn-sm btn-neutral"
                                    onClick={() => setCancelPlanModalOpen(false)}
                                  >
                                    Close
                                  </button>
                                  <Button
                                    onClick={cancelShopifySubscription}
                                    label="Cancel Subscription"
                                    className="btn btn-sm btn-danger"
                                    showLoading={cancellingSubscription}
                                  />
                                </div>
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition.Root>
                </>
              )}
          </div>
        </div>
      </section>

      {/* Change Shopify Plan Modal */}
      {hasSubscriptionPlan() &&
        selectedIntegration?.plan_id &&
        selectedIntegration?.type === "ShopifyIntegration" && (
          <Transition.Root show={changeShopifyPlanModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => {
                setChangeShopifyPlanModalOpen(false);
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                    className="hidden sm:inline-block sm:h-screen sm:align-middle"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative my-8 inline-block w-full max-w-7xl transform overflow-hidden rounded-lg bg-white p-8 text-left align-bottom shadow-xl transition-all sm:align-middle">
                      <div>
                        <div className="space-y-5">
                          <div className="flex w-full items-center justify-between">
                            <h4 className="text-xl font-bold">Change Subscription</h4>
                            <a
                              className="inline-flex items-center space-x-2 text-xs text-emerald-500 no-underline hover:text-emerald-600"
                              href="https://use.partbot.io/pricing"
                              target="_blank"
                            >
                              <span>View All Plan Features</span>{" "}
                              <FontAwesomeIcon className="text-sm" icon={faExternalLink} />
                            </a>
                          </div>
                          <Switch.Group as="div" className="flex items-center">
                            <Switch
                              checked={annualBilling}
                              onChange={setAnnualBilling}
                              className={classNames(
                                annualBilling ? "bg-emerald-600" : "bg-gray-200",
                                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                              )}
                            >
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  annualBilling ? "translate-x-5" : "translate-x-0",
                                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                )}
                              />
                            </Switch>
                            <Switch.Label as="span" className="ml-3 flex items-center space-x-2">
                              <span className="text-base font-medium text-gray-900">
                                {annualBilling ? "Annual" : "Monthly"} billing
                              </span>
                              <span className="ml-2 text-xs text-gray-500">
                                Prices shown in AUD and include GST - Shopify plans will be billed
                                in USD
                              </span>
                            </Switch.Label>
                          </Switch.Group>
                        </div>
                        <div className="grid grid-cols-3 gap-8 pt-8">
                          {products.map((plan) => {
                            const price = plan.prices.data.find((p) =>
                              annualBilling
                                ? p.recurring.interval === "year"
                                : p.recurring.interval === "month"
                            );

                            const matchingProduct = products.find(
                              (p) => p.name === selectedIntegration.plan_name.replace(" plan", "")
                            );

                            const planComparison = comparePlans(
                              {
                                name: selectedIntegration.plan_name.replace(" plan", ""),
                                price: parseFloat(
                                  annualBilling && selectedIntegration.billing_cycle === ANNUAL
                                    ? matchingProduct?.metadata.yearly_usd ||
                                        selectedIntegration.plan_price
                                    : matchingProduct?.metadata.monthly_usd ||
                                        selectedIntegration.plan_price
                                ),
                              },
                              {
                                name: plan.name,
                                price: parseFloat(
                                  annualBilling
                                    ? plan.metadata.yearly_usd
                                    : plan.metadata.monthly_usd
                                ),
                              }
                            );

                            return (
                              <div
                                key={plan.id}
                                className={clsx(
                                  "relative flex min-h-[300px] w-full flex-col justify-between rounded-lg border border-emerald-200 bg-white p-6 shadow",
                                  plan.metadata.pim_access === "false" &&
                                    has_catalogued_products &&
                                    "pointer-events-none opacity-50"
                                )}
                                disabled={
                                  plan.metadata.pim_access === "false" && has_catalogued_products
                                }
                              >
                                <div>
                                  <div className="flex items-start justify-between">
                                    <div>
                                      <h3 className="heading-sm !text-emerald-600">{plan.name}</h3>

                                      <div className="heading mt-2 flex items-center text-3xl">
                                        <span className={team.catalogue && "line-through"}>
                                          {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "AUD",
                                            currencyDisplay: "narrowSymbol",
                                            minimumFractionDigits: 0,
                                          }).format(price.unit_amount / 100)}
                                        </span>
                                        <span className="ml-2 text-sm font-bold leading-3 text-emerald-500">
                                          per
                                          <br />
                                          {annualBilling ? "year" : "month"}
                                        </span>
                                      </div>
                                      <div className="mt-3 inline-flex rounded-md bg-emerald-50 px-2 py-1 text-[10px] font-bold text-emerald-500">
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                          currencyDisplay: "code",
                                          minimumFractionDigits: 0,
                                        }).format(
                                          annualBilling
                                            ? plan.metadata.yearly_usd
                                            : plan.metadata.monthly_usd
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <ul className="my-3 divide-y divide-gray-100">
                                    {plan.metadata.key_features.split("|").map((feature) => (
                                      <li
                                        key={feature}
                                        className="flex items-center space-x-2 py-2 text-xs text-emerald-500"
                                      >
                                        <FontAwesomeIcon icon={faCheck} />
                                        <span>{feature}</span>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <div className="mt-8">
                                  {selectedIntegration.plan_name.replace(" plan", "") ===
                                    plan.name &&
                                  ((annualBilling &&
                                    selectedIntegration.billing_cycle === ANNUAL) ||
                                    (!annualBilling &&
                                      selectedIntegration.billing_cycle === EVERY_30_DAYS)) ? (
                                    <Button
                                      label="Current Subscription"
                                      disabled={true}
                                      className="btn !btn-emerald !m-0 w-full"
                                    />
                                  ) : (
                                    <Button
                                      label={
                                        {
                                          "-1": "Upgrade Plan",
                                          0: `Change to ${
                                            annualBilling ? "Annual" : "Monthly"
                                          } Billing`,
                                          1: "Downgrade Plan",
                                        }[planComparison]
                                      }
                                      onClick={(e) =>
                                        subscribeToPlan(
                                          plan,
                                          price,
                                          annualBilling
                                            ? plan.metadata.yearly_usd
                                            : plan.metadata.monthly_usd
                                        )
                                      }
                                      showLoading={loadingPlan && selectedPlan.id === plan.id}
                                      className={classNames(`btn !btn-emerald !m-0 w-full`)}
                                      disabled={
                                        plan.metadata.pim_access === "false" &&
                                        has_catalogued_products
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )}
      <ShopifyWarningModal
        open={showShopifyWarning}
        onClose={() => {
          // store the warning in local storage so it doesn't show again
          localStorage.setItem("showShopifyWarning", false);
          setShowShopifyWarning(false);
        }}
        url={shopify_app_store_url}
      />
    </>
  );
}

function ShopifyPlanDetails({ integration, onChangeSelectedPlan }) {
  return (
    <>
      <div className="my-4 text-sm text-gray-500">
        <div className="space-y-2">
          <div>
            Subscribed to{" "}
            <span className="whitespace-nowrap rounded bg-emerald-100 px-2 py-1 font-bold text-emerald-600">
              {integration.plan_name}
            </span>{" "}
            <span className="font-medium text-emerald-600">
              (
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(integration.plan_price)}{" "}
              USD per {integration.billing_cycle === ANNUAL ? "year" : "month"}
              ).
            </span>
          </div>
          {integration.cancelled_on ? (
            <div>
              Your plan will be cancelled on{" "}
              <span className="font-medium text-gray-900">
                {integration.billing_cycle === ANNUAL
                  ? dayjs(integration.updated_at).add(1, "year").format("DD MMMM, YYYY")
                  : dayjs(integration.updated_at).add(30, "day").format("DD MMMM, YYYY")}
                .
              </span>
            </div>
          ) : (
            <div>
              Subscription renews on{" "}
              <span className="font-medium text-gray-900">
                {integration.billing_cycle === ANNUAL
                  ? dayjs(integration.billing_on).add(1, "year").format("DD MMMM, YYYY")
                  : dayjs(integration.billing_on).add(30, "day").format("DD MMMM, YYYY")}
              </span>
            </div>
          )}
        </div>
      </div>
      <div>
        <Button
          label="Change Subscription"
          onClick={onChangeSelectedPlan}
          className="btn btn-sm !btn-emerald"
        />
      </div>
    </>
  );
}

function StripePlanDetails({ subscription, billing_url }) {
  return (
    <>
      <div className="my-4 text-sm text-gray-500">
        <div className="space-y-2">
          <div>
            Subscribed to{" "}
            <span className="whitespace-nowrap rounded bg-emerald-100 px-2 py-1 font-bold text-emerald-600">
              {subscription.plan.product.name}
            </span>{" "}
            <span className="font-medium text-emerald-600">
              (
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "AUD",
              }).format(subscription.plan.amount / 100)}{" "}
              per {subscription.plan.interval}).
            </span>
          </div>
          {subscription.cancel_at_period_end ? (
            <div>
              Subscription will be cancelled on{" "}
              <span className="font-medium text-gray-900">
                {dayjs.unix(subscription.current_period_end).format("DD MMMM, YYYY")}.
              </span>
            </div>
          ) : (
            <div>
              Subscription renews on{" "}
              <span className="font-medium text-gray-900">
                {dayjs.unix(subscription.current_period_end).format("DD MMMM, YYYY")}.
              </span>
            </div>
          )}
        </div>
      </div>
      <a className="btn btn-sm !btn-emerald" href={billing_url}>
        <span>Manage your subscription</span>
        <FontAwesomeIcon icon={faArrowRightLong} />
      </a>
    </>
  );
}

function PlanUsageStats({ stores, listings, fulfilments, total_listings }) {
  return (
    <div className="space-y-5 rounded-lg bg-white p-8 shadow">
      <h4 className="text-xl font-bold">Plan Usage</h4>
      <div className="grid grid-cols-3 gap-3">
        <div className="flex flex-col items-center space-y-2 rounded-lg border border-emerald-200 p-4">
          <h5 className="heading-sm !text-emerald-600">Stores</h5>
          <span className="text-xl font-bold">
            <span className="text-emerald-500">{stores | 0}</span>
          </span>
        </div>
        <div className="flex flex-col items-center space-y-2 rounded-lg border border-emerald-200 p-4">
          <h5 className="heading-sm !text-emerald-600">Listings</h5>
          <span className="text-base font-bold">
            <span className="text-emerald-500">{listings | 0}</span> /{" "}
            {new Intl.NumberFormat("en-US").format(total_listings)}
          </span>
        </div>
        <div className="flex flex-col items-center space-y-2 rounded-lg border border-emerald-200 p-4">
          <h5 className="heading-sm !text-emerald-600">Fulfilments</h5>
          <span className="text-xl font-bold">{fulfilments}</span>
        </div>
      </div>
    </div>
  );
}

function RevenueShareCharges({ revenue_share_charges }) {
  return (
    <div className="space-y-5 rounded-lg bg-white p-8 shadow">
      <h4 className="text-xl font-bold">Revenue Share Charges</h4>
      <div className="grid grid-cols-3 gap-3">
        <div className="flex flex-col items-center space-y-2 rounded-lg border border-emerald-200 p-4">
          <h5 className="heading-sm text-center !text-emerald-600">Order Units</h5>
          <span className="text-base font-bold">
            <span className="text-emerald-500">{revenue_share_charges.order_units | 0}</span> /{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(revenue_share_charges.order_units_total_after_discount)}
          </span>
          <span className="text-center text-xs text-stone-500">
            {revenue_share_charges.order_units_rate}% of unit sale price
            {revenue_share_charges.order_units_total_after_discount >
              revenue_share_charges.order_units_total && (
              <span className="line-through">
                <br />
                {`${new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(revenue_share_charges.order_units_total)} (discounted)`}
              </span>
            )}
          </span>
        </div>
        <div className="flex flex-col items-center space-y-2 rounded-lg border border-emerald-200 p-4">
          <h5 className="heading-sm text-center !text-emerald-600">Fulfilment Units</h5>
          <span className="text-base font-bold">
            <span className="text-emerald-500">{revenue_share_charges.fulfilment_units | 0}</span> /{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(revenue_share_charges.fulfillment_units_total)}
          </span>

          <span className="text-xs text-stone-500">2% capped at $20 per item</span>
        </div>
        <div className="flex flex-col items-center space-y-2 rounded-lg border border-emerald-200 p-4">
          <h5 className="heading-sm text-center !text-emerald-600">Total</h5>
          <span className="text-base font-bold">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(revenue_share_charges.total_capped)}
          </span>
          {revenue_share_charges.total_capped > revenue_share_charges.total && (
            <span className="text-xs text-stone-500 line-through">
              {" "}
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(revenue_share_charges.total)}{" "}
              (discounted)
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

function PlateSearchCharges({
  team,
  plate_search_subscription,
  plate_search_usage,
  transform_plate_search_usage,
  plate_search_plan_each,
  plate_search_plan_pack,
  addPlateSearch,
}) {
  const getPlateSearchPackSavings = () => {
    // a pack is 500 units, each is 1 unit, so 500 eachs = 1 pack
    // calculate the savings of buying a pack vs 500 eachs
    const eachPrice = plate_search_plan_each.unit_amount / 100;
    const packPrice = plate_search_plan_pack.unit_amount / 100;
    const packSavings = 500 * eachPrice - packPrice;
    return packSavings;
  };

  return (
    <div className="space-y-5 rounded-lg bg-white p-8 shadow">
      {team.plate_search_active && team.plate_search_plan ? (
        <>
          <h4 className="text-xl font-bold">
            PL8SRCH<sup>&reg;</sup>
          </h4>
          {plate_search_subscription && (
            <>
              <p className="text-xs text-gray-500">
                Current billing period:{" "}
                {dayjs.unix(plate_search_subscription.current_period_start).format("DD MMMM, YYYY")}{" "}
                to{" "}
                {dayjs.unix(plate_search_subscription.current_period_end).format("DD MMMM, YYYY")}
              </p>
              <div className="grid grid-cols-3 gap-3">
                <div className="flex flex-col items-center space-y-2 rounded-lg border border-emerald-200 p-4">
                  <h5 className="heading-sm !text-emerald-600">Requests</h5>
                  <span className="text-xl font-bold">{plate_search_usage.total_usage}</span>
                </div>
                <div className="flex flex-col items-center space-y-2 rounded-lg border border-emerald-200 p-4">
                  <h5 className="heading-sm !text-emerald-600">Plan</h5>
                  <div>
                    <div className="text-center text-xl font-bold">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "AUD",
                      }).format(plate_search_subscription.plan.amount / 100)}{" "}
                    </div>
                    <div className="mt-1 text-center text-xs font-bold text-emerald-500">
                      Per{" "}
                      {transform_plate_search_usage
                        ? `${plate_search_subscription.plan.transform_usage.divide_by} requests`
                        : "request"}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center space-y-2 rounded-lg border border-emerald-200 p-4">
                  <h5 className="heading-sm !text-emerald-600">Total</h5>
                  <span className="text-xl font-bold">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "AUD",
                    }).format(
                      Math.ceil(
                        (transform_plate_search_usage
                          ? Math.max(
                              plate_search_usage.total_usage,
                              plate_search_subscription.plan.transform_usage.divide_by
                            )
                          : plate_search_usage.total_usage) /
                          (transform_plate_search_usage
                            ? Math.max(plate_search_subscription.plan.transform_usage.divide_by, 1)
                            : 1)
                      ) *
                        (plate_search_subscription.plan.amount / 100) // 50
                    )}
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="flex items-start justify-between space-x-6">
            <div>
              <h4 className="text-xl font-bold">
                PL8SRCH<sup>&reg;</sup>
              </h4>
              <p className="text-sm text-gray-500">
                Add an Australian Number Plate Search to your website.{" "}
                <a href="https://pl8srch.partbot.io" className="link" target="_blank">
                  Learn more.
                </a>
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center space-x-3 rounded-lg bg-emerald-500 p-2">
              <div className="whitespace-nowrap pl-2 text-center text-sm font-bold text-white">
                Try Me!
              </div>
              <plate-search
                data-api-key="pb_868d33e4423a20a949c9a229e45adf81"
                data-callback-url="/billing"
                data-theme-color="#4f46e5"
              ></plate-search>
            </div>
          </div>
          <div className="pt-6">
            <div className="grid grid-cols-2 gap-8">
              <div className="flex flex-col items-center justify-between space-y-4 rounded-lg border border-emerald-200 p-4">
                <div className="flex flex-col items-center space-y-3">
                  <div className="flex items-center space-x-2">
                    <div className="heading-sm !text-emerald-600">Per Search</div>
                  </div>
                  <div className="text-lg font-bold text-gray-900">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "AUD",
                    }).format(plate_search_plan_each.unit_amount / 100)}{" "}
                    {plate_search_plan_each.metadata.unit_description}
                  </div>
                  <div className="text-center text-xs text-emerald-500">
                    {plate_search_plan_each.metadata.description}
                  </div>
                </div>
                <button
                  onClick={(e) => addPlateSearch(plate_search_plan_each.id)}
                  className="btn btn-light-emerald mt-8 block w-full"
                >
                  Add Subscription
                </button>
              </div>
              <div className="flex flex-col items-center justify-between space-y-4 rounded-lg border border-emerald-200 p-4">
                <div className="flex flex-col items-center space-y-3">
                  <div className="flex items-center space-x-2">
                    <div className="heading-sm !text-emerald-600">Packs</div>
                    <span className="rounded bg-emerald-100 px-2 py-0.5 text-xs text-emerald-600">
                      Save{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "AUD",
                      }).format(getPlateSearchPackSavings())}
                    </span>
                  </div>
                  <div className="text-lg font-bold text-gray-900">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "AUD",
                    }).format(plate_search_plan_pack.unit_amount / 100)}{" "}
                    {plate_search_plan_pack.metadata.unit_description}
                  </div>
                  <div className="text-center text-xs text-emerald-500">
                    {plate_search_plan_pack.metadata.description}
                  </div>
                </div>
                <button
                  onClick={(e) => addPlateSearch(plate_search_plan_pack.id)}
                  className="btn btn-light-emerald mt-8 block w-full"
                >
                  Add Subscription
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function ShopifyWarningModal({ open, onClose, url }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-gray-900">
                Using Shopify?
              </Dialog.Title>
              <div className="prose prose-sm mt-2 text-sm text-gray-500">
                <p>
                  If you're planning to use Partbot with your Shopify store, you will need to
                  install the app first and set up your subscription plan in Shopify.
                </p>
              </div>

              <div className="btn-group mt-8">
                <Button
                  label="Install Shopify App"
                  className="btn btn-shopify"
                  icon={faShopify}
                  onClick={() => {
                    window.location.href = url;
                  }}
                />
                <Button
                  label="I'm not using Shopify"
                  onClick={onClose}
                  className="btn btn-neutral"
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
