import React from "react";
import { FulfillmentUnitTitle } from "./FulfillmentUnitTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpenFull,
  faBoxCheck,
  faBoxOpen,
  faWrench,
  faDownToLine,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { Button } from "../../../UI/components/Button";

const packagingMessges = {
  missing_weight: {
    type: "error",
    message: "Missing weight",
  },
  missing_dimensions: {
    type: "error",
    message: "Missing dimensions",
  },
  no_distributor_box: {
    type: "warning",
    message: "No suitable packaging found",
  },
  ships_separate: {
    type: "info",
    message: "Ships in own box. Not to be packaged with other items",
  },
};

export default function LineItemRow({ lineItem, unit, isEven, onResolve, onAllocate }) {
  return (
    <tr key={unit.id} className={isEven ? "bg-white" : "bg-gray-50"}>
      <td className="px-3 py-2 text-gray-500">
        <FulfillmentUnitTitle unit={unit} />
      </td>
      <td className="whitespace-nowrap px-3 py-2 text-gray-500">
        {lineItem.merchant_order_line_item.product.sku}
      </td>
      <td className="whitespace-nowrap px-3 py-2 text-gray-500">{unit.quantity}</td>
      <td className="whitespace-nowrap px-3 py-3 text-gray-500">
        <span className="inline-flex text-xs">
          {unit.packaging.status == "allocated" && (
            <span className="">
              <FontAwesomeIcon icon={faBoxCheck} className="mr-2 text-emerald-500" />
              Allocated
            </span>
          )}
          {unit.packaging.status == "partially_allocated" && (
            <span className="">
              <FontAwesomeIcon icon={faBoxOpenFull} className="mr-2 text-amber-500" />
              {unit.quantity_packed} / {unit.quantity} Allocated
            </span>
          )}
          {unit.packaging.status == "unallocated" && (
            <span className="">
              <FontAwesomeIcon icon={faBoxOpen} className="mr-2 text-rose-500" />
              Unallocated
            </span>
          )}
        </span>
      </td>
      <td className="py-3 text-gray-500">
        <div className="flex space-x-2 text-xs">
          <ul className="">
            {unit.packaging.message_codes.map((messageCode, messageCodeIndex) => {
              switch (packagingMessges[messageCode].type) {
                case "info":
                  return (
                    <li key={messageCodeIndex} className="text-blue-500">
                      <span>{packagingMessges[messageCode].message}</span>
                    </li>
                  );
                case "error":
                  return (
                    <li key={messageCodeIndex} className="text-rose-500">
                      <span>{packagingMessges[messageCode].message}</span>
                    </li>
                  );

                case "warning":
                  return (
                    <li key={messageCodeIndex} className="text-amber-500">
                      <span>{packagingMessges[messageCode].message}</span>
                    </li>
                  );
              }
            })}
          </ul>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-gray-500">
        {unit.packaging.message_codes.length > 0 &&
          (unit.packaging.message_codes.includes("missing_dimensions") ||
            unit.packaging.message_codes.includes("missing_weight")) && (
            <div className="flex items-center justify-between space-x-2">
              <Button
                label="Resolve"
                className="btn btn-sm"
                icon={faWrench}
                onClick={() => {
                  onResolve(unit.id);
                }}
              />
            </div>
          )}
        {(unit.packaging.status === "partially_allocated" ||
          unit.packaging.status === "unallocated") &&
          !unit.packaging.message_codes.includes("missing_dimensions") &&
          !unit.packaging.message_codes.includes("missing_weight") && (
            <div className="flex items-center justify-between space-x-2">
              <Button
                label={"Allocate"}
                icon={faDownToLine}
                className="btn btn-sm"
                onClick={() => {
                  onAllocate(unit);
                }}
              />
            </div>
          )}
      </td>
    </tr>
  );
}
