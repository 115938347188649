import { faPlus } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef } from "react";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import LocationEditor from "./components/LocationEditor";
import { APIProvider } from "@vis.gl/react-google-maps";

export default function DistributorLocations({ distributor, locations, team, app_contexts }) {
  const [distributorLocations, setDistributorLocations] = useState(locations);

  console.log(process.env.GOOGLE_API_KEY);

  const handleAddLocation = async () => {
    const location = {
      distributor_id: distributor.id,
      name: "",
      location: null,
      contact_name: team?.name,
      contact_phone: team?.phone,
      contact_email: team?.email,
    };

    // append new location to the list
    setDistributorLocations([...distributorLocations, location]);
  };

  return (
    <>
      <Header app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8 pb-24 pt-5">
            <div className="flex w-full items-center justify-between">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Locations</h2>
              <div title="bulk actions">
                <button className="btn" onClick={() => handleAddLocation()}>
                  <span>Add Location</span>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>

            <div className="space-y-5 pb-5">
              <APIProvider apiKey={process.env.GOOGLE_API_KEY}>
                {distributorLocations &&
                  distributorLocations.map((location) => {
                    console.log("location", location);
                    return (
                      <LocationEditor
                        key={location.id || location.name}
                        distributor={distributor}
                        location={location}
                      />
                    );
                  })}
              </APIProvider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
