import React from "react";

export const ImportListSkeleton = ({ itemsCount }) => {
  const items = Array.from(Array(itemsCount), (_, i) => i + 1);

  return (
    <>
      {items.map((index) => (
        <div key={index} className="rounded-lg bg-white p-6 shadow-md" style={{ height: "460px" }}>
          <div className="flex animate-pulse space-x-4">
            <div className="h-10 w-10 rounded-full bg-gray-200"></div>
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 rounded bg-gray-200"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2 h-2 rounded bg-gray-200"></div>
                  <div className="col-span-1 h-2 rounded bg-gray-200"></div>
                </div>
              </div>
              <div className="h-2 rounded bg-gray-200"></div>
              <div className="h-2 rounded bg-gray-200"></div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
