import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faTrashCan,
  faMagnifyingGlassPlus,
  faFilePdf,
  faTvRetro,
  faCube,
  faChartLine,
  faHeart,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import axios from "axios";
import ReactOnRails from "react-on-rails";
import AddMediaModal from "./AddMediaModal";
import { FormModal } from "../../UI/components";
import toast from "react-hot-toast";
import pluralize from "pluralize";
import { classNames } from "../../../utils";
// var pluralize = require('pluralize')

/* 
    Media types represent the array of images, documents, files etc.
    that can be uploade, and stored remotely
*/
const media_types = [
  {
    id: "image",
    title: "Image",
    formats: "jpg, jpeg, png",
    supported_mime_types: ["image/jpeg", "image/jpg", "image/png"],
  },
  {
    id: "document",
    title: "Document",
    formats: "pdf",
    supported_mime_types: ["application/pdf"],
  },
  {
    id: "video",
    title: "Video",
    formats: "mp4",
    supported_mime_types: ["video/mp4"],
  },
  {
    id: "model",
    title: "3D Model",
    formats: "gbl, usdz",
    supported_mime_types: [""],
  },
];

/* 
  Charts are a special type of media type, and are not stored remotely. 
  A JSON or CSV file will be uploaded, converted to usable chart data and stored 
  in the database.
*/
const chart_types = [
  {
    id: "graph",
    title: "Graph",
    formats: "csv, json",
    supported_mime_types: ["application/json", "text/csv"],
  },
];

const media_icons = {
  document: faFilePdf,
  video: faTvRetro,
  model: faCube,
};

export default function ProductMedia({ product, onUpdate }) {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isAddMediaModalOpen, setIsAddMediaModalOpen] = useState(false);
  const [formModalChild, setFormModalChild] = useState(null);
  const [formModalTitle, setFormModalTitle] = useState("");

  const [mediaType, setMediaType] = useState(media_types[0]);
  // const [productMediaId, setProductMediaId] = useState(null);

  async function closePreviewModal() {
    setIsPreviewModalOpen(false);
  }

  async function closeAddMediaModal() {
    setIsAddMediaModalOpen(false);
  }

  const openPreviewModal = (media) => {
    setFormModalChild(<img src={media.url} alt="" />);
    setFormModalTitle(media.file_name);
    setIsPreviewModalOpen(true);
  };

  const openAddMediaModal = (id, type) => {
    if (type === "media") {
      setMediaType(media_types.find((media) => media.id === id));
    } else if (type === "chart") {
      setMediaType(chart_types.find((media) => media.id === id));
    }
    setIsAddMediaModalOpen(true);
  };

  const makePrimary = async (media) => {
    try {
      const result = await axios({
        method: "put",
        url: `media/${media.id}/make_primary`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });
      toast.success(result.data.success);

      // await onUpdate();
      // reload the page
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message ?? `Unable to set featured image ${mediaType}`);
    }
  };

  const onDelete = async (media) => {
    const mediaType = media.media_type ? "media" : "charts";

    if (
      confirm(
        `Are you sure you want to delete this ${media.media_type ? media.media_type : "chart"}?`
      )
    ) {
      try {
        const result = await axios({
          method: "delete",
          url: `${mediaType}/${media.id}`,
          responseType: "json",
          headers: ReactOnRails.authenticityHeaders(),
        });
        toast.success(result.data.success);

        await onUpdate();
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message ?? `Error Deleting Product ${mediaType}`);
      }
    }
  };

  const thumbs = (medias) =>
    medias.map((media, index) => (
      <div key={`${media.id}-${media.media_type}`} className="group relative">
        <div
          className={classNames(
            "relative flex h-32 w-32 cursor-pointer items-center justify-center overflow-hidden rounded-xl border-4  p-3 transition ",
            media.media_type === "image" && media.position === 1
              ? "border-success-500"
              : "border-gray-300 group-hover:border-indigo-500"
          )}
        >
          {media.media_type === "image" ? (
            <img
              src={media.url}
              alt=""
              className="w-auto object-scale-down transition group-hover:opacity-50"
            />
          ) : (
            <FontAwesomeIcon
              icon={media.media_type ? media_icons[media.media_type] : faChartLine}
              className="h-16 w-16 text-indigo-500 group-hover:opacity-50"
            />
          )}
          <div className="absolute inset-0 flex h-full w-full items-center justify-center gap-1 opacity-0 transition group-hover:opacity-100">
            {media.media_type === "image" && media.position !== 1 && (
              <button
                type="button"
                className="btn btn-success btn-icon"
                onClick={() => makePrimary(media)}
              >
                <FontAwesomeIcon icon={faHeart} />
              </button>
            )}

            <button
              type="button"
              className="btn btn-primary btn-icon"
              onClick={() => {
                if (media.media_type === "image") {
                  openPreviewModal(media);
                } else {
                  window.open(media.url, "_blank");
                }
              }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
            </button>

            <button
              type="button"
              className="btn btn-danger btn-icon"
              onClick={() => onDelete(media)}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        </div>
        <div className="mt-1 w-32 text-center text-xs">
          <span className="block overflow-hidden truncate font-medium text-gray-500">
            {media.media_type ? media.file_name : media.title}
          </span>
          <span>
            {media.id}-{media.media_type}
          </span>
        </div>
      </div>
    ));

  return (
    <>
      <div className="space-y-5 divide-y divide-gray-200 sm:space-y-4">
        <div className="space-y-5 divide-y divide-gray-200 sm:space-y-4">
          {media_types.map((media_type) => (
            <div key={media_type.id} className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-email">
                <div
                  className="text-base font-medium text-gray-900 sm:col-span-6 sm:text-sm sm:text-gray-700"
                  id="label-email"
                >
                  {pluralize(media_type.title)}
                </div>
                <div className="mt-3 flex items-start justify-start space-x-5">
                  <div>
                    <button
                      type="button"
                      className="h-32 overflow-hidden rounded-lg  border-2 border-dashed border-indigo-200 bg-white hover:border-indigo-400"
                      onClick={() => openAddMediaModal(media_type.id, "media")}
                    >
                      <div className="p-5">
                        <div className="flex flex-col items-center">
                          <div className="flex w-40 flex-1 items-center justify-center space-x-3">
                            <span className="text-sm font-medium text-indigo-700 hover:text-indigo-900">
                              Add {media_type.title}
                            </span>
                            <i className="fal fa-plus text-xl text-indigo-200"></i>
                          </div>
                          <div className="mt-1 rounded bg-indigo-100 px-2 py-0.5 text-xs uppercase text-indigo-500">
                            {media_type.formats}
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="mt-4 sm:col-span-4 sm:mt-0 sm:items-center">
                    <div className="relative flex flex-wrap gap-4 first-letter:items-start">
                      {thumbs(product.product_media.filter((x) => x.media_type == media_type.id))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {chart_types.map((chart_type) => (
            <div key={`${chart_type.id}-chart`} className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-email">
                <div
                  className="text-base font-medium text-gray-900 sm:col-span-6 sm:text-sm sm:text-gray-700"
                  id="label-email"
                >
                  {pluralize(chart_type.title)}
                </div>
                <div className="mt-3 flex space-x-5">
                  <div>
                    <button
                      type="button"
                      className="h-32 overflow-hidden rounded-lg border-2 border-dashed border-indigo-200 bg-white hover:border-indigo-400"
                      onClick={() => {
                        openAddMediaModal(chart_type.id, "chart");
                      }}
                    >
                      <div className="p-5">
                        <div className="flex flex-col items-center">
                          <div className="flex w-40 flex-1 items-center justify-center space-x-3">
                            <span className="text-sm font-medium text-indigo-700 hover:text-indigo-900">
                              Add {chart_type.title}
                            </span>
                            <i className="fal fa-plus text-xl text-indigo-200"></i>
                          </div>
                          <div className="mt-1 rounded bg-indigo-100 px-2 py-0.5 text-xs uppercase text-indigo-500">
                            {chart_type.formats}
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="mt-4 sm:col-span-4 sm:mt-0 sm:items-center">
                    <div className="relative flex flex-wrap items-start gap-4">
                      {thumbs(product.product_charts)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <FormModal
        title={formModalTitle}
        onCancel={closePreviewModal}
        isOpen={isPreviewModalOpen}
        children={formModalChild}
        size="xl"
      />
      <AddMediaModal
        closeModal={closeAddMediaModal}
        isOpen={isAddMediaModalOpen}
        mediaType={mediaType}
        product={product}
        onUpdate={onUpdate}
      />
    </>
  );
}
