import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { useDebouncedCallback } from "use-debounce";

const ProgressBar = ({ accepted = 0, total = 2 }) => {
  const progress = Math.round((accepted / total) * 100);
  const completed = progress === 100;

  return (
    <div>
      <h4 className="sr-only">Agreements accepted</h4>
      {!completed && (
        <p className="text-center text-xs font-medium text-gray-900">
          Agreement {accepted + 1} of {total}
        </p>
      )}
      {completed && (
        <p className="text-success-900 flex items-center justify-center text-xs font-medium">
          <CheckCircleIcon className="-ml-1 mr-3 h-6 w-6" aria-hidden="true" /> All agreements
          accepted
        </p>
      )}
      <div className="mt-4" aria-hidden="true">
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div
            className="h-2 rounded-full bg-indigo-600 transition-all duration-1000 ease-out"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export const BrandSellerAgreementModal = ({ isOpen, onClose, onConfirm, agreements }) => {
  const cancelButtonRef = useRef(null);
  const [agreementIndex, setAgreementIndex] = useState(0);
  const agreementsAccepted = agreementIndex > agreements.length - 1;
  const currentAgreement =
    agreementIndex < agreements.length
      ? agreements[agreementIndex]
      : agreements[agreements.length - 1];

  const debouncedConfirmation = useDebouncedCallback(() => {
    onConfirm();
  }, 1000);

  useEffect(() => {
    if (isOpen) {
      setAgreementIndex(0);
    }
  }, [isOpen]);

  const handleConfirm = () => {
    if (agreementIndex < agreements.length - 1) {
      setAgreementIndex(agreementIndex + 1);
    } else {
      setAgreementIndex(agreementIndex + 1);
      debouncedConfirmation();
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className="flex items-center justify-center">
                  <Dialog.Panel className="relative inline-block w-[420px] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:p-6 sm:align-middle">
                    <div className="my-5 mt-3 sm:mt-5">
                      {currentAgreement?.brand && (
                        <div className="mx-auto mb-4 flex items-center justify-center rounded-full bg-green-100 px-20">
                          <img
                            src={`https://res.cloudinary.com/proex/image/upload/v1/partbot/marketplace/brands/${currentAgreement?.brand
                              .replace(/ /g, "-")
                              .toLowerCase()}-logo.svg`}
                            className="h-full"
                            alt={currentAgreement?.brand}
                          />
                        </div>
                      )}
                      <Dialog.Title
                        as="h3"
                        className="text-center text-lg font-medium leading-6 text-gray-900"
                      >
                        Brand Seller Agreement
                      </Dialog.Title>
                      <div className="my-6 h-[330px] overflow-y-scroll rounded-lg border border-gray-300 p-4">
                        <div
                          className="prose prose-sm text-gray-500"
                          dangerouslySetInnerHTML={{ __html: currentAgreement?.text }}
                        ></div>
                      </div>
                    </div>
                    {agreements.length > 1 && (
                      <div className="mt-3">
                        <ProgressBar accepted={agreementIndex} total={agreements?.length} />
                      </div>
                    )}
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={onClose}
                        ref={cancelButtonRef}
                        disabled={agreementsAccepted}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn"
                        onClick={handleConfirm}
                        disabled={agreementsAccepted}
                      >
                        Accept
                      </button>
                    </div>
                  </Dialog.Panel>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
